import {
	IStockPointListRequest,
	IStockPointSaveRequest,
	ITenantStockPointRequest,
} from '../interface/StockPoint.interface';
import { getEntity, post } from './apiContext';

export const getStockPointConfig = async (request: IStockPointListRequest) => {
	return getEntity(`/api/settings/stockPoints`, request.setData, request.setIsLoading);
};

export const getTenantStockPointConfig = async (request: ITenantStockPointRequest) => {
	return getEntity(`/api/settings/stockPointConfig`, request.setData, request.setIsLoading);
};

export const postStockPointConfig = async (request: IStockPointSaveRequest) => {
	return post(`/api/settings/v2/stockPointConfig`, request);
};
