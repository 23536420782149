import axios from 'axios';
import { ILoginRequest } from '../interface/Auth.interface';

export const authenticateUser = async ({ domain, email, password }: ILoginRequest) => {
	return axios.post(
		`${process.env.REACT_APP_BASE_URL}/api/auth/signin`,
		{
			domain,
			email,
			password,
		},
		{
			headers: {
				'Content-Type': 'application/json',
			},
		},
	);
};
