import { IAuthenticateUserResponse } from '../interface/Auth.interface';
import { getAuthUser } from '../api/apiContext';
import { ROLE_NAME_MAPPING } from '../constants/roles.constant';
import { uploadMediaApi } from '../api/mediaResourceApi';
import { evaluate } from 'mathjs';

export const getDownloadPDF = (type: string, documentNumber: string | undefined) => {
	if (documentNumber) {
		const user: IAuthenticateUserResponse = getAuthUser();
		return `${process.env.REACT_APP_BASE_URL}/api/${type}/preview/${documentNumber}?token=${user?.accessToken}`;
	}
	return '#';
};

export const openPDFView = (type: string, documentNumber: string | undefined) => {
	const url = getDownloadPDF(type, documentNumber);
	if (url != '#') {
		window.open(url, '_blank');
	}
};

export function getRoleName(key: string): string | undefined {
	return ROLE_NAME_MAPPING[key as keyof typeof ROLE_NAME_MAPPING];
}

export function getLoggedInUser(): IAuthenticateUserResponse {
	const authUser: IAuthenticateUserResponse = JSON.parse(
		localStorage.getItem('authUser') as string,
	);
	return authUser;
}

export const uploadMedia = async (formData: FormData) => {
	return uploadMediaApi(formData);
};

export const parseExpression = (oprand1: number, operator: string, oprand2: number) => {
	operator = operator == 'x' ? '*' : '/';
	return Math.round(evaluate(`${oprand1} ${operator} ${oprand2}`));
};
