import React from 'react';
import PageWrapper from '../../components/layouts/PageWrapper/PageWrapper';
import Container from '../../components/layouts/Container/Container';
import { useTranslation } from 'react-i18next';

const CookiePolicyPage = () => {
	const { t } = useTranslation();
	return (
		<PageWrapper isProtectedRoute={false} title={t('Cookie Policy')}>
			<Container breakpoint={null} className='w-full sm:py-0 md:py-4'>
				<section className='-mx-4 lg:py-20'>
					<div className='mx-5 overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
						<div className='container mx-auto px-4'>
							<p>
								<h1 className='display-2 mb-0'>{t('Cookie Policy')}</h1>
							</p>
							<p>
								<h4 className='h4 mb-sm-4 mb-3'>
									{t('Information About Our Use of Cookies')}
								</h4>
							</p>
							<p className='mb-sm-4'>
								{t(
									'Our website, www.centrasync.se, uses cookies to distinguish you from other users of our website. This helps us to provide you with a good and safe experience when you browse our website and also allows us to improve our website and services.',
								)}
							</p>

							<h4 className='h4 mb-sm-4 mb-3'>{t('What Are Cookies?')}</h4>
							<p className='mb-sm-4 mb-3'>
								{t(
									'A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer or mobile device only if you agree to it.',
								)}{' '}
								{t(
									"Cookies contain information that is transferred to your device's hard drive.",
								)}{' '}
								{t(
									'We use tools such as Google Analytics, Google Tag Manager, and [Insert Other Relevant Tools] to help us provide the best possible experience to our users and customers.',
								)}{' '}
								{t(
									'These programs use both session cookies, which are cookies that expire once you close your web browser, and tracking cookies,',
								)}{' '}
								{t(
									'which expire after a longer period and allow us to deliver personalized content to users and improve our site based on usage data and analytics.',
								)}
							</p>

							<h4 className='h4 mb-sm-4 mb-3'>{t('The Cookies We Use')}</h4>
							<p className='mb-sm-4 mb-3'>
								{t(
									'The cookies we use can be split into the following categories:',
								)}
								<li>
									{t(
										'Strictly Necessary Cookies: These are cookies that are required for the operation of our website.',
									)}{' '}
									{t(
										'They include, for example, a cookie that enables us to remember your cookie tracking preferences.',
									)}
								</li>
								<li>
									{t(
										'Analytical / Performance Cookies: These allow us to recognize and and count the number of visitors and to see how visitors move around our website when they are using it.',
									)}{' '}
									{t(
										'This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.',
									)}{' '}
									{t('Google Analytics collects information anonymously.')}{' '}
									{t(
										'It reports website trends without identifying individual visitors.',
									)}{' '}
									{t(
										'We also use Hubspot cookies to track sessions and visits on our site and other media in order to optimise our marketing.',
									)}
								</li>
								<li>
									{t('Targeting cookies.')}{' '}
									{t(
										'We use Google Tag Manager to record your visit to our website, the pages you have visited and the links you have followed.',
									)}{' '}
									{t(
										'We use this information to make our website and our assets on other media more relevant to your interests.',
									)}
								</li>
							</p>

							<h4 className='h4 mb-sm-4 mb-3'>
								{t('How to opt out of cookies on our website')}
							</h4>
							<p className='mb-sm-4 mb-3'>
								{t(
									'You can opt out of the use of cookies on intergiro.com by selecting the ‘Decline’ option on our cookie consent tool which appears on your first visit to www.centrasync.se.',
								)}
							</p>
							<p>
								{t(
									'If you ‘Decline’ and block cookies, we will not be able to show you personalised content or offers which are tailored for your business.',
								)}{' '}
								{t(
									'Your information won’t be tracked when you visit this website however a single cookie will be used in your browser to remember your preference not to be tracked.',
								)}
							</p>
							<p>
								{t(
									'If you would like to change your cookie preferences at any time, please contact us at kundservice@centrasync.se',
								)}
							</p>
						</div>
					</div>
				</section>
			</Container>
		</PageWrapper>
	);
};

export default CookiePolicyPage;
