import React from 'react';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Container from '../components/layouts/Container/Container';
import UnderConstruction from '../components/utils/UnderConstruction';
import { useTranslation } from 'react-i18next';

const UnderConstructionPage = () => {
	const { t } = useTranslation();
	return (
		<PageWrapper title={t('Under Construction')}>
			<Container className='flex h-full items-center justify-center'>
				<UnderConstruction />
			</Container>
		</PageWrapper>
	);
};

export default UnderConstructionPage;
