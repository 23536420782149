import React from 'react';
import { LalegalContainer } from './Lalegal';

const PrivacyPolicy = () => {
	return (
		<LalegalContainer title='Privacy Policy | Linn Alfredsson'>
			<div className='max-xl:scroll-mt-offset min-h-dvh snap-center bg-white font-poppins'>
				<div className='grid min-h-dvh grid-cols-12'>
					<div className='relative col-span-12 flex h-full flex-col justify-center px-4 py-12 max-md:bg-white/50 max-md:px-4 md:px-6 xl:px-12 xl:py-24 2xl:px-24'>
						<div className='flex flex-col gap-8 pe-4'>
							<div className='text-3xl font-bold tracking-widest text-linn-500 xl:text-5xl 2xl:text-6xl'>
								Privacy Policy
							</div>
							<div className='text-lg !leading-relaxed md:max-w-screen-md xl:text-2xl 2xl:text-3xl'>
								LA Legal Website
								<div className='text-sm'>
									<b>Last Updated:</b> 20 Jan 2025
								</div>
							</div>
							<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
								This Privacy Policy explains how personal data is collected, used,
								and safeguarded when visiting <b>linnalfredsson.se</b>
								("Website") or using services provided, including booking
								consultations or enrolling in courses. By using this Website, you
								agree to the terms of this Privacy Policy.
							</div>

							<div className='grid grid-cols-12 gap-8 xl:gap-12'>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>1. Personal Data Collected</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										The following types of personal data may be collected:
										<ul className='list-inside list-disc'>
											<li>
												<b>Identification Data</b>, such as your name, email
												address, and any other details provided during
												bookings, inquiries, or subscriptions.
											</li>
											<li>
												<b>Technical Data</b>, including your IP address,
												browser type, and usage patterns collected through
												tracking technologies.
											</li>
											<li>
												<b>Communication Data</b>, such as the content of
												your inquiries, emails, and messages.
											</li>
										</ul>
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>2. How and Why Personal Data is Collected</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										Personal data is collected through various interactions with
										the Website to provide services, improve functionality, and
										comply with legal obligations.
										<br />
										<br />
										<b>The main ways personal data is collected include:</b>
										<ul className='list-inside list-disc'>
											<li>
												<b>Consultation Bookings:</b> Personal details such
												as your name and email address are collected to
												confirm your appointment and communicate about the
												service.
											</li>
											<li>
												<b>Inquiries and Contact Forms:</b> Data is gathered
												when contacting the company through email or forms
												to ensure responses are tailored to your inquiry.
											</li>
											<li>
												<b>Newsletter Subscriptions:</b> Data such as your
												name and email is collected when subscribing to
												newsletters or marketing materials to deliver
												relevant updates or promotional content.
											</li>
											<li>
												<b>Cookies and Tracking Technologies:</b> Technical
												data, such as IP address and usage patterns, is
												collected automatically during Website interactions
												to analyze user activity, maintain functionality,
												and improve user experience.
											</li>
										</ul>
										<br />
										<b>Why this data is collected:</b>
										<ul className='list-inside list-decimal'>
											<li>
												To ensure requested services, such as consultations,
												are properly scheduled and delivered.
											</li>
											<li>
												To communicate effectively and respond to inquiries
												in a timely and relevant manner.
											</li>
											<li>
												To optimize the Website’s design, performance, and
												functionality based on user activity.
											</li>
											<li>
												To fulfill legal and regulatory obligations, such as
												maintaining records for accounting or data
												protection compliance.
											</li>
										</ul>
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>3. Purpose of Data Collection</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										Personal data is used to:
										<br />
										<ul className='list-inside list-disc'>
											<li>
												Deliver booked consultations and related services.
											</li>
											<li>Respond to inquiries and send relevant updates.</li>
											<li>
												Analyze user activity and improve Website
												functionality.
											</li>
											<li>
												Fulfill obligations under applicable laws, such as
												maintaining business records.
											</li>
										</ul>
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>4. Legal Basis for Processing</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										Under the General Data Protection Regulation (GDPR),
										personal data is processed based on:
										<br />
										<ul className='list-inside list-decimal'>
											<li>
												<b>Contractual Necessity</b>, such as delivering
												consultations or responding to inquiries.
											</li>
											<li>
												<b>Legitimate Interests</b>, including Website
												performance optimization and communication
												management.
											</li>
											<li>
												<b>Consent</b>, for specific activities like
												marketing communications (if opted-in).
											</li>
										</ul>
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>5. Data Sharing</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										Personal data is not sold or rented. However, it may be
										shared with:
										<br />
										<ul className='list-inside list-disc'>
											<li>
												<b>Service Providers</b> that assist in hosting or
												managing the Website.
											</li>
											<li>
												<b>Legal Authorities</b> if required by law or
												necessary to protect legal rights.
											</li>
										</ul>
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>6. International Data Transfers</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										Certain data may be transferred internationally if service
										providers are located outside the European Economic Area
										(EEA). Appropriate safeguards, such as Standard Contractual
										Clauses (SCCs), ensure compliance with data protection laws.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>7. Cookies and Tracking Technologies</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										This Website uses cookies and similar tracking technologies
										to maintain sessions, remember preferences, and analyze
										usage patterns. Cookie preferences can be controlled through
										browser settings. Disabling certain cookies may impact the
										Website’s functionality.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>8. Data Retention</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										Personal data is retained only as long as necessary to
										fulfill the purposes outlined or to comply with legal
										obligations. For example:
										<br />
										<ul className='list-inside list-disc'>
											<li>
												Consultation data is retained for up to 2 years.
											</li>
											<li>
												Communication records are kept for 1 year unless
												needed for ongoing correspondence.
											</li>
										</ul>
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>9. Data Subject Rights</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										Under GDPR, data subjects have the following rights:
										<br />
										<ul className='list-inside list-disc'>
											<li>
												<b>Access:</b> Request a copy of personal data held.
											</li>
											<li>
												<b>Rectification:</b> Request corrections to
												inaccurate or incomplete data.
											</li>
											<li>
												<b>Erasure:</b> Request deletion of personal data,
												subject to legal obligations.
											</li>
											<li>
												<b>Restriction:</b> Limit how personal data is
												processed.
											</li>
											<li>
												<b>Portability:</b> Request personal data in a
												structured, commonly used format.
											</li>
											<li>
												<b>Objection:</b> Object to data processing for
												legitimate interests or marketing.
											</li>
											<li>
												<b>Withdraw Consent:</b> Revoke consent for specific
												processing activities at any time.
											</li>
										</ul>
										Requests related to these rights can be made by contacting{' '}
										<b>
											<a href='mailto:hello@lalegal.se'>hello@lalegal.se</a>
										</b>
										.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>10. Data Security</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										Appropriate technical and organizational measures are
										implemented to protect personal data from unauthorized
										access, alteration, or destruction. While every effort is
										made to ensure security, no system can guarantee absolute
										protection.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>11. Third-Party Links</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										The Website may contain links to external platforms, such as
										course providers. These platforms are governed by their own
										privacy policies, and <b>LA Legal</b>
										is not responsible for their practices.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>12. Updates to This Privacy Policy</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										This Privacy Policy may be updated to reflect changes in
										services, practices, or legal requirements. Updates will be
										posted on this page with a revised "Last Updated" date.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>13. Contact Information</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										For questions about this Privacy Policy or to exercise data
										protection rights:
										<br />
										<b>Email:</b>{' '}
										<a href='mailto:hello@lalegal.se' className='underline'>
											hello@lalegal.se
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</LalegalContainer>
	);
};

export default PrivacyPolicy;
