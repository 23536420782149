import React from 'react';
import { DeliveryMan1 } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const UnderConstruction = () => {
	const { t } = useTranslation();
	return (
		<div className='grid grid-cols-12 gap-4'>
			<div className='col-span-12 mb-16 flex flex-wrap justify-center gap-4'>
				<div className='flex basis-full justify-center'>
					<span className='text-5xl font-semibold'>{t('Under Construction')}</span>
				</div>
				<div className='flex basis-full justify-center'>
					<span className='text-zinc-500'>
						{t(
							'We are working to publish the page as soon as possible, if you want to support us, you can buy it now and share your suggestions with us.',
						)}
					</span>
				</div>
			</div>
			<div className='col-span-3' />
			<div className='col-span-6 flex justify-center'>
				<img src={DeliveryMan1 as string} alt='' className='max-h-[32rem]' />
			</div>
		</div>
	);
};

export default UnderConstruction;
