import React from 'react';
import AsideHeadPart from './_parts/AsideHead.part';
import Aside, { AsideBody } from '../../../components/layouts/Aside/Aside';
import Nav, { NavItem, NavSeparator, NavTitle } from '../../../components/layouts/Navigation/Nav';
import { subscriptionPages, tenantViewPages } from '../../../config/pages.config';
import AsideFooterPart from './_parts/AsideFooter.part';
import { useTranslation } from 'react-i18next';

const SubscriptionAsideTemplate = () => {
	const { t } = useTranslation();

	const subsPages = subscriptionPages.subscriptionMainPages.subPages;
	return (
		<Aside>
			<AsideHeadPart />
			<AsideBody>
				<Nav>
					<NavTitle>{t('Subscription')}</NavTitle>
					<NavItem
						text={subsPages.companyInformationPage.text}
						to={subsPages.companyInformationPage.to}
						icon={subsPages.companyInformationPage.icon}
						isEnd={false}
					/>
					<NavItem
						text={subsPages.licensesPage.text}
						to={subsPages.licensesPage.to}
						icon={subsPages.licensesPage.icon}
						isEnd={false}
					/>
					<NavItem
						text={subsPages.invoicesPage.text}
						to={subsPages.invoicesPage.to}
						icon={subsPages.invoicesPage.icon}
						isEnd={false}
					/>
					<NavItem
						text={subsPages.termsPage.text}
						to={subsPages.termsPage.to}
						icon={subsPages.termsPage.icon}
						isEnd={false}
					/>
					<NavItem
						text={subsPages.inviteFriendsPage.text}
						to={subsPages.inviteFriendsPage.to}
						icon={subsPages.inviteFriendsPage.icon}
						isEnd={false}
					/>
					<NavSeparator />
					<NavItem
						{...tenantViewPages.tenantPage.subPages.dashboardPage}
						text={t('Back to Operations')}
						icon='HeroArrowLeft'
					/>
				</Nav>
			</AsideBody>
			<AsideFooterPart />
		</Aside>
	);
};

export default SubscriptionAsideTemplate;
