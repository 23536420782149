import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { tenantDocPages } from '../../config/pages.config';
import DocumentationDocPage from '../../pages/documentation/Documentation.doc.page';

/**
 * Customers
 */
const CustomersDocPage = lazy(() => import('../../pages/documentation/Customers.doc.page'));

/**
 * Products
 */
const ArticlesDocPage = lazy(() => import('../../pages/documentation/products/Articles.doc.page'));
const ArticleGroupsDocPage = lazy(
	() => import('../../pages/documentation/products/ArticleGroups.doc.page'),
);
const CategoriesDocPage = lazy(
	() => import('../../pages/documentation/products/Categories.doc.page'),
);
const ProductListDocPage = lazy(
	() => import('../../pages/documentation/products/ProductList.doc.page'),
);
const ManufacturersDocPage = lazy(
	() => import('../../pages/documentation/products/Manufacturers.doc.page'),
);

/**
 * Sales
 */
const OffersDocPage = lazy(() => import('../../pages/documentation/sales/Offers.doc.page'));
const OrdersDocPage = lazy(() => import('../../pages/documentation/sales/Orders.doc.page'));
const InvoicesDocPage = lazy(() => import('../../pages/documentation/sales/Invoices.doc.page'));
const StatisticsDocPage = lazy(() => import('../../pages/documentation/sales/Statistics.doc.page'));

/**
 * Work Management
 */
const ProjectsDocPage = lazy(
	() => import('../../pages/documentation/workManagement/Projects.doc.page'),
);
const TasksDocPage = lazy(() => import('../../pages/documentation/workManagement/Tasks.doc.page'));
const TimeslipsDocPage = lazy(
	() => import('../../pages/documentation/workManagement/Timeslips.doc.page'),
);

const EventsDocPage = lazy(() => import('../../pages/documentation/Events.doc.page'));
const UsersDocPage = lazy(() => import('../../pages/documentation/Users.doc.page'));
const OrganizationDocPage = lazy(() => import('../../pages/documentation/Organization.doc.page'));

const DOC_PAGES = tenantDocPages.documentationPage.subPages;

const documentationRoutes: RouteProps[] = [
	{
		path: tenantDocPages.documentationPage.to,
		element: <DocumentationDocPage />, // Only redirect CustomersDocPage
	},

	/**
	 * Customers
	 */
	{
		path: DOC_PAGES.customersPage.to,
		element: <CustomersDocPage />,
	},

	/**
	 * Products
	 */
	{
		path: DOC_PAGES.productsPage.subPages.articlesPage.to,
		element: <ArticlesDocPage />,
	},
	{
		path: DOC_PAGES.productsPage.subPages.articleGroupsPage.to,
		element: <ArticleGroupsDocPage />,
	},
	{
		path: DOC_PAGES.productsPage.subPages.categoriesPage.to,
		element: <CategoriesDocPage />,
	},
	{
		path: DOC_PAGES.productsPage.subPages.productListsPage.to,
		element: <ProductListDocPage />,
	},
	{
		path: DOC_PAGES.productsPage.subPages.manufacturersPage.to,
		element: <ManufacturersDocPage />,
	},

	/**
	 * Sales
	 */
	{
		path: DOC_PAGES.salesPage.subPages.offersPage.to,
		element: <OffersDocPage />,
	},
	{
		path: DOC_PAGES.salesPage.subPages.ordersPage.to,
		element: <OrdersDocPage />,
	},
	{
		path: DOC_PAGES.salesPage.subPages.invoicesPage.to,
		element: <InvoicesDocPage />,
	},
	{
		path: DOC_PAGES.salesPage.subPages.statisticsPage.to,
		element: <StatisticsDocPage />,
	},

	/**
	 * Work Management
	 */
	{
		path: DOC_PAGES.workManagementPages.subPages.projectsPage.to,
		element: <ProjectsDocPage />,
	},
	{
		path: DOC_PAGES.workManagementPages.subPages.tasksPage.to,
		element: <TasksDocPage />,
	},
	{
		path: DOC_PAGES.workManagementPages.subPages.timeslipsPage.to,
		element: <TimeslipsDocPage />,
	},

	{
		path: DOC_PAGES.eventsPage.to,
		element: <EventsDocPage />,
	},
	{
		path: DOC_PAGES.usersPage.to,
		element: <UsersDocPage />,
	},
	{
		path: DOC_PAGES.organizationPage.to,
		element: <OrganizationDocPage />,
	},
];

export default documentationRoutes;
