import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import useSaveBtn from '../../../hooks/useSaveBtn';
import Button from '../../../components/ui/Button';
import { timezones } from '../../../constants/timezone.costant';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasFooter,
	OffCanvasFooterChild,
	OffCanvasHeader,
} from '../../../components/ui/OffCanvas';
import Card, { CardBody, CardFooter, CardFooterChild } from '../../../components/ui/Card';
import classNames from 'classnames';
import Label from '../../../components/form/Label';
import FieldWrap from '../../../components/form/FieldWrap';
import Icon from '../../../components/icon/Icon';
import Select from '../../../components/form/Select';
import Input from '../../../components/form/Input';
import Avatar from '../../../components/Avatar';
import Visible from '../../../components/utils/Visible';
import { useTranslation } from 'react-i18next';
import FormCancelBtn from '../../../components/utils/FormCancelBtn';
import { useAppDataContext } from '../../../context/appDataContext';
import SelectReact, { TSelectOption, TSelectOptions } from '../../../components/form/SelectReact';
import { uploadMedia } from '../../../services/utilService';
import { CUSTOMER_ROLES, ROLE_NAME_MAPPING } from '../../../constants/roles.constant';
import { IUser, IUserDataResponse } from '../../../interface/UserResource.interface';
import { createOrUpdateCustomerUser } from '../../../services/userService';
import { toast } from 'react-toastify';
import { APIRequestParams } from '../../../interface/APIRequestParams.interface';

const MEDIA_URL = process.env.REACT_APP_MEDIA_BASE_URL;

const CustomerUserEditPartial = ({
	isSubTab,
	inCanvas,
	isNew,
	isOpen,
	setIsOpen,
	apiRequestParams,
	user,
}: {
	isSubTab?: boolean;
	inCanvas?: boolean;
	isNew?: boolean;
	isOpen?: boolean;
	setIsOpen?: Dispatch<SetStateAction<boolean>>;
	apiRequestParams?: APIRequestParams;
	user?: IUser;
}) => {
	const { t } = useTranslation();

	const { uid } = useParams();
	const isNewItem = isNew || uid === 'new';
	const { customers, reloadData } = useAppDataContext();

	const notify = (promise: Promise<unknown>) =>
		toast
			.promise(
				promise
					.then((response) => response)
					.catch((error) => {
						const errorMessage =
							error?.response?.data[0] || 'An unexpected error occurred';
						toast.error(errorMessage);
						throw error;
					}),
				{
					pending: 'Saving..',
					success: 'User saved successfully..',
				},
			)
			.then(() => {
				reloadData();
				window.history.back();
			})
			.finally(() => {
				setIsSaving(false);
			});

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const formik = useFormik<IUserDataResponse>({
		enableReinitialize: true,
		initialValues: {
			id: user?.id ?? null,
			customerId: apiRequestParams?.customerId
				? apiRequestParams.customerId
				: user?.customer?.id,
			title: user?.title ?? '',
			name: user?.name ?? '',
			email: user?.email ?? '',
			city: user?.city ?? '',
			timezone: user?.timezone ?? '',
			phone: user?.phone ?? '',
			mobile: user?.mobile ?? '',
			userRole: user?.roles[0].name.toString() ?? '',
			profileImage: user?.profileImage?.s3Key ?? '',
			profileImageId: null,
		},
		onSubmit: (values) => {
			const savePromise = createOrUpdateCustomerUser(values);
			notify(savePromise);
		},
	});
	const { saveBtnText, saveBtnColor, saveBtnDisable } = useSaveBtn({
		isNewItem,
		isSaving,
		isDirty: formik.dirty,
	});

	const [file, setFile] = useState<string | undefined>();

	const handleProfilePictureChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files && files[0]) {
			setFile(URL.createObjectURL(files[0]));
			const formData = new FormData();
			formData.append('files[]', files[0]);
			const media = await uploadMedia(formData);
			if (media) {
				await formik.setFieldValue('profileImageId', media.id);
				await formik.setFieldValue('profileImage', media.s3Key);
			}
		}
	};

	function handleProfilePictureRemove() {
		setFile(undefined);
		formik.setFieldValue('profileImage', undefined);
	}

	const _TITLE = `${isNewItem ? t('New') : `${user?.name}'s`} ${t('User Details')}`;

	const _CONTENT = (
		<div className='grid grid-cols-12 gap-4'>
			<div className='col-span-12 lg:col-span-6'>
				<div className='flex gap-4'>
					<div className='flex-shrink-0'>
						<Avatar
							src={file || `${MEDIA_URL}/${formik.values.profileImage}`}
							className='!w-24'
							name={`${user?.name}`}
						/>
					</div>
					<div className='flex grow items-center'>
						<div className='w-full'>
							<Visible is={!formik.values.profileImage}>
								<Label
									htmlFor='profileImage'
									description={t(
										'At least 800x800 px recommended. JPG or PNG and GIF is allowed',
									)}>
									{t('Upload new image')}
								</Label>
								<Input
									id='profileImage'
									name='profileImage'
									type='file'
									value={formik.values.profileImage}
									onChange={handleProfilePictureChange}
									accept='image/png, image/jpeg'
								/>
							</Visible>
							<Visible is={!!formik.values.profileImage}>
								<Button
									variant='outline'
									color='red'
									icon='HeroTrash'
									onClick={handleProfilePictureRemove}>
									{t('Delete Profile Picture')}
								</Button>
							</Visible>
						</div>
					</div>
				</div>
			</div>

			{!apiRequestParams?.customerId && (
				<div className='col-span-12 lg:col-span-6 lg:col-start-1'>
					<Label htmlFor='customer'>{t('Customer')}</Label>
					<FieldWrap
						firstSuffix={<Icon icon='HeroUserGroup' className='mx-2' />}
						lastSuffix={<Icon icon='HeroChevronDown' className='mx-2' />}>
						<SelectReact
							options={customers as TSelectOptions}
							id='customer'
							name='customer'
							value={
								customers.find(
									(option) => option.value === formik.values.customerId,
								) as TSelectOption
							}
							className='ps-10'
							onChange={(value) => formik.setFieldValue('customer', value)}
						/>
					</FieldWrap>
				</div>
			)}
			<div className='col-span-12 lg:col-span-6'>
				<Label htmlFor='title'>{t('Title')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroTag' className='mx-2' />}>
					<Input
						id='title'
						name='title'
						onChange={formik.handleChange}
						value={formik.values.title}
						placeholder={t('Title')}
					/>
				</FieldWrap>
			</div>
			<div className='col-span-12 lg:col-span-6'>
				<Label htmlFor='name'>{t('Name')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroUser' className='mx-2' />}>
					<Input
						id='name'
						name='name'
						onChange={formik.handleChange}
						value={formik.values.name}
						placeholder={t('Name')}
					/>
				</FieldWrap>
			</div>
			<div className='col-span-12 lg:col-span-6'>
				<Label htmlFor='email'>{t('Email')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroAtSymbol' className='mx-2' />}>
					<Input
						id='email'
						name='email'
						onChange={formik.handleChange}
						value={formik.values.email}
						placeholder={t('Email')}
						autoComplete='email'
					/>
				</FieldWrap>
			</div>
			<div className='col-span-12 lg:col-span-6'>
				<Label htmlFor='city'>{t('City')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroMap' className='mx-2' />}>
					<Input
						id='city'
						name='city'
						onChange={formik.handleChange}
						value={formik.values.city}
						placeholder={t('City')}
						autoComplete='address-level2'
					/>
				</FieldWrap>
			</div>
			<div className='col-span-12 lg:col-span-6'>
				<Label htmlFor='timezone'>{t('Timezone')}</Label>
				<FieldWrap
					firstSuffix={<Icon icon='HeroClock' className='mx-2' />}
					lastSuffix={<Icon icon='HeroChevronDown' className='mx-2' />}>
					<Select
						name='options'
						onChange={formik.handleChange}
						value={formik.values.timezone}
						placeholder={t('Select Timezone')}>
						{timezones.map((i) => (
							<option key={i} value={i}>
								{i}
							</option>
						))}
					</Select>
				</FieldWrap>
			</div>
			<div className='col-span-12 lg:col-span-6'>
				<Label htmlFor='phone'>{t('Phone')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroPhone' className='mx-2' />}>
					<Input
						id='phone'
						name='phone'
						onChange={formik.handleChange}
						value={formik.values.phone}
						placeholder={t('Phone')}
						autoComplete='tel'
					/>
				</FieldWrap>
			</div>
			<div className='col-span-12 lg:col-span-6'>
				<Label htmlFor='mobile'>{t('Mobile')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroDevicePhoneMobile' className='mx-2' />}>
					<Input
						id='mobile'
						name='mobile'
						onChange={formik.handleChange}
						value={formik.values.mobile}
						placeholder={t('Mobile')}
						autoComplete='tel'
					/>
				</FieldWrap>
			</div>
			<div className='col-span-12 lg:col-span-6'>
				<Label htmlFor='privilege'>{t('Privilege')}</Label>
				<FieldWrap
					firstSuffix={<Icon icon='HeroShieldCheck' className='mx-2' />}
					lastSuffix={<Icon icon='HeroChevronDown' className='mx-2' />}>
					<Select
						id='privilege'
						name='privilege'
						onChange={formik.handleChange}
						value={formik.values.userRole}
						placeholder={t('Select Privilege')}>
						{Object.keys(CUSTOMER_ROLES).map((i) => (
							<option key={i} value={i}>
								{ROLE_NAME_MAPPING[i as keyof typeof ROLE_NAME_MAPPING]}
							</option>
						))}
					</Select>
				</FieldWrap>
			</div>
		</div>
	);
	const _SAVE_BTN = (
		<Button
			icon='HeroServer'
			variant='solid'
			color={saveBtnColor}
			isDisable={saveBtnDisable}
			onClick={() => formik.handleSubmit()}>
			{t(saveBtnText)}
		</Button>
	);

	if (inCanvas) {
		return (
			<OffCanvas
				isOpen={isOpen as boolean}
				setIsOpen={setIsOpen as Dispatch<SetStateAction<boolean>>}
				dialogClassName='max-md:max-w-full md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl'>
				<OffCanvasHeader>{_TITLE}</OffCanvasHeader>
				<OffCanvasBody>{_CONTENT}</OffCanvasBody>
				<OffCanvasFooter>
					<OffCanvasFooterChild>
						<Button
							onClick={() => {
								// @ts-ignore
								setIsOpen(false);
								formik.resetForm();
							}}
							color='red'>
							{t('Close')}
						</Button>
					</OffCanvasFooterChild>
					<OffCanvasFooterChild>{_SAVE_BTN}</OffCanvasFooterChild>
				</OffCanvasFooter>
			</OffCanvas>
		);
	}
	return (
		<Card className={classNames({ 'border border-zinc-500/25': isSubTab })}>
			<CardBody>{_CONTENT}</CardBody>
			<CardFooter>
				<CardFooterChild>
					<FormCancelBtn dirty={formik.dirty} />
				</CardFooterChild>
				<CardFooterChild>{_SAVE_BTN}</CardFooterChild>
			</CardFooter>
		</Card>
	);
};

export default CustomerUserEditPartial;
