const ROLES = {
	ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
	ROLE_TENANT_ADMIN: 'ROLE_TENANT_ADMIN',
	ROLE_TENANT_USER: 'ROLE_TENANT_USER',
	ROLE_CUSTOMER_ADMIN: 'ROLE_CUSTOMER_ADMIN',
	ROLE_CUSTOMER_USER: 'ROLE_CUSTOMER_USER',
};

export const ROLE_NAME_MAPPING = {
	ROLE_TENANT_ADMIN: 'Tenant Admin',
	ROLE_TENANT_USER: 'Tenant User',
	ROLE_CUSTOMER_ADMIN: 'Customer Admin',
	ROLE_CUSTOMER_USER: 'Customer User',
	ROLE_CONTACT_USER: 'Contact User',
};

export const TENANT_ROLES = {
	ROLE_TENANT_ADMIN: 'ROLE_TENANT_ADMIN',
	ROLE_TENANT_USER: 'ROLE_TENANT_USER',
};

export const CUSTOMER_ROLES = {
	ROLE_CUSTOMER_ADMIN: 'ROLE_CUSTOMER_ADMIN',
	ROLE_CUSTOMER_USER: 'ROLE_CUSTOMER_USER',
};

export default ROLES;
