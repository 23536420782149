import React, { ReactNode } from 'react';
import Container from '../../components/layouts/Container/Container';
import PageWrapper from '../../components/layouts/PageWrapper/PageWrapper';
import Button from '../../components/ui/Button';
import { HeroImg } from '../../assets/images';
import { TIcons } from '../../types/icons.type';
import Icon from '../../components/icon/Icon';
import { useTranslation } from 'react-i18next';
import useHash from '../../hooks/useHash';
import { customerViewPages } from '../../config/pages.config';
import { Link } from 'react-router-dom';

const IconContentPartial = ({
	icon,
	title,
	children,
}: {
	icon: TIcons;
	title: string;
	children: ReactNode;
}) => {
	return (
		<div>
			<div className='flex flex-wrap gap-4'>
				<div className='flex items-center justify-center'>
					<Icon icon={icon} size='text-5xl' color='blue' />
				</div>
				<div className='flex basis-full text-2xl font-extrabold'>{title}</div>
				<div className='flex basis-full'>
					<div className='text-zinc-600 dark:text-zinc-400'>{children}</div>
				</div>
			</div>
		</div>
	);
};

const ServiceProvidersPage = () => {
	const { t } = useTranslation();
	useHash();
	return (
		<PageWrapper isProtectedRoute={false} title={t('Service Based')}>
			<Container breakpoint={null} className='w-full'>
				<section
					id='top'
					className='-mx-4 -mt-[var(--header-height)] bg-indigo-500/5 pt-[var(--header-height)] lg:pt-[calc(var(--header-height)+6rem)]'>
					<div className='overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
						<div className='container mx-auto px-4'>
							<div className='grid grid-cols-12 items-center gap-14 lg:gap-32'>
								<div className='col-span-12 flex w-full flex-wrap gap-8 lg:col-span-6'>
									<h1 className='mb-4 max-w-2xl text-4xl font-extrabold !leading-snug text-zinc-700 dark:text-white md:text-5xl xl:text-6xl'>
										{t('Service Providers')}
									</h1>
								</div>
								<div className='col-span-12 flex w-full flex-col flex-wrap gap-4 lg:col-span-6'>
									<p className='max-w-3xl text-justify text-2xl font-extrabold !leading-relaxed text-zinc-700 dark:text-white'>
										{t('Streamline Your Services with Our Platform')}
									</p>
									<p className='max-w-3xl text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										{t(
											'Our platform is tailored to help service providers optimize workflows and deliver seamless experiences. With our solution, you can:',
										)}
									</p>
									<ul className='max-w-3xl list-inside list-disc text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										<li>
											<span className='font-extrabold'>
												{t('Manage Customer Information:')}{' '}
											</span>
											{t(
												'Keep customer data organized and easily accessible.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Sell Your Services:')}{' '}
											</span>
											{t(
												'Promote and sell offerings through an integrated webshop.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Handle Support & SLA Management:')}{' '}
											</span>
											{t(
												'Create, track, and manage support cases while meeting SLA commitments.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Automate Billing:')}{' '}
											</span>
											{t(
												'Simplify invoicing to save time and reduce errors.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('CRM Integration:')}{' '}
											</span>
											{t(
												'Strengthen customer relationships by tracking every interaction.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Project Management:')}{' '}
											</span>
											{t(
												'Stay organized with the ability to handle up to 6 active projects simultaneously.',
											)}
										</li>
									</ul>
									<p className='max-w-3xl text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										{t(
											'Focus on providing high-quality services while reducing administrative overhead.',
										)}
									</p>
									<p className='max-w-3xl text-justify text-2xl font-extrabold !leading-relaxed text-zinc-700 dark:text-white'>
										{t('For Your Customers')}
									</p>
									<ul className='max-w-3xl list-inside list-disc text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										<li>
											<span className='font-extrabold'>{t('Portal :')} </span>
											{t(
												'Customers can view and update their information, invoices, and ongoing projects.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Support Access:')}{' '}
											</span>
											{t(
												'Track support cases and stay informed about their status.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Easy Purchases:')}{' '}
											</span>
											{t(
												'Buy services through your integrated webshop with minimal hassle.',
											)}
										</li>
									</ul>
									<p className='max-w-3xl text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										{t(
											'This level of transparency fosters satisfaction and builds trust.',
										)}
									</p>
									<p className='max-w-3xl text-justify text-2xl font-extrabold !leading-relaxed text-zinc-700 dark:text-white'>
										{t('Why Choose Our Platform?')}
									</p>
									<p className='max-w-3xl text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										{t(
											'Our platform simplifies how service-based businesses sell services, manage support, and streamline billing.',
										)}{' '}
										{t(
											'With all the tools you need in one intuitive system, you deliver better results, save time, and build long-term customer relationships.',
										)}
									</p>
									<p className='max-w-3xl text-justify text-2xl font-extrabold !leading-relaxed text-zinc-700 dark:text-white'>
										{t(
											'Take the next step with our platform and elevate your service business today!',
										)}
									</p>
									<div className='flex basis-full'>
										<Link
											to={{
												pathname: customerViewPages.contactUsPage.to,
												search: '?subject=Demo&description=Please contact me to schedule a demo',
											}}>
											<Button variant='solid' size='xl'>
												{t('Start your project')}
											</Button>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className='-mx-4 bg-indigo-500/5'>
					<div className='overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
						<div className='container mx-auto px-4'>
							<div className='grid grid-cols-1 items-center gap-14 border-t border-zinc-500/50 py-20 lg:grid-cols-12 lg:gap-32'>
								<div className='flex w-full flex-wrap gap-8 lg:col-span-6'>
									<div className='grid grid-cols-1 items-center gap-4'>
										<div className='flex w-full flex-wrap'>
											<div className='mb-16 flex justify-center text-3xl font-extrabold dark:text-white md:text-4xl xl:text-5xl'>
												{t('Features')}
											</div>
										</div>
										<div className='flex w-full flex-wrap'>
											<div className='grid grid-cols-1 gap-16 sm:grid-cols-2 md:grid-cols-2'>
												<IconContentPartial
													icon='HeroRocketLaunch'
													title={t('Projekthantering')}>
													{t(
														'Koppla försäljning och arbetstid till projekt',
													)}
												</IconContentPartial>
												<IconContentPartial
													icon='HeroHandThumbUp'
													title={t('Ärendehantering')}>
													{t(
														'Skapa ärenden, koppla till projekt, kategorisera ärenden och rapportera arbetstid',
													)}
												</IconContentPartial>
												<IconContentPartial
													icon='HeroUsers'
													title={t('Arbetstid')}>
													{t(
														'Kategorisera och rapportera betald och icke betald arbetstid. Kunder kan logga in och se och godkänna upparbetad arbetstid innan ni fakturerar',
													)}
												</IconContentPartial>
												<IconContentPartial
													icon='HeroClock'
													title={t('Business intelligence')}>
													{t(
														'Lär er mer om er verksamhet genom att utforska data och övervaka nyckeltal',
													)}
												</IconContentPartial>
												<IconContentPartial
													icon='HeroClock'
													title={t('Händelser')}>
													{t(
														'För varje händelse i kundportalen skapas en logg som ni sedan kan ta del av',
													)}
												</IconContentPartial>
											</div>
										</div>
									</div>
								</div>
								<div className='block w-full lg:col-span-6'>
									<div className='w-full max-w-[96rem] rounded-3xl bg-gradient-to-r from-zinc-500/50 to-purple-950/25 p-6 sm:w-auto lg:w-[55vw]'>
										<img
											src={HeroImg}
											alt='Dashboard image'
											className='w-full rounded-xl object-cover lg:h-auto'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Container>
		</PageWrapper>
	);
};

export default ServiceProvidersPage;
