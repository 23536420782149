import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Button from '../components/ui/Button';
import { useAuth } from '../context/authContext';
import Input from '../components/form/Input';
import LogoTemplate from '../templates/layouts/Logo/Logo.template';
import FieldWrap from '../components/form/FieldWrap';
import Icon from '../components/icon/Icon';
import Validation from '../components/form/Validation';
import getRedirectUrlByRole from '../utils/getRedirectUrlByRole.util';
import { useTranslation } from 'react-i18next';
import { contractsPages } from '../config/pages.config';
import ThemeContext from '../context/themeContext';
import LANG from '../constants/lang.constant';
import ButtonGroup from '../components/ui/ButtonGroup';
import themeConfig from '../config/theme.config';

type TValues = {
	username: string;
	password: string;
};

const LoginPage = () => {
	const { onLogin, authUser } = useAuth();
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		localStorage.removeItem('authUser');
	}, []);

	useEffect(() => {
		if (authUser) navigate('/');
	});

	const [passwordShowStatus, setPasswordShowStatus] = useState<boolean>(false);

	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validate: (values: TValues) => {
			const errors: Partial<TValues> = {};

			if (!values.username) {
				errors.username = 'Required';
			}

			if (!values.password) {
				errors.password = 'Required';
			}

			return errors;
		},
		onSubmit: (values: TValues, { setFieldError }) => {
			onLogin({ domain: 'localhost', email: values.username, password: values.password })
				.then((data) => {
					// @ts-ignore
					navigate(getRedirectUrlByRole(data.roles) as string);
				})
				.catch((e: Error) => {
					if (e.cause === 'username') {
						setFieldError('username', e.message);
						setFieldError('password', e.message);
					}
					if (e.cause === 'password') setFieldError('password', e.message);
				});
		},
	});

	const onKeyDownEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.key === 'Enter') {
			e.preventDefault();
			formik.submitForm();
		}
	};

	const { i18n } = useTranslation();
	const { setLanguage, isLoadingLng } = useContext(ThemeContext);

	const langArray = Object.values(LANG);
	const [activeLang, setActiveLang] = useState(
		langArray.filter((key) => key.lng === i18n.language || themeConfig.language)[0],
	);
	useEffect(() => {
		setActiveLang(langArray.filter((key) => key.lng === i18n.language)[0]);
	}, [i18n.language, langArray]);

	return (
		<PageWrapper
			isProtectedRoute={false}
			className='bg-white dark:bg-inherit'
			title={t('Sign In')}>
			<div className='container mx-auto flex h-full items-center justify-center'>
				<div className='flex max-w-sm flex-col gap-8'>
					<div>
						<Link to='/' aria-label={t('Logo')}>
							<LogoTemplate className='h-12' />
						</Link>
					</div>
					<div>
						<span className='text-4xl font-semibold'>{t('Sign in')}</span>
					</div>
					<form className='flex flex-col gap-4' noValidate>
						<div
							className={classNames({
								'mb-2': !formik.isValid,
							})}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.username}
								invalidFeedback={formik.errors.username}
								validFeedback='Good'>
								<FieldWrap
									firstSuffix={<Icon icon='HeroEnvelope' className='mx-2' />}>
									<Input
										dimension='lg'
										id='username'
										autoComplete='username'
										name='username'
										placeholder={t('Email or username')}
										value={formik.values.username}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onKeyDown={onKeyDownEnter}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div
							className={classNames({
								'mb-2': !formik.isValid,
							})}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.password}
								invalidFeedback={formik.errors.password}
								validFeedback='Good'>
								<FieldWrap
									firstSuffix={<Icon icon='HeroKey' className='mx-2' />}
									lastSuffix={
										<Icon
											className='mx-2 cursor-pointer'
											icon={passwordShowStatus ? 'HeroEyeSlash' : 'HeroEye'}
											onClick={() => {
												setPasswordShowStatus(!passwordShowStatus);
											}}
										/>
									}>
									<Input
										dimension='lg'
										type={passwordShowStatus ? 'text' : 'password'}
										autoComplete='current-password'
										id='password'
										name='password'
										placeholder={t('Password')}
										value={formik.values.password}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										onKeyDown={onKeyDownEnter}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div>
							<Button
								size='lg'
								variant='solid'
								className='w-full font-semibold'
								onClick={() => formik.handleSubmit()}>
								{t('Sign in')}
							</Button>
						</div>
					</form>
					<div>
						<div className='text-zinc-500'>
							{t('By entering this site you accept our')} “
							<Link
								to={
									contractsPages.contractsMainPages.subPages
										.termsAndConditionsPages.to
								}>
								<u>{t('terms and conditions')}</u>
							</Link>
							"
						</div>
					</div>
					<div>
						<span className='flex gap-2 text-sm'>
							<span className='text-zinc-400 dark:text-zinc-600'>
								{t('Don’t have an account?')}
							</span>
							<Link to='/' className='hover:text-inherit'>
								{t('Sign up')}
							</Link>
						</span>
					</div>
					<div className='col-span-1 flex flex-col gap-4'>
						<ButtonGroup size='xs'>
							{langArray.map((item) => (
								<Button
									className='me-2 !px-0'
									icon={item.icon}
									isActive={activeLang.lng === item.lng}
									key={item.lng}
									isLoading={isLoadingLng}
									onClick={() => setLanguage(item.lng)}>
									{item.text}
								</Button>
							))}
						</ButtonGroup>
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export default LoginPage;
