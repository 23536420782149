import React, {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { ILabelValue } from '../interface/LabelValue.interface';
import { get, getAuthUser } from '../api/apiContext';
import { ICurrency } from '../interface/Currency.interface';
import { IDeliveryAddress } from '../interface/DeliveryAddress.interface';
import { useTranslation } from 'react-i18next';

export interface IAppDataContextProps {
	reloadData: () => Promise<void>;
	customers: ILabelValue[];
	setCustomers: Dispatch<SetStateAction<ILabelValue[]>>;
	tenantUsers: ILabelValue[];
	setTenantUsers: Dispatch<SetStateAction<ILabelValue[]>>;
	customerUsers: ILabelValue[];
	setCustomerUsers: Dispatch<SetStateAction<ILabelValue[]>>;
	articles: ILabelValue[];
	setArticles: Dispatch<SetStateAction<ILabelValue[]>>;
	articleGroups: ILabelValue[];
	setArticleGroups: Dispatch<SetStateAction<ILabelValue[]>>;
	projects: ILabelValue[];
	setProjects: Dispatch<SetStateAction<ILabelValue[]>>;
	manufacturers: ILabelValue[];
	setManufacturers: Dispatch<SetStateAction<ILabelValue[]>>;
	termsOfDeliveries: ILabelValue[];
	setTermsOfDeliveries: Dispatch<SetStateAction<ILabelValue[]>>;
	wayOfDeliveries: ILabelValue[];
	setWayOfDeliveries: Dispatch<SetStateAction<ILabelValue[]>>;
	termsOfPayments: ILabelValue[];
	setTermsOfPayments: Dispatch<SetStateAction<ILabelValue[]>>;
	fetchAppData: () => Promise<void>;
	projectTemplates: ILabelValue[];
	setProjectTemplates: Dispatch<SetStateAction<ILabelValue[]>>;
	taskCategories: ILabelValue[];
	setTaskCategories: Dispatch<SetStateAction<ILabelValue[]>>;
	taskPriorities: ILabelValue[];
	setTaskPriorities: Dispatch<SetStateAction<ILabelValue[]>>;
	tasks: ILabelValue[];
	setTasks: Dispatch<SetStateAction<ILabelValue[]>>;
	timeSlipTypes: ILabelValue[];
	setTimeSlipTypes: Dispatch<SetStateAction<ILabelValue[]>>;
	currencies: ICurrency[];
	setCurrencies: Dispatch<SetStateAction<ICurrency[]>>;
	adminUsers: ILabelValue[];
	setAdminUsers: Dispatch<SetStateAction<ILabelValue[]>>;
	deliveryAddress: IDeliveryAddress[];
	setDeliveryAddress: Dispatch<SetStateAction<IDeliveryAddress[]>>;
	stockPoints: ILabelValue[];
	setStockPoints: Dispatch<SetStateAction<ILabelValue[]>>;
	entityTypes: ILabelValue[];
	setEntityTypes: Dispatch<SetStateAction<ILabelValue[]>>;
}

const AppDataContext = createContext<IAppDataContextProps>({} as IAppDataContextProps);

interface IAppDataProviderProps {
	children: ReactNode;
}

export const useAppDataContext = () => {
	const context = useContext(AppDataContext);
	if (!context) {
		throw new Error('useAppDataContext must be used within an AppDataProvider');
	}
	return context;
};

export const AppDataProvider: React.FC<IAppDataProviderProps> = ({ children }) => {
	const [customers, setCustomers] = useState<ILabelValue[]>([]);
	const [tenantUsers, setTenantUsers] = useState<ILabelValue[]>([]);
	const [customerUsers, setCustomerUsers] = useState<ILabelValue[]>([]);
	const [articles, setArticles] = useState<ILabelValue[]>([]);
	const [articleGroups, setArticleGroups] = useState<ILabelValue[]>([]);
	const [projects, setProjects] = useState<ILabelValue[]>([]);
	const [manufacturers, setManufacturers] = useState<ILabelValue[]>([]);
	const [termsOfDeliveries, setTermsOfDeliveries] = useState<ILabelValue[]>([]);
	const [wayOfDeliveries, setWayOfDeliveries] = useState<ILabelValue[]>([]);
	const [termsOfPayments, setTermsOfPayments] = useState<ILabelValue[]>([]);
	const [projectTemplates, setProjectTemplates] = useState<ILabelValue[]>([]);
	const [taskCategories, setTaskCategories] = useState<ILabelValue[]>([]);
	const [taskPriorities, setTaskPriorities] = useState<ILabelValue[]>([]);
	const [tasks, setTasks] = useState<ILabelValue[]>([]);
	const [timeSlipTypes, setTimeSlipTypes] = useState<ILabelValue[]>([]);
	const [currencies, setCurrencies] = useState<ICurrency[]>([]);
	const [adminUsers, setAdminUsers] = useState<ILabelValue[]>([]);
	const [deliveryAddress, setDeliveryAddress] = useState<IDeliveryAddress[]>([]);
	const [stockPoints, setStockPoints] = useState<ILabelValue[]>([]);
	const [entityTypes, setEntityTypes] = useState<ILabelValue[]>([]);

	const { i18n } = useTranslation();
	const collator = new Intl.Collator(i18n.language, { sensitivity: 'base' });
	const sortingFn = (a: string, b: string) => collator.compare(a, b);

	const fetchData = <T,>(url: string, setData: (data: T[]) => void): void => {
		const authUser = getAuthUser();
		if (authUser && authUser?.accessToken) {
			get(url).then((response) => {
				const data = response.data as ILabelValue[];
				setData(
					data
						.slice()
						.sort((a, b) => sortingFn(a?.label as string, b?.label as string)) as T[],
				);
			});
		}
	};

	const fetchCustomerData = async (
		url: string,
		setData: (data: ILabelValue[]) => void,
	): Promise<void> => {
		const authUser = getAuthUser();
		if (authUser && authUser?.accessToken) {
			const response = await get(url);
			const data = response.data as ILabelValue[];
			data?.forEach((customer: ILabelValue) => {
				if (customer.favorite) {
					customer.label = `★ ${customer.label}`;
				}
			});
			setData(data.slice().sort((a, b) => sortingFn(a?.label as string, b?.label as string)));
		}
	};

	const fetchAppData = useCallback(async () => {
		fetchCustomerData(`/api/label-value/customers`, setCustomers);
		fetchData<ILabelValue>(`/api/label-value/tenant-users`, setTenantUsers);
		fetchData<ILabelValue>(`/api/label-value/customer-users`, setCustomerUsers);
		fetchData<ILabelValue>(`/api/label-value/articles`, setArticles);
		fetchData<ILabelValue>(`/api/label-value/article-groups`, setArticleGroups);
		fetchData<ILabelValue>(`/api/label-value/projects`, setProjects);
		fetchData<ILabelValue>(`/api/label-value/manufacturers`, setManufacturers);
		fetchData<ILabelValue>(`/api/label-value/task-categories`, setTaskCategories);
		fetchData<ILabelValue>(`/api/label-value/task-priorities`, setTaskPriorities);
		fetchData<ILabelValue>(`/api/label-value/task`, setTasks);
		fetchData<ILabelValue>(`/api/label-value/time-slip-types`, setTimeSlipTypes);
		fetchData<ICurrency>(`/api/currency`, setCurrencies);
		fetchData<ILabelValue>(`/api/label-value/admin-users`, setAdminUsers);
		fetchData<ILabelValue>(`/api/label-value/project-template`, setProjectTemplates);
		fetchData<ILabelValue>(`/api/label-value/terms-of-deliveries`, setTermsOfDeliveries);
		fetchData<ILabelValue>(`/api/label-value/way-of-deliveries`, setWayOfDeliveries);
		fetchData<ILabelValue>(`/api/label-value/terms-of-payments`, setTermsOfPayments);
		fetchData<IDeliveryAddress>(`/api/delivery-address`, setDeliveryAddress);
		fetchData<ILabelValue>(`/api/label-value/stock-points`, setStockPoints);
		fetchData<ILabelValue>(`/api/label-value/entity-types`, setEntityTypes);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language]);

	useEffect(() => {
		fetchAppData();
	}, [fetchAppData]);

	// New reloadData method calls fetchAppData
	const reloadData = useCallback(async () => {
		try {
			await fetchAppData();
			console.log('App data reloaded successfully');
		} catch (error) {
			console.error('Failed to reload app data:', error);
		}
	}, [fetchAppData]);

	const values: IAppDataContextProps = useMemo(
		() => ({
			customers,
			setCustomers,
			tenantUsers,
			setTenantUsers,
			customerUsers,
			setCustomerUsers,
			articles,
			setArticles,
			articleGroups,
			setArticleGroups,
			projects,
			setProjects,
			manufacturers,
			setManufacturers,
			fetchAppData,
			reloadData,
			termsOfDeliveries,
			setTermsOfDeliveries,
			wayOfDeliveries,
			setWayOfDeliveries,
			termsOfPayments,
			setTermsOfPayments,
			projectTemplates,
			setProjectTemplates,
			taskCategories,
			setTaskCategories,
			taskPriorities,
			setTaskPriorities,
			tasks,
			setTasks,
			timeSlipTypes,
			setTimeSlipTypes,
			currencies,
			setCurrencies,
			adminUsers,
			setAdminUsers,
			deliveryAddress,
			setDeliveryAddress,
			stockPoints,
			setStockPoints,
			entityTypes,
			setEntityTypes,
		}),
		[
			customers,
			tenantUsers,
			customerUsers,
			articles,
			articleGroups,
			projects,
			manufacturers,
			fetchAppData,
			reloadData,
			termsOfDeliveries,
			wayOfDeliveries,
			termsOfPayments,
			projectTemplates,
			taskCategories,
			taskPriorities,
			tasks,
			timeSlipTypes,
			currencies,
			adminUsers,
			deliveryAddress,
			stockPoints,
			entityTypes,
		],
	);

	return <AppDataContext.Provider value={values}>{children}</AppDataContext.Provider>;
};

export default AppDataContext;
