import {
	createOrUpdateProjectApi,
	getActiveProjects as getActiveProjectsApi,
	getNextProjectNumberApi,
	getProject as getProjectApi,
	postProjectNumberConfigApi,
} from '../api/projectResourceApi';
import {
	IFetchActiveProjectsRequest,
	IFetchNextProjectNumberRequest,
	IFetchProjectRequest,
	TProjectDataResponse,
} from '../interface/ProjectResource.interface';
import { APIRequestParams } from '../interface/APIRequestParams.interface';

export const fetchProject = async ({
	projectId,
	setProjectData,
	setIsLoading,
}: IFetchProjectRequest) => {
	return getProjectApi({ projectId, setProjectData, setIsLoading });
};

export const fetchActiveProjects = async (
	request: IFetchActiveProjectsRequest,
	apiRequestParams?: APIRequestParams,
) => {
	return getActiveProjectsApi(request, apiRequestParams);
};

export const fetchNextProjectNumber = async (request: IFetchNextProjectNumberRequest) => {
	return getNextProjectNumberApi(request);
};

export const postProjectNumberConfig = async (projectNumber: number) => {
	return postProjectNumberConfigApi(projectNumber);
};

export const createOrUpdateProject = async (form: TProjectDataResponse) => {
	return createOrUpdateProjectApi(form);
};
