import React from 'react';
import AsideHeadPart from './_parts/AsideHead.part';
import Aside, { AsideBody } from '../../../components/layouts/Aside/Aside';
import Nav, {
	NavCollapse,
	NavItem,
	NavSeparator,
	NavTitle,
} from '../../../components/layouts/Navigation/Nav';
import { tenantDocPages, tenantViewPages } from '../../../config/pages.config';
import AsideFooterPart from './_parts/AsideFooter.part';
import { useTranslation } from 'react-i18next';

const DocumentationAsideTemplate = () => {
	const { t } = useTranslation();

	const tenantPages = tenantDocPages.documentationPage.subPages;
	return (
		<Aside>
			<AsideHeadPart />
			<AsideBody>
				<Nav>
					<NavTitle>{t('Documentation')}</NavTitle>
					<NavItem
						text={tenantPages.customersPage.text}
						to={tenantPages.customersPage.to}
						icon={tenantPages.customersPage.icon}
						isEnd={false}
					/>
					<NavCollapse
						text={tenantPages.productsPage.text}
						to={tenantPages.productsPage.to}
						icon={tenantPages.productsPage.icon}>
						<NavItem
							{...tenantPages.productsPage.subPages.articlesPage}
							isEnd={false}
						/>
						<NavItem
							{...tenantPages.productsPage.subPages.articleGroupsPage}
							isEnd={false}
						/>
						<NavItem
							{...tenantPages.productsPage.subPages.categoriesPage}
							isEnd={false}
						/>
						<NavItem
							{...tenantPages.productsPage.subPages.productListsPage}
							isEnd={false}
						/>
						<NavItem
							{...tenantPages.productsPage.subPages.manufacturersPage}
							isEnd={false}
						/>
					</NavCollapse>
					<NavCollapse
						text={tenantPages.salesPage.text}
						to={tenantPages.salesPage.to}
						icon={tenantPages.salesPage.icon}>
						<NavItem {...tenantPages.salesPage.subPages.offersPage} isEnd={false} />
						<NavItem {...tenantPages.salesPage.subPages.ordersPage} isEnd={false} />
						<NavItem {...tenantPages.salesPage.subPages.invoicesPage} isEnd={false} />
						<NavItem {...tenantPages.salesPage.subPages.statisticsPage} isEnd={false} />
					</NavCollapse>
					<NavCollapse
						text={tenantPages.workManagementPages.text}
						to={tenantPages.workManagementPages.to}
						icon={tenantPages.workManagementPages.icon}>
						<NavItem
							{...tenantPages.workManagementPages.subPages.projectsPage}
							isEnd={false}
						/>
						<NavItem
							{...tenantPages.workManagementPages.subPages.tasksPage}
							isEnd={false}
						/>
						<NavItem
							{...tenantPages.workManagementPages.subPages.timeslipsPage}
							isEnd={false}
						/>
					</NavCollapse>
					<NavItem {...tenantPages.eventsPage} isEnd={false} />
					<NavItem {...tenantPages.usersPage} isEnd={false} />
					<NavItem {...tenantPages.organizationPage} isEnd={false} />
					<NavSeparator />
					<NavItem
						{...tenantViewPages.tenantPage.subPages.dashboardPage}
						text={t('Back to Operations')}
						icon='HeroArrowLeft'
					/>
				</Nav>
			</AsideBody>
			<AsideFooterPart />
		</Aside>
	);
};

export default DocumentationAsideTemplate;
