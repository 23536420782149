import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { subscriptionPages } from '../../config/pages.config';

const SubscriptionSubsPage = lazy(() => import('../../pages/subscription/Subscription.subs.page'));
const CompanyInformationSubsPage = lazy(
	() => import('../../pages/subscription/CompanyInformation.subs.page'),
);
const InviteFriendsSubsPage = lazy(
	() => import('../../pages/subscription/InviteFriends.subs.page'),
);
const InvoicesSubsPage = lazy(() => import('../../pages/subscription/invoice/Invoices.subs.page'));
const InvoiceViewSubsPage = lazy(
	() => import('../../pages/subscription/invoice/InvoiceView.subs.page'),
);
const LicensesSubsPage = lazy(() => import('../../pages/subscription/Licenses.subs.page'));
const TermsSubsPage = lazy(() => import('../../pages/subscription/Terms.subs.page'));

const subsRoutes: RouteProps[] = [
	{
		path: subscriptionPages.subscriptionMainPages.to,
		element: <SubscriptionSubsPage />,
	},
	{
		path: subscriptionPages.subscriptionMainPages.subPages.companyInformationPage.to,
		element: <CompanyInformationSubsPage />,
	},
	{
		path: subscriptionPages.subscriptionMainPages.subPages.inviteFriendsPage.to,
		element: <InviteFriendsSubsPage />,
	},
	{
		path: subscriptionPages.subscriptionMainPages.subPages.invoicesPage.to,
		element: <InvoicesSubsPage />,
	},
	{
		path: `${subscriptionPages.subscriptionMainPages.subPages.invoicesPage.to}/:id`,
		element: <InvoiceViewSubsPage />,
	},
	{
		path: subscriptionPages.subscriptionMainPages.subPages.licensesPage.to,
		element: <LicensesSubsPage />,
	},
	{
		path: subscriptionPages.subscriptionMainPages.subPages.termsPage.to,
		element: <TermsSubsPage />,
	},
];

export default subsRoutes;
