import React, {
	createContext,
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import { objTimeslipsViewTypes, TViewID } from '../types/timeslipsView.type';
import { ITimeslipFilter } from '../interface/TimeslipResourceInterface';
import { ITaskFilter } from '../interface/TaskResourceInterface';
import { IProjectFilter } from '../interface/ProjectResource.interface';
import { IEventFilter } from '../interface/EventResource.interface';
import { IUserFilter } from '../interface/UserResource.interface';
import { IDashboardFilter } from '../interface/DashboardResource.interface';
import { IOfferFilter } from '../interface/OfferResource.interface';
import { IOrderFilter } from '../interface/OrderResource.interface';
import { IInvoiceFilter } from '../interface/Invoice.interface';
import { IStatisticsFilter } from '../interface/StatisticsResource.interface';
import dayjs from 'dayjs';
import { ICustomerFilter } from '../interface/Customer.interface';
import { TPeriodKEY } from '../constants/periods.constant';

export const FILTER_INITIAL_STATES = {
	STATISTICS: {
		period: 'MONTH',
		date: {
			startDate: dayjs().startOf('months').toDate(),
			endDate: dayjs().endOf('months').toDate(),
		},
	} as IStatisticsFilter,
	CUSTOMERS: {
		hidden: false,
		sync: false,
		type: 'all',
		accountManagerId: null,
	} as ICustomerFilter,
	USER: {
		userType: 'TENANT',
		status: 'ACTIVE',
		customerId: null,
	} as IUserFilter,
};

export interface ITSettingsContextProps {
	timeslipViewType: TViewID;
	setTimeslipViewType: Dispatch<SetStateAction<TViewID>>;
	timeslipFilter: ITimeslipFilter;
	setTimeslipFilter: Dispatch<SetStateAction<ITimeslipFilter>>;
	taskFilter: ITaskFilter;
	setTaskFilter: Dispatch<SetStateAction<ITaskFilter>>;
	projectFilter: IProjectFilter;
	setProjectFilter: Dispatch<SetStateAction<IProjectFilter>>;
	eventFilter: IEventFilter;
	setEventFilter: Dispatch<SetStateAction<IEventFilter>>;
	userFilter: IUserFilter;
	setUserFilter: Dispatch<SetStateAction<IUserFilter>>;
	offerFilter: IOfferFilter;
	setOfferFilter: Dispatch<SetStateAction<IOfferFilter>>;
	orderFilter: IOrderFilter;
	setOrderFilter: Dispatch<SetStateAction<IOrderFilter>>;
	invoiceFilter: IInvoiceFilter;
	setInvoiceFilter: Dispatch<SetStateAction<IInvoiceFilter>>;
	dashboardFilter: IDashboardFilter;
	setDashboardFilter: Dispatch<SetStateAction<IDashboardFilter>>;
	statisticsFilter: IStatisticsFilter;
	setStatisticsFilter: Dispatch<SetStateAction<IStatisticsFilter>>;
	customerFilter: ICustomerFilter;
	setCustomerFilter: Dispatch<SetStateAction<ICustomerFilter>>;
	lastFetchTime: string;
	setLastFetchTime: Dispatch<SetStateAction<string>>;
	isLoadingRefresh: boolean;
	setIsLoadingRefresh: Dispatch<SetStateAction<boolean>>;
	activePeriod: TPeriodKEY;
	setActivePeriod: Dispatch<SetStateAction<TPeriodKEY>>;
	pageSize: number;
	setPageSize: Dispatch<SetStateAction<number>>;
}

const SettingsContext = createContext<ITSettingsContextProps>({} as ITSettingsContextProps);

interface ISettingsContextProviderProps {
	children: ReactNode;
}

export const SettingsContextProvider: FC<ISettingsContextProviderProps> = ({ children }) => {
	const [timeslipViewType, setTimeslipViewType] = useState<TViewID>(
		objTimeslipsViewTypes.list.id,
	);

	const [timeslipFilter, setTimeslipFilter] = useState<ITimeslipFilter>({
		userId: undefined,
		customerId: undefined,
		timeslipTypeId: undefined,
		taskId: undefined,
		projectId: undefined,
	});

	const [taskFilter, setTaskFilter] = useState<ITaskFilter>({
		taskType: 'ALL',
		status: 'OPEN',
		customerId: undefined,
		tenantId: undefined,
		assignedUserId: undefined,
		reporterUserId: undefined,
		projectId: undefined,
	});

	const [projectFilter, setProjectFilter] = useState<IProjectFilter>({
		projectType: 'ALL',
		status: 'ONGOING',
		label: '',
	});

	const [eventFilter, setEventFilter] = useState<IEventFilter>({
		operation: 'ALL',
		entityType: undefined,
	});

	const [userFilter, setUserFilter] = useState<IUserFilter>(FILTER_INITIAL_STATES.USER);

	const [offerFilter, setOfferFilter] = useState<IOfferFilter>({
		articleGroupId: null,
		articleNumber: null,
		customerId: null,
		endDate: null,
		manufacturer: null,
		offerType: 'all',
		ourReference: null,
		projectNumber: null,
		startDate: null,
		yourReference: null,
	});

	const [orderFilter, setOrderFilter] = useState<IOrderFilter>({
		customerId: null,
		type: 'all',
		startDate: null,
		endDate: null,
		ourReference: null,
		articleNumber: null,
		projectNumber: null,
		yourReference: null,
		articleGroupId: null,
		manufacturer: null,
	});

	const [invoiceFilter, setInvoiceFilter] = useState<IInvoiceFilter>({
		customerId: null,
		type: 'all',
		startDate: null,
		endDate: null,
		ourReference: null,
		yourReference: null,
	});

	const [statisticsFilter, setStatisticsFilter] = useState<IStatisticsFilter>(
		FILTER_INITIAL_STATES.STATISTICS,
	);

	const [dashboardFilter, setDashboardFilter] = useState<IDashboardFilter>({
		ourReference: undefined,
		articleNumber: undefined,
		articleGroupId: undefined,
		projectNumber: undefined,
		manufacturer: undefined,
		customerNumber: undefined,
	});

	const [customerFilter, setCustomerFilter] = useState<ICustomerFilter>(
		FILTER_INITIAL_STATES.CUSTOMERS,
	);

	const [lastFetchTime, setLastFetchTime] = useState<string>('');
	const [isLoadingRefresh, setIsLoadingRefresh] = useState<boolean>(false);

	const [activePeriod, setActivePeriod] = useState<TPeriodKEY>(
		localStorage.getItem('fyr_activePeriod')
			? (localStorage.getItem('fyr_activePeriod') as TPeriodKEY)
			: 'MONTH',
	);
	useLayoutEffect(() => {
		localStorage.setItem('fyr_activePeriod', activePeriod);
	}, [activePeriod]);

	const [pageSize, setPageSize] = useState<number>(
		localStorage.getItem('fyr_pageSize') ? Number(localStorage.getItem('fyr_pageSize')) : 10,
	);
	useLayoutEffect(() => {
		localStorage.setItem('fyr_pageSize', pageSize?.toString());
	}, [pageSize]);

	const values: ITSettingsContextProps = useMemo(
		() => ({
			timeslipViewType,
			setTimeslipViewType,
			timeslipFilter,
			setTimeslipFilter,
			taskFilter,
			setTaskFilter,
			projectFilter,
			setProjectFilter,
			eventFilter,
			setEventFilter,
			userFilter,
			setUserFilter,
			offerFilter,
			setOfferFilter,
			orderFilter,
			setOrderFilter,
			invoiceFilter,
			setInvoiceFilter,
			dashboardFilter,
			setDashboardFilter,
			statisticsFilter,
			setStatisticsFilter,
			customerFilter,
			setCustomerFilter,
			lastFetchTime,
			setLastFetchTime,
			isLoadingRefresh,
			setIsLoadingRefresh,
			activePeriod,
			setActivePeriod,
			pageSize,
			setPageSize,
		}),
		[
			timeslipViewType,
			timeslipFilter,
			taskFilter,
			projectFilter,
			eventFilter,
			userFilter,
			offerFilter,
			orderFilter,
			invoiceFilter,
			dashboardFilter,
			statisticsFilter,
			customerFilter,
			lastFetchTime,
			isLoadingRefresh,
			activePeriod,
			pageSize,
		],
	);

	return <SettingsContext.Provider value={values}>{children}</SettingsContext.Provider>;
};

export default SettingsContext;
