import React, { ReactNode, useState } from 'react';
import Container from '../../components/layouts/Container/Container';
import PageWrapper from '../../components/layouts/PageWrapper/PageWrapper';
import {
	ForWho1,
	HeroImg,
	Logo1,
	Logo1Dark,
	Logo2,
	Logo2Dark,
	Logo3,
	Logo3Dark,
	Logo4,
	Logo4Dark,
	Logo5,
	Logo5Dark,
} from '../../assets/images';
import Icon from '../../components/icon/Icon';
import Button from '../../components/ui/Button';
import Visible from '../../components/utils/Visible';
import { TIcons } from '../../types/icons.type';
import Badge from '../../components/ui/Badge';
import Checkbox from '../../components/form/Checkbox';
import Label from '../../components/form/Label';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import useDarkMode from '../../hooks/useDarkMode';
import { useTranslation } from 'react-i18next';
import useHash from '../../hooks/useHash';
import { customerViewPages } from '../../config/pages.config';

const ListItemPartial = ({ title }: { title: string }) => {
	return (
		<div className='flex items-center gap-2'>
			<Icon icon='HeroCheckCircle' size='text-2xl' color='blue' />
			<span className='text-lg text-zinc-600 dark:text-zinc-400'>{title}</span>
		</div>
	);
};
const IconContentPartial = ({
	icon,
	title,
	children,
}: {
	icon: TIcons;
	title: string;
	children: ReactNode;
}) => {
	return (
		<div>
			<div className='flex flex-wrap justify-center gap-4'>
				<div className='flex h-20 w-20 items-center justify-center rounded-full bg-indigo-500/5 dark:bg-indigo-500/10'>
					<Icon icon={icon} size='text-5xl' color='indigo' />
				</div>
				<div className='flex basis-full justify-center text-2xl'>{title}</div>
				<div className='flex basis-full justify-center'>
					<div className='text-center text-zinc-600 dark:text-zinc-400'>{children}</div>
				</div>
			</div>
		</div>
	);
};
const LogoItemPartial = ({
	src,
	srcLight,
	alt,
	isUnder = false,
}: {
	src: string;
	srcLight: string;
	alt: string;
	isUnder?: boolean;
}) => {
	const { t } = useTranslation();
	const { isDarkTheme } = useDarkMode();
	return (
		<div className='group/logo relative'>
			<div className='flex cursor-pointer items-center justify-center rounded-full border border-transparent bg-indigo-500/5 p-8 transition duration-300 ease-in-out group-hover/logo:-translate-y-2 group-hover/logo:border-zinc-500 dark:bg-indigo-500/10'>
				<img src={isDarkTheme ? src : srcLight} alt={alt} className='' />
			</div>
			<Visible is={isUnder}>
				<Badge
					variant='solid'
					rounded='rounded-full'
					color='emerald'
					className='absolute -right-4 bottom-1'>
					{t('Under development')}
				</Badge>
			</Visible>
		</div>
	);
};
const PriceItemPartial = ({
	name,
	desc,
	monthlyPrice,
	yearlyPrice,
	user,
	linkText,
	isFeatured,
	isShowYearly,
}: {
	name: string;
	desc: string;
	monthlyPrice: string;
	yearlyPrice: string;
	user: string;
	linkText: string;
	isFeatured?: boolean;
	isShowYearly?: boolean;
}) => {
	return (
		<div
			className={classNames('flex flex-wrap items-center justify-center border px-4 py-16', {
				'rounded-xl !border-zinc-50/25 bg-blue-500 outline outline-[1rem] outline-blue-500':
					isFeatured,
				'border-zinc-500/25 !bg-white dark:border-zinc-700/25 dark:!bg-zinc-500/5':
					!isFeatured,
				'first:rounded-se-xl first:rounded-ss-xl sm:first:rounded-se-none sm:first:rounded-ss-xl md:first:rounded-s-xl md:last:rounded-e-xl sm:[&:nth-child(2)]:rounded-se-xl md:[&:nth-child(2)]:rounded-se-none sm:[&:nth-child(3)]:rounded-es-xl md:[&:nth-child(3)]:rounded-es-none':
					!isFeatured,
			})}>
			<div className='flex flex-col items-center justify-center'>
				<div
					className={classNames('mb-4 text-3xl font-extrabold', {
						'text-white': isFeatured,
					})}>
					{name}
				</div>
				<div
					className={classNames('mb-24 text-xl', {
						'text-white': isFeatured,
					})}>
					{desc}
				</div>
				<div
					className={classNames('mb-4 text-6xl font-bold', {
						'text-white': isFeatured,
					})}>
					{isShowYearly ? yearlyPrice : monthlyPrice}
				</div>
				<div
					className={classNames('mb-24 text-sm', {
						'text-zinc-500': !isFeatured,
						'text-white/90': isFeatured,
					})}>
					{user}
				</div>
				<Link
					to={{
						pathname: customerViewPages.contactUsPage.to,
						search: '?subject=Demo&description=Please contact me to schedule a demo',
					}}>
					<Button
						size='xl'
						variant={isFeatured ? 'solid' : 'outline'}
						className={classNames({
							'bg-white text-zinc-500 hover:bg-white/90': isFeatured,
						})}>
						{linkText}
					</Button>
				</Link>
			</div>
		</div>
	);
};

const HeroSection = () => {
	const { isDarkTheme } = useDarkMode();
	const { t } = useTranslation();
	useHash();
	return (
		<section
			id='top'
			className='-mx-4 -mt-[var(--header-height)] pt-[var(--header-height)]'
			style={{
				background: isDarkTheme
					? 'radial-gradient(84.79% 172.9% at 73.31% 31.4%, rgba(99, 135, 241, 0.3) 0%, rgba(218, 70, 239, 0.09) 52.47%, rgba(99, 135, 241, 0.2) 100%)'
					: 'radial-gradient(84.79% 172.9% at 73.31% 31.4%, rgba(99, 135, 241, 0.05) 0%, rgba(218, 70, 239, 0.025) 52.47%, rgba(99, 135, 241, 0.05) 100%)',
			}}>
			<div className='m-5 overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
				<div className='container mx-auto px-4 sm:px-6 lg:px-8'>
					<div className='grid grid-cols-1 items-center gap-14 lg:grid-cols-12 lg:gap-32'>
						<div className='flex w-full flex-wrap gap-8 lg:col-span-6 xl:col-span-5 xl:-mx-0 2xl:-mx-5'>
							<h1 className='mb-4 max-w-2xl text-4xl font-extrabold !leading-snug text-zinc-700 dark:text-white md:text-5xl xl:text-6xl'>
								{t('For an efficient, hassle-free data-driven business')}
							</h1>
							<p className='mb-6 max-w-2xl font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:mb-8 lg:text-xl'>
								{t(
									'CentraSync streamlines time reporting, e-commerce, CRM, and support. Through our platform, you simplify and automate your work processes.',
								)}
							</p>
							<div className='flex basis-full'>
								<Link
									to={{
										pathname: customerViewPages.contactUsPage.to,
										search: '?subject=Demo&description=Please contact me to schedule a demo',
									}}>
									<Button variant='solid' size='xl'>
										{t('Book a Demo')}
									</Button>
								</Link>
							</div>
							<div className='mt-24 flex gap-8 text-zinc-500'>
								<div className='flex flex-col gap-4'>
									<ListItemPartial title={t('Project Management')} />
									<ListItemPartial title={t('Time Reporting')} />
									<ListItemPartial title={t('CRM Systems')} />
								</div>
								<div className='flex flex-col gap-4'>
									<ListItemPartial title={t('Webshop')} />
									<ListItemPartial title={t('Report Templates')} />
									<ListItemPartial title={t('KPIs')} />
								</div>
							</div>
						</div>
						<div className='block w-full lg:col-span-6 xl:col-span-7'>
							<div className='w-full max-w-[96rem] rounded-3xl bg-gradient-to-r from-zinc-500/50 to-purple-950/25 p-6 sm:w-auto lg:w-[55vw] xl:ml-16'>
								<img
									src={HeroImg}
									alt='Dashboard image'
									className='w-full rounded-xl object-cover lg:h-auto'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
const ForWhoSection = () => {
	const { t } = useTranslation();

	const tabName = {
		consulting: {
			id: 'consulting',
			name: 'Consulting',
			icon: 'HeroStar',
		},
		productBased: {
			id: 'productBased',
			name: 'Product-Based',
			icon: 'HeroArchiveBox',
		},
		serviceProviders: {
			id: 'serviceProviders',
			name: 'Service Providers',
			icon: 'HeroBriefcase',
		},
		boardOfDirectors: {
			id: 'boardOfDirectors',
			name: 'Board of Directors',
			icon: 'HeroChartBar',
		},
		investors: {
			id: 'investors',
			name: 'Investors',
			icon: 'HeroArrowTrendingUp',
		},
		procurement: {
			id: 'procurement',
			name: 'Procurement',
			icon: 'HeroTruck',
		},
	};
	const [activeTab, setActiveTab] = useState(tabName.consulting.id);

	return (
		<section className='-mx-4 lg:pt-20'>
			<div className='m-5 overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
				<div className='container mx-auto px-4'>
					<div className='mb-16 flex justify-center text-3xl font-extrabold dark:text-white md:text-4xl xl:text-5xl'>
						{t('For whom are we building CentraSync?')}
					</div>
					<div className='flex flex-col justify-center gap-4 md:flex-row'>
						{Object.values(tabName).map((item) => (
							<Button
								key={item.id}
								size='lg'
								variant={activeTab === item.id ? 'solid' : undefined}
								icon={item.icon}
								className={classNames('font-semibold', {
									'!bg-indigo-500/5': activeTab !== item.id,
								})}
								onClick={() => setActiveTab(item.id)}>
								{t(item.name)}
							</Button>
						))}
					</div>
				</div>
				<div className='container mx-auto mt-8 rounded-lg bg-indigo-500/5 p-4 dark:bg-indigo-500/10'>
					<Visible is={activeTab === tabName.consulting.id}>
						<div className='grid grid-cols-12 items-center gap-8 px-8 py-8 md:gap-24 md:px-32'>
							<div className='col-span-5'>
								<div className='flex flex-wrap gap-8'>
									<div className='text-2xl font-bold dark:text-white md:text-3xl xl:text-4xl'>
										{t('Consulting Businesses')}
									</div>
									<div className='text-justify text-lg !leading-relaxed text-zinc-600 dark:text-zinc-400'>
										<p>
											{t(
												'Amplify Your Consulting Services with Our Platform',
											)}
										</p>
										<p>
											{t(
												'As a consultant, your expertise and time are your most valuable resources.',
											)}{' '}
											{t(
												'Our platform streamlines the entire consulting process—from quoting and client onboarding to project execution and invoicing—so you can focus on what truly matters, a good delivery for your customers.',
											)}{' '}
											{t(
												'A centralized dashboard provides real-time oversight of client interactions, tasks, and timeslips, while automated billing ensures prompt and accurate payments.',
											)}{' '}
											{t(
												'Whether you’re an independent consultant or part of a larger team, you can allocate resources effectively, track deadlines, and maintain full transparency with your clients.',
											)}{' '}
											{t(
												'Built-in communication tools and robust reporting features help strengthen client relationships, optimize workflows, and free you to deliver impactful insights and solutions.',
											)}
										</p>
										<p>
											{t(
												'Take the next step with our platform and transform your consulting services today!',
											)}
										</p>
									</div>
									<div>
										<Link
											to={{
												pathname:
													customerViewPages.businessTypesPages.subPages
														.consultingPages.to,
												hash: '#top',
											}}>
											<Button variant='solid' size='lg'>
												{t('Read More')}
											</Button>
										</Link>
									</div>
								</div>
							</div>
							<div className='col-span-7'>
								<img src={ForWho1} alt='Screens' />
							</div>
						</div>
					</Visible>
					<Visible is={activeTab === tabName.productBased.id}>
						<div className='grid grid-cols-12 items-center gap-8 px-8 py-8 md:gap-24 md:px-32'>
							<div className='col-span-5'>
								<div className='flex flex-wrap gap-8'>
									<div className='text-2xl font-bold dark:text-white md:text-3xl xl:text-4xl'>
										{t('Product-Based Companies')}
									</div>
									<div className='text-justify text-lg !leading-relaxed text-zinc-600 dark:text-zinc-400'>
										<p>
											{t('Elevate Your Product Business with Our Platform')}
										</p>
										<p>
											{t(
												'In today’s competitive market, product-based businesses need streamlined operations and exceptional customer experiences to stand out.',
											)}{' '}
											{t(
												'Our platform offers a complete ecosystem—combining product management, billing, and an integrated online store—within one intuitive interface.',
											)}{' '}
											{t(
												'Maintain accurate product catalogs, track inventory in real time, and automate invoicing to simplify transactions.',
											)}{' '}
											{t(
												'Meanwhile, powerful CRM features help strengthen customer relationships through personalized communication and consistent support.',
											)}{' '}
											{t(
												'By consolidating processes like warranties, returns, and order management, you reduce administrative burdens, enhance transparency, and make faster, data-driven decisions.',
											)}
										</p>
										<p>
											{t(
												'Take the next step with our platform and transform your product business today!',
											)}
										</p>
									</div>
									<div>
										<Link
											to={{
												pathname:
													customerViewPages.businessTypesPages.subPages
														.productBasedPages.to,
												hash: '#top',
											}}>
											<Button variant='solid' size='lg'>
												{t('Read More')}
											</Button>
										</Link>
									</div>
								</div>
							</div>
							<div className='col-span-7'>
								<img src={ForWho1} alt='Screens' />
							</div>
						</div>
					</Visible>
					<Visible is={activeTab === tabName.serviceProviders.id}>
						<div className='grid grid-cols-12 items-center gap-8 px-8 py-8 md:gap-24 md:px-32'>
							<div className='col-span-5'>
								<div className='flex flex-wrap gap-8'>
									<div className='text-2xl font-bold dark:text-white md:text-3xl xl:text-4xl'>
										{t('Service Providers')}
									</div>
									<div className='text-justify text-lg !leading-relaxed text-zinc-600 dark:text-zinc-400'>
										<p>
											{t('Elevate Your Service Offering with Our Platform')}
										</p>
										<p>
											{t(
												'As a service provider, delivering exceptional customer experiences is paramount.',
											)}{' '}
											{t(
												'Our platform streamlines service delivery by integrating everything from quoting and project management to billing and SLA compliance into one system.',
											)}{' '}
											{t(
												'A centralized dashboard offering real-time insight into open tasks, customer requests, and deadlines, while automated workflows minimize repetitive work and free up your team’s time.',
											)}{' '}
											{t(
												'By efficiently managing resources and tracking project progress, you maintain transparency with customers and ensure every service meets expectations.',
											)}{' '}
											{t(
												'Built-in analytics and reporting help you optimize performance, drive continuous improvement, and strengthen client relationships.',
											)}
										</p>
										<p>
											{t(
												'Take the next step with our platform and transform your service business today!',
											)}
										</p>
									</div>
									<div>
										<Link
											to={{
												pathname:
													customerViewPages.businessTypesPages.subPages
														.serviceProvidersPages.to,
												hash: '#top',
											}}>
											<Button variant='solid' size='lg'>
												{t('Read More')}
											</Button>
										</Link>
									</div>
								</div>
							</div>
							<div className='col-span-7'>
								<img src={ForWho1} alt='Screens' />
							</div>
						</div>
					</Visible>
					<Visible is={activeTab === tabName.boardOfDirectors.id}>
						<div className='grid grid-cols-12 items-center gap-8 px-8 py-8 md:gap-24 md:px-32'>
							<div className='col-span-5'>
								<div className='flex flex-wrap gap-8'>
									<div className='text-2xl font-bold dark:text-white md:text-3xl xl:text-4xl'>
										{t('Board of Directors')}
									</div>
									<div className='text-justify text-lg !leading-relaxed text-zinc-600 dark:text-zinc-400'>
										<p>{t('Empower Your Board with Real-Time Insights')}</p>
										<p>
											{t(
												'Board members need immediate access to accurate, up-to-date information to steer the organization toward sustainable growth.',
											)}{' '}
											{t(
												'Our platform consolidates metrics—covering financials, customer satisfaction, project progress, and SLA compliance—into a single, real-time dashboard.',
											)}{' '}
											{t(
												'Dynamic data allows you to pinpoint trends, evaluate risks, and identify opportunities faster than ever before.',
											)}{' '}
											{t(
												'Automated alerts highlight issues like overdue invoices or underperforming initiatives, ensuring swift, proactive action.',
											)}{' '}
											{t(
												'With intuitive reporting tools, you’ll gain the visibility needed to make data-driven decisions that strengthen governance and accelerate long-term success.',
											)}
										</p>
										<p>
											{t(
												'Take the next step with our platform and guide your organization to new heights!',
											)}
										</p>
									</div>
									<div>
										<Link
											to={{
												pathname:
													customerViewPages.businessTypesPages.subPages
														.boardOfDirectorsPages.to,
												hash: '#top',
											}}>
											<Button variant='solid' size='lg'>
												{t('Read More')}
											</Button>
										</Link>
									</div>
								</div>
							</div>
							<div className='col-span-7'>
								<img src={ForWho1} alt='Screens' />
							</div>
						</div>
					</Visible>
					<Visible is={activeTab === tabName.investors.id}>
						<div className='grid grid-cols-12 items-center gap-8 px-8 py-8 md:gap-24 md:px-32'>
							<div className='col-span-5'>
								<div className='flex flex-wrap gap-8'>
									<div className='text-2xl font-bold dark:text-white md:text-3xl xl:text-4xl'>
										{t('Investors')}
									</div>
									<div className='text-justify text-lg !leading-relaxed text-zinc-600 dark:text-zinc-400'>
										<p>{t('Accelerate ROI with Live Portfolio Visibility')}</p>
										<p>
											{t(
												'As an investor, you need a comprehensive view to track performance, mitigate risks, and spot new growth opportunities across multiple ventures.',
											)}{' '}
											{t(
												'Our platform offers real-time dashboards covering revenue, expenses, and customer data for each of your investments, enabling deeper insights into their financial health.',
											)}{' '}
											{t(
												'Automated alerts flag potential issues—like overdue invoices or project overruns—so you can respond swiftly and proactively.',
											)}{' '}
											{t(
												'With interactive analytics and scenario modeling, you can make data-driven decisions that optimize returns.',
											)}
										</p>
										<p>
											{t(
												'Take the next step with our platform and guide your portfolio to sustained success!',
											)}
										</p>
									</div>
									<div>
										<Link
											to={{
												pathname:
													customerViewPages.businessTypesPages.subPages
														.investorsPages.to,
												hash: '#top',
											}}>
											<Button variant='solid' size='lg'>
												{t('Read More')}
											</Button>
										</Link>
									</div>
								</div>
							</div>
							<div className='col-span-7'>
								<img src={ForWho1} alt='Screens' />
							</div>
						</div>
					</Visible>
					<Visible is={activeTab === tabName.procurement.id}>
						<div className='grid grid-cols-12 items-center gap-8 px-8 py-8 md:gap-24 md:px-32'>
							<div className='col-span-5'>
								<div className='flex flex-wrap gap-8'>
									<div className='text-2xl font-bold dark:text-white md:text-3xl xl:text-4xl'>
										{t('Procurement Departments')}
									</div>
									<div className='text-justify text-lg !leading-relaxed text-zinc-600 dark:text-zinc-400'>
										<p>
											{t(
												'Optimize Your Procurement Strategy with Our Platform',
											)}
										</p>
										<p>
											{t(
												'As a purchasing department, controlling costs, ensuring timely deliveries, and maintaining strong supplier relationships are essential.',
											)}{' '}
											{t(
												'Our platform streamlines procurement by centralizing supplier data, purchase orders, invoices, and spend analytics in one interface.',
											)}{' '}
											{t(
												'Real-time dashboards and automated workflows enable you to monitor order progress, compare quotes, and detect potential risks before they escalate.',
											)}{' '}
											{t(
												'By seamlessly integrating with your ERP and finance systems, the solution reduces manual tasks and provides accurate financial insights.',
											)}{' '}
											{t(
												'With a transparent overview of supplier performance and contract terms, you can foster better communication, minimize administrative overhead, and focus on strategic sourcing decisions.',
											)}
										</p>
										<p>
											{t(
												'Take the next step with our platform and enhance your procurement process today!',
											)}
										</p>
									</div>
									<div>
										<Link
											to={{
												pathname:
													customerViewPages.businessTypesPages.subPages
														.procurementPages.to,
												hash: '#top',
											}}>
											<Button variant='solid' size='lg'>
												{t('Read More')}
											</Button>
										</Link>
									</div>
								</div>
							</div>
							<div className='col-span-7'>
								<img src={ForWho1} alt='Screens' />
							</div>
						</div>
					</Visible>
				</div>
			</div>
		</section>
	);
};
const ServicesSection = () => {
	const { t } = useTranslation();
	return (
		<section className='-mx-4 lg:pt-20'>
			<div className='m-5 overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
				<div className='container mx-auto px-4'>
					<div className='mb-16 flex justify-center text-3xl font-extrabold dark:text-white md:text-4xl xl:text-5xl'>
						{t('Features')}
					</div>
					<div className='grid grid-cols-1 gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
						<IconContentPartial title={t('E-commerce')} icon='HeroShieldCheck'>
							{t(
								'Let customers log in to view their order/invoice history and place new orders',
							)}
						</IconContentPartial>
						<IconContentPartial
							title={t('Product Information')}
							icon='HeroChatBubbleLeftRight'>
							{t('Manage product groups, categories, product data, and images')}
						</IconContentPartial>
						<IconContentPartial
							title={t('Direct Sales')}
							icon='HeroPresentationChartLine'>
							{t('Manage quotes, orders, and invoices')}
						</IconContentPartial>
						<IconContentPartial title={t('CRM')} icon='HeroBellAlert'>
							{t('View all detailed information and history for each customer')}
						</IconContentPartial>
						<IconContentPartial
							title={t('Project Management')}
							icon='HeroDocumentCheck'>
							{t('Link sales and working hours to projects')}
						</IconContentPartial>
						<IconContentPartial
							title={t('Case Management')}
							icon='HeroClipboardDocumentCheck'>
							{t(
								'Create cases, link them to projects, categorize them, and report time',
							)}
						</IconContentPartial>
						<IconContentPartial title={t('Hours worked')} icon='HeroClock'>
							{t(
								'Categorize and report paid and unpaid work hours. Customers can log in to view and approve accrued time before you invoice.',
							)}
						</IconContentPartial>
						<IconContentPartial
							title={t('Support Portal')}
							icon='HeroQuestionMarkCircle'>
							{t('Let customers log in and create new support tickets')}
						</IconContentPartial>
						<IconContentPartial title={t('Business Intelligence')} icon='HeroUsers'>
							{t(
								'Learn more about your operations by exploring data and monitoring key metrics',
							)}
						</IconContentPartial>
						<IconContentPartial title={t('Events')} icon='HeroShieldExclamation'>
							{t(
								'For every event in the customer portal, a log is created that you can review',
							)}
						</IconContentPartial>
					</div>
				</div>
			</div>
		</section>
	);
};
const IntegrationsSection = () => {
	const { t } = useTranslation();
	return (
		<section className='-mx-4 lg:py-20'>
			<div className='xl:py-18 m-5 overflow-hidden py-16 lg:m-0 2xl:py-20'>
				<div className='container mx-auto px-4'>
					<div className='mb-8 flex justify-center text-3xl font-extrabold dark:text-white md:text-4xl xl:text-5xl'>
						{t('Easily integrated with the systems you already use')}
					</div>
					<div className='mb-16 flex justify-center text-lg text-zinc-500 md:text-xl xl:text-2xl'>
						{t(
							'We ensure your data is synced in real time so you never miss any information.',
						)}
					</div>
					<div className='grid grid-cols-1 gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5'>
						<LogoItemPartial src={Logo1Dark} srcLight={Logo1} alt='Fortnox' />
						<LogoItemPartial src={Logo4Dark} srcLight={Logo4} alt='Postnord' />
						<LogoItemPartial src={Logo5Dark} srcLight={Logo5} alt='Ongoing' />
						<LogoItemPartial src={Logo2Dark} srcLight={Logo2} alt='Visma' isUnder />
						<LogoItemPartial
							src={Logo3Dark}
							srcLight={Logo3}
							alt='Bjorn Lunden'
							isUnder
						/>
					</div>
				</div>
			</div>
		</section>
	);
};
export const PricingSection = ({ isShowRequestDemo = true }: { isShowRequestDemo?: boolean }) => {
	const { t } = useTranslation();
	const [yearly, setYearly] = useState<boolean>(false);
	const priceList = [
		{
			id: 'Consulting Firm',
			name: t('Consulting Firm'),
			desc: t('Cases and Time Reporting'),
			monthlyPrice: '199kr',
			yearlyPrice: '1990kr',
			user: t('per user'),
			linkText: t('Get started now'),
			isFeatured: false,
		},
		{
			id: 'Service Companies',
			name: t('Service Companies'),
			desc: t('A complete customer portal'),
			monthlyPrice: '299kr',
			yearlyPrice: '2990kr',
			user: t('per user'),
			linkText: t('Get started now'),
			isFeatured: false,
		},
		{
			id: 'Product Companies',
			name: t('Product Companies'),
			desc: t('A complete e-commerce solution'),
			monthlyPrice: '399kr',
			yearlyPrice: '3990kr',
			user: t('per user'),
			linkText: t('Get started now'),
			isFeatured: false,
		},
		{
			id: 'Premium',
			name: t('Premium'),
			desc: t('All features included'),
			monthlyPrice: '499kr',
			yearlyPrice: '4990kr',
			user: t('per user'),
			linkText: t('Get started now'),
			isFeatured: true,
		},
	];
	return (
		<section
			className={classNames('-mx-4 bg-indigo-500/5', {
				'-mt-[var(--header-height)] pt-[var(--header-height)] lg:pt-[calc(var(--header-height)+5rem)]':
					!isShowRequestDemo,
				'lg:pt-20': isShowRequestDemo,
			})}>
			<div className='mx-5 overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
				<div className='container mx-auto px-4'>
					<div className='mb-16 flex justify-center text-3xl font-extrabold dark:text-white md:text-4xl xl:text-5xl'>
						{t('Flexible pricing for you')}
					</div>
					<div className='flex flex-wrap items-center justify-center'>
						<Label htmlFor='optionA' className='!mb-0 me-2.5 basis-0 !text-xl'>
							{t('Monthly')}
						</Label>
						<Checkbox
							label={t('Yearly')}
							id='optionA'
							variant='switch'
							onChange={() => setYearly(!yearly)}
							checked={yearly}
							dimension='xl'
							inputClassName='!border-zinc-500/50 checked:!border-blue-500'
						/>
						<span className='ms-2 -translate-y-3 text-red-500'>-16,7%</span>
					</div>
					<div className='my-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'>
						{priceList.map((item) => (
							// eslint-disable-next-line react/jsx-props-no-spreading
							<PriceItemPartial key={item.id} isShowYearly={yearly} {...item} />
						))}
					</div>
					<Visible is={isShowRequestDemo}>
						<div className='mb-48 flex justify-center gap-2 text-xl'>
							<span>{t('Want to know more about our plans?')}</span>
							<Link
								to={{
									pathname: '/pricing',
									hash: '#plan-comparison',
								}}
								className='text-blue-500'>
								{t('Click here')}
							</Link>
						</div>
						<div className='mb-8 flex justify-center text-3xl font-extrabold dark:text-white md:text-4xl xl:text-5xl'>
							{t('Ready to try it out?')}
						</div>
						<div className='mb-16 flex justify-center text-xl text-zinc-500 md:text-2xl xl:text-3xl'>
							{t('Try any plan free for a month.')}
						</div>
						<div className='mb-24 flex justify-center'>
							<Link
								to={{
									pathname: customerViewPages.contactUsPage.to,
									search: '?subject=Demo&description=Please contact me to schedule a demo',
								}}>
								<Button variant='solid' size='xl'>
									{t('Book a Demo')}
								</Button>
							</Link>
						</div>
					</Visible>
				</div>
			</div>
		</section>
	);
};

const LandingPagePage = () => {
	const { t } = useTranslation();
	return (
		<PageWrapper isProtectedRoute={false} title={t('Landing')}>
			<Container breakpoint={null} className='w-full sm:py-0 md:py-4'>
				<HeroSection />
				<ForWhoSection />
				<ServicesSection />
				<IntegrationsSection />
				<PricingSection />
			</Container>
		</PageWrapper>
	);
};

export default LandingPagePage;
