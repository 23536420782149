import { modulesDbList } from './modules.db';
import { TPermission } from '../../constants/permissions.constant';

export type TRole = {
	id: string;
	name: string;
	modules: { [key: string]: TPermission['value'] };
	loginUrl: string;
};

export const rolesDbList = {
	superAdmin: {
		id: 'superAdmin',
		name: 'Super Admin',
		loginUrl: '/admin/dashboard',
		modules: {
			[modulesDbList.customer.id]: 7 as TPermission['value'],
			[modulesDbList.product.id]: 7 as TPermission['value'],
			[modulesDbList.category.id]: 7 as TPermission['value'],
		},
	},
	tenantAdmin: {
		id: 'tenantAdmin',
		name: 'Tenant Admin',
		loginUrl: '/tenant',
		modules: {
			[modulesDbList.customer.id]: 7 as TPermission['value'],
			[modulesDbList.product.id]: 7 as TPermission['value'],
			[modulesDbList.category.id]: 7 as TPermission['value'],
		},
	},
	tenantUser: {
		id: 'tenantUser',
		name: 'Tenant User',
		loginUrl: '/tenant',
		modules: {
			[modulesDbList.customer.id]: 0 as TPermission['value'],
			[modulesDbList.product.id]: 4 as TPermission['value'],
			[modulesDbList.category.id]: 4 as TPermission['value'],
		},
	},
	customerAdmin: {
		id: 'customerAdmin',
		name: 'Customer Admin',
		loginUrl: '/customer/dashboard',
		modules: {
			[modulesDbList.customer.id]: 7 as TPermission['value'],
			[modulesDbList.product.id]: 7 as TPermission['value'],
			[modulesDbList.category.id]: 7 as TPermission['value'],
		},
	},
	customerUser: {
		id: 'customerUser',
		name: 'Customer User',
		loginUrl: '/customer/dashboard',
		modules: {
			[modulesDbList.customer.id]: 7 as TPermission['value'],
			[modulesDbList.product.id]: 7 as TPermission['value'],
			[modulesDbList.category.id]: 7 as TPermission['value'],
		},
	},
};

const rolesDb: TRole[] = Object.values(rolesDbList);

export default rolesDb;
