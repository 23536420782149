import {
	IDeliveryAddressDetailsRequest,
	IDeliveryAddressRequest,
	IDeliveryForm,
} from '../interface/DeliveryAddress.interface';
import { APIRequestParams } from '../interface/APIRequestParams.interface';
import { getEntity, post, put, del } from './apiContext';

export const getDeliveryAddressApi = (
	deliveryAddressRequest: IDeliveryAddressRequest,
	apiRequestParams?: APIRequestParams,
) => {
	return getEntity(
		`/api/delivery-address`,
		deliveryAddressRequest.setData,
		deliveryAddressRequest.setIsLoading,
		{
			customerId: apiRequestParams?.customerId ?? deliveryAddressRequest?.filter?.customerId,
			projectId: apiRequestParams?.projectId ?? deliveryAddressRequest?.filter?.projectId,
		},
	);
};

export const getDeliveryAddressByIdApi = async ({
	addressId,
	setIsLoading,
	setData,
}: IDeliveryAddressDetailsRequest) => {
	return getEntity(`/api/delivery-address/${addressId}`, setData, setIsLoading);
};

export const createOrUpdateDeliveryAddressApi = async (form: IDeliveryForm) => {
	if (form.id) {
		return put(`/api/delivery-address/${form.id}`, form);
	}
	return post(`/api/delivery-address?customerId=${form.customerId}`, form);
};

export const deleteDeliveryAddressApi = async (addressId: number) => {
	return del(`/api/customers/deliveryAddress/${addressId}`);
};
