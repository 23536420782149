export type TPeriodKEY = 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR' | 'LAST5YEARS';
export type TPeriodText = 'Week' | 'Month' | 'Quarter' | 'Year' | '5 Years';

export type TPeriod = {
	text: TPeriodText;
};
export type TPeriods = {
	[key in TPeriodKEY]: TPeriod;
};

const PERIOD: TPeriods = {
	WEEK: { text: 'Week' },
	MONTH: { text: 'Month' },
	QUARTER: { text: 'Quarter' },
	YEAR: { text: 'Year' },
	LAST5YEARS: { text: '5 Years' },
};

export default PERIOD;
