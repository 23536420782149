import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { tenantViewPages } from '../../config/pages.config';
import { AppDataProvider } from '../../context/appDataContext';
import InvoiceEditPage from '../../pages/tenant/sales/Invoice/InvoiceEdit.page';

/**
 * Dashboard
 */
const DashboardsPage = lazy(() => import('../../pages/tenant/Dashboard/Dashboards.page'));

/**
 * Customers
 */
const CustomersPage = lazy(() => import('../../pages/tenant/Customers/Customers.page'));
const CustomerViewPage = lazy(() => import('../../pages/tenant/Customers/CustomerView.page'));
const CustomerUsersViewPage = lazy(
	() => import('../../pages/tenant/Customers/Users/CustomerUsersView.page'),
);

/**
 * Products
 */
const ArticlesPage = lazy(() => import('../../pages/tenant/products/Articles.page'));
const ArticleGroupsPage = lazy(() => import('../../pages/tenant/products/ArticleGroups.page'));
const CategoriesPage = lazy(() => import('../../pages/tenant/products/Categories.page'));
const ProductListsPage = lazy(() => import('../../pages/tenant/products/ProductLists.page'));
const ManufacturersPage = lazy(() => import('../../pages/tenant/products/Manufacturers.page'));

/**
 * Sales
 */
const OffersPage = lazy(() => import('../../pages/tenant/sales/Offers/Offers.page'));
const OfferViewPage = lazy(() => import('../../pages/tenant/sales/Offers/OfferView.page'));
const OfferEditPage = lazy(() => import('../../pages/tenant/sales/Offers/OfferEdit.page'));
const OrdersPage = lazy(() => import('../../pages/tenant/sales/Orders/Orders.page'));
const OrderViewPage = lazy(() => import('../../pages/tenant/sales/Orders/OrderView.page'));
const OrderEditPage = lazy(() => import('../../pages/tenant/sales/Orders/OrderEdit.page'));
const InvoicePage = lazy(() => import('../../pages/tenant/sales/Invoice/Invoice.page'));
const InvoiceViewPage = lazy(() => import('../../pages/tenant/sales/Invoice/InvoiceView.page'));

/**
 * Work Management
 */
const ProjectsPage = lazy(() => import('../../pages/tenant/workManagement/Project/Projects.page'));
const ProjectViewPage = lazy(
	() => import('../../pages/tenant/workManagement/Project/ProjectView.page'),
);
const TasksPage = lazy(() => import('../../pages/tenant/workManagement/Tasks/Tasks.page'));
const TasksViewPage = lazy(() => import('../../pages/tenant/workManagement/Tasks/TaskView.page'));
const TimeslipsPage = lazy(
	() => import('../../pages/tenant/workManagement/Timeslips/Timeslips.page'),
);
const TimeslipsViewPage = lazy(
	() => import('../../pages/tenant/workManagement/Timeslips/TimeslipsView.page'),
);

/**
 * Article
 */
const ArticlesListPage = lazy(() => import('../../pages/tenant/content/Posts/Posts.page'));
const ArticleEditPage = lazy(() => import('../../pages/tenant/content/Posts/PostEdit.page'));

/**
 * Events
 */
const EventsPage = lazy(() => import('../../pages/tenant/Events.page'));

/**
 * Users
 */
const UsersPage = lazy(() => import('../../pages/tenant/Users/Users.page'));
const UserViewPage = lazy(() => import('../../pages/tenant/Users/UserView.page'));

/**
 * Organization
 */
const OrganizationPage = lazy(() => import('../../pages/tenant/Organization.page'));

/**
 * Delivery Address
 */
const DeliveryAddressPage = lazy(() => import('../../pages/tenant/DeliveryAddressView.page'));

const tRoutes: RouteProps[] = [
	/**
	 * Dashboard
	 */
	{
		path: tenantViewPages.tenantPage.subPages.dashboardPage.to,
		element: <DashboardsPage />,
	},

	/**
	 * Customers
	 */
	{
		path: tenantViewPages.tenantPage.subPages.customersPage.to,
		element: <CustomersPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.customersPage.to}/:id`,
		element: <CustomerViewPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.customersPage.to}/:id/${tenantViewPages.tenantPage.subPages.customersPage.subPages.usersPage.onlyTo}/:uid`,
		element: <CustomerUsersViewPage />,
	},

	/**
	 * Products
	 */
	{
		path: tenantViewPages.tenantPage.subPages.productsPage.subPages.articlesPage.to,
		element: <ArticlesPage />,
	},
	{
		path: tenantViewPages.tenantPage.subPages.productsPage.subPages.articleGroupsPage.to,
		element: <ArticleGroupsPage />,
	},
	{
		path: tenantViewPages.tenantPage.subPages.productsPage.subPages.categoriesPage.to,
		element: <CategoriesPage />,
	},
	{
		path: tenantViewPages.tenantPage.subPages.productsPage.subPages.productListsPage.to,
		element: <ProductListsPage />,
	},
	{
		path: tenantViewPages.tenantPage.subPages.productsPage.subPages.manufacturersPage.to,
		element: <ManufacturersPage />,
	},

	/**
	 * Sales
	 */
	{
		path: tenantViewPages.tenantPage.subPages.salesPage.subPages.offersPage.to,
		element: <OffersPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.salesPage.subPages.offersPage.to}/:id`,
		element: <OfferViewPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.salesPage.subPages.offersPage.to}/new`,
		element: <OfferEditPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.salesPage.subPages.offersPage.to}/:id/edit`,
		element: <OfferEditPage />,
	},
	{
		path: tenantViewPages.tenantPage.subPages.salesPage.subPages.ordersPage.to,
		element: <OrdersPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.salesPage.subPages.ordersPage.to}/:id`,
		element: <OrderViewPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.salesPage.subPages.ordersPage.to}/new`,
		element: <OrderEditPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.salesPage.subPages.ordersPage.to}/:id/edit`,
		element: <OrderEditPage />,
	},
	{
		path: tenantViewPages.tenantPage.subPages.salesPage.subPages.invoicesPage.to,
		element: <InvoicePage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.salesPage.subPages.invoicesPage.to}/:id`,
		element: <InvoiceViewPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.salesPage.subPages.invoicesPage.to}/new`,
		element: <InvoiceEditPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.salesPage.subPages.invoicesPage.to}/:id/edit`,
		element: <InvoiceEditPage />,
	},

	/**
	 * Work Management
	 */
	{
		path: tenantViewPages.tenantPage.subPages.workManagementPages.subPages.projectsPage.to,
		element: <ProjectsPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.workManagementPages.subPages.projectsPage.to}/:id`,
		element: <ProjectViewPage />,
	},
	{
		path: tenantViewPages.tenantPage.subPages.workManagementPages.subPages.tasksPage.to,
		element: <TasksPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.workManagementPages.subPages.tasksPage.to}/:id`,
		element: <TasksViewPage />,
	},
	{
		path: tenantViewPages.tenantPage.subPages.workManagementPages.subPages.timeslipsPage.to,
		element: <TimeslipsPage />,
	},
	{
		path: `${
			tenantViewPages.tenantPage.subPages.workManagementPages.subPages.timeslipsPage.to
		}/:id`,
		element: <TimeslipsViewPage />,
	},

	/**
	 * Article
	 */
	{
		path: tenantViewPages.tenantPage.subPages.content.subPages.postsPage.to,
		element: <ArticlesListPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.content.subPages.postsPage.to}/:id`,
		element: <ArticleEditPage />,
	},

	/**
	 * Events
	 */
	{
		path: tenantViewPages.tenantPage.subPages.eventsPage.to,
		element: <EventsPage />,
	},

	/**
	 * Users
	 */
	{
		path: tenantViewPages.tenantPage.subPages.usersPage.to,
		element: <UsersPage />,
	},
	{
		path: `${tenantViewPages.tenantPage.subPages.usersPage.to}/:id`,
		element: <UserViewPage />,
	},

	/**
	 * Organization
	 */
	{
		path: tenantViewPages.tenantPage.subPages.organizationPage.to,
		element: <OrganizationPage />,
	},

	/**
	 * Delivery Address
	 */
	{
		path: `${tenantViewPages.tenantPage.subPages.deliveryAddressesPage.to}/:id`,
		element: <DeliveryAddressPage />,
	},
];

const tenantRoutes = tRoutes.map((route) => ({
	...route,
	element: <AppDataProvider>{route.element}</AppDataProvider>,
}));
export default tenantRoutes;
