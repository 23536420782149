import React from 'react';
import useAccessPage from '../../../../hooks/useAccessPage';
import ROLES from '../../../../constants/roles.constant';
import InvoiceEditPartial from '../../../_partial/edit/InvoiceEdit.partial';

const InvoiceEditPage = () => {
	useAccessPage([ROLES.ROLE_TENANT_ADMIN, ROLES.ROLE_TENANT_USER]);

	return <InvoiceEditPartial />;
};

export default InvoiceEditPage;
