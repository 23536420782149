import {
	IFetchActiveProjectsRequest,
	IFetchNextProjectNumberRequest,
	IFetchProjectRequest,
	TProjectDataResponse,
	TProjectStatus,
	TProjectType,
} from '../interface/ProjectResource.interface';
import { getEntity, post, put } from './apiContext';
import { APIRequestParams } from '../interface/APIRequestParams.interface';

export const getProject = async ({
	projectId,
	setProjectData,
	setIsLoading,
}: IFetchProjectRequest) => {
	return getEntity(`/api/projects/${projectId}`, setProjectData, setIsLoading);
};

export const getActiveProjects = async (
	{
		setActiveProjectsData,
		setIsLoading,
		filter,
		page,
		size,
		sort,
		direction,
		search,
	}: IFetchActiveProjectsRequest,
	apiRequestParams?: APIRequestParams,
) => {
	return getEntity('/api/v2/projects', setActiveProjectsData, setIsLoading, {
		filter: filter?.projectType as TProjectType,
		status: filter?.status as TProjectStatus,
		label: filter?.label ? filter.label : undefined,
		assignedUserId: filter?.assignedUserId,
		customerId: apiRequestParams?.customerId ?? filter?.customerId,
		participantId: apiRequestParams?.participantId,
		leaderId: apiRequestParams?.leaderId,
		page,
		size,
		sort,
		direction,
		search: search ? search : undefined,
	});
};

export const getNextProjectNumberApi = async (request: IFetchNextProjectNumberRequest) => {
	return getEntity('/api/projects/suggestedProjectNumber', request.setData, request.setIsLoading);
};

export const postProjectNumberConfigApi = async (projectNumber: number) => {
	return post(`/api/settings/projectConfig`, { startingProjectNumber: projectNumber });
};

export const createOrUpdateProjectApi = async (form: TProjectDataResponse) => {
	const data = {
		id: form.id,
		ProjectNumber: form.projectNumber,
		Description: form.description,
		Comments: form.comments,
		StartDate: form.startDate,
		EndDate: form.endDate,
		Status: form.status,
		projectParticipants: form.projectParticipants?.map((e) => e?.value) ?? [],
		projectLeaders: form.projectLeader?.map((e) => e?.value) ?? [],
		projectTemplate: {
			id: form?.templateName,
		},
	};
	return form.id
		? put(`/api/projects/${form.projectNumber}`, data)
		: post(`/api/projects?customerId=${form?.customerName}`, data);
};
