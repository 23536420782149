import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import {
	authPages,
	contractsPages,
	customerViewPages,
	documentationPages,
	subscriptionPages,
	supportPages,
} from '../config/pages.config';
import NotFoundPage from '../pages/NotFound.page';
import LoginPage from '../pages/Login.page';
import LandingPagePage from '../pages/LandingPage/LandingPage.page';
import ConsultingPage from '../pages/businessTypes/Consulting.page';
import ProductBasedPage from '../pages/businessTypes/ProductBased.page';
import ServiceProvidersPage from '../pages/businessTypes/ServiceProviders.page';
import BoardOfDirectorsPage from '../pages/businessTypes/BoardOfDirectors.page';
import InvestorsPage from '../pages/businessTypes/Investors.page';
import ProcurementPage from '../pages/businessTypes/Procurement.page';
import PricingPage from '../pages/Pricing/Pricing.page';
import ContactUsPage from '../pages/ContactUs.page';
import UnderConstructionPage from '../pages/UnderConstruction.page';
import CookiePolicyPage from '../pages/contracts/CookiePolicy.page';
import PrivacyPolicyPage from '../pages/contracts/PrivacyPolicy.page';
import TermsAndConditionsPage from '../pages/contracts/TermsAndConditions.page';
import NoAccessPage from '../pages/NoAccess.page';
import Lalegal from '../pages/Lalegal/Lalegal';
import PrivacyPolicy from '../pages/Lalegal/PrivacyPolicy';
import TermsOfUse from '../pages/Lalegal/TermsOfUse';
import tenantRoutes from './contents/tenantRoutes';
import settingsRoutes from './contents/settingsRoutes';
import documentationRoutes from './contents/documentationRoutes';
import subsRoutes from './contents/subsRoutes';
import supportRoutes from './contents/supportRoutes';
import adminRoutes from './contents/adminRoutes';
import { AppDataProvider } from '../context/appDataContext';

const CustomerDashboardPage = lazy(() => import('../pages/customer/CustomerDashboard.page'));

/**
 * Other
 */
// const UnderConstructionPage = lazy(() => import('../pages/UnderConstruction.page'));

const contentRoutes: RouteProps[] = [
	{
		path: '/lalegal',
		element: <Lalegal />,
	},
	{
		path: '/lalegal/privacy-policy',
		element: <PrivacyPolicy />,
	},
	{
		path: '/lalegal/terms',
		element: <TermsOfUse />,
	},
	/**
	 * LANDING::BEGIN
	 */
	{
		path: customerViewPages.landingPage.to,
		element: <LandingPagePage />,
	},
	{
		path: customerViewPages.businessTypesPages.subPages.consultingPages.to,
		element: <ConsultingPage />,
	},
	{
		path: customerViewPages.businessTypesPages.subPages.productBasedPages.to,
		element: <ProductBasedPage />,
	},
	{
		path: customerViewPages.businessTypesPages.subPages.serviceProvidersPages.to,
		element: <ServiceProvidersPage />,
	},
	{
		path: customerViewPages.businessTypesPages.subPages.boardOfDirectorsPages.to,
		element: <BoardOfDirectorsPage />,
	},
	{
		path: customerViewPages.businessTypesPages.subPages.investorsPages.to,
		element: <InvestorsPage />,
	},
	{
		path: customerViewPages.businessTypesPages.subPages.procurementPages.to,
		element: <ProcurementPage />,
	},
	{
		path: customerViewPages.pricingPage.to,
		element: <PricingPage />,
	},
	{
		path: customerViewPages.contactUsPage.to,
		element: <ContactUsPage />,
	},
	/**
	 * LANDING::END
	 */

	{
		path: contractsPages.contractsMainPages.subPages.cookiePolicyPages.to,
		element: <CookiePolicyPage />,
	},
	{
		path: contractsPages.contractsMainPages.subPages.privacyPolicyPages.to,
		element: <PrivacyPolicyPage />,
	},
	{
		path: contractsPages.contractsMainPages.subPages.termsAndConditionsPages.to,
		element: <TermsAndConditionsPage />,
	},

	/**
	 * CUSTOMER::BEGIN
	 */
	{
		path: `${customerViewPages.customerPage.to}/*`,
		element: (
			<AppDataProvider>
				<CustomerDashboardPage />
			</AppDataProvider>
		),
	},
	/**
	 * CUSTOMER::END
	 */

	...tenantRoutes,
	...settingsRoutes,
	...documentationRoutes,
	...subsRoutes,
	...supportRoutes,
	...adminRoutes,

	{ path: subscriptionPages.subscriptionMainPages.to, element: <UnderConstructionPage /> },
	{ path: supportPages.supportMainPages.to, element: <UnderConstructionPage /> },
	{ path: documentationPages.documentationMainPages.to, element: <UnderConstructionPage /> },
	{ path: '/no-access', element: <NoAccessPage /> },

	{ path: authPages.loginPage.to, element: <LoginPage /> },

	{ path: '*', element: <NotFoundPage /> },
];

export default contentRoutes;
