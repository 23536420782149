import React from 'react';
import { RouteProps } from 'react-router-dom';
// import DefaultFooterTemplate from '../templates/layouts/Footers/DefaultFooter.template';
import { authPages, contractsPages, customerViewPages } from '../config/pages.config';
import LandingPageFooterTemplate from '../templates/layouts/Footers/LandingPageFooter.template';

const footerRoutes: RouteProps[] = [
	{ path: authPages.loginPage.to, element: null },
	{
		path: customerViewPages.landingPage.to,
		element: <LandingPageFooterTemplate />,
	},
	{
		path: customerViewPages.pricingPage.to,
		element: <LandingPageFooterTemplate />,
	},
	{
		path: customerViewPages.contactUsPage.to,
		element: <LandingPageFooterTemplate />,
	},
	{
		path: `${customerViewPages.businessTypesPages.to}/*`,
		element: <LandingPageFooterTemplate />,
	},
	{
		path: `${contractsPages.contractsMainPages.to}/*`,
		element: <LandingPageFooterTemplate />,
	},
	{
		path: `/lalegal`,
		element: null,
	},
	{
		path: `/lalegal/privacy-policy`,
		element: null,
	},
	{
		path: `/lalegal/terms`,
		element: null,
	},
	// { path: '*', element: <DefaultFooterTemplate /> },
	{ path: '*', element: null },
];

export default footerRoutes;
