import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import detector from 'i18next-browser-languagedetector';

import 'dayjs/locale/en';
import 'dayjs/locale/sv';

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
	// pass the i18n instance to react-i18next.
	.use(detector)
	.use(initReactI18next)
	.use(Backend)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		supportedLngs: ['en', 'sv'],
		detection: {
			order: ['localStorage', 'querystring', 'navigator'],
			lookupQuerystring: 'lng',
			lookupLocalStorage: 'I18N_LANGUAGE',
		},
		fallbackLng: 'en',
		debug: false,
		backend: {
			loadPath:
				`${process.env.REACT_APP_BASE_URL}/locales/{{lng}}/{{ns}}` +
				`?domain=${window.location.hostname}`,
			crossDomain: false,
			withCredentials: false,
		},
	})
	.then();

export default i18n;
