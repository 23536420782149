import React from 'react';
import { RouteProps } from 'react-router-dom';
import DefaultAsideTemplate from '../templates/layouts/Asides/DefaultAside.template';
import {
	authPages,
	contractsPages,
	customerViewPages,
	documentationPages,
	settingsPages,
	subscriptionPages,
	supportPages,
} from '../config/pages.config';
import SettingsAsideTemplate from '../templates/layouts/Asides/SettingsAside.template';
import SupportAsideTemplate from '../templates/layouts/Asides/SupportAside.template';
import DocumentationAsideTemplate from '../templates/layouts/Asides/DocumentationAside.template';
import SubscriptionAsideTemplate from '../templates/layouts/Asides/SubscriptionAside.template';

const asideRoutes: RouteProps[] = [
	{ path: authPages.loginPage.to, element: null },
	{
		path: customerViewPages.landingPage.to,
		element: null,
	},
	{
		path: customerViewPages.pricingPage.to,
		element: null,
	},
	{
		path: customerViewPages.contactUsPage.to,
		element: null,
	},
	{
		path: `${customerViewPages.businessTypesPages.to}/*`,
		element: null,
	},
	{
		path: `${customerViewPages.customerPage.to}/*`,
		element: null,
	},
	{
		path: `${settingsPages.settingsMainPages.to}/*`,
		element: <SettingsAsideTemplate />,
	},
	{
		path: `${subscriptionPages.subscriptionMainPages.to}/*`,
		element: <SubscriptionAsideTemplate />,
	},
	{
		path: `${supportPages.supportMainPages.to}/*`,
		element: <SupportAsideTemplate />,
	},
	{
		path: `${documentationPages.documentationMainPages.to}/*`,
		element: <DocumentationAsideTemplate />,
	},
	{
		path: `${contractsPages.contractsMainPages.to}/*`,
		element: null,
	},
	{
		path: `/lalegal`,
		element: null,
	},
	{
		path: `/lalegal/privacy-policy`,
		element: null,
	},
	{
		path: `/lalegal/terms`,
		element: null,
	},
	{ path: '*', element: <DefaultAsideTemplate /> },
];

export default asideRoutes;
