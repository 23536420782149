import ROLES from '../constants/roles.constant';
import { rolesDbList } from '../mocks/db/roles.db';
import { authPages } from '../config/pages.config';

const getRedirectUrlByRole = (roles: string[]): string => {
	let redirectUrl = authPages.loginPage.to;

	switch (true) {
		case roles.includes(ROLES.ROLE_CUSTOMER_ADMIN):
			redirectUrl = rolesDbList.customerAdmin.loginUrl;
			break;
		case roles.includes(ROLES.ROLE_CUSTOMER_USER):
			redirectUrl = rolesDbList.customerAdmin.loginUrl; // TODO
			break;
		case roles.includes(ROLES.ROLE_SUPER_ADMIN):
			redirectUrl = rolesDbList.superAdmin.loginUrl;
			break;
		case roles.includes(ROLES.ROLE_TENANT_ADMIN):
			redirectUrl = rolesDbList.tenantAdmin.loginUrl;
			break;
		case roles.includes(ROLES.ROLE_TENANT_USER):
			redirectUrl = rolesDbList.tenantUser.loginUrl;
			break;
		default:
			redirectUrl = '/login';
	}

	return redirectUrl;
};

export default getRedirectUrlByRole;
