import { RouteProps } from 'react-router-dom';
import React, { lazy } from 'react';
import { superAdminPage } from '../../config/pages.config';
import AdminTenantUserViewPage from '../../pages/admin/Tenants/tabs/User/AdminTenantUserView.page';
import { AppDataProvider } from '../../context/appDataContext';

const AdminPage = lazy(() => import('../../pages/admin/Admin.page'));
const DashboardPage = lazy(() => import('../../pages/admin/Dashboard.page'));
const TenantsPage = lazy(() => import('../../pages/admin/Tenants/Tenants.page'));
const TenantViewPage = lazy(() => import('../../pages/admin/Tenants/TenantView.page'));
const TasksPage = lazy(() => import('../../pages/admin/Tasks/Tasks.page'));
const TaskViewPage = lazy(() => import('../../pages/admin/Tasks/TaskView'));
const TimeslipViewPage = lazy(() => import('../../pages/admin/Timeslips/TimeslipView.page'));
const SessionsPage = lazy(() => import('../../pages/admin/Sessions.page'));
const LogsPage = lazy(() => import('../../pages/admin/Logs.page'));
const PerformancesPage = lazy(() => import('../../pages/admin/Performances.page'));

const sRoutes: RouteProps[] = [
	{
		path: superAdminPage.superAdminPage.to,
		element: <AdminPage />,
	},
	{
		path: superAdminPage.superAdminPage.subPages.dashboardPage.to,
		element: <DashboardPage />,
	},
	{
		path: superAdminPage.superAdminPage.subPages.tenantsPage.to,
		element: <TenantsPage />,
	},
	{
		path: `${superAdminPage.superAdminPage.subPages.tenantsPage.to}/:id`,
		element: <TenantViewPage />,
	},
	{
		path: `${superAdminPage.superAdminPage.subPages.tenantsPage.to}/:id/user/:uid`,
		element: <AdminTenantUserViewPage />,
	},
	{
		path: superAdminPage.superAdminPage.subPages.tasksPage.to,
		element: <TasksPage />,
	},
	{
		path: `${superAdminPage.superAdminPage.subPages.tasksPage.to}/:id`,
		element: <TaskViewPage />,
	},
	{
		path: `${superAdminPage.superAdminPage.subPages.timeslipsPage.to}/:id`,
		element: <TimeslipViewPage />,
	},
	{
		path: superAdminPage.superAdminPage.subPages.sessionsPage.to,
		element: <SessionsPage />,
	},
	{
		path: superAdminPage.superAdminPage.subPages.logsPage.to,
		element: <LogsPage />,
	},
	{
		path: superAdminPage.superAdminPage.subPages.performancesPage.to,
		element: <PerformancesPage />,
	},
];

const adminRoutes = sRoutes.map((route) => ({
	...route,
	element: <AppDataProvider>{route.element}</AppDataProvider>,
}));
export default adminRoutes;
