import { useAuth } from '../context/authContext';

const useAccessCheck = (roles: string[]) => {
	const { authUser } = useAuth();
	const authRoles = authUser?.roles || [];

	return roles.some((r) => authRoles.some((aR) => r.includes(aR)));
};

export default useAccessCheck;
