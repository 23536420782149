import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Card, {
	CardBody,
	CardFooter,
	CardFooterChild,
	CardHeader,
	CardHeaderChild,
} from '../../../components/ui/Card';
import Button from '../../../components/ui/Button';
import Label from '../../../components/form/Label';
import FieldWrap from '../../../components/form/FieldWrap';
import Icon from '../../../components/icon/Icon';
import Input from '../../../components/form/Input';
import { useFormik } from 'formik';
import useSaveBtn from '../../../hooks/useSaveBtn';
import classNames from 'classnames';
import { useParams, useSearchParams } from 'react-router-dom';
import Visible from '../../../components/utils/Visible';
import BackButton from '../../../components/utils/BackButton';
import Title from '../../../components/utils/Title';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasFooter,
	OffCanvasFooterChild,
	OffCanvasHeader,
} from '../../../components/ui/OffCanvas';
import { IDeliveryAddress, IDeliveryForm } from '../../../interface/DeliveryAddress.interface';
import { useTranslation } from 'react-i18next';
import {
	createOrUpdateDeliveryAddress,
	fetchDeliveryAddressById,
} from '../../../services/deliveryAddressService';
import { toast } from 'react-toastify';

const DeliveryAddressEditPartial = ({
	isSubTab,
	inCanvas,
	isNew,
	isOpen,
	setIsOpen,
	setDeliveryAddressData,
}: {
	isSubTab?: boolean;
	inCanvas?: boolean;
	isNew?: boolean;
	isOpen?: boolean;
	setIsOpen?: Dispatch<SetStateAction<boolean>>;
	// For state up
	setDeliveryAddressData?: Dispatch<SetStateAction<IDeliveryAddress | undefined>>;
}) => {
	const { id } = useParams();
	const isNewItem = isNew || id === 'new';
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const isDuplicate: boolean = searchParams.get('duplicate') === 'true';
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const [deliveryAddress, setDeliveryAddress] = useState<IDeliveryAddress>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleFetchDeliveryAddress = (id: number) => {
		if (id !== undefined) {
			fetchDeliveryAddressById({
				addressId: id,
				setIsLoading,
				setData: setDeliveryAddress,
			});
		}
	};

	useEffect(() => {
		if (id !== 'new') {
			handleFetchDeliveryAddress(Number(id));
		}
	}, [id]);

	useEffect(() => {
		if (!!setDeliveryAddressData && !!deliveryAddress) {
			setDeliveryAddressData(deliveryAddress);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deliveryAddress]);

	const notify = (promise: Promise<unknown>) =>
		toast
			.promise(
				promise
					.then((response) => response)
					.catch((error) => {
						const errorMessage =
							error?.response?.data[0] || 'An unexpected error occurred';
						toast.error(errorMessage);
						throw error;
					}),
				{
					pending: 'Saving..',
					success: 'Delivery saved successfully..',
				},
			)
			.then(() => {
				window.history.back();
			})
			.finally(() => {
				setIsSaving(false);
			});

	const formik = useFormik<IDeliveryForm>({
		enableReinitialize: true,
		initialValues: {
			id: isDuplicate ? null : (deliveryAddress?.id ?? null),
			name: deliveryAddress?.name ?? '',
			address: deliveryAddress?.address ?? '',
			country: deliveryAddress?.country ?? '',
			city: deliveryAddress?.city ?? '',
			zipCode: deliveryAddress?.zipCode ?? '',
			phone: deliveryAddress?.phone ?? '',
			projectId: deliveryAddress?.projectId?.id ?? null,
			customerId: deliveryAddress?.customerId ?? null,
		},
		onSubmit: (values) => {
			const savePromise = createOrUpdateDeliveryAddress(values);
			notify(savePromise);
		},
	});
	const { saveBtnText, saveBtnColor, saveBtnDisable } = useSaveBtn({
		isNewItem,
		isSaving,
		isDirty: formik.dirty,
	});

	const _TITLE = `${isNewItem ? t('New') : `${deliveryAddress?.name}`}`;
	const _CONTENT = (
		<div className='grid grid-cols-12 gap-4'>
			<div className='col-span-12'>
				<Label htmlFor='name'>{t('Name')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroTag' className='mx-2' />}>
					<Input
						id='name'
						name='name'
						onChange={formik.handleChange}
						value={formik.values.name}
						placeholder={t('Name')}
					/>
				</FieldWrap>
			</div>
			<div className='col-span-12'>
				<Label htmlFor='deliveryAddress'>{t('Delivery Address')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroMapPin' className='mx-2' />}>
					<Input
						id='deliveryAddress'
						name='deliveryAddress'
						onChange={formik.handleChange}
						value={formik.values.address}
						autoComplete='address-line1'
						placeholder={t('Delivery Address')}
					/>
				</FieldWrap>
			</div>
			<div className='col-span-6 lg:col-span-4'>
				<Label htmlFor='country'>{t('Country')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroGlobeEuropeAfrica' className='mx-2' />}>
					<Input
						id='country'
						name='country'
						onChange={formik.handleChange}
						value={formik.values.country}
						autoComplete='country-name'
						placeholder={t('Country')}
					/>
				</FieldWrap>
			</div>
			<div className='col-span-6 lg:col-span-4'>
				<Label htmlFor='city'>{t('City')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroMap' className='mx-2' />}>
					<Input
						id='city'
						name='city'
						onChange={formik.handleChange}
						value={formik.values.city}
						autoComplete='address-level2'
						placeholder={t('City')}
					/>
				</FieldWrap>
			</div>
			<div className='col-span-6 lg:col-span-4'>
				<Label htmlFor='zipCode'>{t('ZIP Code')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroInbox' className='mx-2' />}>
					<Input
						id='zipCode'
						name='zipCode'
						onChange={formik.handleChange}
						value={formik.values.zipCode}
						autoComplete='postal-code'
						placeholder={t('ZIP Code')}
					/>
				</FieldWrap>
			</div>
			<div className='col-span-6 lg:col-span-4'>
				<Label htmlFor='phone'>{t('Phone')}</Label>
				<FieldWrap firstSuffix={<Icon icon='HeroPhone' className='mx-2' />}>
					<Input
						id='phone'
						name='phone'
						onChange={formik.handleChange}
						value={formik.values.phone}
						autoComplete='tel'
						placeholder={t('Phone')}
					/>
				</FieldWrap>
			</div>
		</div>
	);
	const _SAVE_BTN = (
		<Button
			icon='HeroServer'
			variant='solid'
			color={saveBtnColor}
			isDisable={saveBtnDisable}
			onClick={() => formik.handleSubmit()}>
			{saveBtnText}
		</Button>
	);

	if (inCanvas) {
		return (
			<OffCanvas
				isOpen={isOpen as boolean}
				setIsOpen={setIsOpen as Dispatch<SetStateAction<boolean>>}
				dialogClassName='max-md:max-w-full md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl'>
				<OffCanvasHeader>{_TITLE}</OffCanvasHeader>
				<OffCanvasBody>{_CONTENT}</OffCanvasBody>
				<OffCanvasFooter>
					<OffCanvasFooterChild>
						<Button
							onClick={() => {
								// @ts-ignore
								setIsOpen(false);
								formik.resetForm();
							}}
							color='red'>
							Close
						</Button>
					</OffCanvasFooterChild>
					<OffCanvasFooterChild>{_SAVE_BTN}</OffCanvasFooterChild>
				</OffCanvasFooter>
			</OffCanvas>
		);
	}
	return (
		<Card className={classNames({ 'border border-zinc-500/25': isSubTab })}>
			<CardHeader>
				<CardHeaderChild>
					<Visible is={!!isSubTab}>
						<BackButton />
					</Visible>
					<Title title={_TITLE} />
				</CardHeaderChild>
				<CardHeaderChild>{_SAVE_BTN}</CardHeaderChild>
			</CardHeader>
			<CardBody>{_CONTENT}</CardBody>
			<CardFooter>
				<CardFooterChild>
					<Button
						color='red'
						onClick={() => {
							formik.resetForm();
						}}>
						Cancel
					</Button>
				</CardFooterChild>
				<CardFooterChild>{_SAVE_BTN}</CardFooterChild>
			</CardFooter>
		</Card>
	);
};

export default DeliveryAddressEditPartial;
