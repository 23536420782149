import {
	ICreateOfferForm,
	IFetchOfferByIdRequest,
	IFetchOfferRequest,
} from '../interface/OfferResource.interface';
import { getEntity, post, put } from './apiContext';
import { APIRequestParams } from '../interface/APIRequestParams.interface';
import { parseExpression } from '../services/utilService';

export const getOffers = async (
	{ filter, setData, setIsLoading, page, size, sort, direction, search }: IFetchOfferRequest,
	apiRequestParams?: APIRequestParams,
) => {
	return getEntity('/api/offer', setData, setIsLoading, {
		articleGroupId: filter.articleGroupId,
		articleNumber: apiRequestParams?.articleNumber ?? filter.articleNumber,
		customerId: apiRequestParams?.customerId ?? filter.customerId,
		endDate: filter.endDate,
		manufacturer: filter.manufacturer,
		offerType: filter.offerType,
		ourReference: apiRequestParams?.ourReference ?? filter.ourReference,
		projectNumber: apiRequestParams?.projectNumber ?? filter.projectNumber,
		startDate: filter.startDate,
		yourReference: apiRequestParams?.yourReference ?? filter.yourReference,
		page,
		size,
		sort,
		direction,
		search,
	});
};

export const getOfferById = async ({ offerId, setData, setIsLoading }: IFetchOfferByIdRequest) => {
	return getEntity(`/api/offer/details/${offerId}`, setData, setIsLoading);
};

export const createOrUpdateOfferApi = async (form: ICreateOfferForm) => {
	return post(`api/offer`, {
		offerDetails: {
			Offer: {
				CustomerName: form.CustomerName.replace('★ ', ''),
				CustomerNumber: form.CustomerNumber,
				OfferRows: form.OfferRows.map((row) => {
					const items: unknown[] = [
						{
							ArticleNumber: row.ArticleNumber,
							Description: row.Description,
							Discount: row.Discount,
							Quantity: row.Quantity,
							Price: row.Price,
						},
					];
					if (row.articlePackage && row.articlePackage.length > 0) {
						if (row.articlePackage[0].type == 'DYNAMIC') {
							row.articlePackage[0].packageItems.forEach((item) => {
								items.push({
									ArticleNumber: item.articleDto.articleNumber,
									Description: item.articleDto.description,
									Discount: 0,
									Quantity: parseExpression(
										row.Quantity,
										item.operator,
										item.quantity,
									),
									Price: 0,
								});
							});
						}
					}
					return items;
				}).flat(),
				Project: form.Project,
				DocumentNumber: form.DocumentNumber,
				YourReference: form.YourReference,
				OurReference: form.OurReference,
				DeliveryAddress1: form.DeliveryAddress1,
				DeliveryAddress2: form.DeliveryAddress2,
				DeliveryName: form.DeliveryName,
				DeliveryCountry: form.DeliveryCountry,
				DeliveryCity: form.DeliveryCity,
				DeliveryZipCode: form.DeliveryZipCode,
				Phone1: form.Phone1,
				ExpireDate: form.ExpireDate,
				OfferDate: form.OfferDate,
				PriceList: form.PriceList,
				Freight: form.Freight,
				TermsOfDelivery: form.TermsOfDelivery,
				TermsOfPayment: form.TermsOfPayment,
				WayOfDelivery: form.WayOfDelivery,
			},
		},
		deliveryAddressId: form.deliveryAddressId,
	});
};

export const createOrderFromOfferApi = (offerId: string) => {
	return put(`/api/offer/${offerId}/createorder`, null);
};
