import React, { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import Button from '../../../../components/ui/Button';

export const SortableArticleItemPartial = (props: { id: number; children: React.ReactNode }) => {
	const { id, children } = props;
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id,
	});

	const style: CSSProperties = {
		transform: CSS.Transform.toString(transform),
		transition,
		opacity: isDragging ? 0.3 : 1,
		zIndex: isDragging ? 1 : 0,
		position: 'relative',
	};

	return (
		<div
			id={id.toString()}
			ref={setNodeRef}
			className={classNames(
				'col-span-12 flex gap-4 rounded-xl border border-zinc-500/25 p-4',
				{ 'outline-dashed outline-2 outline-blue-500': isDragging },
			)}
			style={style}>
			<div className='flex items-center gap-4'>
				<Button
					{...attributes}
					{...listeners}
					// size='sm'
					icon='HeroArrowsUpDown'
					className='h-full'
					borderWidth='border-0'
				/>
			</div>
			{children}
		</div>
	);
};

export default SortableArticleItemPartial;
