import {
	ICreateInvoiceForm,
	IFetchInvoiceDetailsRequest,
	IFetchInvoiceRequest,
} from '../interface/Invoice.interface';
import { getEntity, post } from './apiContext';
import { APIRequestParams } from '../interface/APIRequestParams.interface';

export const getInvoiceApi = async (
	{ filter, setData, setIsLoading, page, size, sort, direction, search }: IFetchInvoiceRequest,
	apiRequestParams?: APIRequestParams,
) => {
	return getEntity(`/api/invoices`, setData, setIsLoading, {
		customerId: apiRequestParams?.customerId ?? filter.customerId,
		type: filter?.type,
		startDate: filter?.startDate,
		endDate: filter?.endDate,
		ourReference: apiRequestParams?.ourReference ?? filter?.ourReference,
		yourReference: apiRequestParams?.yourReference ?? filter?.yourReference,
		projectNumber: apiRequestParams?.projectNumber ?? filter?.projectNumber,
		page,
		size,
		sort,
		direction,
		search,
	});
};

export const getInvoiceById = async ({
	invoiceId,
	setData,
	setIsLoading,
}: IFetchInvoiceDetailsRequest) =>
	getEntity(`/api/invoices/${invoiceId}/details`, setData, setIsLoading);

export const createOrUpdateInvoiceApi = async (form: ICreateInvoiceForm) => {
	form.CustomerName = form.CustomerName.replace('★ ', '');
	return post(`/api/invoice`, { Invoice: form });
};
