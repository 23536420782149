import {
	IDeliveryAddressDetailsRequest,
	IDeliveryAddressRequest,
	IDeliveryForm,
} from '../interface/DeliveryAddress.interface';
import { APIRequestParams } from '../interface/APIRequestParams.interface';
import {
	createOrUpdateDeliveryAddressApi,
	deleteDeliveryAddressApi,
	getDeliveryAddressApi,
	getDeliveryAddressByIdApi,
} from '../api/deliveryAddressResourceApi';

export const fetchDeliveryAddress = async (
	deliveryAddressRequest: IDeliveryAddressRequest,
	apiRequestParams?: APIRequestParams,
) => {
	return getDeliveryAddressApi(deliveryAddressRequest, apiRequestParams);
};

export const fetchDeliveryAddressById = async (request: IDeliveryAddressDetailsRequest) => {
	return getDeliveryAddressByIdApi(request);
};

export const createOrUpdateDeliveryAddress = async (form: IDeliveryForm) => {
	return createOrUpdateDeliveryAddressApi(form);
};

export const deleteDeliveryAddress = async (addressId: number) => {
	return deleteDeliveryAddressApi(addressId);
};
