import React from 'react';
import AsideHeadPart from './_parts/AsideHead.part';
import Aside, { AsideBody } from '../../../components/layouts/Aside/Aside';
import Nav, { NavItem, NavSeparator, NavTitle } from '../../../components/layouts/Navigation/Nav';
import { supportPages, tenantViewPages } from '../../../config/pages.config';
import AsideFooterPart from './_parts/AsideFooter.part';
import { useTranslation } from 'react-i18next';

const SupportAsideTemplate = () => {
	const { t } = useTranslation();
	return (
		<Aside>
			<AsideHeadPart />
			<AsideBody>
				<Nav>
					<NavTitle>{t('Support')}</NavTitle>
					<NavItem
						{...supportPages.supportMainPages.subPages.supportStartPages}
						isEnd={false}
					/>
					<NavItem
						{...supportPages.supportMainPages.subPages.contactSupportPages}
						isEnd={false}
					/>
					<NavItem
						{...supportPages.supportMainPages.subPages.mySupportCasesPages}
						isEnd={false}
					/>
					<NavSeparator />
					<NavItem
						{...tenantViewPages.tenantPage.subPages.dashboardPage}
						text={t('Back to Operations')}
						icon='HeroArrowLeft'
					/>
				</Nav>
			</AsideBody>
			<AsideFooterPart />
		</Aside>
	);
};

export default SupportAsideTemplate;
