import React, { useContext } from 'react';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../../components/ui/Dropdown';
import Button from '../../../../components/ui/Button';
import ButtonGroup from '../../../../components/ui/ButtonGroup';
import DARK_MODE from '../../../../constants/darkMode.constant';
import useFontSize from '../../../../hooks/useFontSize';
import useDarkMode from '../../../../hooks/useDarkMode';
import { useTranslation } from 'react-i18next';
import Select from '../../../../components/form/Select';
import Icon from '../../../../components/icon/Icon';
import FieldWrap from '../../../../components/form/FieldWrap';
import Label from '../../../../components/form/Label';
import PERIOD, { TPeriodKEY } from '../../../../constants/periods.constant';
import SettingsContext from '../../../../context/settingsContext';

const SettingsPartial = () => {
	const { fontSize, setFontSize } = useFontSize();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { t } = useTranslation();

	const { activePeriod, setActivePeriod, pageSize, setPageSize } = useContext(SettingsContext);

	return (
		<Dropdown className='max-sm:hidden'>
			<DropdownToggle hasIcon={false}>
				<Button icon='HeroAdjustmentsHorizontal' aria-label={t('Settings')} />
			</DropdownToggle>
			<DropdownMenu placement='bottom-end' isCloseAfterLeave={false}>
				<div className='flex w-full gap-4 p-4'>
					<div className='flex w-full items-center gap-4'>
						<div className='grow'>
							<Label htmlFor='period' className='!m-0 text-zinc-500'>
								{t('Default period')}
							</Label>
						</div>
						<FieldWrap lastSuffix={<Icon icon='HeroChevronDown' className='mx-2' />}>
							<Select
								id='period'
								name='period'
								placeholder={t('Select period')}
								onChange={(e) => setActivePeriod(e.target.value as TPeriodKEY)}
								value={activePeriod}>
								{Object.keys(PERIOD).map((i) => (
									<option key={i} value={i}>
										{PERIOD?.[`${i as TPeriodKEY}`].text}
									</option>
								))}
							</Select>
						</FieldWrap>
					</div>
				</div>
				<div className='flex w-full gap-4 p-4'>
					<div className='flex w-full items-center gap-4'>
						<div className='grow'>
							<Label htmlFor='pagination' className='!m-0 text-zinc-500'>
								{t('Default pagination')}
							</Label>
						</div>
						<FieldWrap lastSuffix={<Icon icon='HeroChevronDown' className='mx-2' />}>
							<Select
								id='pagination'
								name='pagination'
								placeholder={t('Select pagination')}
								onChange={(e) => setPageSize(Number(e.target.value))}
								value={pageSize}>
								{[10, 20, 30, 40, 50].map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										{t('Show')} {pageSize}
									</option>
								))}
							</Select>
						</FieldWrap>
					</div>
				</div>
				<div className='flex w-full gap-4 p-4'>
					<div className='flex w-full items-center gap-4'>
						<div className='grow'>
							<Label htmlFor='pagination' className='!m-0 text-zinc-500'>
								{t('Font Size')}
							</Label>
						</div>
						<ButtonGroup>
							<Button
								icon='HeroMinus'
								onClick={() => setFontSize(fontSize - 1)}
								isDisable={fontSize <= 12}
							/>
							<Button isDisable>{fontSize}</Button>
							<Button
								icon='HeroPlus'
								onClick={() => setFontSize(fontSize + 1)}
								isDisable={fontSize >= 18}
							/>
						</ButtonGroup>
					</div>
				</div>
				<div className='flex w-full gap-4 p-4'>
					<div className='flex w-full items-center gap-4'>
						<div className='grow'>
							<Label htmlFor='pagination' className='!m-0 text-zinc-500'>
								{t('Dark Mode')}
							</Label>
						</div>
						<ButtonGroup>
							<Button
								icon='HeroMoon'
								onClick={() => setDarkModeStatus(DARK_MODE.DARK)}
								isActive={darkModeStatus === DARK_MODE.DARK}
							/>
							<Button
								icon='HeroSun'
								onClick={() => setDarkModeStatus(DARK_MODE.LIGHT)}
								isActive={darkModeStatus === DARK_MODE.LIGHT}
							/>
							<Button
								icon='HeroComputerDesktop'
								onClick={() => setDarkModeStatus(DARK_MODE.SYSTEM)}
								isActive={darkModeStatus === DARK_MODE.SYSTEM}
							/>
						</ButtonGroup>
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
};

export default SettingsPartial;
