import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { useLayoutEffect } from 'react';

const useAccessPage = (roles: string[]) => {
	const navigate = useNavigate();

	const { authUser } = useAuth();
	const authRoles = authUser?.roles || [];

	const hasAccess = roles.some((r) => authRoles.some((aR) => r.includes(aR)));

	useLayoutEffect(() => {
		if (!hasAccess) {
			navigate('/no-access');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasAccess]);
};

export default useAccessPage;
