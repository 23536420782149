import React from 'react';
import PageWrapper from '../../components/layouts/PageWrapper/PageWrapper';
import Container from '../../components/layouts/Container/Container';
import { useTranslation } from 'react-i18next';

const PrivacyPolicyPage = () => {
	const { t } = useTranslation();
	return (
		<PageWrapper isProtectedRoute={false} title={t('Privacy Policy')}>
			<Container breakpoint={null} className='w-full sm:py-0 md:py-4'>
				<section className='-mx-4 lg:py-20'>
					<div className='mx-5 overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
						<div className='container mx-auto px-4'>
							<p>
								<h1 className='display-2 mb-0'>{t('Privacy Policy')}</h1>
							</p>
							<p>
								<h6 className='h6 mb-sm-4 mb-3'>
									{t('Effective Date:')} 2024-08-30
								</h6>
							</p>
							<p className='mb-sm-4'>
								{t(
									'Centrasync AB ("we," "us," or "our") respects your privacy and is committed to protecting your personal data.',
								)}
								{t(
									'This Privacy Policy explains how we collect, use, store, and protect your information when you use our online platform ("Portal") and related services, including any data collected through third-party integrations such as Fortnox.',
								)}
							</p>

							<h4 className='h4 mb-sm-4 mb-3'>{t('Information We Collect')}</h4>

							<h5 className='h5 mb-sm-4 mb-3'>{t('Account Information')}</h5>
							<p className='mb-sm-4 mb-3'>
								{t(
									'When you create an account on our Portal, we collect the following information:',
								)}
								<li>
									{t(
										'Personal Information: Your name, email address, and any other personal details provided during account registration.',
									)}
								</li>
								<li>
									{t(
										'Company Information: Information about your company, such as company name, registration number, and billing details.',
									)}
								</li>
							</p>

							<h5 className='h5 mb-sm-4 mb-3'>
								{t('Data from Third-Party Integrations')}
							</h5>
							<p className='mb-sm-4 mb-3'>
								{t(
									'If you choose to integrate your Portal account with third-party services like Fortnox, we may collect additional data, including:',
								)}
								<li>
									{t(
										'Financial Data: Invoices, transaction records, and other financial data relevant to your business.',
									)}
								</li>
								<li>
									{t(
										'Operational Data: Information related to inventory management, order processing, and other business operations managed through third-party platforms.',
									)}
								</li>
							</p>

							<h5 className='h5 mb-sm-4 mb-3'>{t('Usage Data')}</h5>
							<p className='mb-sm-4 mb-3'>
								{t(
									'We collect information about your use of the Portal, including:',
								)}
								<li>
									{t(
										'Log Data: IP addresses, browser type, pages viewed, and the time and date of your visit.',
									)}
								</li>
								<li>
									{t(
										'Device Information: Details about the device used to access the Portal, including hardware model and operating system.',
									)}
								</li>
							</p>

							<h4 className='h4 mb-sm-4 mb-3'>{t('How We Use Your Information')}</h4>

							<h5 className='h5 mb-sm-4 mb-3'>
								{t('Providing and Improving Our Services')}
							</h5>
							<p className='mb-sm-4 mb-3'>
								{t('We use the information we collect to:')}
								<li>
									{t(
										'Provide Services: Facilitate the creation and management of your account, enable third-party integrations, and deliver the features and services of the Portal.',
									)}
								</li>
								<li>
									{t(
										'Improve Services: Analyze usage patterns to enhance the functionality, security, and user experience of the Portal.',
									)}
								</li>
								<li>
									{t(
										'Communicate with You: Send updates, notifications, and other communications related to your use of the Portal, including customer support and privacy-related inquiries.',
									)}
								</li>
							</p>

							<h5 className='h5 mb-sm-4 mb-3'>{t('Data Storage')}</h5>
							<p className='mb-sm-4 mb-3'>
								<li>
									{t(
										'Storage Locations: Your personal data is stored on secure servers located within the European Economic Area (EEA).',
									)}
									{t(
										'In cases where data is transferred outside of these regions, we ensure that appropriate safeguards are in place, such as standard contractual clauses or equivalent mechanisms, to protect your data.',
									)}
								</li>
								<li>
									{t(
										'Storage Duration: We retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.',
									)}
								</li>
							</p>
						</div>
					</div>
				</section>
			</Container>
		</PageWrapper>
	);
};

export default PrivacyPolicyPage;
