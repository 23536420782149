import React from 'react';
import AsideHeadPart from './_parts/AsideHead.part';
import Aside, { AsideBody } from '../../../components/layouts/Aside/Aside';
import Nav, {
	NavCollapse,
	NavItem,
	NavSeparator,
	NavTitle,
} from '../../../components/layouts/Navigation/Nav';
import { settingsPages, tenantViewPages } from '../../../config/pages.config';
import AsideFooterPart from './_parts/AsideFooter.part';
import { useTranslation } from 'react-i18next';

export const SettingsMenuPartial = () => {
	const pages = settingsPages.settingsMainPages.subPages;
	return (
		<>
			<NavItem {...pages.erpPages} isEnd={false} />
			<NavCollapse
				text={pages.siteSettingsPages.text}
				to={pages.siteSettingsPages.to}
				icon={pages.siteSettingsPages.icon}>
				<NavItem {...pages.siteSettingsPages.subPages.brandingPages} isEnd={false} />
				<NavItem {...pages.siteSettingsPages.subPages.languagesPages} isEnd={false} />
				<NavItem {...pages.siteSettingsPages.subPages.dateTimePages} isEnd={false} />
				<NavItem {...pages.siteSettingsPages.subPages.currenciesPages} isEnd={false} />
				<NavItem {...pages.siteSettingsPages.subPages.emailPages} isEnd={false} />
			</NavCollapse>
			<NavCollapse
				text={pages.dataSyncPages.text}
				to={pages.dataSyncPages.to}
				icon={pages.dataSyncPages.icon}>
				<NavItem {...pages.dataSyncPages.subPages.fullSyncPages} isEnd={false} />
				<NavItem {...pages.dataSyncPages.subPages.websocketLogPages} isEnd={false} />
			</NavCollapse>
			<NavCollapse
				text={pages.articlesPages.text}
				to={pages.articlesPages.to}
				icon={pages.articlesPages.icon}>
				<NavItem {...pages.articlesPages.subPages.settingsPages} isEnd={false} />
				<NavItem {...pages.articlesPages.subPages.fieldsPages} isEnd={false} />
				<NavItem {...pages.articlesPages.subPages.tabsPages} isEnd={false} />
				<NavItem {...pages.articlesPages.subPages.templatesPages} isEnd={false} />
				<NavItem {...pages.articlesPages.subPages.importExportDataPages} isEnd={false} />
			</NavCollapse>
			<NavCollapse
				text={pages.projectsPages.text}
				to={pages.projectsPages.to}
				icon={pages.projectsPages.icon}>
				<NavItem {...pages.projectsPages.subPages.settingsPages} isEnd={false} />
				<NavItem {...pages.projectsPages.subPages.fieldsPages} isEnd={false} />
				<NavItem {...pages.projectsPages.subPages.templatesPages} isEnd={false} />
			</NavCollapse>
			<NavCollapse
				text={pages.tasksPages.text}
				to={pages.tasksPages.to}
				icon={pages.tasksPages.icon}>
				<NavItem {...pages.tasksPages.subPages.categoriesPages} isEnd={false} />
				<NavItem {...pages.tasksPages.subPages.prioritiesPages} isEnd={false} />
			</NavCollapse>
			<NavCollapse
				text={pages.timeslipsPages.text}
				to={pages.timeslipsPages.to}
				icon={pages.timeslipsPages.icon}>
				<NavItem {...pages.timeslipsPages.subPages.timeslipsSettingsPages} isEnd={false} />
				<NavItem {...pages.timeslipsPages.subPages.timeslipsTypesPages} isEnd={false} />
			</NavCollapse>
			<NavCollapse text={pages.content.text} to={pages.content.to} icon={pages.content.icon}>
				<NavItem {...pages.content.subPages.categoriesPages} isEnd={false} />
			</NavCollapse>
		</>
	);
};

const SettingsAsideTemplate = () => {
	const { t } = useTranslation();
	return (
		<Aside>
			<AsideHeadPart />
			<AsideBody>
				<Nav>
					<NavTitle>{t('Settings')}</NavTitle>
					<SettingsMenuPartial />
					<NavSeparator />
					<NavItem
						{...tenantViewPages.tenantPage.subPages.dashboardPage}
						text={t('Back to Operations')}
						icon='HeroArrowLeft'
					/>
				</Nav>
			</AsideBody>
			<AsideFooterPart />
		</Aside>
	);
};

export default SettingsAsideTemplate;
