import React, { ReactNode } from 'react';
import PageWrapper from '../../components/layouts/PageWrapper/PageWrapper';
import Container from '../../components/layouts/Container/Container';
import Button from '../../components/ui/Button';
import { HeroImg } from '../../assets/images';
import { TIcons } from '../../types/icons.type';
import Icon from '../../components/icon/Icon';
import { useTranslation } from 'react-i18next';
import useHash from '../../hooks/useHash';
import { customerViewPages } from '../../config/pages.config';
import { Link } from 'react-router-dom';

const IconContentPartial = ({
	icon,
	title,
	children,
}: {
	icon: TIcons;
	title: string;
	children: ReactNode;
}) => {
	return (
		<div>
			<div className='flex flex-wrap gap-4'>
				<div className='flex items-center justify-center'>
					<Icon icon={icon} size='text-5xl' color='blue' />
				</div>
				<div className='flex basis-full text-2xl font-extrabold'>{title}</div>
				<div className='flex basis-full'>
					<div className='text-zinc-600 dark:text-zinc-400'>{children}</div>
				</div>
			</div>
		</div>
	);
};

const ProcurementPage = () => {
	const { t } = useTranslation();
	useHash();
	return (
		<PageWrapper isProtectedRoute={false} title={t('Procurement')}>
			<Container breakpoint={null} className='w-full'>
				<section
					id='top'
					className='-mx-4 -mt-[var(--header-height)] bg-indigo-500/5 pt-[var(--header-height)] lg:pt-[calc(var(--header-height)+6rem)]'>
					<div className='overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
						<div className='container mx-auto px-4'>
							<div className='grid grid-cols-12 items-center gap-14 lg:gap-32'>
								<div className='col-span-12 flex w-full flex-wrap gap-8 lg:col-span-6'>
									<h1 className='mb-4 max-w-2xl text-4xl font-extrabold !leading-snug text-zinc-700 dark:text-white md:text-5xl xl:text-6xl'>
										{t('Procurement Departments')}
									</h1>
								</div>
								<div className='col-span-12 flex w-full flex-col flex-wrap gap-4 lg:col-span-6'>
									<p className='max-w-3xl text-justify text-2xl font-extrabold !leading-relaxed text-zinc-700 dark:text-white'>
										{t('Optimize Your Procurement Process with Our Platform')}
									</p>
									<p className='max-w-3xl text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										{t(
											'Our platform is designed to make your purchasing department more efficient, offering advanced supplier management tools and a centralized, purchase-focused dashboard.',
										)}{' '}
										{t(
											'By consolidating supplier data, purchase orders, invoices, and spend analytics in one interface, you gain complete visibility over your procurement activities.',
										)}{' '}
										{t(
											'Automated workflows and real-time insights reduce administrative overhead, help prevent costly mistakes, and enable data-driven decisions that benefit the entire organization.',
										)}
									</p>
									<p className='max-w-3xl text-justify text-2xl font-extrabold !leading-relaxed text-zinc-700 dark:text-white'>
										{t('For Purchasing Departments')}
									</p>
									<ul className='max-w-3xl list-inside list-disc text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										<li>
											<span className='font-extrabold'>
												{t('Comprehensive Supplier Management:')}{' '}
											</span>
											{t(
												'Store and update supplier information—like contacts, catalogs, and contracts—in one secure location.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Purchase Order Creation & Tracking:')}{' '}
											</span>
											{t(
												'Generate and approve POs directly in the system, then monitor their status in real time.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Invoice & Payment Coordination:')}{' '}
											</span>
											{t(
												'Automate invoice matching and payment approvals for accuracy and prompt payments.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Quotes & Price Comparisons:')}{' '}
											</span>
											{t(
												'Request and compare bids side by side to make cost-effective decisions.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Budget Tracking & Spend Analysis:')}{' '}
											</span>
											{t(
												'Track expenditures against budgets for better financial control.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Approval Workflows:')}{' '}
											</span>
											{t(
												'Implement role-based permissions and multi-level approvals for compliance.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Collaboration & Communication:')}{' '}
											</span>
											{t(
												'Interact with suppliers directly through the platform, attaching relevant documents.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Automated Notifications:')}{' '}
											</span>
											{t(
												'Get alerts for overdue orders, expiring contracts, and other critical events.',
											)}
										</li>
									</ul>
									<p className='max-w-3xl text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										{t(
											'Centralize procurement tasks, enhance supplier relationships, and drive strategic purchasing decisions that deliver better outcomes.',
										)}
									</p>
									<p className='max-w-3xl text-justify text-2xl font-extrabold !leading-relaxed text-zinc-700 dark:text-white'>
										{t('For Your Suppliers')}
									</p>
									<ul className='max-w-3xl list-inside list-disc text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										<li>
											<span className='font-extrabold'>
												{t('Secure Portal:')}{' '}
											</span>
											{t(
												'Suppliers can log in to view and update company details, track POs, and submit invoices.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Faster Response Times:')}{' '}
											</span>
											{t(
												'Real-time notifications keep suppliers informed of order changes or payment statuses.',
											)}
										</li>
										<li>
											<span className='font-extrabold'>
												{t('Streamlined Communication:')}{' '}
											</span>
											{t(
												'Shared messaging and file uploads reduce email clutter and keep records organized.',
											)}
										</li>
									</ul>
									<p className='max-w-3xl text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										{t(
											'This clarity fosters a collaborative environment and leads to stronger, more reliable supplier partnerships.',
										)}
									</p>
									<p className='max-w-3xl text-justify text-2xl font-extrabold !leading-relaxed text-zinc-700 dark:text-white'>
										{t('Why Choose Our Platform?')}
									</p>
									<p className='max-w-3xl text-justify font-light !leading-relaxed text-zinc-600 dark:text-zinc-400 md:text-lg lg:text-xl'>
										{t(
											'Our solution serves as a single source of truth for all procurement-related tasks.',
										)}{' '}
										{t(
											'By automating repetitive processes, offering real-time insights, and promoting supplier collaboration, your team can focus on strategic initiatives and cost-saving opportunities.',
										)}
									</p>
									<p className='max-w-3xl text-justify text-2xl font-extrabold !leading-relaxed text-zinc-700 dark:text-white'>
										{t(
											'Take the next step with our platform and elevate your procurement process today!',
										)}
									</p>
									<div className='flex basis-full'>
										<Link
											to={{
												pathname: customerViewPages.contactUsPage.to,
												search: '?subject=Demo&description=Please contact me to schedule a demo',
											}}>
											<Button variant='solid' size='xl'>
												{t('Start your project')}
											</Button>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className='-mx-4 bg-indigo-500/5'>
					<div className='overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
						<div className='container mx-auto px-4'>
							<div className='grid grid-cols-1 items-center gap-14 border-t border-zinc-500/50 py-20 lg:grid-cols-12 lg:gap-32'>
								<div className='flex w-full flex-wrap gap-8 lg:col-span-6'>
									<div className='grid grid-cols-1 items-center gap-4'>
										<div className='flex w-full flex-wrap'>
											<div className='mb-16 flex justify-center text-3xl font-extrabold dark:text-white md:text-4xl xl:text-5xl'>
												{t('Features')}
											</div>
										</div>
										<div className='flex w-full flex-wrap'>
											<div className='grid grid-cols-1 gap-16 sm:grid-cols-2 md:grid-cols-2'>
												<IconContentPartial
													icon='HeroRocketLaunch'
													title={t('Projekthantering')}>
													{t(
														'Koppla försäljning och arbetstid till projekt',
													)}
												</IconContentPartial>
												<IconContentPartial
													icon='HeroHandThumbUp'
													title={t('Ärendehantering')}>
													{t(
														'Skapa ärenden, koppla till projekt, kategorisera ärenden och rapportera arbetstid',
													)}
												</IconContentPartial>
												<IconContentPartial
													icon='HeroUsers'
													title={t('Arbetstid')}>
													{t(
														'Kategorisera och rapportera betald och icke betald arbetstid. Kunder kan logga in och se och godkänna upparbetad arbetstid innan ni fakturerar',
													)}
												</IconContentPartial>
												<IconContentPartial
													icon='HeroClock'
													title={t('Business intelligence')}>
													{t(
														'Lär er mer om er verksamhet genom att utforska data och övervaka nyckeltal',
													)}
												</IconContentPartial>
												<IconContentPartial
													icon='HeroClock'
													title={t('Händelser')}>
													{t(
														'För varje händelse i kundportalen skapas en logg som ni sedan kan ta del av',
													)}
												</IconContentPartial>
											</div>
										</div>
									</div>
								</div>
								<div className='block w-full lg:col-span-6'>
									<div className='w-full max-w-[96rem] rounded-3xl bg-gradient-to-r from-zinc-500/50 to-purple-950/25 p-6 sm:w-auto lg:w-[55vw]'>
										<img
											src={HeroImg}
											alt='Dashboard image'
											className='w-full rounded-xl object-cover lg:h-auto'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Container>
		</PageWrapper>
	);
};

export default ProcurementPage;
