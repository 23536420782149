import React, { FC } from 'react';
import UserTemplate from '../../User/User.template';
import DarkModeSwitcherPart from './DarkModeSwitcher.part';
import { AsideFooter } from '../../../../components/layouts/Aside/Aside';
import Nav, { NavItem, NavSeparator } from '../../../../components/layouts/Navigation/Nav';
import Visible from '../../../../components/utils/Visible';
import { settingsPages } from '../../../../config/pages.config';

interface IAsideFooterPartProps {
	showSettings?: boolean;
	showSupport?: boolean;
	showDocumentation?: boolean;
}
const AsideFooterPart: FC<IAsideFooterPartProps> = (props) => {
	const { showSettings, showSupport, showDocumentation } = props;
	return (
		<AsideFooter>
			<Nav>
				<Visible is={!!showSettings || !!showSupport || !!showDocumentation}>
					<NavSeparator />
				</Visible>
				<Visible is={!!showSettings}>
					<NavItem
						to={settingsPages.settingsMainPages.to}
						text={settingsPages.settingsMainPages.text}
						icon={settingsPages.settingsMainPages.icon}
					/>
				</Visible>
				<Visible is={!!showSupport}>
					<NavItem to='/support' text='Support' icon='HeroQuestionMarkCircle' />
				</Visible>
				<Visible is={!!showDocumentation}>
					<NavItem to='/documentation' text='Documentation' icon='HeroBookOpen' />
				</Visible>
			</Nav>
			<UserTemplate />
			<DarkModeSwitcherPart />
		</AsideFooter>
	);
};

export default AsideFooterPart;
