import {
	IFetchUserListRequest,
	IFetchUserRequest,
	IUpdateUserPasswordRequest,
	IUserDataResponse,
} from '../interface/UserResource.interface';
import { del, get, getAuthUser, getEntity, post, put } from './apiContext';
import { APIRequestParams } from '../interface/APIRequestParams.interface';

export const getUserDetails = async ({ uid, setUserData, setIsLoading }: IFetchUserRequest) => {
	return getEntity(`/api/user/${uid}`, setUserData, setIsLoading);
};

export const getTenantUsers = async (
	{ filter, setData, setIsLoading }: IFetchUserListRequest,
	apiRequestParams?: APIRequestParams,
) => {
	const apiFilter = filter.status === 'ALL' ? null : filter.status?.toLocaleLowerCase();
	return getEntity(`/api/tenant/users`, setData, setIsLoading, {
		filter: apiFilter,
		tenantId: apiRequestParams?.tenantId,
	});
};

export const getCustomerUsers = async (
	{ filter, setData, setIsLoading }: IFetchUserListRequest,
	apiRequestParams?: APIRequestParams,
) => {
	const apiFilter = filter.status === 'ALL' ? null : filter.status?.toLocaleLowerCase();
	return getEntity(
		`/api/customers/${apiRequestParams?.customerId ?? filter.customerId}/users`,
		setData,
		setIsLoading,
		{
			filter: apiFilter,
		},
	);
};

export const getCustomerUsersForTenant = async ({
	filter,
	setData,
	setIsLoading,
}: IFetchUserListRequest) => {
	const apiFilter = filter.status === 'ALL' ? null : filter.status?.toLocaleLowerCase();
	return getEntity(`/api/customers/users`, setData, setIsLoading, {
		filter: apiFilter,
	});
};

export const getProjectUsersForTenant = async (
	{ filter, setData, setIsLoading }: IFetchUserListRequest,
	apiRequestParams?: APIRequestParams,
) => {
	return getEntity(
		`/api/projects/users/${apiRequestParams?.projectId ?? filter.projectId}`,
		setData,
		setIsLoading,
	);
};

export const createOrUpdateTenantUserApi = async (form: IUserDataResponse) => {
	const authUser = getAuthUser();
	const data = {
		role: form.userRole,
		title: form.title,
		name: form.name,
		username: form.email,
		city: form.city,
		timezone: form.timezone,
		phone: form.phone,
		mobile: form.mobile,
		fortnoxUsername: form.fortnoxUsername,
		id: form.id,
		profileImageId: form.profileImageId,
	};
	return form.id
		? put(`/api/tenant/${authUser.tenantId}/users/${form.id}`, data)
		: post(`/api/tenant/${authUser.tenantId}/users`, data);
};

export const createOrUpdateCustomerUserApi = async (form: IUserDataResponse) => {
	const data = {
		id: form.id,
		title: form.title,
		name: form.name,
		username: form.email,
		city: form.city,
		timezone: form.timezone,
		phone: form.phone,
		mobile: form.mobile,
		role: form.userRole,
		customer: form.customerId,
		fortnoxUsername: form.fortnoxUsername,
		profileImageId: form.profileImageId,
	};
	return form.id
		? put(`/api/customers/${form.customerId}/users/${form.id}`, data)
		: post(`/api/customers/${form.customerId}/users`, data);
};

export const updatePasswordApi = async (request: IUpdateUserPasswordRequest) => {
	return put(`/api/user/overridePassword/${request.userId}`, {
		password: request?.password,
		confirmPassword: request?.confirmPassword,
	});
};

export const deactivateUserApi = async (userId: number) => {
	return del(`/api/user/${userId}`);
};

export const activateUserApi = async (userId: number) => {
	return get(`/api/user/activate/${userId}`);
};
