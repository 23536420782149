import { get } from './apiContext';
import { IArticle } from '../interface/Article.interface';
import { IPricesResponse } from '../interface/Article.interface';

export const getArticleDetailsApi = (articleNumber: string) => {
	return get<IArticle>(`/article/${articleNumber}?sync=false`);
};

export const getArticlePriceListApi = (articleId: number, priceListCode: string) => {
	return get<IPricesResponse>(`/api/article/v2/${articleId}/priceList/${priceListCode}`);
};
