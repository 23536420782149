import React from 'react';
import Aside, { AsideBody } from '../../../components/layouts/Aside/Aside';
import { subscriptionPages, superAdminPage, tenantViewPages } from '../../../config/pages.config';
import Nav, {
	NavButton,
	NavCollapse,
	NavItem,
	NavSeparator,
	NavTitle,
} from '../../../components/layouts/Navigation/Nav';
import AsideHeadPart from './_parts/AsideHead.part';
import AsideFooterPart from './_parts/AsideFooter.part';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAccessCheck from '../../../hooks/useAccessCheck';
import ROLES from '../../../constants/roles.constant';
import Visible from '../../../components/utils/Visible';
import {
	hasAnyModuleEnabled,
	hasModuleEnabledForLoggedInUser,
} from '../../../utils/permission.util';

const DefaultAsideTemplate = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const hasSuperAdminAccess = useAccessCheck([ROLES.ROLE_SUPER_ADMIN]);
	const hasTenantAccess = useAccessCheck([ROLES.ROLE_TENANT_ADMIN, ROLES.ROLE_TENANT_USER]);

	const tenantPages = tenantViewPages.tenantPage.subPages;
	const superAdminPages = superAdminPage.superAdminPage.subPages;

	if (window.location.hostname === 'linnalfredsson.se') return null;
	return (
		<Aside>
			<AsideHeadPart />
			<AsideBody>
				<Visible is={hasTenantAccess}>
					<Nav>
						<NavTitle>{t('Operations')}</NavTitle>
						<NavItem {...tenantPages.dashboardPage} />
						{hasModuleEnabledForLoggedInUser('CUSTOMERS') && (
							<NavItem
								text={tenantPages.customersPage.text}
								to={tenantPages.customersPage.to}
								icon={tenantPages.customersPage.icon}
								isEnd={false}
							/>
						)}
						{hasModuleEnabledForLoggedInUser('ARTICLES') && (
							<NavCollapse
								text={tenantPages.productsPage.text}
								to={tenantPages.productsPage.to}
								icon={tenantPages.productsPage.icon}>
								<NavItem
									{...tenantPages.productsPage.subPages.articlesPage}
									isEnd={false}
								/>
								<NavItem
									{...tenantPages.productsPage.subPages.articleGroupsPage}
									isEnd={false}
								/>
								<NavItem
									{...tenantPages.productsPage.subPages.categoriesPage}
									isEnd={false}
								/>
								<NavItem
									{...tenantPages.productsPage.subPages.productListsPage}
									isEnd={false}
								/>
								<NavItem
									{...tenantPages.productsPage.subPages.manufacturersPage}
									isEnd={false}
								/>
							</NavCollapse>
						)}
						{hasAnyModuleEnabled(['OFFERS', 'ORDERS', 'INVOICES']) && (
							<>
								<NavTitle>{tenantPages.salesPage.text}</NavTitle>
								{hasModuleEnabledForLoggedInUser('OFFERS') && (
									<NavItem
										{...tenantPages.salesPage.subPages.offersPage}
										isEnd={false}>
										<NavButton
											icon='HeroPlusCircle'
											iconColor='emerald'
											title={t('New Offer')}
											onClick={() => {
												navigate(
													`../${tenantPages.salesPage.subPages.offersPage.to}/new`,
												);
											}}
										/>
									</NavItem>
								)}
								{hasModuleEnabledForLoggedInUser('ORDERS') && (
									<NavItem
										{...tenantPages.salesPage.subPages.ordersPage}
										isEnd={false}>
										<NavButton
											icon='HeroPlusCircle'
											iconColor='emerald'
											title={t('New Order')}
											onClick={() => {
												navigate(
													`../${tenantPages.salesPage.subPages.ordersPage.to}/new`,
												);
											}}
										/>
									</NavItem>
								)}
								{hasModuleEnabledForLoggedInUser('INVOICES') && (
									<NavItem
										{...tenantPages.salesPage.subPages.invoicesPage}
										isEnd={false}>
										<NavButton
											icon='HeroPlusCircle'
											iconColor='emerald'
											title={t('New Invoice')}
											onClick={() => {
												navigate(
													`../${tenantPages.salesPage.subPages.invoicesPage.to}/new`,
												);
											}}
										/>
									</NavItem>
								)}
								<NavSeparator />
							</>
						)}
						{/*{hasAnyModuleEnabled(['OFFERS', 'ORDERS', 'INVOICES']) && (*/}
						{/*	<NavCollapse*/}
						{/*		text={tenantPages.salesPage.text}*/}
						{/*		to={tenantPages.salesPage.to}*/}
						{/*		icon={tenantPages.salesPage.icon}>*/}
						{/*		Children*/}
						{/*	</NavCollapse>*/}
						{/*)}*/}
						{hasAnyModuleEnabled(['PROJECTS', 'TASKS']) && (
							<>
								<NavTitle>{tenantPages.workManagementPages.text}</NavTitle>
								{hasModuleEnabledForLoggedInUser('PROJECTS') && (
									<NavItem
										{...tenantPages.workManagementPages.subPages.projectsPage}
										isEnd={false}>
										<NavButton
											icon='HeroPlusCircle'
											iconColor='emerald'
											title={t('New Project')}
											onClick={() => {
												navigate(
													`../${tenantPages.workManagementPages.subPages.projectsPage.to}/new?activeTab=details`,
												);
											}}
										/>
									</NavItem>
								)}
								{hasModuleEnabledForLoggedInUser('TASKS') && (
									<NavItem
										{...tenantPages.workManagementPages.subPages.tasksPage}
										isEnd={false}>
										<NavButton
											icon='HeroPlusCircle'
											iconColor='emerald'
											title={t('New Task')}
											onClick={() => {
												navigate(
													`../${tenantPages.workManagementPages.subPages.tasksPage.to}/new`,
												);
											}}
										/>
									</NavItem>
								)}
								{hasModuleEnabledForLoggedInUser('TASKS') && (
									<NavItem
										{...tenantPages.workManagementPages.subPages.timeslipsPage}
										isEnd={false}>
										<NavButton
											icon='HeroPlusCircle'
											iconColor='emerald'
											title={t('New Timeslip')}
											onClick={() => {
												navigate(
													`../${tenantPages.workManagementPages.subPages.timeslipsPage.to}/new`,
												);
											}}
										/>
									</NavItem>
								)}
								<NavSeparator />
							</>
						)}
						{/*{hasAnyModuleEnabled(['PROJECTS', 'TASKS']) && (*/}
						{/*	<NavCollapse*/}
						{/*		text={tenantPages.workManagementPages.text}*/}
						{/*		to={tenantPages.workManagementPages.to}*/}
						{/*		icon={tenantPages.workManagementPages.icon}>*/}
						{/*		Children*/}
						{/*	</NavCollapse>*/}
						{/*)}*/}
						{hasModuleEnabledForLoggedInUser('CONTENT') && (
							<NavCollapse
								text={tenantPages.content.text}
								to={tenantPages.content.to}
								icon={tenantPages.content.icon}>
								<NavItem
									{...tenantPages.content.subPages.postsPage}
									isEnd={false}
								/>
							</NavCollapse>
						)}
						{hasModuleEnabledForLoggedInUser('EVENTS') && (
							<NavItem {...tenantPages.eventsPage} isEnd={false} />
						)}
						<NavItem {...tenantPages.usersPage} isEnd={false} />
						<NavItem {...tenantPages.organizationPage} isEnd={false} />
						<NavSeparator />
						<NavItem {...subscriptionPages.subscriptionMainPages} isEnd={false} />
					</Nav>
				</Visible>
				<Visible is={hasSuperAdminAccess}>
					<Nav>
						<NavTitle>{t('Operations')}</NavTitle>
						<NavItem {...superAdminPages.dashboardPage} isEnd={false} />
						<NavItem {...superAdminPages.tenantsPage} isEnd={false} />
						<NavItem {...superAdminPages.tasksPage} isEnd={false} />
						<NavItem {...superAdminPages.sessionsPage} isEnd={false} />
						<NavItem {...superAdminPages.logsPage} isEnd={false} />
						<NavItem {...superAdminPages.performancesPage} isEnd={false} />
					</Nav>
				</Visible>
			</AsideBody>
			<AsideFooterPart />
		</Aside>
	);
};

export default DefaultAsideTemplate;
