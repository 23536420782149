import {
	activateUserApi,
	createOrUpdateCustomerUserApi,
	createOrUpdateTenantUserApi,
	deactivateUserApi,
	getCustomerUsers,
	getCustomerUsersForTenant,
	getProjectUsersForTenant,
	getTenantUsers,
	getUserDetails,
	updatePasswordApi,
} from '../api/userResourceApi';
import {
	IFetchUserListRequest,
	IFetchUserRequest,
	IUpdateUserPasswordRequest,
	IUserDataResponse,
} from '../interface/UserResource.interface';
import { APIRequestParams } from '../interface/APIRequestParams.interface';

export const fetchUserDetails = async ({ uid, setUserData, setIsLoading }: IFetchUserRequest) => {
	return getUserDetails({ uid, setUserData, setIsLoading });
};

export const fetchUsers = async (
	{ filter, setData, setIsLoading }: IFetchUserListRequest,
	apiRequestParams?: APIRequestParams,
) => {
	if (apiRequestParams?.projectId || filter.projectId) {
		return getProjectUsersForTenant({ filter, setData, setIsLoading }, apiRequestParams);
	}
	if (filter.userType === 'TENANT') {
		return getTenantUsers({ filter, setData, setIsLoading }, apiRequestParams);
	}
	if (filter.userType === 'CUSTOMERS' && (filter.customerId || apiRequestParams?.customerId)) {
		return getCustomerUsers({ filter, setData, setIsLoading }, apiRequestParams);
	}
	if (filter.userType === 'CUSTOMERS') {
		return getCustomerUsersForTenant({ filter, setData, setIsLoading });
	}
	throw new Error('Invalid user type');
};

export const createOrUpdateUser = async (form: IUserDataResponse) => {
	return createOrUpdateTenantUserApi(form);
};

export const createOrUpdateCustomerUser = async (form: IUserDataResponse) => {
	return createOrUpdateCustomerUserApi(form);
};

export const updateUserPassword = async (request: IUpdateUserPasswordRequest) => {
	return updatePasswordApi(request);
};

export const deactivateUser = async (userId: number) => {
	return deactivateUserApi(userId);
};

export const activateUser = async (userId: number) => {
	return activateUserApi(userId);
};
