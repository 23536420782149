import axios, { AxiosResponse } from 'axios';
import { IAuthenticateUserResponse } from '../interface/Auth.interface';

export const getTokenExpiry = () => {
	const token: string = getAccessToken();
	if (token) {
		const decoded = JSON.parse(atob(token.split('.')[1]));
		return decoded.exp;
	}
	return 0;
};

export const isTokenExpired = (): boolean => {
	const expiryDate = new Date(getTokenExpiry() * 1000);
	return expiryDate < new Date();
};

export const getAccessToken = (): string => {
	try {
		const authUser: IAuthenticateUserResponse = JSON.parse(
			localStorage.getItem('authUser') as string,
		);
		return authUser.accessToken;
	} catch (e) {
		console.error('Error with accessToken: ', e);
		window.location.href = '/login';
	}
	return '';
};

/*const addAuthHeader = (config: any) => {
	const accessToken = getAccessToken();
	if (accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`;
	}
	return config;
};
axiosClient.interceptors.request.use(addAuthHeader);*/

export const getAuthUser = (): IAuthenticateUserResponse => {
	return JSON.parse(localStorage.getItem('authUser') as string);
};

export const isCustomerLogin = () => {
	const authUser = getAuthUser();
	return (
		authUser?.roles.includes('ROLE_CUSTOMER_ADMIN') ||
		authUser?.roles.includes('ROLE_CUSTOMER_USER')
	);
};

export const isTenantLogin = () => {
	const authUser = getAuthUser();
	return (
		authUser?.roles.includes('ROLE_TENANT_ADMIN') ||
		authUser?.roles.includes('ROLE_TENANT_USER')
	);
};

const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

axiosClient.interceptors.request.use(async (config) => {
	const expiryTimestamp = getTokenExpiry() * 1000;
	const timeDifferenceInMillis = expiryTimestamp - new Date().getTime();
	const timeDifferenceInMinutes = timeDifferenceInMillis / (1000 * 60);
	if (timeDifferenceInMinutes < 10) {
		const updateToken: string = await refreshToken();
		config.headers.Authorization = `Bearer ${updateToken}`;
	}
	return config;
});

axiosClient.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response && error.response.status === 401) {
			try {
				await refreshToken();
				return axios(error.config);
			} catch (refreshError) {
				console.error('Token refresh failed', refreshError);
				window.location.href = '/login';
			}
		}
		return Promise.reject(error);
	},
);

const refreshToken = async () => {
	const refreshToken = getAccessToken();
	const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/refreshtoken`, {
		refreshToken,
	});
	if (response.status !== 200) throw new Error();
	const data: IAuthenticateUserResponse = response.data;
	localStorage.setItem('authUser', JSON.stringify(data));
	return data.accessToken;
};

export const get = async <T>(
	url: string,
	params?: Record<string, unknown>,
): Promise<AxiosResponse<T>> => {
	const accessToken = getAccessToken();
	return axiosClient.get<T>(url, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
		params,
	});
};

export const del = async (
	url: string,
	params?: Record<string, unknown>,
): Promise<AxiosResponse> => {
	const accessToken = getAccessToken();
	return axiosClient.delete(url, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
		params,
	});
};

export const getEntity = async <T>(
	url: string,
	setData?: (data: T) => void,
	setIsLoading?: (isLoading: boolean) => void,
	params?: Record<string, unknown>,
): Promise<T> => {
	try {
		if (setIsLoading) {
			setIsLoading(true);
		}
		const response = await get(url, params);
		// @ts-ignore
		setData(response.data);
		if (setIsLoading) {
			setIsLoading(false);
		}
		return response.data as T;
	} catch (error) {
		// @ts-ignore
		console.error('Error fetching entity:', error.response?.data || error.message);
		if (setIsLoading) {
			setIsLoading(false);
		}
	}
	return undefined as unknown as T;
};

export const postMultiPart = async (url: string, data: FormData): Promise<AxiosResponse> => {
	const accessToken = getAccessToken();
	return axiosClient.postForm(url, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

export const post = async (url: string, data: unknown): Promise<AxiosResponse> => {
	const accessToken = getAccessToken();
	return axiosClient.post(url, data, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

export const put = async (url: string, data: unknown): Promise<AxiosResponse> => {
	const accessToken = getAccessToken();
	return axiosClient.put(url, data, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};
