import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SelectReact, { TSelectOptions } from '../../../components/form/SelectReact';
import { useFormik } from 'formik';
import useSaveBtn from '../../../hooks/useSaveBtn';
import Card, { CardBody, CardFooter, CardFooterChild } from '../../../components/ui/Card';
import Label from '../../../components/form/Label';
import FieldWrap from '../../../components/form/FieldWrap';
import Icon from '../../../components/icon/Icon';
import Select from '../../../components/form/Select';
import Input from '../../../components/form/Input';
import Textarea from '../../../components/form/Textarea';
import Button from '../../../components/ui/Button';
import classNames from 'classnames';
import { useParams, useSearchParams } from 'react-router-dom';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasFooter,
	OffCanvasFooterChild,
	OffCanvasHeader,
} from '../../../components/ui/OffCanvas';
import { useTranslation } from 'react-i18next';
import {
	IFetchProjectResponse,
	IProjectId,
	TProjectDataResponse,
} from '../../../interface/ProjectResource.interface';
import { useAppDataContext } from '../../../context/appDataContext';
import FormCancelBtn from '../../../components/utils/FormCancelBtn';
import {
	createOrUpdateProject,
	fetchNextProjectNumber,
	fetchProject,
} from '../../../services/projectService';
import { toast } from 'react-toastify';
import { getAuthUser, isCustomerLogin } from '../../../api/apiContext';
import { IAuthenticateUserResponse } from '../../../interface/Auth.interface';
import Validation from '../../../components/form/Validation';
import dayjs from 'dayjs';
import Progress from '../../../components/ui/Progress';

const ProjectDetailsEditPartial = ({
	isSubTab,
	inCanvas,
	isNew,
	isOpen,
	setIsOpen,
	project,
}: {
	isSubTab?: boolean;
	inCanvas?: boolean;
	isNew?: boolean;
	isOpen?: boolean;
	setIsOpen?: Dispatch<SetStateAction<boolean>>;
	project?: IFetchProjectResponse;
}) => {
	const { t } = useTranslation();
	const { id } = useParams();
	const isNewItem = isNew || id === 'new';
	const [searchParam] = useSearchParams();
	const customerLogin: boolean = isCustomerLogin();
	const authUser: IAuthenticateUserResponse = getAuthUser();
	const customerId = customerLogin ? authUser.customerId : searchParam.get('customerId');
	const [suggestedProjectNumber, setSuggestedProjectNumber] = useState<number | undefined>(
		undefined,
	);
	const { tenantUsers, customerUsers, customers, projectTemplates } = useAppDataContext();

	useEffect(() => {
		if (!suggestedProjectNumber) {
			fetchNextProjectNumber({
				setData: setSuggestedProjectNumber,
				setIsLoading: () => {},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const notify = (promise: Promise<unknown>) =>
		toast
			.promise(
				promise
					.then((response) => response)
					.catch((error) => {
						const errorMessage =
							error?.response?.data[0] || 'An unexpected error occurred';
						toast.error(errorMessage);
						throw error;
					}),
				{
					pending: 'Saving..',
					success: 'Project saved successfully..',
				},
			)
			.then(() => {
				window.history.back();
			})
			.finally(() => {
				setIsSaving(false);
			});

	const [projectData, setProjectData] = useState<IFetchProjectResponse>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isUniqueId, setIsUniqueId] = useState<boolean>(false);

	const handleFetchProject = async (projectId: IProjectId['projectId']) => {
		await fetchProject({ projectId, setProjectData, setIsLoading });
	};

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const formik = useFormik<TProjectDataResponse>({
		enableReinitialize: true,
		initialValues: {
			id: project?.id ?? null,
			projectNumber: project?.ProjectNumber ?? suggestedProjectNumber?.toString() ?? '',
			customerName: customerId ? customerId.toString() : (project?.customerId ?? ''),
			templateName: project?.projectTemplate?.id?.toString() ?? '',
			description: project?.Description ?? '',
			projectLeader:
				(project?.projectLeaders
					.map((p) => {
						const user = tenantUsers?.find((user) => user.id === p);
						return user ? { label: user?.label, value: user?.id?.toString() } : null;
					})
					.filter(Boolean) as TSelectOptions) ?? [],
			projectParticipants:
				(project?.projectParticipants
					.map((p) => {
						const user = tenantUsers?.find((user) => user.id === p);
						return user ? { label: user?.label, value: user?.id?.toString() } : null;
					})
					.filter(Boolean) as TSelectOptions) ?? [],
			startDate: project?.StartDate ?? '',
			endDate: project?.EndDate ?? '',
			status: project?.Status ?? '',
			comments: project?.Comments ?? '',
		},
		validate: (values) => {
			const errors: { [key: string]: string } = {};

			if (!values.customerName) {
				errors.customerName = t('Required');
			}

			if (!values.projectNumber) {
				errors.projectNumber = t('Required');
			}
			if (!isUniqueId) {
				errors.projectNumber = t('Must be an unique name');
			}

			if (!values.description || values.description === '') {
				errors.description = t('Required');
			}

			if (dayjs(values.startDate).isBefore(dayjs(), 'day') && isNewItem) {
				errors.startDate = t('A date before today cannot be selected.');
			}

			if (dayjs(values.endDate).isBefore(dayjs(values.startDate), 'day') && isNewItem) {
				errors.endDate = t('A date before the start date cannot be selected.');
			}

			if (!values.status) {
				errors.status = t('Required');
			}

			if (values.comments.length >= 500) {
				errors.comments = t('Must be less than 500 characters.');
			}

			return errors;
		},
		onSubmit: (values) => {
			const savePromise = createOrUpdateProject(values);
			notify(savePromise);
		},
	});

	useEffect(() => {
		if (isNewItem) handleFetchProject(formik.values.projectNumber as string);
	}, [formik.values.projectNumber, isNewItem]);

	useEffect(() => {
		setIsUniqueId(!(projectData?.ProjectNumber === formik.values.projectNumber));
	}, [formik.values.projectNumber, projectData]);

	const { saveBtnText, saveBtnColor, saveBtnDisable } = useSaveBtn({
		isNewItem,
		isSaving,
		isDirty: formik.dirty,
	});

	const getProjectUsers = () => {
		const users = [
			{
				label: 'Tenant',
				options: tenantUsers,
			},
		];
		if (customerLogin) {
			const customers = customerUsers.filter(
				(user) => user.customerId === Number(customerId),
			);
			users.push({ label: 'Customer', options: customers });
		} else {
			const customers = customerUsers.filter(
				(user) => user.customerId === Number(formik.values.customerName),
			);
			users.push({ label: 'Customer', options: customers });
		}
		return users;
	};

	const _TITLE = `${isNewItem ? t('New') : `${project?.ProjectNumber}'s`} ${t('Project Details')}`;
	const _CONTENT = (
		<form noValidate className='grid grid-cols-12 gap-4'>
			{!customerId && (
				<div className='col-span-12'>
					<Label htmlFor='customerName'>{t('Customer Name')}</Label>
					<Validation
						isValid={formik.isValid}
						isTouched={formik.touched.customerName}
						invalidFeedback={formik.errors.customerName}
						validFeedback='Good'>
						<FieldWrap
							firstSuffix={<Icon icon='HeroUser' className='mx-2' />}
							lastSuffix={<Icon icon='HeroChevronDown' className='mx-2' />}>
							<Select
								id='customerName'
								name='customerName'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.customerName}
								placeholder={t('Select Customer')}>
								{[
									{
										id: undefined,
										label: t('Select Customer'),
										value: undefined,
									},
									...(customers || []),
								]?.map((i) => (
									<option key={i.id} value={i.id ?? ''}>
										{i.label}
									</option>
								))}
							</Select>
						</FieldWrap>
					</Validation>
				</div>
			)}
			<div className='col-span-12'>
				<Label htmlFor='templateName'>{t('Template Name')}</Label>

				<FieldWrap
					firstSuffix={<Icon icon='HeroComputerDesktop' className='mx-2' />}
					lastSuffix={<Icon icon='HeroChevronDown' className='mx-2' />}>
					<Select
						id='templateName'
						name='templateName'
						onChange={formik.handleChange}
						value={formik.values.templateName}
						placeholder={t('Select Template')}>
						{[
							{
								id: undefined,
								label: t('Select Template'),
								value: undefined,
							},
							...(projectTemplates || []),
						].map((i) => (
							<option key={i.id} value={i.id ?? ''}>
								{i.label}
							</option>
						))}
					</Select>
				</FieldWrap>
			</div>
			<div className='col-span-12'>
				<Label htmlFor='projectNumber'>{t('Project Number')}</Label>
				<Validation
					isValid={formik.isValid}
					isTouched={formik.touched.projectNumber}
					invalidFeedback={formik.errors.projectNumber}
					validFeedback='Good'>
					<FieldWrap firstSuffix={<Icon icon='HeroHashtag' className='mx-2' />}>
						<Input
							id='projectNumber'
							name='projectNumber'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.projectNumber}
							placeholder={t('Project Number')}
						/>
					</FieldWrap>
				</Validation>
			</div>
			<div className='col-span-12'>
				<Label htmlFor='description'>{t('Description')}</Label>
				<Validation
					isValid={formik.isValid}
					isTouched={formik.touched.description}
					invalidFeedback={formik.errors.description}
					validFeedback='Good'>
					<Textarea
						id='description'
						name='description'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.description}
						placeholder={t('Description')}
					/>
				</Validation>
			</div>
			<div className='col-span-12 lg:col-span-6' key={formik.values.customerName}>
				<Label htmlFor='projectLeader'>{t('Project Leaders')}</Label>
				<FieldWrap lastSuffix={<Icon icon='HeroChevronDown' className='mx-2' />}>
					<SelectReact
						id='projectLeader'
						name='projectLeader'
						placeholder={t('Select Leaders')}
						options={getProjectUsers().map((group) => ({
							...group,
							options: group.options.map((option) => ({
								...option,
								label: option.label || '',
								value: option.value || '',
							})),
						}))}
						isMulti
						value={formik.values.projectLeader}
						menuPlacement='auto'
						onChange={(value) => formik.setFieldValue('projectLeader', value)}
					/>
				</FieldWrap>
			</div>
			<div className='col-span-12 lg:col-span-6'>
				<Label htmlFor='projectParticipants'>{t('Project Participants')}</Label>
				<FieldWrap lastSuffix={<Icon icon='HeroChevronDown' className='mx-2' />}>
					<SelectReact
						id='projectParticipants'
						name='projectParticipants'
						placeholder={t('Select Participants')}
						options={getProjectUsers().map((group) => ({
							...group,
							options: group.options.map((option) => ({
								label: option.label || '',
								value: option.value || '',
							})),
						}))}
						isMulti
						value={formik.values.projectParticipants}
						menuPlacement='auto'
						onChange={(value) => formik.setFieldValue('projectParticipants', value)}
					/>
				</FieldWrap>
			</div>
			<div className='col-span-14 lg:col-span-4'>
				<Label htmlFor='startDate'>{t('Start Date')}</Label>
				<Validation
					isValid={formik.isValid}
					isTouched={formik.touched.startDate}
					invalidFeedback={formik.errors.startDate}
					validFeedback='Good'>
					<FieldWrap firstSuffix={<Icon icon='HeroCalendarDays' className='mx-2' />}>
						<Input
							id='startDate'
							name='startDate'
							type='date'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.startDate}
							className='flex h-[33px] items-center'
							placeholder={t('Start Date')}
						/>
					</FieldWrap>
				</Validation>
			</div>
			<div className='col-span-12 lg:col-span-4'>
				<Label htmlFor='startDate'>{t('End Date')}</Label>
				<Validation
					isValid={formik.isValid}
					isTouched={formik.touched.endDate}
					invalidFeedback={formik.errors.endDate}
					validFeedback='Good'>
					<FieldWrap firstSuffix={<Icon icon='HeroCalendarDays' className='mx-2' />}>
						<Input
							id='endDate'
							name='endDate'
							type='date'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.endDate}
							className='flex h-[33px] items-center'
							placeholder={t('End Date')}
							disabled={!dayjs(formik.values.startDate).isValid()}
						/>
					</FieldWrap>
				</Validation>
			</div>
			<div className='col-span-12 lg:col-span-4'>
				<Label htmlFor='status'>{t('Status')}</Label>
				<Validation
					isValid={formik.isValid}
					isTouched={formik.touched.status}
					invalidFeedback={formik.errors.status}
					validFeedback='Good'>
					<FieldWrap
						firstSuffix={<Icon icon='HeroFlag' className='mx-2' />}
						lastSuffix={<Icon icon='HeroChevronDown' className='mx-2' />}>
						<Select
							id='status'
							name='status'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.status}
							placeholder={t('Select Status')}>
							{[
								{ value: '', label: t('Select status') },
								{ value: 'ONGOING', label: t('Ongoing') },
								{ value: 'NOTSTARTED', label: t('Not started') },
								{ value: 'COMPLETED', label: t('Completed') },
							].map((i) => (
								<option key={i.value} value={i.value}>
									{i.label}
								</option>
							))}
						</Select>
					</FieldWrap>
				</Validation>
			</div>
			<div className='col-span-12'>
				<Label htmlFor='comments'>{t('Comments')}</Label>
				<Validation
					isValid={formik.isValid}
					isTouched={formik.touched.comments}
					invalidFeedback={formik.errors.comments}
					validFeedback='Good'>
					<Textarea
						id='comments'
						name='comments'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.comments}
						placeholder={t('Comments')}
					/>
				</Validation>
				<div className='mt-2 flex items-center gap-4 text-xs text-zinc-500'>
					<div className='flex-shrink-0'>
						{t('Max length')} :{' '}
						<span className='font-mono'>
							{formik.values.comments.length}
							/500
						</span>
					</div>
					<Progress
						className='!h-2'
						value={formik.values.comments.length}
						max={500}
						color={formik.values.comments.length <= 500 ? 'blue' : 'red'}
					/>
				</div>
			</div>
		</form>
	);
	const _SAVE_BTN = (
		<Button
			icon='HeroServer'
			variant='solid'
			color={saveBtnColor}
			isDisable={saveBtnDisable}
			onClick={() => formik.handleSubmit()}>
			{t(saveBtnText)}
		</Button>
	);

	if (inCanvas) {
		return (
			<OffCanvas
				isOpen={isOpen as boolean}
				setIsOpen={setIsOpen as Dispatch<SetStateAction<boolean>>}
				dialogClassName='max-md:max-w-full md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl'>
				<OffCanvasHeader>{_TITLE}</OffCanvasHeader>
				<OffCanvasBody>{_CONTENT}</OffCanvasBody>
				<OffCanvasFooter>
					<OffCanvasFooterChild>
						<Button
							onClick={() => {
								// @ts-ignore
								setIsOpen(false);
								formik.resetForm();
							}}
							color='red'>
							{t('Close')}
						</Button>
					</OffCanvasFooterChild>
					<OffCanvasFooterChild>{_SAVE_BTN}</OffCanvasFooterChild>
				</OffCanvasFooter>
			</OffCanvas>
		);
	}
	return (
		<Card className={classNames({ 'border border-zinc-500/25': isSubTab })}>
			<CardBody>{_CONTENT}</CardBody>
			<CardFooter>
				<CardFooterChild>
					<FormCancelBtn dirty={formik.dirty} />
				</CardFooterChild>
				<CardFooterChild>{_SAVE_BTN}</CardFooterChild>
			</CardFooter>
		</Card>
	);
};

export default ProjectDetailsEditPartial;
