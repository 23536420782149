import React from 'react';
import Visible from '../../../../components/utils/Visible';
import Icon from '../../../../components/icon/Icon';
import useAsideStatus from '../../../../hooks/useAsideStatus';
import LogoTemplate from '../../Logo/Logo.template';
import {
	// documentationPages,
	// settingsPages,
	// subscriptionPages,
	superAdminPage,
	// supportPages,
	tenantViewPages,
} from '../../../../config/pages.config';
// import Dropdown, {
// 	DropdownMenu,
// 	DropdownNavLinkItem,
// 	DropdownToggle,
// } from '../../../../components/ui/Dropdown';
// import Button from '../../../../components/ui/Button';
import { useTranslation } from 'react-i18next';
import useAccessCheck from '../../../../hooks/useAccessCheck';
import ROLES from '../../../../constants/roles.constant';
import { Link } from 'react-router-dom';

const LogoAndAsideTogglePart = () => {
	const { t } = useTranslation();

	const { asideStatus, setAsideStatus } = useAsideStatus();

	const hasSuperAdminAccess = useAccessCheck([ROLES.ROLE_SUPER_ADMIN]);
	const hasTenantAccess = useAccessCheck([ROLES.ROLE_TENANT_ADMIN, ROLES.ROLE_TENANT_USER]);

	return (
		<>
			<Visible is={asideStatus}>
				{/*{hasTenantAccess && (*/}
				{/*	<Dropdown>*/}
				{/*		<DropdownToggle>*/}
				{/*			<Button className='!border-0 !p-0'>*/}
				{/*				<LogoTemplate className='h-8' />*/}
				{/*			</Button>*/}
				{/*		</DropdownToggle>*/}
				{/*		<DropdownMenu>*/}
				{/*			<DropdownNavLinkItem*/}
				{/*				icon={tenantViewPages.tenantPage.subPages.dashboardPage.icon}*/}
				{/*				to={tenantViewPages.tenantPage.subPages.dashboardPage.to}>*/}
				{/*				{t('Operations')}*/}
				{/*			</DropdownNavLinkItem>*/}
				{/*			<DropdownNavLinkItem {...documentationPages.documentationMainPages}>*/}
				{/*				{t(documentationPages.documentationMainPages.text)}*/}
				{/*			</DropdownNavLinkItem>*/}
				{/*			<DropdownNavLinkItem {...supportPages.supportMainPages}>*/}
				{/*				{t(supportPages.supportMainPages.text)}*/}
				{/*			</DropdownNavLinkItem>*/}
				{/*			<DropdownNavLinkItem {...subscriptionPages.subscriptionMainPages}>*/}
				{/*				{t(subscriptionPages.subscriptionMainPages.text)}*/}
				{/*			</DropdownNavLinkItem>*/}
				{/*			<DropdownNavLinkItem*/}
				{/*				icon={settingsPages.settingsMainPages.icon}*/}
				{/*				to={settingsPages.settingsMainPages.to}>*/}
				{/*				{t(settingsPages.settingsMainPages.text)}*/}
				{/*			</DropdownNavLinkItem>*/}
				{/*		</DropdownMenu>*/}
				{/*	</Dropdown>*/}
				{/*)}*/}
				{hasTenantAccess && (
					<Link to={tenantViewPages.tenantPage.subPages.dashboardPage.to}>
						<LogoTemplate className='h-8' />
					</Link>
				)}
				{hasSuperAdminAccess && (
					<Link to={superAdminPage.superAdminPage.to}>
						<LogoTemplate className='h-8' />
					</Link>
				)}
			</Visible>
			<button
				type='button'
				aria-label={t('Toggle Aside Menu')}
				onClick={() => setAsideStatus(!asideStatus)}
				className='flex h-12 w-12 items-center justify-center'>
				<Icon
					icon={asideStatus ? 'HeroBars3BottomLeft' : 'HeroBars3'}
					className='text-2xl'
				/>
			</button>
		</>
	);
};

export default LogoAndAsideTogglePart;
