import tailwindcssColors from 'tailwindcss/colors';
import { DefaultColors } from 'tailwindcss/types/generated/colors';

interface AllColor extends DefaultColors {
	customColor: {
		'50': '#eff6ff';
		'100': '#dbeafe';
		'200': '#bfdbfe';
		'300': '#93c5fd';
		'400': '#60a5fa';
		'500': '#3b82f6';
		'600': '#2563eb';
		'700': '#1d4ed8';
		'800': '#1e40af';
		'900': '#1e3a8a';
		'950': '#172554';
	};
	linn: {
		50: '#FFC3C9';
		100: '#FFA9AE';
		200: '#FA8F93';
		300: '#EF888C';
		400: '#E48286';
		500: '#E48286';
		600: '#CD7578';
		700: '#AB6164';
		800: '#884E50';
		900: '#724143';
		950: '#5B3435';
	};
}

const colors: AllColor = {
	...tailwindcssColors,
	customColor: {
		50: '#eff6ff',
		100: '#dbeafe',
		200: '#bfdbfe',
		300: '#93c5fd',
		400: '#60a5fa',
		500: '#3b82f6',
		600: '#2563eb',
		700: '#1d4ed8',
		800: '#1e40af',
		900: '#1e3a8a',
		950: '#172554',
	},
	linn: {
		50: '#FFC3C9',
		100: '#FFA9AE',
		200: '#FA8F93',
		300: '#EF888C',
		400: '#E48286',
		500: '#E48286',
		600: '#CD7578',
		700: '#AB6164',
		800: '#884E50',
		900: '#724143',
		950: '#5B3435',
	},
};

export default colors;
