import React, { FC } from 'react';

interface ITitleProps {
	title: string;
	subTitle?: string;
}
const Title: FC<ITitleProps> = (props) => {
	const { title, subTitle } = props;
	return (
		<span className='flex gap-2'>
			<span className='text-xl font-extrabold'>{title}</span>
			<span className='text-xl font-extrabold text-zinc-500'>{subTitle}</span>
		</span>
	);
};

export default Title;
