import { authenticateUser as signInApi } from '../api/authResourceApi';
import { IAuthenticateUserResponse, ILoginRequest } from '../interface/Auth.interface';

export const signIn = async ({
	domain,
	email,
	password,
}: ILoginRequest): Promise<IAuthenticateUserResponse> => {
	const response = await signInApi({ domain, email, password });
	const data: IAuthenticateUserResponse = response.data;
	localStorage.setItem('authUser', JSON.stringify(data));
	return data;
};

export const signOut = () => {
	localStorage.removeItem('authUser');
};
