import { TIcons } from './icons.type';

export type TViewID = 'list' | 'table';
export type TViewType = {
	id: TViewID;
	text: 'List' | 'Table';
	icon: TIcons;
};
export const objTimeslipsViewTypes: Record<TViewID, TViewType> = {
	list: {
		id: 'list',
		text: 'List',
		icon: 'HeroTableCells',
	},
	table: {
		id: 'table',
		text: 'Table',
		icon: 'HeroCalendar',
	},
};
