import React from 'react';
import Footer from '../../../components/layouts/Footer/Footer';
import { Link, NavLink } from 'react-router-dom';
import Label from '../../../components/form/Label';
import FieldWrap from '../../../components/form/FieldWrap';
import Button from '../../../components/ui/Button';
import Input from '../../../components/form/Input';
import { useFormik } from 'formik';
import Icon from '../../../components/icon/Icon';
import { LogoDark } from '../../../assets/images';
import { contractsPages, customerViewPages } from '../../../config/pages.config';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const LandingPageFooter = () => {
	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: {
			emailAddress: '',
		},
		onSubmit: () => {},
	});

	if (window.location.hostname === 'linnalfredsson.se') return null;
	return (
		<Footer className='-mt-4 grow flex-col border-t border-zinc-500/25 bg-zinc-950 py-14 text-white'>
			<section className='-mx-4'>
				<div className='overflow-hidden'>
					<div className='container mx-auto px-4'>
						<div className='grid grid-cols-2 gap-4'>
							<div className='col-span-2 lg:col-span-1'>
								<div className='mb-16'>
									<Link to='/' aria-label={t('Logo')}>
										<img src={LogoDark} className='h-12' alt={t('Logo')} />
									</Link>
								</div>
								<div className='max-w-md'>
									<Label htmlFor='emailAddress'>
										{t('Subscribe to our newsletter')}
									</Label>
									<FieldWrap
										firstSuffix={
											<Icon
												icon='HeroEnvelope'
												className='mx-3 text-zinc-900 dark:text-white'
											/>
										}
										lastSuffix={
											<Button
												className='translate-x-1.5 rounded-l-none'
												variant='solid'>
												{t('Subscribe')}
											</Button>
										}>
										<Input
											type='email'
											id='emailAddress'
											name='emailAddress'
											placeholder={t('Your email address')}
											onChange={formik.handleChange}
											value={formik.values.emailAddress}
											autoComplete='email'
										/>
									</FieldWrap>
								</div>
							</div>
							<div className='col-span-2 mt-12 lg:col-span-1 lg:mt-0'>
								<div className='grid grid-cols-1 gap-4 lg:grid-cols-3'>
									<div className='col-span-1 flex flex-col gap-4'>
										<NavLink
											className={({ isActive }) =>
												isActive
													? classNames(
															'text-lg text-blue-500 transition duration-300 ease-in-out',
														)
													: classNames(
															'text-lg transition duration-300 ease-in-out hover:text-blue-500',
														)
											}
											to={
												contractsPages.contractsMainPages.subPages
													.termsAndConditionsPages.to
											}>
											{
												contractsPages.contractsMainPages.subPages
													.termsAndConditionsPages.text
											}
										</NavLink>
										<NavLink
											className={({ isActive }) =>
												isActive
													? classNames(
															'text-lg text-blue-500 transition duration-300 ease-in-out',
														)
													: classNames(
															'text-lg transition duration-300 ease-in-out hover:text-blue-500',
														)
											}
											to={
												contractsPages.contractsMainPages.subPages
													.privacyPolicyPages.to
											}>
											{
												contractsPages.contractsMainPages.subPages
													.privacyPolicyPages.text
											}
										</NavLink>
										<NavLink
											className={({ isActive }) =>
												isActive
													? classNames(
															'text-lg text-blue-500 transition duration-300 ease-in-out',
														)
													: classNames(
															'text-lg transition duration-300 ease-in-out hover:text-blue-500',
														)
											}
											to={
												contractsPages.contractsMainPages.subPages
													.cookiePolicyPages.to
											}>
											{
												contractsPages.contractsMainPages.subPages
													.cookiePolicyPages.text
											}
										</NavLink>
									</div>
									<div className='col-span-1 flex flex-col gap-4'>
										<a
											className='text-lg transition duration-300 ease-in-out hover:text-blue-500'
											href='https://www.linkedin.com/company/centrasync'>
											LinkedIn
										</a>
									</div>
									<div className='col-span-1 flex flex-col'>
										<div className='mb-4 text-lg font-bold'>
											{t('Contact us')}
										</div>
										{/*<a*/}
										{/*	className='text-lg font-bold text-blue-500 underline transition duration-300 ease-in-out hover:decoration-0'*/}
										{/*	href='mailto:info@centrasync.se'>*/}
										{/*	info@centrasync.se*/}
										{/*</a>*/}
										<Link
											to={customerViewPages.contactUsPage.to}
											className='text-lg font-bold text-blue-500 underline transition duration-300 ease-in-out hover:decoration-0'>
											{t('Contact us')}
										</Link>
										<a
											className='text-lg font-bold text-blue-500 underline transition duration-300 ease-in-out hover:decoration-0'
											href='tel:0106500000'>
											010-650 00 00
										</a>
									</div>
								</div>
							</div>
							<div className='col-span-2 mt-12'>
								<div className='flex gap-1.5'>
									<span className='text-zinc-500'>
										© All rights reserved. Made by
									</span>
									<a
										href='#'
										className='font-bold transition duration-300 ease-in-out hover:text-blue-500'>
										CentraSync
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Footer>
	);
};

export default LandingPageFooter;
