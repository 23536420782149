import React, { useContext, useState } from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import Button from '../../../components/ui/Button';
import LogoTemplate from '../Logo/Logo.template';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import {
	authPages,
	customerTabs,
	customerViewPages,
	tenantViewPages,
} from '../../../config/pages.config';
import { AuthContext, useAuth } from '../../../context/authContext';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownNavLinkItem,
	DropdownToggle,
} from '../../../components/ui/Dropdown';
import useDarkMode from '../../../hooks/useDarkMode';
import DARK_MODE from '../../../constants/darkMode.constant';
import ButtonGroup from '../../../components/ui/ButtonGroup';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasFooter,
	OffCanvasFooterChild,
	OffCanvasHeader,
} from '../../../components/ui/OffCanvas';
import Collapse from '../../../components/utils/Collapse';
import Visible from '../../../components/utils/Visible';
import { TIcons } from '../../../types/icons.type';
import { NavSeparator } from '../../../components/layouts/Navigation/Nav';
import ROLES from '../../../constants/roles.constant';
import hasAnyRequiredRole from '../../../utils/hasAnyRequiredRole.util';
import LanguageSelectorPartial from './_partial/LanguageSelector.partial';
import { useTranslation } from 'react-i18next';
import { TColors } from '../../../types/colors.type';
import {
	hasAnyModuleEnabled,
	hasModuleEnabledForLoggedInUser,
} from '../../../utils/permission.util';

export const NavLinkItem = ({
	text,
	to,
	icon,
	className,
}: {
	text: string;
	to: string;
	icon?: TIcons;
	className?: string;
}) => {
	const match = useMatch({ path: to });
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Button icon={icon} isActive={!!match} onClick={() => navigate(to)} className={className}>
			{t(text)}
		</Button>
	);
};

const tenantViewSubPages = tenantViewPages.tenantPage.subPages;
const tenantViewProductsPages = tenantViewSubPages.productsPage;
const tenantViewSalesPages = tenantViewSubPages.salesPage;
const tenantWorkManagementPages = tenantViewSubPages.workManagementPages;

const LandingPageHeaderTemplate = ({
	isHeaderLeft = true,
	isDarkMode = true,
	isLang = true,
	isBlur = true,
	color,
}: {
	isHeaderLeft?: boolean;
	isDarkMode?: boolean;
	isLang?: boolean;
	isBlur?: boolean;
	color?: TColors;
	scrollTop?: number;
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { onLogout } = useAuth();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [state, setState] = useState<boolean>(false);

	const authContext = useContext(AuthContext);
	const { authUser } = authContext;

	const id = authUser?.uid;

	/*const [isLoading, setIsLoading] = useState<boolean>(true);*/
	/*const [userData, setUserData] = useState<IFetchUserResponse>();*/

	/*const handleFetchUser = async (uid: number) => {
        await fetchUser({ uid, setUserData, setIsLoading });
    };*/
	/*useEffect(() => {
        handleFetchUser(Number(uid));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);*/

	const isCustomer = hasAnyRequiredRole(authUser?.roles, [
		ROLES.ROLE_CUSTOMER_USER,
		ROLES.ROLE_CUSTOMER_ADMIN,
	]);
	const isTenantAndAdmin = hasAnyRequiredRole(authUser?.roles, [
		ROLES.ROLE_TENANT_USER,
		ROLES.ROLE_TENANT_ADMIN,
		ROLES.ROLE_SUPER_ADMIN,
	]);

	const [stateCollapse, setStateCollapse] = useState<boolean>(false);

	const _CUSTOMER_MENU_ITEMS = (
		<>
			<DropdownNavLinkItem
				to={`${customerViewPages.customerPage.to}/${customerTabs.dashboardTab.to}`}
				icon={customerTabs.dashboardTab.icon}>
				{t(customerTabs.dashboardTab.text)}
			</DropdownNavLinkItem>
			{hasModuleEnabledForLoggedInUser('PROJECTS') && (
				<DropdownNavLinkItem
					to={`${customerViewPages.customerPage.to}/${customerTabs.projectsTab.to}`}
					icon={customerTabs.projectsTab.icon}>
					{t(customerTabs.projectsTab.text)}
				</DropdownNavLinkItem>
			)}
			{hasModuleEnabledForLoggedInUser('TASKS') && (
				<DropdownNavLinkItem
					to={`${customerViewPages.customerPage.to}/${customerTabs.tasksTab.to}`}
					icon={customerTabs.tasksTab.icon}>
					{t(customerTabs.tasksTab.text)}
				</DropdownNavLinkItem>
			)}
			{hasModuleEnabledForLoggedInUser('TASKS') && (
				<DropdownNavLinkItem
					to={`${customerViewPages.customerPage.to}/${customerTabs.timeslipsTab.to}`}
					icon={customerTabs.timeslipsTab.icon}>
					{t(customerTabs.timeslipsTab.text)}
				</DropdownNavLinkItem>
			)}
			{hasModuleEnabledForLoggedInUser('OFFERS') && (
				<DropdownNavLinkItem
					to={`${customerViewPages.customerPage.to}/${customerTabs.offersTab.to}`}
					icon={customerTabs.offersTab.icon}>
					{t(customerTabs.offersTab.text)}
				</DropdownNavLinkItem>
			)}
			{hasModuleEnabledForLoggedInUser('ORDERS') && (
				<DropdownNavLinkItem
					to={`${customerViewPages.customerPage.to}/${customerTabs.ordersTab.to}`}
					icon={customerTabs.ordersTab.icon}>
					{t(customerTabs.ordersTab.text)}
				</DropdownNavLinkItem>
			)}
			{hasModuleEnabledForLoggedInUser('INVOICES') && (
				<DropdownNavLinkItem
					to={`${customerViewPages.customerPage.to}/${customerTabs.invoicesTab.to}`}
					icon={customerTabs.invoicesTab.icon}>
					{t(customerTabs.invoicesTab.text)}
				</DropdownNavLinkItem>
			)}
			<DropdownNavLinkItem
				className='border-t-2'
				to={`${customerViewPages.customerPage.to}/${customerTabs.organizationTab.to}`}
				icon={customerTabs.organizationTab.icon}>
				{t(customerTabs.organizationTab.text)}
			</DropdownNavLinkItem>
			<DropdownNavLinkItem
				to={`${customerViewPages.customerPage.to}/${customerTabs.usersTab.to}`}
				icon={customerTabs.usersTab.icon}>
				{t(customerTabs.usersTab.text)}
			</DropdownNavLinkItem>
		</>
	);
	const _CUSTOMER_MOBILE_MENU_ITEMS = (
		<>
			<NavLinkItem
				{...customerTabs.dashboardTab}
				to={`${customerViewPages.customerPage.to}/${customerTabs.dashboardTab.to} `}
			/>
			{hasModuleEnabledForLoggedInUser('PROJECTS') && (
				<NavLinkItem
					{...customerTabs.projectsTab}
					to={`${customerViewPages.customerPage.to}/${customerTabs.projectsTab.to}`}
				/>
			)}
			{hasModuleEnabledForLoggedInUser('TASKS') && (
				<NavLinkItem
					{...customerTabs.tasksTab}
					to={`${customerViewPages.customerPage.to}/${customerTabs.tasksTab.to}`}
				/>
			)}
			{hasModuleEnabledForLoggedInUser('TASKS') && (
				<NavLinkItem
					{...customerTabs.timeslipsTab}
					to={`${customerViewPages.customerPage.to}/${customerTabs.timeslipsTab.to}`}
				/>
			)}
			{hasModuleEnabledForLoggedInUser('OFFERS') && (
				<NavLinkItem
					{...customerTabs.offersTab}
					to={`${customerViewPages.customerPage.to}/${customerTabs.offersTab.to}`}
				/>
			)}
			{hasModuleEnabledForLoggedInUser('ORDERS') && (
				<NavLinkItem
					{...customerTabs.ordersTab}
					to={`${customerViewPages.customerPage.to}/${customerTabs.ordersTab.to}`}
				/>
			)}
			{hasModuleEnabledForLoggedInUser('INVOICES') && (
				<NavLinkItem
					{...customerTabs.invoicesTab}
					to={`${customerViewPages.customerPage.to}/${customerTabs.invoicesTab.to}`}
				/>
			)}
			<NavSeparator className='w-full' />
			<NavLinkItem
				{...customerTabs.organizationTab}
				to={`${customerViewPages.customerPage.to}/${customerTabs.organizationTab.to}`}
			/>
			<NavLinkItem
				{...customerTabs.usersTab}
				to={`${customerViewPages.customerPage.to}/${customerTabs.usersTab.to}`}
			/>
		</>
	);
	const _TENANT_MENU_ITEMS = (
		<>
			<DropdownNavLinkItem
				to={tenantViewSubPages.dashboardPage.to}
				icon={tenantViewSubPages.dashboardPage.icon}>
				{t(tenantViewSubPages.dashboardPage.text)}
			</DropdownNavLinkItem>
			{hasModuleEnabledForLoggedInUser('CUSTOMERS') && (
				<DropdownNavLinkItem
					to={tenantViewSubPages.customersPage.to}
					icon={tenantViewSubPages.customersPage.icon}>
					{t(tenantViewSubPages.customersPage.text)}
				</DropdownNavLinkItem>
			)}
			{hasModuleEnabledForLoggedInUser('ARTICLES') && (
				<DropdownItem className='!p-0'>
					<Dropdown>
						<DropdownToggle>
							<DropdownItem icon={tenantViewProductsPages.icon}>
								{t(tenantViewProductsPages.text)}
							</DropdownItem>
						</DropdownToggle>
						<DropdownMenu placement='right-start'>
							<DropdownNavLinkItem
								to={tenantViewProductsPages.subPages.articlesPage.to}
								icon={tenantViewProductsPages.subPages.articlesPage.icon}>
								{t(tenantViewProductsPages.subPages.articlesPage.text)}
							</DropdownNavLinkItem>
							<DropdownNavLinkItem
								to={tenantViewProductsPages.subPages.articleGroupsPage.to}
								icon={tenantViewProductsPages.subPages.articleGroupsPage.icon}>
								{t(tenantViewProductsPages.subPages.articleGroupsPage.text)}
							</DropdownNavLinkItem>
							<DropdownNavLinkItem
								to={tenantViewProductsPages.subPages.categoriesPage.to}
								icon={tenantViewProductsPages.subPages.categoriesPage.icon}>
								{t(tenantViewProductsPages.subPages.categoriesPage.text)}
							</DropdownNavLinkItem>
							<DropdownNavLinkItem
								to={tenantViewProductsPages.subPages.productListsPage.to}
								icon={tenantViewProductsPages.subPages.productListsPage.icon}>
								{t(tenantViewProductsPages.subPages.productListsPage.text)}
							</DropdownNavLinkItem>
						</DropdownMenu>
					</Dropdown>
				</DropdownItem>
			)}
			{hasAnyModuleEnabled(['OFFERS', 'ORDERS', 'INVOICES']) && (
				<DropdownItem className='!p-0'>
					<Dropdown>
						<DropdownToggle>
							<DropdownItem icon={tenantViewSalesPages.icon}>
								{t(tenantViewSalesPages.text)}
							</DropdownItem>
						</DropdownToggle>
						<DropdownMenu placement='right-start'>
							{hasModuleEnabledForLoggedInUser('OFFERS') && (
								<DropdownNavLinkItem
									to={tenantViewSalesPages.subPages.offersPage.to}
									icon={tenantViewSalesPages.subPages.offersPage.icon}>
									{t(tenantViewSalesPages.subPages.offersPage.text)}
								</DropdownNavLinkItem>
							)}
							{hasModuleEnabledForLoggedInUser('ORDERS') && (
								<DropdownNavLinkItem
									to={tenantViewSalesPages.subPages.ordersPage.to}
									icon={tenantViewSalesPages.subPages.ordersPage.icon}>
									{t(tenantViewSalesPages.subPages.ordersPage.text)}
								</DropdownNavLinkItem>
							)}
							{hasModuleEnabledForLoggedInUser('INVOICES') && (
								<DropdownNavLinkItem
									to={tenantViewSalesPages.subPages.invoicesPage.to}
									icon={tenantViewSalesPages.subPages.invoicesPage.icon}>
									{t(tenantViewSalesPages.subPages.invoicesPage.text)}
								</DropdownNavLinkItem>
							)}
						</DropdownMenu>
					</Dropdown>
				</DropdownItem>
			)}
			{hasAnyModuleEnabled(['PROJECTS', 'TASKS']) && (
				<DropdownItem className='!p-0'>
					<Dropdown>
						<DropdownToggle>
							<DropdownItem icon={tenantWorkManagementPages.icon}>
								{t(tenantWorkManagementPages.text)}
							</DropdownItem>
						</DropdownToggle>
						<DropdownMenu placement='right-start'>
							{hasModuleEnabledForLoggedInUser('PROJECTS') && (
								<DropdownNavLinkItem
									to={tenantWorkManagementPages.subPages.projectsPage.to}
									icon={tenantWorkManagementPages.subPages.projectsPage.icon}>
									{t(tenantWorkManagementPages.subPages.projectsPage.text)}
								</DropdownNavLinkItem>
							)}
							{hasModuleEnabledForLoggedInUser('TASKS') && (
								<DropdownNavLinkItem
									to={tenantWorkManagementPages.subPages.tasksPage.to}
									icon={tenantWorkManagementPages.subPages.tasksPage.icon}>
									{t(tenantWorkManagementPages.subPages.tasksPage.text)}
								</DropdownNavLinkItem>
							)}
							{hasModuleEnabledForLoggedInUser('TASKS') && (
								<DropdownNavLinkItem
									to={tenantWorkManagementPages.subPages.timeslipsPage.to}
									icon={tenantWorkManagementPages.subPages.timeslipsPage.icon}>
									{t(tenantWorkManagementPages.subPages.timeslipsPage.text)}
								</DropdownNavLinkItem>
							)}
						</DropdownMenu>
					</Dropdown>
				</DropdownItem>
			)}
			{hasModuleEnabledForLoggedInUser('EVENTS') && (
				<DropdownNavLinkItem
					to={tenantViewSubPages.eventsPage.to}
					icon={tenantViewSubPages.eventsPage.icon}>
					{t(tenantViewSubPages.eventsPage.text)}
				</DropdownNavLinkItem>
			)}
			<DropdownNavLinkItem
				to={tenantViewSubPages.usersPage.to}
				icon={tenantViewSubPages.usersPage.icon}>
				{t(tenantViewSubPages.usersPage.text)}
			</DropdownNavLinkItem>
			<DropdownNavLinkItem
				to={tenantViewSubPages.organizationPage.to}
				icon={tenantViewSubPages.organizationPage.icon}>
				{t(tenantViewSubPages.organizationPage.text)}
			</DropdownNavLinkItem>
		</>
	);
	const [tenantProductsCollapse, setTenantProductsCollapse] = useState(false);
	const [tenantSalesCollapse, setTenantSalesCollapse] = useState(false);
	const [tenantWorkManagementCollapse, setTenantWorkManagementCollapse] = useState(false);
	const _TENANT_MOBILE_MENU_ITEMS = (
		<>
			<NavLinkItem {...tenantViewSubPages.dashboardPage} />
			<NavLinkItem {...tenantViewSubPages.customersPage} />

			{hasModuleEnabledForLoggedInUser('ARTICLES') && (
				<Button
					icon={tenantViewSubPages.productsPage.icon}
					onClick={() => setTenantProductsCollapse(!tenantProductsCollapse)}
					rightIcon={tenantProductsCollapse ? 'HeroChevronUp' : 'HeroChevronDown'}>
					{t(tenantViewSubPages.productsPage.text)}
				</Button>
			)}
			<Collapse isOpen={tenantProductsCollapse} className='w-full'>
				<div className='flex w-full flex-col items-start gap-4'>
					<NavLinkItem {...tenantViewSubPages.productsPage.subPages.articlesPage} />
					<NavLinkItem {...tenantViewSubPages.productsPage.subPages.articleGroupsPage} />
					<NavLinkItem {...tenantViewSubPages.productsPage.subPages.categoriesPage} />
					<NavLinkItem {...tenantViewSubPages.productsPage.subPages.productListsPage} />
					<NavLinkItem {...tenantViewSubPages.productsPage.subPages.manufacturersPage} />
					<NavSeparator className='w-full' />
				</div>
			</Collapse>

			{hasAnyModuleEnabled(['OFFERS', 'ORDERS', 'INVOICES', 'STATISTICS']) && (
				<Button
					icon={tenantViewSubPages.salesPage.icon}
					onClick={() => setTenantSalesCollapse(!tenantSalesCollapse)}
					rightIcon={tenantSalesCollapse ? 'HeroChevronUp' : 'HeroChevronDown'}>
					{t(tenantViewSubPages.salesPage.text)}
				</Button>
			)}
			<Collapse isOpen={tenantSalesCollapse} className='w-full'>
				<div className='flex w-full flex-col items-start gap-4'>
					{hasModuleEnabledForLoggedInUser('OFFERS') && (
						<NavLinkItem {...tenantViewSubPages.salesPage.subPages.offersPage} />
					)}
					{hasModuleEnabledForLoggedInUser('ORDERS') && (
						<NavLinkItem {...tenantViewSubPages.salesPage.subPages.ordersPage} />
					)}
					{hasModuleEnabledForLoggedInUser('INVOICES') && (
						<NavLinkItem {...tenantViewSubPages.salesPage.subPages.invoicesPage} />
					)}
					<NavSeparator className='w-full' />
				</div>
			</Collapse>

			{hasAnyModuleEnabled(['PROJECTS', 'TASKS']) && (
				<Button
					icon={tenantViewSubPages.workManagementPages.icon}
					onClick={() => setTenantWorkManagementCollapse(!tenantWorkManagementCollapse)}
					rightIcon={tenantWorkManagementCollapse ? 'HeroChevronUp' : 'HeroChevronDown'}>
					{t(tenantViewSubPages.workManagementPages.text)}
				</Button>
			)}
			<Collapse isOpen={tenantWorkManagementCollapse} className='w-full'>
				<div className='flex w-full flex-col items-start gap-4'>
					{hasModuleEnabledForLoggedInUser('PROJECTS') && (
						<NavLinkItem
							{...tenantViewSubPages.workManagementPages.subPages.projectsPage}
						/>
					)}
					{hasModuleEnabledForLoggedInUser('TASKS') && (
						<NavLinkItem
							{...tenantViewSubPages.workManagementPages.subPages.tasksPage}
						/>
					)}
					{hasModuleEnabledForLoggedInUser('TASKS') && (
						<NavLinkItem
							{...tenantViewSubPages.workManagementPages.subPages.timeslipsPage}
						/>
					)}
					<NavSeparator className='w-full' />
				</div>
			</Collapse>

			{hasModuleEnabledForLoggedInUser('EVENTS') && (
				<NavLinkItem {...tenantViewSubPages.eventsPage} />
			)}
			<NavLinkItem {...tenantViewSubPages.usersPage} />
			<NavLinkItem {...tenantViewSubPages.organizationPage} />
		</>
	);

	return (
		<Header blur={isBlur} className='!border-0 !bg-transparent'>
			<div className='container mx-auto flex w-full justify-between px-4'>
				<Visible is={isHeaderLeft}>
					<HeaderLeft isShowToggleAside={false}>
						<Link
							to={{
								pathname: '/',
								hash: '#top',
							}}
							aria-label={t('Logo')}
							className='h-12 w-auto'>
							<LogoTemplate className='h-12' />
						</Link>
						<div className='hidden px-8 xl:inline-flex'>
							<Dropdown>
								<DropdownToggle>
									<Button className='text-lg font-extrabold'>
										{t(customerViewPages.businessTypesPages.text)}
									</Button>
								</DropdownToggle>
								<DropdownMenu>
									{Object.values(
										customerViewPages.businessTypesPages.subPages,
									).map((item) => (
										<DropdownNavLinkItem
											key={item.id}
											{...item}
											icon={undefined}>
											{t(item.text)}
										</DropdownNavLinkItem>
									))}
								</DropdownMenu>
							</Dropdown>
							<NavLinkItem
								{...customerViewPages.pricingPage}
								icon={undefined}
								className='text-lg font-extrabold'
							/>
							<NavLinkItem
								{...customerViewPages.contactUsPage}
								icon={undefined}
								className='text-lg font-extrabold'
							/>
							<Visible is={isCustomer || isTenantAndAdmin}>
								<Dropdown>
									<DropdownToggle>
										<Button className='text-lg font-extrabold'>
											{t('Admin')}
										</Button>
									</DropdownToggle>
									<DropdownMenu>
										<Visible is={isCustomer}>{_CUSTOMER_MENU_ITEMS}</Visible>
										<Visible is={isTenantAndAdmin}>
											{_TENANT_MENU_ITEMS}
										</Visible>
									</DropdownMenu>
								</Dropdown>
							</Visible>
						</div>
					</HeaderLeft>
				</Visible>
				<HeaderRight>
					{/* DARK MODE */}
					<Visible is={isDarkMode}>
						<ButtonGroup className='!hidden xl:!flex'>
							<Button
								icon='HeroMoon'
								onClick={() => setDarkModeStatus(DARK_MODE.DARK)}
								isActive={darkModeStatus === DARK_MODE.DARK}
							/>
							<Button
								icon='HeroSun'
								onClick={() => setDarkModeStatus(DARK_MODE.LIGHT)}
								isActive={darkModeStatus === DARK_MODE.LIGHT}
							/>
							<Button
								icon='HeroComputerDesktop'
								onClick={() => setDarkModeStatus(DARK_MODE.SYSTEM)}
								isActive={darkModeStatus === DARK_MODE.SYSTEM}
							/>
						</ButtonGroup>
					</Visible>
					<Visible is={isLang}>
						<LanguageSelectorPartial />
					</Visible>
					{/* USER MENU */}
					{/*<Visible is={isLoading}>Loading...</Visible>*/}
					{/*<Visible is={!isLoading}>*/}
					<Visible is={!!authUser}>
						<Dropdown className='!hidden xl:!flex'>
							<DropdownToggle>
								<Button
									color='zinc'
									icon='HeroUser'
									className='text-lg font-extrabold'>
									{authUser?.username}
								</Button>
							</DropdownToggle>
							<DropdownMenu placement='bottom-end'>
								<Visible is={isCustomer}>
									<DropdownNavLinkItem
										to={`${customerViewPages.customerPage.to}/${customerTabs.usersTab.to}/${id}`}
										icon={customerTabs.accountTab.icon}>
										{t('My Account')}
									</DropdownNavLinkItem>
								</Visible>
								<Visible is={isTenantAndAdmin}>
									<DropdownNavLinkItem
										to={`${tenantViewPages.tenantPage.subPages.usersPage.to}/${id}`}>
										{t('My Account')}
									</DropdownNavLinkItem>
								</Visible>
								<DropdownItem
									icon='HeroArrowRightOnRectangle'
									className='border-t-2'
									onClick={() => onLogout()}>
									{t('Logout')}
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</Visible>
					<Visible is={!authUser}>
						<Button
							color={color}
							variant='solid'
							icon='HeroArrowLeftOnRectangle'
							onClick={() => navigate(authPages.loginPage.to)}
							className='text-lg font-extrabold'>
							{t('Login')}
						</Button>
					</Visible>
					{/*</Visible>*/}
					{/* MOBILE MENU */}
					<Visible is={isHeaderLeft}>
						<Button
							onClick={() => setState(true)}
							className='xl:!hidden'
							icon='HeroBars3'
						/>
						<OffCanvas isOpen={state} setIsOpen={setState}>
							<OffCanvasHeader>{t('Menu')}</OffCanvasHeader>
							<OffCanvasBody className='flex shrink-0 flex-col items-start gap-4'>
								<Button
									icon={customerViewPages.businessTypesPages.icon}
									onClick={() => setStateCollapse(!stateCollapse)}
									rightIcon={stateCollapse ? 'HeroChevronUp' : 'HeroChevronDown'}>
									{t(customerViewPages.businessTypesPages.text)}
								</Button>
								<Collapse isOpen={stateCollapse} className='w-full'>
									<div className='flex w-full flex-col items-start gap-4'>
										{Object.values(
											customerViewPages.businessTypesPages.subPages,
										).map((item) => (
											<NavLinkItem
												key={item.id}
												to={item.to}
												icon={item.icon}
												text={t(item.text)}
											/>
										))}
										<NavSeparator className='w-full' />
									</div>
								</Collapse>
								<NavLinkItem {...customerViewPages.pricingPage} />
								<NavLinkItem {...customerViewPages.contactUsPage} />
								<Visible is={isCustomer || isTenantAndAdmin}>
									<NavSeparator className='w-full' />
									<Visible is={isCustomer}>{_CUSTOMER_MOBILE_MENU_ITEMS}</Visible>
									<Visible is={isTenantAndAdmin}>
										{_TENANT_MOBILE_MENU_ITEMS}
									</Visible>
									<NavSeparator className='w-full' />
									<Button
										icon='HeroArrowRightOnRectangle'
										onClick={() => onLogout()}>
										{authUser?.username}, {t('Logout')}
									</Button>
								</Visible>
							</OffCanvasBody>
							<OffCanvasFooter>
								<OffCanvasFooterChild>
									<Button onClick={() => setState(false)} color='red'>
										{t('Close')}
									</Button>
								</OffCanvasFooterChild>
							</OffCanvasFooter>
						</OffCanvas>
					</Visible>
				</HeaderRight>
			</div>
		</Header>
	);
};

export default LandingPageHeaderTemplate;
