import React, { FC } from 'react';
import { TIcons } from '../../../types/icons.type';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Visible from '../../utils/Visible';
import Badge from '../../ui/Badge';
import { TColors } from '../../../types/colors.type';
import Icon from '../../icon/Icon';
import { useTranslation } from 'react-i18next';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../ui/Dropdown';
import Button from '../../ui/Button';

interface IBreadcrumbListProps {
	className?: string;
	list: {
		text: string;
		to?: string;
		icon?: TIcons;
		type?: string;
		color?: TColors;
		// documentationTo?: string;
		// settingsTo?: string;
		isLoading?: boolean;
	}[];
}
const BreadcrumbList: FC<IBreadcrumbListProps> = (props) => {
	const { className, list } = props;

	const { t } = useTranslation();

	return (
		<div
			className={classNames(
				className,
				'flex gap-2 text-xl font-extrabold max-lg:[&>*:first-child]:flex max-lg:[&>*:last-child]:flex max-lg:[&>*]:hidden',
			)}>
			<Dropdown className='lg:hidden'>
				<DropdownToggle>
					<Button icon={list[0].icon}>{list[0].text}</Button>
				</DropdownToggle>
				<DropdownMenu>
					{list.slice(0, -1).map((item) => {
						const _TYPE = (
							<Visible is={!!item.type}>
								<Badge
									variant='outline'
									className='border-transparent text-sm'
									color={item.color}>
									{t(item.type as string)}
								</Badge>
							</Visible>
						);
						if (item.to) {
							return (
								<DropdownItem key={item.text}>
									<div className="flex items-center gap-4 text-inherit after:ms-2 after:content-['>'] last:after:content-none">
										<NavLink
											end
											to={item.to}
											className={({ isActive }) =>
												isActive
													? classNames(
															'flex items-center gap-2 text-inherit',
														)
													: classNames(
															'flex items-center gap-2 text-zinc-500',
														)
											}>
											{item.icon && <Icon icon={item.icon as TIcons} />}
											{t(item.text)}
											{_TYPE}
										</NavLink>
										{/*{_SETTINGS_LINK}*/}
										{/*{_DOCUMENTATION_LINK}*/}
									</div>
								</DropdownItem>
							);
						}
						return (
							<DropdownItem key={item.text}>
								<div
									key={item.text}
									className="flex items-center gap-4 text-inherit after:ms-2 after:content-['>'] last:after:content-none">
									<div className='flex items-center gap-2 text-zinc-500'>
										{item.icon && <Icon icon={item.icon as TIcons} />}
										{t(item.text)}
										{_TYPE}
									</div>
									{/*{_SETTINGS_LINK}*/}
									{/*{_DOCUMENTATION_LINK}*/}
								</div>
							</DropdownItem>
						);
					})}
				</DropdownMenu>
			</Dropdown>
			{list.map((item) => {
				const _TYPE = (
					<Visible is={!!item.type}>
						<Badge
							variant='outline'
							className='border-transparent text-sm'
							color={item.color}>
							{t(item.type as string)}
						</Badge>
					</Visible>
				);

				// const _DOCUMENTATION_LINK = item.documentationTo && (
				// 	<NavLink end to={item.documentationTo}>
				// 		<Icon icon='HeroBookOpen' color='violet' />
				// 	</NavLink>
				// );

				// const _SETTINGS_LINK = item.settingsTo && (
				// 	<NavLink end to={item.settingsTo}>
				// 		<Icon icon='HeroCog8Tooth' color='amber' />
				// 	</NavLink>
				// );

				if (item.isLoading) {
					return (
						<div
							key={item.text}
							className='h-[24px] w-32 animate-pulse rounded-full bg-zinc-800/25 dark:bg-zinc-200/25'
						/>
					);
				}
				if (item.to) {
					return (
						<div
							key={item.text}
							className="flex items-center gap-4 text-inherit after:ms-2 after:content-['>'] last:after:content-none">
							<NavLink
								end
								to={item.to}
								className={({ isActive }) =>
									isActive
										? classNames('flex items-center gap-2 text-inherit')
										: classNames('flex items-center gap-2 text-zinc-500')
								}>
								{item.icon && <Icon icon={item.icon as TIcons} />}
								{t(item.text)}
								{_TYPE}
							</NavLink>
							{/*{_SETTINGS_LINK}*/}
							{/*{_DOCUMENTATION_LINK}*/}
						</div>
					);
				}
				return (
					<div
						key={item.text}
						className="flex items-center gap-4 text-inherit after:ms-2 after:content-['>'] last:after:content-none">
						<div className='flex items-center gap-2 text-zinc-500'>
							{item.icon && <Icon icon={item.icon as TIcons} />}
							{t(item.text)}
							{_TYPE}
						</div>
						{/*{_SETTINGS_LINK}*/}
						{/*{_DOCUMENTATION_LINK}*/}
					</div>
				);
			})}
		</div>
	);
};

export default BreadcrumbList;
