import {
	ICreateInvoiceForm,
	IFetchInvoiceDetailsRequest,
	IFetchInvoiceRequest,
} from '../interface/Invoice.interface';
import { createOrUpdateInvoiceApi, getInvoiceApi, getInvoiceById } from '../api/invoiceResourceApi';
import { APIRequestParams } from '../interface/APIRequestParams.interface';

export const fetchInvoice = async (
	request: IFetchInvoiceRequest,
	apiRequestParams?: APIRequestParams,
) => {
	return getInvoiceApi(request, apiRequestParams);
};

export const fetchInvoiceById = async ({
	invoiceId,
	setData,
	setIsLoading,
}: IFetchInvoiceDetailsRequest) => {
	return getInvoiceById({ invoiceId, setData, setIsLoading });
};

export const createOrUpdateInvoice = async (form: ICreateInvoiceForm) => {
	return createOrUpdateInvoiceApi(form);
};
