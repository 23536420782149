import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../context/authContext';
import { authPages } from '../../../config/pages.config';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import themeConfig from '../../../config/theme.config';

interface IPageWrapperProps {
	children: ReactNode;
	className?: string;
	isProtectedRoute?: boolean;
	title?: string;
	name?: string;
}
const PageWrapper: FC<IPageWrapperProps> = (props) => {
	const { children, className, isProtectedRoute = true, title, name, ...rest } = props;

	const { authUser } = useAuth();

	useDocumentTitle({
		title,
		name: name || authUser?.tenantName || themeConfig.projectName,
	});

	if (isProtectedRoute && !authUser) {
		// user is not authenticated
		return <Navigate to={authPages.loginPage.to} />;
	}
	return (
		<main
			data-component-name='PageWrapper'
			className={classNames('flex shrink-0 grow flex-col', className)}
			{...rest}>
			{children}
		</main>
	);
};

export default PageWrapper;
