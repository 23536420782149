import React from 'react';
import { RouteProps } from 'react-router-dom';
import DefaultHeaderTemplate from '../templates/layouts/Headers/DefaultHeader.template';
import {
	authPages,
	contractsPages,
	customerViewPages,
	settingsPages,
	subscriptionPages,
	superAdminPage,
	supportPages,
	tenantDocPages,
	tenantViewPages,
} from '../config/pages.config';
import LandingPageHeaderTemplate from '../templates/layouts/Headers/LandingPageHeader.template';

const tenantSubPages = tenantViewPages.tenantPage.subPages;
const tenantSalesSubPages = tenantSubPages.salesPage.subPages;
const tenantWorkManagementPages = tenantSubPages.workManagementPages.subPages;

const headerRoutes: RouteProps[] = [
	{ path: authPages.loginPage.to, element: null },
	{ path: `${tenantSubPages.dashboardPage.to}/*`, element: null },
	{ path: `${tenantSubPages.customersPage.to}/*`, element: null },
	{ path: `${tenantWorkManagementPages.projectsPage.to}/*`, element: null },
	{ path: `${tenantWorkManagementPages.tasksPage.to}/*`, element: null },
	{ path: `${tenantWorkManagementPages.timeslipsPage.to}/*`, element: null },
	{ path: `${tenantSubPages.content.subPages.postsPage.to}/*`, element: null },
	{ path: `${tenantSubPages.usersPage.to}/*`, element: null },
	{ path: `${tenantSubPages.eventsPage.to}/*`, element: null },
	{ path: `${tenantSubPages.eventsPage.to}/*`, element: null },
	{ path: `${tenantSalesSubPages.offersPage.to}/*`, element: null },
	{ path: `${tenantSalesSubPages.ordersPage.to}/*`, element: null },
	{ path: `${tenantSalesSubPages.invoicesPage.to}/*`, element: null },
	{ path: `${tenantSalesSubPages.invoicesPage.to}/*`, element: null },
	{ path: `${tenantSubPages.organizationPage.to}/*`, element: null },

	{ path: `${settingsPages.settingsMainPages.to}/*`, element: null },

	{ path: `${tenantDocPages.documentationPage.to}/*`, element: null },

	{ path: `${subscriptionPages.subscriptionMainPages.to}/*`, element: null },

	{ path: `${supportPages.supportMainPages.to}/*`, element: null },

	{ path: `${superAdminPage.superAdminPage.to}/*`, element: null },

	{ path: `${tenantSubPages.deliveryAddressesPage.to}/*`, element: null },

	{
		path: customerViewPages.landingPage.to,
		element: <LandingPageHeaderTemplate />,
	},
	{
		path: customerViewPages.pricingPage.to,
		element: <LandingPageHeaderTemplate />,
	},
	{
		path: customerViewPages.contactUsPage.to,
		element: <LandingPageHeaderTemplate />,
	},
	{
		path: `${customerViewPages.businessTypesPages.to}/*`,
		element: <LandingPageHeaderTemplate />,
	},
	{
		path: `${customerViewPages.customerPage.to}/*`,
		element: <LandingPageHeaderTemplate />,
	},
	{
		path: `${contractsPages.contractsMainPages.to}/*`,
		element: <LandingPageHeaderTemplate />,
	},

	{
		path: `/lalegal`,
		element: null,
	},
	{
		path: `/lalegal/privacy-policy`,
		element: null,
	},
	{
		path: `/lalegal/terms`,
		element: null,
	},
	{ path: '*', element: <DefaultHeaderTemplate /> },
];

export default headerRoutes;
