import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../ui/Button';

interface IFormCancelBtnProps {
	dirty: boolean;
}
const FormCancelBtn: FC<IFormCancelBtnProps> = (props) => {
	const { dirty } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Button color={dirty ? 'red' : 'zinc'} onClick={() => navigate(-1)}>
			{dirty ? t('Cancel') : t('Back')}
		</Button>
	);
};

export default FormCancelBtn;
