import React, { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IAuthContextProps, ILoginRequest } from '../interface/Auth.interface';
import { signIn, signOut } from '../services/authService';
import { getAuthUser } from '../api/apiContext';

export const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthProviderProps {
	children: ReactNode;
}

export const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const authUser = getAuthUser();

	const onLogin = async ({ domain, email, password }: ILoginRequest) => {
		try {
			setIsLoading(true);
			const data = await signIn({ domain, email, password });
			setIsLoading(false);

			// navigate(rolesDbList.customerAdmin.loginUrl);

			return data;
		} catch (error) {
			setIsLoading(false);
			// @ts-ignore
			console.error('Login error:', error.response?.data || error.message);
		}
	};

	const onLogout = async () => {
		signOut();
		navigate(`../`, { replace: true });
	};

	const value = useMemo(
		() => ({
			authUser,
			onLogin,
			onLogout,
			isLoading,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[authUser, isLoading],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};
