import {
	ICreateOfferForm,
	IFetchOfferByIdRequest,
	IFetchOfferRequest,
} from '../interface/OfferResource.interface';
import {
	createOrUpdateOfferApi,
	createOrderFromOfferApi,
	getOfferById,
	getOffers as getOffersAPi,
} from '../api/offerResourceApi';
import { APIRequestParams } from '../interface/APIRequestParams.interface';

export const fetchOffer = async (
	request: IFetchOfferRequest,
	apiRequestParams?: APIRequestParams,
) => {
	return getOffersAPi(request, apiRequestParams);
};

export const fetchOfferById = async ({
	setData,
	setIsLoading,
	offerId,
}: IFetchOfferByIdRequest) => {
	return getOfferById({
		setData,
		setIsLoading,
		offerId,
	});
};

export const createOrUpdateOffer = async (form: ICreateOfferForm) => {
	return createOrUpdateOfferApi(form);
};

export const createOrderFromOffer = async (offerId: string) => {
	return createOrderFromOfferApi(offerId);
};
