import React, { useLayoutEffect } from 'react';
import useFontSize from '../../hooks/useFontSize';
import { LalegalContainer } from './Lalegal';
import { Link } from 'react-router-dom';

const TermsOfUse = () => {
	const { setFontSize } = useFontSize();
	useLayoutEffect(() => {
		setFontSize(16);
	});

	return (
		<LalegalContainer title='Terms of Use | Linn Alfredsson'>
			<div className='max-xl:scroll-mt-offset min-h-dvh snap-center bg-white font-poppins'>
				<div className='grid min-h-dvh grid-cols-12'>
					<div className='relative col-span-12 flex h-full flex-col justify-center px-4 py-12 max-md:bg-white/50 max-md:px-4 md:px-6 xl:px-12 xl:py-24 2xl:px-24'>
						<div className='flex flex-col gap-8 pe-4'>
							<div className='text-3xl font-bold tracking-widest text-linn-500 xl:text-5xl 2xl:text-6xl'>
								Terms of Use
							</div>
							<div className='text-lg !leading-relaxed md:max-w-screen-md xl:text-2xl 2xl:text-3xl'>
								LA Legal Website
								<div className='text-sm'>
									<b>Last Updated:</b> 20 Jan 2025
								</div>
							</div>
							<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
								<b>Welcome to my website!</b>
								<br />
								Thank you for visiting. These Terms of Use explain the rules for
								using this Website and outline how my services, including
								consultations and courses, are offered. By accessing or using this
								Website, booking consultations, or enrolling in courses, you agree
								to these Terms. Please take a moment to read them carefully.
							</div>

							<div className='grid grid-cols-12 gap-8 xl:gap-12'>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>1. Using This Website</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										You’re welcome to explore and use this Website, as long as
										you do so respectfully and in compliance with the law. You
										may not use the Website to upload harmful content, attempt
										unauthorized access, or engage in any activity that disrupts
										the Website’s functionality or reputation. If these terms
										are violated, I reserve the right to limit or terminate your
										access.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>2. Booking Consultations</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										This Website allows you to book one-on-one consultations
										with me. When booking, please note the following:
										<br />
										<ul className='list-inside list-disc'>
											<li>
												<b>Accurate Information:</b> You must provide
												accurate and complete information during the booking
												process.
											</li>
											<li>
												<b>Confirmation:</b> All bookings are subject to
												availability and will be confirmed via email.
											</li>
											<li>
												<b>Cancellations:</b> Cancellation and refund
												policies will be outlined during the booking process
												or in the confirmation email.
											</li>
										</ul>
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>3. Courses</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										I offer courses and workshops that are hosted on a
										third-party platform. Please keep the following in mind:
										<br />
										<ul className='list-inside list-disc'>
											<li>
												<b>Third-Party Terms:</b> Enrollment, payment, and
												participation are governed by the terms and policies
												of the third-party platform.
											</li>
											<li>
												<b>Course Information:</b> Details about my courses
												and links to the hosting platform are available in
												the course section of this Website.
											</li>
											<li>
												<b>Personal Use Only:</b> Course materials are for
												your personal use and may not be copied, shared, or
												modified without permission.
											</li>
										</ul>
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>4. Copyright and Intellectual Property</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										All content and materials associated with this Website,
										consultations, and courses are protected under copyright and
										intellectual property laws. This includes, but is not
										limited to, text, graphics, designs, videos, logos, and
										other materials created by or on behalf of <b>LA Legal</b>.
										<br />
										<br />
										You are welcome to view, download, or print materials from
										this Website for personal, non-commercial use only. Any
										other use, such as reproduction, distribution, or
										modification of the content, is prohibited without prior
										written consent.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>5. Privacy</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										Your privacy is important to me. My{' '}
										<Link to='../lalegal/privacy-policy' className='underline'>
											Privacy Policy
										</Link>{' '}
										explains how your personal data is collected, used, and
										protected when you visit this Website or use my services. By
										using this Website, you agree to the terms of the Privacy
										Policy.
										<br />
										To read the full Privacy Policy,{' '}
										<Link to='../lalegal/privacy-policy' className='underline'>
											click here
										</Link>
										.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>6. Payments</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										Payments for consultations and courses are handled through
										the methods outlined during the booking or enrollment
										process (via third party platforms). Any applicable taxes or
										fees will be clearly stated. Refunds are only available if
										specifically stated in the cancellation or refund policies
										provided at the time of booking or enrollment.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>7. Disclaimers</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										I do my best to provide accurate and up-to-date information,
										both on the Website and through my services. However, I
										can’t guarantee that everything will always be perfect. The
										Website and my services are offered “as is” without any
										guarantees, and I’m not responsible for delays,
										interruptions, or errors.
										<br />
										<br />
										While I aim to offer helpful insights and guidance during
										consultations or courses, my services are not a substitute
										for professional, legal, or financial advice tailored to
										your specific circumstances.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>8. Limitation of Liability</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										To the fullest extent permitted by law, I’m not responsible
										for any damages that arise from your use of the Website or
										services. This includes things like data loss, business
										interruptions, or reliance on the information provided. If
										you’re unhappy with the Website or services, the best course
										of action is to stop using them.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>9. Changes to These Terms</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										From time to time, I may update these Terms to reflect
										changes in my services or legal requirements. If updates are
										made, I’ll post them here with a revised “Last Updated”
										date. By continuing to use the Website or services after the
										updates, you agree to the new Terms.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>10. Governing Law</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										These Terms are governed by the laws of Sweden. Any disputes
										arising from these Terms or your use of the Website and
										services will be resolved in Swedish courts.
									</div>
								</div>
								<div className='col-span-12 flex flex-col flex-wrap gap-4'>
									<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[48rem]'>
										<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
										<span>11. Contact Information</span>
									</div>
									<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
										If you have any questions about these Terms of Use, or if
										you’d like to get in touch with me, here’s how you can reach
										me:
										<br />
										<b>Email:</b>{' '}
										<a href='mailto:hello@lalegal.se' className='underline'>
											hello@lalegal.se
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</LalegalContainer>
	);
};

export default TermsOfUse;
