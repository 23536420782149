import React from 'react';
import Header, { HeaderLeft } from '../components/layouts/Header/Header';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Container from '../components/layouts/Container/Container';
import { DeliveryMan5WithDog } from '../assets/images';
import { useTranslation } from 'react-i18next';

const NoAccessPage = () => {
	const { t } = useTranslation();
	return (
		<>
			<Header>
				<HeaderLeft>NoAccessPage</HeaderLeft>
			</Header>
			<PageWrapper>
				<Container className='flex h-full items-center justify-center'>
					<div className='grid grid-cols-12 gap-4'>
						<div className='col-span-12 mb-16 flex flex-wrap justify-center gap-4'>
							<div className='flex basis-full justify-center'>
								<span className='text-5xl font-semibold'>{t('No Access')}</span>
							</div>
							<div className='flex basis-full justify-center'>
								<span className='text-zinc-500'>
									{t(
										'You do not have access to the page, please contact the support team..',
									)}
								</span>
							</div>
						</div>
						<div className='col-span-3' />
						<div className='col-span-6 flex justify-center'>
							<img
								src={DeliveryMan5WithDog as string}
								alt=''
								className='max-h-[32rem]'
							/>
						</div>
					</div>
				</Container>
			</PageWrapper>
		</>
	);
};

export default NoAccessPage;
