import {
	IStockPointListRequest,
	IStockPointSaveRequest,
	ITenantStockPointRequest,
} from '../interface/StockPoint.interface';
import {
	getStockPointConfig,
	getTenantStockPointConfig,
	postStockPointConfig,
} from '../api/stockPointResourceApi';
import { IArticleConfigRequest } from '../interface/ArticleConfig.interface';
import { getArticleConfig } from '../api/articleConfigResourceApi';
import { getArticleDetailsApi, getArticlePriceListApi } from '../api/articleResourceApi';
import { IPricesResponse } from '../interface/Article.interface';

export const fetchStockPointConfigList = async (request: IStockPointListRequest) => {
	return getStockPointConfig(request);
};

export const fetchTenantStockPointConfig = async (request: ITenantStockPointRequest) => {
	return getTenantStockPointConfig(request);
};

export const fetchArticleStockConfig = async (request: IArticleConfigRequest) => {
	return getArticleConfig(request);
};

export const saveStockPointConfig = async (request: IStockPointSaveRequest) => {
	return postStockPointConfig(request);
};

export const fetchArticleDetails = async (articleNumber: string) => {
	return getArticleDetailsApi(articleNumber);
};

export const fetchArticlePriceList = async (
	articleId: number,
	priceListCode: string,
): Promise<IPricesResponse> => {
	const response = await getArticlePriceListApi(articleId, priceListCode);
	return response.data as IPricesResponse;
};
