import React from 'react';
import Container from '../../components/layouts/Container/Container';
import PageWrapper from '../../components/layouts/PageWrapper/PageWrapper';
import { PricingSection } from '../LandingPage/LandingPage.page';
import Icon from '../../components/icon/Icon';
import Button from '../../components/ui/Button';
import { Link } from 'react-router-dom';
import Visible from '../../components/utils/Visible';
import { useTranslation } from 'react-i18next';
import useHash from '../../hooks/useHash';
import { customerViewPages } from '../../config/pages.config';

interface PackageDetails {
	name: string;
	consultingCompany: string | boolean;
	serviceCompany: string | boolean;
	productCompany: string | boolean;
	premium: string | boolean;
}
interface Packages {
	[key: string]: PackageDetails;
}
interface TransformedPackage {
	[key: string]: {
		[key: string]: {
			name: string;
			value: string | boolean;
		};
	};
}

const PackageComparisonSection = () => {
	const { t } = useTranslation();

	const packages: Packages = {
		emailSupport: {
			name: t('Email Support'),
			consultingCompany: `8 ${t('hours')}`,
			serviceCompany: `8 ${t('hours')}`,
			productCompany: `8 ${t('hours')}`,
			premium: `8 ${t('hours')}`,
		},
		liveChatSupport: {
			name: t('Live Chat Support'),
			consultingCompany: true,
			serviceCompany: true,
			productCompany: true,
			premium: true,
		},
		telephoneSupport: {
			name: t('Phone Support'),
			consultingCompany: false,
			serviceCompany: false,
			productCompany: false,
			premium: true,
		},
		events: {
			name: t('Events'),
			consultingCompany: true,
			serviceCompany: true,
			productCompany: true,
			premium: true,
		},
		webshop: {
			name: t('Webshop'),
			consultingCompany: false,
			serviceCompany: false,
			productCompany: true,
			premium: true,
		},
		productInformation: {
			name: t('Product Information'),
			consultingCompany: false,
			serviceCompany: false,
			productCompany: true,
			premium: true,
		},
		directSales: {
			name: t('Direct Sales'),
			consultingCompany: false,
			serviceCompany: false,
			productCompany: true,
			premium: true,
		},
		crm: {
			name: t('CRM'),
			consultingCompany: true,
			serviceCompany: true,
			productCompany: true,
			premium: true,
		},
		projectManagement: {
			name: t('Project Management'),
			consultingCompany: `4 ${t('active')}`,
			serviceCompany: `6 ${t('active')}`,
			productCompany: `10 ${t('active')}`,
			premium: t('Unlimited'),
		},
		caseManagement: {
			name: t('Case Management'),
			consultingCompany: true,
			serviceCompany: true,
			productCompany: false,
			premium: true,
		},
		timeReporting: {
			name: t('Time Reporting'),
			consultingCompany: true,
			serviceCompany: false,
			productCompany: false,
			premium: true,
		},
		supportPortal: {
			name: t('Support Portal'),
			consultingCompany: false,
			serviceCompany: true,
			productCompany: false,
			premium: true,
		},
		businessIntelligence: {
			name: t('Business Intelligence'),
			consultingCompany: false,
			serviceCompany: false,
			productCompany: false,
			premium: true,
		},
	};

	const getCellContent = (content: string | boolean, isFeatured?: boolean) => {
		if (typeof content === 'string') {
			return content;
		} else if (content) {
			return (
				<Icon
					icon='HeroCheck'
					color='blue'
					size='text-2xl'
					className={isFeatured ? 'text-white' : ''}
				/>
			);
		}
		return <Icon icon='HeroXMark' size='text-2xl' />;
	};

	function transformPackages(packages: Packages): TransformedPackage {
		const result: TransformedPackage = {};

		for (const [key, value] of Object.entries(packages)) {
			for (const [subKey, subValue] of Object.entries(value)) {
				if (subKey !== 'name') {
					if (!result[subKey]) {
						result[subKey] = {};
					}
					result[subKey][key] = { name: value.name, value: subValue };
				}
			}
		}

		return result;
	}

	const transformedPackages = transformPackages(packages);

	return (
		<section id='plan-comparison' className='-mx-4 bg-indigo-500/5 lg:pt-20'>
			<div className='overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
				<div className='container mx-auto hidden px-4 lg:block'>
					<div className='mb-16 grid grid-cols-12'>
						<div className='col-span-4'>
							<div className='mb-8 text-3xl font-extrabold dark:text-white md:text-4xl xl:text-5xl'>
								{t('Plan Comparison')}
							</div>
							<div className='text-lg text-zinc-500'>
								{t('Choose the plan that best suits your business.')}
							</div>
						</div>
						<div className='col-span-8'>
							<div className='grid grid-cols-4 text-center text-2xl font-extrabold'>
								<div>{t('Consulting Firms')}</div>
								<div>{t('Service Companies')}</div>
								<div>{t('Product Companies')}</div>
								<div>{t('Premium')}</div>
							</div>
						</div>
					</div>
					{Object.values(packages).map((item, index) => (
						<div
							key={item.name}
							className='grid grid-cols-12 rounded-2xl even:bg-indigo-500/5 even:dark:bg-indigo-500/10 [&>*]:p-6'>
							<div className='col-span-4 text-lg font-bold'>{item.name}</div>
							<div className='col-span-2 flex items-center justify-center text-zinc-600 dark:text-zinc-400'>
								{getCellContent(item.consultingCompany)}
							</div>
							<div className='col-span-2 flex items-center justify-center text-zinc-600 dark:text-zinc-400'>
								{getCellContent(item.serviceCompany)}
							</div>
							<div className='col-span-2 flex items-center justify-center text-zinc-600 dark:text-zinc-400'>
								{getCellContent(item.productCompany)}
							</div>
							<div className='relative col-span-2 flex items-center justify-center bg-blue-500 text-white'>
								{getCellContent(item.premium, true)}
								<Visible is={index === 0}>
									<span className='absolute -top-8 h-8 w-full rounded-t-xl bg-blue-500' />
								</Visible>
							</div>
						</div>
					))}
					<div className='grid grid-cols-12 rounded-2xl even:bg-indigo-500/5 even:dark:bg-indigo-500/10 [&>*]:p-6'>
						<div className='col-span-4 text-lg font-bold' />
						<div className='col-span-2 flex items-center justify-center text-zinc-600 dark:text-zinc-400'>
							<Link
								to={{
									pathname: customerViewPages.contactUsPage.to,
									search: '?subject=Demo&description=Please contact me to schedule a demo',
								}}>
								<Button variant='outline'>{t('Get started now')}</Button>
							</Link>
						</div>
						<div className='col-span-2 flex items-center justify-center text-zinc-600 dark:text-zinc-400'>
							<Link
								to={{
									pathname: customerViewPages.contactUsPage.to,
									search: '?subject=Demo&description=Please contact me to schedule a demo',
								}}>
								<Button variant='outline'>{t('Get started now')}</Button>
							</Link>
						</div>
						<div className='col-span-2 flex items-center justify-center text-zinc-600 dark:text-zinc-400'>
							<Link
								to={{
									pathname: customerViewPages.contactUsPage.to,
									search: '?subject=Demo&description=Please contact me to schedule a demo',
								}}>
								<Button variant='outline'>{t('Get started now')}</Button>
							</Link>
						</div>
						<div className='col-span-2 flex items-center justify-center rounded-b-xl bg-blue-500 text-white'>
							<Link
								to={{
									pathname: customerViewPages.contactUsPage.to,
									search: '?subject=Demo&description=Please contact me to schedule a demo',
								}}>
								<Button className='bg-white !text-blue-500 hover:opacity-70'>
									{t('Get started now')}
								</Button>
							</Link>
						</div>
					</div>
				</div>
				<div className='container mx-auto px-4 lg:hidden'>
					<div className='mb-4 mt-8 text-xl font-extrabold'>Konsultbolag</div>
					{Object.values(transformedPackages.consultingCompany).map((item) => (
						<div
							key={item.name}
							className='flex gap-2 rounded-lg p-2 even:bg-indigo-500/5 even:dark:bg-indigo-500/10'>
							<div>{item.name}:</div>
							<div>{getCellContent(item.value)}</div>
						</div>
					))}
					<div className='mb-4 mt-8 text-xl font-extrabold'>Tjänstebolag</div>
					{Object.values(transformedPackages.serviceCompany).map((item) => (
						<div
							key={item.name}
							className='flex gap-2 rounded-lg p-2 even:bg-indigo-500/5 even:dark:bg-indigo-500/10'>
							<div>{item.name}:</div>
							<div>{getCellContent(item.value)}</div>
						</div>
					))}
					<div className='mb-4 mt-8 text-xl font-extrabold'>Produktbolag</div>
					{Object.values(transformedPackages.productCompany).map((item) => (
						<div
							key={item.name}
							className='flex gap-2 rounded-lg p-2 even:bg-indigo-500/5 even:dark:bg-indigo-500/10'>
							<div>{item.name}:</div>
							<div>{getCellContent(item.value)}</div>
						</div>
					))}
					<div className='mb-4 mt-8 text-xl font-extrabold'>Premium</div>
					{Object.values(transformedPackages.premium).map((item) => (
						<div
							key={item.name}
							className='flex gap-2 rounded-lg p-2 even:bg-indigo-500/5 even:dark:bg-indigo-500/10'>
							<div>{item.name}:</div>
							<div>{getCellContent(item.value)}</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

const PricingPage = () => {
	const { t } = useTranslation();
	useHash();

	return (
		<PageWrapper isProtectedRoute={false} title={t('Landing')}>
			<Container breakpoint={null} className='w-full sm:py-0 md:py-4'>
				<PricingSection isShowRequestDemo={false} />
				<PackageComparisonSection />
			</Container>
		</PageWrapper>
	);
};

export default PricingPage;
