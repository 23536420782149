import React from 'react';
import Badge from '../../../components/ui/Badge';
import { NavItem, NavSeparator } from '../../../components/layouts/Navigation/Nav';
import { authPages, tenantViewPages } from '../../../config/pages.config';
import User from '../../../components/layouts/User/User';
import { useAuth } from '../../../context/authContext';
import Icon from '../../../components/icon/Icon';
import useAccessCheck from '../../../hooks/useAccessCheck';
import ROLES from '../../../constants/roles.constant';
import { useTranslation } from 'react-i18next';

const IMAGE_URL = process.env.REACT_APP_MEDIA_BASE_URL;

const UserTemplate = () => {
	const { onLogout } = useAuth();
	const { t } = useTranslation();

	const { authUser } = useAuth();

	const hasSuperAdminAccess = useAccessCheck([ROLES.ROLE_SUPER_ADMIN]);
	const hasTenantAccess = useAccessCheck([ROLES.ROLE_TENANT_ADMIN, ROLES.ROLE_TENANT_USER]);

	return (
		<User
			name={authUser?.username as string}
			nameSuffix={<Icon icon='HeroCheckBadge' color='blue' />}
			position={authUser?.email as string}
			src={authUser?.profileImageId ? `${IMAGE_URL}/${authUser?.profileImageId}` : undefined}
			suffix={
				<Badge
					color={hasSuperAdminAccess ? 'emerald' : 'amber'}
					variant='solid'
					className='text-xs font-bold'>
					{/*TODO*/}
					{hasSuperAdminAccess && t('Admin')}
					{hasTenantAccess && t('Tenant')}
				</Badge>
			}>
			<NavSeparator />
			<NavItem
				{...authPages.profilePage}
				to={`${tenantViewPages.tenantPage.subPages.usersPage.to}/${authUser?.uid}`}
			/>
			{/*<NavItem {...settingsPages.settingsMainPages} isEnd={false} />*/}
			<NavItem text='Logout' icon='HeroArrowRightOnRectangle' onClick={() => onLogout()} />
		</User>
	);
};

export default UserTemplate;
