import React, { FC } from 'react';
import Tooltip from '../ui/Tooltip';
import Button from '../ui/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { supportPages } from '../../config/pages.config';
import { show } from '@intercom/messenger-js-sdk';
import hasAnyRequiredRole from '../../utils/hasAnyRequiredRole.util';
import ROLES from '../../constants/roles.constant';
import { useAuth } from '../../context/authContext';

interface IHelperLinkButtonsProps {
	documentationTo?: string | null;
	supportTo?: string | null;
	settingsTo?: string | null;
}
const HelperLinkButtons: FC<IHelperLinkButtonsProps> = (props) => {
	const {
		// documentationTo,
		// supportTo = supportPages.supportMainPages.subPages.supportStartPages.to,
		settingsTo,
	} = props;
	/**
	 * Utils
	 */
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { authUser } = useAuth();
	const isTenant = hasAnyRequiredRole(authUser?.roles, [
		ROLES.ROLE_TENANT_USER,
		ROLES.ROLE_TENANT_ADMIN,
	]);

	return (
		<>
			{/*{documentationTo && (*/}
			{/*	<Tooltip text={t('Documentation')}>*/}
			{/*		<Button*/}
			{/*			icon='HeroBookOpen'*/}
			{/*			onClick={() => navigate(documentationTo)}*/}
			{/*			className='max-sm:hidden'*/}
			{/*		/>*/}
			{/*	</Tooltip>*/}
			{/*)}*/}
			<Tooltip text={t('Documentation')}>
				<Button
					icon='HeroBookOpen'
					onClick={() => {
						window.open('http://docs.centrasync.io/', '_blank', 'noopener,noreferrer');
					}}
					className='max-sm:hidden'
				/>
			</Tooltip>
			{/*{supportTo && (*/}
			{/*	<Tooltip text={t('Support')}>*/}
			{/*		<Button*/}
			{/*			icon='HeroQuestionMarkCircle'*/}
			{/*			onClick={() => navigate(supportTo)}*/}
			{/*			className='max-sm:hidden'*/}
			{/*		/>*/}
			{/*	</Tooltip>*/}
			{/*)}*/}
			{isTenant && (
				<Tooltip text={t('Support')}>
					<Button
						icon='HeroQuestionMarkCircle'
						onClick={() => show()}
						className='max-sm:hidden'
					/>
				</Tooltip>
			)}
			{settingsTo && (
				<Tooltip text={t('Settings')}>
					<Button
						icon='HeroCog6Tooth'
						onClick={() => navigate(settingsTo)}
						className='max-sm:hidden'
					/>
				</Tooltip>
			)}
		</>
	);
};

export default HelperLinkButtons;
