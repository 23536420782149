import React from 'react';
import Button from '../ui/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BackButton = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<span className='border-e border-zinc-500/25 pe-4'>
			<Button icon='HeroArrowLeft' className='!px-0 lg:hidden' onClick={() => navigate(-1)} />
			<Button
				icon='HeroArrowLeft'
				className='hidden !px-0 lg:inline-flex'
				onClick={() => navigate(-1)}>
				{t('Back')}
			</Button>
		</span>
	);
};

export default BackButton;
