import React, { FC, HTMLAttributes } from 'react';
// import colors from '../../../tailwindcss/colors.tailwind';
// import themeConfig from '../../../config/theme.config';
import { LogoDark, LogoLight } from '../../../assets/images';
import useDarkMode from '../../../hooks/useDarkMode';

type ILogoTemplateProps = HTMLAttributes<HTMLOrSVGElement>;
const LogoTemplate: FC<ILogoTemplateProps> = (props) => {
	const { ...rest } = props;
	const { isDarkTheme } = useDarkMode();

	return <img src={isDarkTheme ? LogoDark : LogoLight} alt='Logo' {...rest} />;
};

export default LogoTemplate;
