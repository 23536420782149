import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/ui/Dropdown';
import Button from '../../../../components/ui/Button';
import LANG from '../../../../constants/lang.constant';
import Icon from '../../../../components/icon/Icon';
import ThemeContext from '../../../../context/themeContext';
import classNames from 'classnames';
import themeConfig from '../../../../config/theme.config';

const LanguageSelectorPartial = () => {
	const { t, i18n } = useTranslation();
	const { setLanguage, isLoadingLng } = useContext(ThemeContext);

	const langArray = Object.values(LANG);
	const [activeLang, setActiveLang] = useState(
		langArray.filter((key) => key.lng === i18n.language || themeConfig.language)[0],
	);
	useEffect(() => {
		setActiveLang(langArray.filter((key) => key.lng === i18n.language)[0]);
	}, [i18n.language, langArray]);

	return (
		<Dropdown className='max-sm:hidden'>
			<DropdownToggle hasIcon={false}>
				<Button
					icon={isLoadingLng ? 'HeroArrowPath' : activeLang.icon}
					aria-label={t('Select Language')}
					className={classNames({ 'animate-spin': isLoadingLng })}
					isDisable={isLoadingLng}
				/>
			</DropdownToggle>
			<DropdownMenu placement='bottom-end'>
				{langArray.map((item) => (
					<DropdownItem
						isActive={activeLang.lng === item.lng}
						key={item.lng}
						onClick={() => setLanguage(item.lng)}>
						<Icon icon={item.icon} size='text-2xl' className='ltr:mr-2 rtl:ml-2' />
						{item.text}
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	);
};

export default LanguageSelectorPartial;
