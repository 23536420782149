import React, { useState } from 'react';
import Container from '../components/layouts/Container/Container';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import { useTranslation } from 'react-i18next';
import useHash from '../hooks/useHash';
import Button from '../components/ui/Button';
import { useFormik } from 'formik';
import Label from '../components/form/Label';
import FieldWrap from '../components/form/FieldWrap';
import Icon from '../components/icon/Icon';
import Input from '../components/form/Input';
import Textarea from '../components/form/Textarea';
import Progress from '../components/ui/Progress';
import Visible from '../components/utils/Visible';
import Validation from '../components/form/Validation';
import { useSearchParams } from 'react-router-dom';

type TValues = {
	name: string;
	email: string;
	companyName: string;
	subject: string;
	description: string;
};

const ContactUsPage = () => {
	const { t } = useTranslation();
	useHash();

	const [searchParams] = useSearchParams();

	const [isSend, setIsSend] = useState<boolean>(false);

	const _subject = searchParams.get('subject');
	const _description = searchParams.get('description');

	const formik = useFormik<TValues>({
		enableReinitialize: true,
		initialValues: {
			name: '',
			email: '',
			companyName: '',
			subject: _subject ? t(_subject) : '',
			description: _description ? t(_description) : '',
		},
		validate: (values: TValues) => {
			const errors: Partial<TValues> = {};

			if (!values.name) {
				errors.name = t('Required');
			}
			if (!values.email) {
				errors.email = t('Required');
			} else if (!/\S+@\S+\.\S+/.test(values.email)) {
				errors.email = t('Invalid email address');
			}
			if (!values.companyName) {
				errors.companyName = t('Required');
			}
			if (!values.subject) {
				errors.subject = t('Required');
			}
			if (!values.description) {
				errors.description = t('Required');
			}

			return errors;
		},
		onSubmit: (values) => {
			setIsSend(true);
			console.log('Form data:', values);
		},
	});

	return (
		<PageWrapper isProtectedRoute={false} title={t('Contact Us')}>
			<Container breakpoint={null} className='w-full'>
				<section
					id='top'
					className='-mx-4 -mt-[var(--header-height)] bg-indigo-500/5 pt-[var(--header-height)] lg:pt-[calc(var(--header-height)+6rem)]'>
					<div className='overflow-hidden py-10 lg:m-0 xl:py-8 2xl:py-16'>
						<div className='container mx-auto px-4'>
							<div className='grid grid-cols-12 items-center'>
								<div className='col-span-12 flex w-full flex-wrap gap-8 xl:col-span-6'>
									<h1 className='mb-4 max-w-2xl text-4xl font-extrabold !leading-snug text-zinc-700 dark:text-white md:text-5xl xl:text-6xl'>
										{t('Contact Us')}
									</h1>
								</div>
								<div className='col-span-12 flex w-full flex-col flex-wrap gap-4 xl:col-span-6'>
									<Visible is={!isSend}>
										<p className='max-w-3xl text-justify text-2xl font-extrabold !leading-relaxed text-zinc-700 dark:text-white'>
											{t('Contact us for your questions')}
										</p>
										<div className='grid grid-cols-2 gap-4'>
											<div className='col-span-2'>
												<Label htmlFor='name'>{t('Name')}</Label>
												<Validation
													isValid={formik.isValid}
													isTouched={formik.touched.name}
													invalidFeedback={formik.errors.name}
													validFeedback='Good'>
													<FieldWrap
														firstSuffix={
															<Icon
																icon='HeroUser'
																className='mx-2'
															/>
														}>
														<Input
															id='name'
															name='name'
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															value={formik.values.name}
															autoComplete='name'
															placeholder={t('Name')}
														/>
													</FieldWrap>
												</Validation>
											</div>
											<div className='col-span-2'>
												<Label htmlFor='email'>{t('Email')}</Label>
												<Validation
													isValid={formik.isValid}
													isTouched={formik.touched.email}
													invalidFeedback={formik.errors.email}
													validFeedback='Good'>
													<FieldWrap
														firstSuffix={
															<Icon
																icon='HeroEnvelope'
																className='mx-2'
															/>
														}>
														<Input
															id='email'
															name='email'
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															value={formik.values.email}
															autoComplete='email'
															placeholder={t('Email')}
														/>
													</FieldWrap>
												</Validation>
											</div>
											<div className='col-span-2'>
												<Label htmlFor='companyName'>
													{t('Company Name')}
												</Label>
												<Validation
													isValid={formik.isValid}
													isTouched={formik.touched.companyName}
													invalidFeedback={formik.errors.companyName}
													validFeedback='Good'>
													<FieldWrap
														firstSuffix={
															<Icon
																icon='HeroBuildingOffice2'
																className='mx-2'
															/>
														}>
														<Input
															id='companyName'
															name='companyName'
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															value={formik.values.companyName}
															autoComplete='organization'
															placeholder={t('Company Name')}
														/>
													</FieldWrap>
												</Validation>
											</div>
											<div className='col-span-2'>
												<Label htmlFor='subject'>{t('Subject')}</Label>
												<Validation
													isValid={formik.isValid}
													isTouched={formik.touched.subject}
													invalidFeedback={formik.errors.subject}
													validFeedback='Good'>
													<FieldWrap
														firstSuffix={
															<Icon icon='HeroTag' className='mx-2' />
														}>
														<Input
															id='subject'
															name='subject'
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															value={formik.values.subject}
															autoComplete='subject'
															placeholder={t('Subject')}
														/>
													</FieldWrap>
												</Validation>
											</div>
											<div className='col-span-2'>
												<Label htmlFor='description'>
													{t('Description')}
												</Label>
												<Validation
													isValid={formik.isValid}
													isTouched={formik.touched.description}
													invalidFeedback={formik.errors.description}
													validFeedback='Good'>
													<Textarea
														id='description'
														name='description'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.description}
														placeholder={t('Enter description...')}
														rows={8}
														maxLength={1000}
													/>
												</Validation>
												<div className='mt-2 flex items-center gap-4 text-xs text-zinc-500'>
													<div className='flex-shrink-0'>
														{t('Max length')} :{' '}
														<span className='font-mono'>
															{formik.values.description.length}
															/1000
														</span>
													</div>
													<Progress
														className='!h-2'
														color={
															formik.values.description.length <= 800
																? 'blue'
																: formik.values.description
																			.length <= 900
																	? 'amber'
																	: 'red'
														}
														value={formik.values.description.length}
														max={1000}
													/>
												</div>
											</div>
											<div className='col-span-2'>
												<Button
													icon='HeroPaperAirplane'
													variant='outline'
													className='w-full'
													size='xl'
													onClick={() => formik.handleSubmit()}>
													{t('Send')}
												</Button>
											</div>
										</div>
									</Visible>
									<Visible is={isSend}>
										<div className='flex flex-col items-center justify-center gap-8'>
											<Icon
												icon='HeroInboxArrowDown'
												color='emerald'
												size='text-9xl'
											/>
											<div className='text-center text-4xl font-semibold leading-normal text-emerald-500'>
												{t(
													'We have received your message, we will get back to you as soon as possible.',
												)}
											</div>
										</div>
									</Visible>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Container>
		</PageWrapper>
	);
};

export default ContactUsPage;
