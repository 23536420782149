import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { supportPages } from '../../config/pages.config';

const SupportSupportPage = lazy(() => import('../../pages/support/Support.support.page'));
const ContactSupportSupportPage = lazy(
	() => import('../../pages/support/ContactSupport.support.page'),
);
const SupportStartSupportPage = lazy(
	() => import('../../pages/support/SupportStart/SupportStart.support.page'),
);
const MySupportCasesSupportPage = lazy(
	() => import('../../pages/support/MySupportCases/MySupportCases.support.page'),
);
const MySupportCaseSupportPage = lazy(
	() => import('../../pages/support/MySupportCases/MySupportCase.support.page'),
);

const supportRoutes: RouteProps[] = [
	{
		path: supportPages.supportMainPages.to,
		element: <SupportSupportPage />,
	},
	{
		path: supportPages.supportMainPages.subPages.contactSupportPages.to,
		element: <ContactSupportSupportPage />,
	},
	{
		path: supportPages.supportMainPages.subPages.supportStartPages.to,
		element: <SupportStartSupportPage />,
	},
	{
		path: supportPages.supportMainPages.subPages.mySupportCasesPages.to,
		element: <MySupportCasesSupportPage />,
	},
	{
		path: `${supportPages.supportMainPages.subPages.mySupportCasesPages.to}/:id`,
		element: <MySupportCaseSupportPage />,
	},
];

export default supportRoutes;
