import React, {
	FC,
	forwardRef,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import PageWrapper from '../../components/layouts/PageWrapper/PageWrapper';
import { Linn02, Linn03, Linn04, Linn05, LinnBadge } from '../../assets/images';
import useFontSize from '../../hooks/useFontSize';
import dayjs from 'dayjs';
import Header, { HeaderLeft, HeaderRight } from '../../components/layouts/Header/Header';
import Button from '../../components/ui/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Visible from '../../components/utils/Visible';
import { authPages } from '../../config/pages.config';
import { AuthContext, useAuth } from '../../context/authContext';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../components/ui/Dropdown';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasFooter,
	OffCanvasFooterChild,
	OffCanvasHeader,
} from '../../components/ui/OffCanvas';
import useHash from '../../hooks/useHash';
import { useFormik } from 'formik';

const SectionOne = forwardRef<HTMLDivElement>((_, ref) => {
	return (
		<div id='home' ref={ref} className='min-h-dvh snap-center bg-white font-poppins'>
			<div className='grid min-h-dvh grid-cols-12'>
				<div className='z-10 col-span-6 flex h-full flex-col justify-center gap-6 bg-linn-500 p-6 md:col-span-4 lg:p-12 xl:gap-12 xl:p-24'>
					{/*SOCIAL*/}
					<div className='flex gap-2 text-lg font-normal uppercase tracking-widest text-[#F8F1FE] max-md:flex-col md:items-center md:gap-8 xl:gap-16 xl:text-xl'>
						<a
							href='https://www.linkedin.com/in/linnalfredsson/'
							target='_blank'
							rel='noreferrer'>
							LinkedIn
						</a>
						{/*<a*/}
						{/*	href='https://www.instagram.com/linnalfredsson/?hl=sv'*/}
						{/*	target='_blank'*/}
						{/*	rel='noreferrer'>*/}
						{/*	INSTAGRAM*/}
						{/*</a>*/}
					</div>
					{/*SOCIAL*/}
					<div className='text-[clamp(3rem,9vw,13rem)] font-bold leading-none text-[#F8F1FE]'>
						<div>Linn</div>
						<div>Alfredsson</div>
					</div>
					<div className='font-normal !leading-relaxed text-linnh md:text-lg xl:text-xl'>
						Tech Lawyer | Public Speaker | Board Professional
					</div>
					<div className='pt-6 xl:pt-12'>
						<img src={Linn04} alt='Linn Signature' className='w-64' />
					</div>
				</div>
				<div
					className='col-span-6 h-full bg-cover bg-top md:col-span-8'
					style={{ backgroundImage: `url(${Linn03})` }}></div>
			</div>
		</div>
	);
});
const SectionTwo = forwardRef<HTMLDivElement>((_, ref) => {
	return (
		<div
			ref={ref}
			id='about'
			className='max-xl:scroll-mt-offset min-h-dvh snap-center bg-white font-poppins'>
			<div className='grid min-h-dvh grid-cols-12'>
				<div
					className='order-2 col-span-12 h-[50vh] bg-cover bg-top max-md:mb-8 md:order-1 md:col-span-4 md:h-full md:min-h-dvh lg:me-12 xl:me-24'
					style={{ backgroundImage: `url(${Linn02})` }}></div>
				<div className='relative order-1 col-span-12 flex h-full flex-col justify-center py-12 max-md:bg-white/50 md:order-2 md:col-span-8 xl:py-24'>
					<div className='flex flex-col gap-8 pe-4 max-lg:px-4'>
						<div className='text-3xl font-bold tracking-widest text-linn-500 xl:text-5xl 2xl:text-6xl'>
							WHO AM I
						</div>
						<div className='text-lg !leading-relaxed md:max-w-screen-md xl:text-2xl 2xl:text-3xl'>
							A tech-savvy lawyer offering innovative legal services to startups and
							corporations. I am passionate about helping companies grow and with a
							hands-on approach, I strive to be your trusted advisor, enabling your
							ideas to flourish while I handle the legal complexities.
						</div>
						<div className='grid grid-cols-12 gap-8 xl:gap-12'>
							<div className='col-span-12 flex flex-wrap gap-4 2xl:col-span-4'>
								<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[18rem]'>
									<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
									<span>Entrepreneurial Spirit</span>
								</div>
								<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
									Leveraging a background where legal acumen meets entrepreneurial
									thinking, I offer creative, forward-thinking solutions that
									drive business growth and innovation.
								</div>
							</div>
							<div className='col-span-12 flex flex-wrap gap-4 2xl:col-span-4'>
								<div className='flex h-[4.5rem] max-w-[14rem] flex-wrap items-center gap-4 text-lg font-bold uppercase leading-9 xl:max-w-[18rem] xl:text-2xl'>
									<span>Beyond Legal Advice</span>
									<div className='h-1.5 w-8 bg-linn-500 2xl:w-[4.5rem]'></div>
								</div>
								<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
									As a team player and doer, I excel in fast-paced environments,
									thriving on opportunities to be more than a typical lawyer—I am
									your commercial strategist and trusted advisor.
								</div>
							</div>
							<div className='col-span-12 flex flex-wrap gap-4 2xl:col-span-4'>
								<div className='flex h-8 items-center gap-4 text-lg font-bold uppercase leading-9 xl:text-2xl 2xl:h-[4.5rem] 2xl:max-w-[18rem]'>
									<div className='h-8 w-1.5 shrink-0 bg-linn-500 2xl:h-[4.5rem]'></div>
									<span>Global Perspective, Local Insight</span>
								</div>
								<div className='pe-4 text-lg font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
									My journey from Stockholm to San Francisco infuses my practice
									with a rich blend of international experience and a deep
									understanding of diverse legal landscapes.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
const SectionThree = forwardRef<HTMLDivElement>((_, ref) => {
	return (
		<div
			ref={ref}
			id='experience'
			className='flex snap-center items-center bg-white font-poppins max-xl:scroll-mt-8 md:min-h-dvh'>
			<div className='grid grid-cols-12 content-center'>
				<div className='col-span-12 flex items-center ps-6 md:col-span-4 xl:ps-12 2xl:ps-24'>
					<div className='text-3xl font-bold uppercase tracking-widest text-linn-500 xl:text-5xl 2xl:text-6xl'>
						Expertise & Experience
					</div>
				</div>
				<div className='col-span-12 flex flex-col justify-center p-6 md:col-span-8 xl:px-12 xl:py-12 2xl:py-24 2xl:ps-24'>
					<div className='max-w-full text-lg !leading-relaxed xl:text-2xl 2xl:max-w-[75%] 2xl:text-3xl'>
						With 10+ years of legal practice, I offer a blend of experience from law
						firms to leading companies such as Microsoft and H&M Group. My journey
						includes impactful roles at innovative startups, equipping me with a unique
						perspective on the legal challenges and opportunities across various
						industries.
					</div>
				</div>
				<div className='col-span-12 flex flex-wrap gap-4 !bg-[#fdf9ff] px-6 py-8 md:col-span-4 xl:px-12 xl:py-16 2xl:px-24 2xl:py-32'>
					<div className='flex h-8 max-w-[18rem] items-center gap-4 font-bold leading-9 xl:text-3xl 2xl:h-[4.5rem] 2xl:text-5xl'>
						<div className='h-8 w-1 shrink-0 bg-linn-500 xl:w-1.5 2xl:h-[4rem]'></div>
						<span>Retail</span>
					</div>
					<div className='font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
						Proven track record in fortifying retail companies legally, securing
						commercial interests, and safeguarding intellectual property rights.
					</div>
				</div>
				<div className='col-span-12 flex flex-wrap gap-4 !bg-[#faf5fe] px-6 py-8 md:col-span-4 xl:px-12 xl:py-16 2xl:px-24 2xl:py-32'>
					<div className='flex h-8 max-w-[18rem] items-center gap-4 font-bold leading-9 xl:text-3xl 2xl:h-[4.5rem] 2xl:text-5xl'>
						<div className='h-8 w-1 shrink-0 bg-linn-500 xl:w-1.5 2xl:h-[4rem]'></div>
						<span>AI</span>
					</div>
					<div className='font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
						Leading the change in AI law by ensuring ethical standards, addressing
						regulatory compliance, and delivering impactful industry talks on navigating
						legal complexities.
					</div>
				</div>
				<div className='col-span-12 flex flex-wrap gap-4 !bg-[#f8f1fe] px-6 py-8 md:col-span-4 xl:px-12 xl:py-16 2xl:px-24 2xl:py-32'>
					<div className='flex h-8 max-w-[18rem] items-center gap-4 font-bold leading-9 xl:text-3xl 2xl:h-[4.5rem] 2xl:text-5xl'>
						<div className='h-8 w-1 shrink-0 bg-linn-500 xl:w-1.5 2xl:h-[4rem]'></div>
						<span>Tech</span>
					</div>
					<div className='font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
						Expertise in navigating GDPR compliance, structuring licensing deals, and
						providing startups with the legal building blocks for innovation and
						investment.
					</div>
				</div>
			</div>
		</div>
	);
});
const SectionFour = forwardRef<HTMLDivElement>((_, ref) => {
	return (
		<div
			ref={ref}
			id='services'
			className='max-xl:scroll-mt-offset snap-center bg-white font-poppins md:min-h-dvh'>
			<div className='grid grid-cols-3 content-center md:min-h-dvh md:grid-cols-12'>
				<div className='col-span-4 flex flex-wrap gap-4 md:min-h-dvh'>
					<div className='flex flex-col justify-end gap-4 px-6 py-8 xl:px-12 xl:py-16 2xl:px-24 2xl:py-20'>
						<div className='text-3xl font-bold uppercase tracking-widest text-linn-500 xl:text-5xl 2xl:text-6xl'>
							Services
						</div>
						<div className='max-w-[75%] !leading-relaxed xl:text-xl 2xl:text-2xl'>
							Legal services and speaking engagements tailored to your needs.
						</div>
					</div>
					<div className='flex w-full flex-col justify-start gap-4 !bg-[#fdfbff] px-6 py-8 xl:px-12 xl:py-16 2xl:px-24 2xl:py-20'>
						<div className='flex h-[4.5rem] flex-col gap-4 text-lg font-bold uppercase leading-9 xl:text-xl 2xl:text-2xl'>
							<span>Speaking engagements</span>
							<div className='h-1.5 w-8 bg-linn-500'></div>
						</div>
						<div className='text-lg font-normal xl:text-xl 2xl:text-2xl'>
							<ul className='list-inside list-disc'>
								<li className='xl:mb-2 2xl:mb-4'>Law & Technology Insights</li>
								<li className='xl:mb-2 2xl:mb-4'>
									Five essential insights for leaders navigating the era of AI
								</li>
								<li className='xl:mb-2 2xl:mb-4'>
									Legal Guide for Femtech Startups
								</li>
								<li className=''>Digital transformation in the legal industry</li>
							</ul>
						</div>
					</div>
				</div>
				<div className='col-span-4 flex flex-wrap gap-4 !bg-[#faf5ff] px-6 py-8 md:min-h-dvh xl:px-12 xl:py-16 2xl:px-24 2xl:py-32'>
					<div className='flex flex-col justify-center gap-4'>
						<div className='flex flex-col gap-8'>
							<div className='flex h-8 gap-4 text-lg font-bold uppercase leading-9 xl:text-xl 2xl:text-2xl'>
								<div className='h-8 w-1.5 shrink-0 bg-linn-500'></div>
								<span>Interim Legal Counsel</span>
							</div>
							<div className='text-lg font-normal !leading-relaxed xl:mb-8 xl:text-xl 2xl:text-2xl'>
								Experienced corporate lawyer with a strong background in contract
								negotiation (including SaaS), GDPR compliance, and commercial law.
								Proficient in addressing both strategic and operational issues
								concerning the legal function.
							</div>
						</div>
						<div className='flex flex-col gap-4'>
							<div className='flex h-8 gap-4 text-lg font-bold leading-9 xl:text-xl 2xl:text-2xl'>
								<span>Core areas of expertise</span>
							</div>
							<div className='text-lg font-normal xl:text-xl 2xl:text-2xl'>
								<ul className='list-inside list-disc'>
									<li className='xl:mb-2 2xl:mb-4'>Commercial Contracts</li>
									<li className='xl:mb-2 2xl:mb-4'>
										Intellectual Property Rights
									</li>
									<li className='xl:mb-2 2xl:mb-4'>Corporate Law</li>
									<li className='xl:mb-2 2xl:mb-4'>Privacy & GDPR</li>
									<li className='xl:mb-2 2xl:mb-4'>Entertainment Law</li>
									<li className=''>Marketing & Social Media Matters</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className='col-span-4 flex flex-wrap gap-4 !bg-[#fdfbff] px-6 py-8 md:min-h-dvh xl:px-12 xl:py-16 2xl:px-24 2xl:py-32'>
					<div className='flex flex-col justify-center gap-4'>
						<div className='flex h-[4.5rem] flex-col gap-4 text-lg font-bold uppercase leading-9 xl:text-xl 2xl:text-2xl'>
							<span>consultancy services</span>
							<div className='h-1.5 w-8 bg-linn-500'></div>
						</div>
						<div className='text-lg font-normal !leading-relaxed xl:mb-8 xl:text-xl 2xl:text-2xl'>
							Expert legal consultancy tailored to drive your business forward,
							ensuring compliance and strategic growth.
						</div>
						<div className='flex h-4 gap-4 text-lg font-bold leading-9 xl:text-xl 2xl:h-8 2xl:text-2xl'>
							<span>Commercial</span>
						</div>
						<div className='text-lg font-normal xl:text-xl 2xl:text-2xl'>
							<ul className='list-inside list-disc'>
								<li className='xl:mb-2 2xl:mb-4'>Expansion Strategy</li>
								<li className='xl:mb-2 2xl:mb-4'>Commercial Contracts</li>
								<li className='xl:mb-2 2xl:mb-4'>Intellectual Property Rights</li>
								<li className='xl:mb-2 2xl:mb-4'>SaaS & Licensing</li>
								<li className='xl:mb-2 2xl:mb-4'>Entertainment Law</li>
								<li className='xl:mb-2 2xl:mb-4'>Marketing & Social Media</li>
							</ul>
						</div>
						<div className='flex h-4 gap-4 text-lg font-bold leading-9 xl:text-xl 2xl:h-8 2xl:text-2xl'>
							<span>Compliance</span>
						</div>
						<div className='text-lg font-normal xl:text-xl 2xl:text-2xl'>
							<ul className='list-inside list-disc'>
								<li className='xl:mb-2 2xl:mb-4'>Privacy & GDPR</li>
								<li className='xl:mb-2 2xl:mb-4'>
									AI Compliance (including ethics and actionable checklist)
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
const SectionFive = forwardRef<HTMLDivElement>((_, ref) => {
	return (
		<div
			ref={ref}
			id='courses'
			className='max-xl:scroll-mt-offset flex snap-center items-center bg-white font-poppins md:min-h-dvh'>
			<div className='grid grid-cols-12 content-center'>
				<div className='order-2 col-span-12 h-full md:order-1 md:col-span-6'>
					<div className='grid h-full grid-cols-12 content-center gap-8'>
						<div className='col-span-12 flex items-center ps-6 pt-6 md:col-span-4 xl:ps-12 xl:pt-12 2xl:ps-24 2xl:pt-24'>
							<div className='text-3xl font-bold uppercase tracking-widest text-linn-500 xl:text-5xl 2xl:text-6xl'>
								Courses
							</div>
						</div>

						<div className='col-span-12 flex flex-wrap gap-4 !bg-[#fdf9ff] px-6 py-8 xl:px-12 xl:py-16 2xl:px-24 2xl:py-32'>
							<div className='flex min-h-8 gap-4 text-lg font-bold uppercase leading-9 xl:text-xl 2xl:text-2xl'>
								<div className='h-8 w-1.5 shrink-0 bg-linn-500'></div>
								<span>Empower Your Organization with Expert AI Knowledge</span>
							</div>
							<div className='font-normal !leading-relaxed xl:text-xl 2xl:text-2xl'>
								<p>
									AI is transforming the business landscape. Comprehensive courses
									in AI Governance and AI Policy are designed for leaders, IT
									professionals, compliance specialists, and other professionals
									who want to deepen their understanding of AI governance and
									policy requirements. Develop sustainable AI strategies that
									adhere to the EU AI Act and drive value for your organization.
								</p>
								<p>
									Discover the perfect course for your needs and take the next
									step in your AI journey.
								</p>
							</div>
							<button className='border-2 border-linn-500 px-6 py-2 text-xl uppercase text-linn-500 transition-all duration-300 ease-in-out hover:border-linnt hover:text-linnt'>
								View Course Selection
							</button>
						</div>
					</div>
				</div>
				<div
					className='order-1 col-span-12 h-[50vh] bg-cover bg-top md:order-2 md:col-span-6 md:h-full md:min-h-dvh'
					style={{ backgroundImage: `url(${Linn05})` }}></div>
			</div>
		</div>
	);
});

interface ILalegalContainerProps {
	children: React.ReactNode;
	title?: string;
}
export const LalegalContainer: FC<ILalegalContainerProps> = (props) => {
	const { children, title } = props;

	const { setFontSize } = useFontSize();
	useLayoutEffect(() => {
		setFontSize(16);
	});

	const navigate = useNavigate();

	const authContext = useContext(AuthContext);
	const { authUser } = authContext;
	const { onLogout } = useAuth();

	const [state, setState] = useState<boolean>(false);

	const sections = [
		{ id: 'home', text: 'Home' },
		{ id: 'about', text: 'About' },
		{ id: 'experience', text: 'Experience' },
		{ id: 'services', text: 'Services' },
		{ id: 'courses', text: 'Courses' },
		{ id: 'contact', text: 'Contact' },
	];

	return (
		<div>
			<style>{`html, body { height: 100vh; overflow: hidden;}`}</style>
			<PageWrapper isProtectedRoute={false} title={title || 'Linn Alfredsson'}>
				<div className='h-dvh overflow-y-scroll scroll-smooth font-normal text-linnt md:snap-y'>
					<Header
						className={classNames(
							'!border-0 !bg-white max-lg:hidden',
							// 'lg:-translate-y-[var(--header-height)] lg:opacity-0',
						)}>
						<div className='container mx-auto flex w-full justify-between px-4'>
							<HeaderLeft isShowToggleAside={false}>
								<Button
									onClick={() => setState(true)}
									className='xl:!hidden'
									icon='HeroBars3'
								/>
								<div className='hidden gap-8 px-8 xl:inline-flex'>
									{sections.map((section) => (
										<Link
											key={section.id}
											to={`../lalegal#${section.id}`}
											className={classNames(
												'cursor-pointer font-bold text-linnt',
											)}>
											{section.text}
										</Link>
									))}
								</div>
							</HeaderLeft>
							<HeaderRight>
								<Visible is={!authUser}>
									<Button
										color='linn'
										variant='solid'
										icon='HeroArrowLeftOnRectangle'
										onClick={() => navigate(authPages.loginPage.to)}
										className='text-lg font-extrabold'>
										Login
									</Button>
								</Visible>
								<Visible is={!!authUser}>
									<Dropdown className='!hidden xl:!flex'>
										<DropdownToggle>
											<Button
												color='zinc'
												icon='HeroUser'
												className='text-lg font-extrabold'>
												{authUser?.username}
											</Button>
										</DropdownToggle>
										<DropdownMenu placement='bottom-end'>
											<DropdownItem
												icon='HeroArrowRightOnRectangle'
												className='border-t-2'
												onClick={() => onLogout()}>
												Logout
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</Visible>
							</HeaderRight>
						</div>
					</Header>
					<OffCanvas position='left' isOpen={state} setIsOpen={setState}>
						<OffCanvasHeader>Menu</OffCanvasHeader>
						<OffCanvasBody className='flex shrink-0 flex-col items-start gap-4'>
							{sections
								.filter((section) => section.id !== 'home')
								.map((section) => (
									<Link
										key={section.id}
										to={`../lalegal#${section.id}`}
										className={classNames('cursor-pointer')}>
										{section.text}
									</Link>
								))}
						</OffCanvasBody>
						<OffCanvasFooter>
							<OffCanvasFooterChild className='w-full'>
								<Visible is={!!authUser}>
									<Button
										icon='HeroArrowRightOnRectangle'
										onClick={() => onLogout()}>
										{authUser?.username}, Logout
									</Button>
								</Visible>
								<Visible is={!authUser}>
									<Button
										color='linn'
										variant='solid'
										icon='HeroArrowLeftOnRectangle'
										onClick={() => navigate(authPages.loginPage.to)}
										className='w-full text-lg font-extrabold'>
										Login
									</Button>
								</Visible>
							</OffCanvasFooterChild>
						</OffCanvasFooter>
						<OffCanvasFooter>
							<OffCanvasFooterChild>
								<Button onClick={() => setState(false)} color='red'>
									Close
								</Button>
							</OffCanvasFooterChild>
						</OffCanvasFooter>
					</OffCanvas>
					{children}
					<SectionFooter />
				</div>
			</PageWrapper>
		</div>
	);
};

interface ContactForm {
	name: string;
	email: string;
	subject: string;
	description: string;
}

const SectionSix = forwardRef<HTMLDivElement>((_, ref) => {
	const formik = useFormik<ContactForm>({
		enableReinitialize: true,
		initialValues: {
			name: '',
			email: '',
			subject: '',
			description: '',
		},
		onSubmit: (values) => {
			console.log('Form data:', values);
		},
	});

	return (
		<div
			ref={ref}
			id='contact'
			className='max-xl:scroll-mt-offset snap-center bg-white font-poppins md:min-h-screen'>
			<div className='grid grid-cols-12 md:min-h-screen'>
				<div
					className='col-span-12 h-[50vh] bg-cover bg-top lg:col-span-4 lg:me-24 lg:h-full'
					style={{ backgroundImage: `url(${Linn03})` }}></div>
				<div className='col-span-12 flex h-full flex-col justify-center lg:col-span-8'>
					<div className='flex flex-col justify-center gap-8 max-lg:p-4 max-md:pb-8 lg:-ms-24 lg:h-3/5 lg:ps-24 2xl:h-2/3'>
						<div className='text-3xl font-bold uppercase tracking-widest text-linn-500 xl:text-5xl 2xl:text-6xl'>
							Get in touch
						</div>
						<div className='max-w-screen-md text-lg xl:text-xl 2xl:text-2xl'>
							I would love to hear from you!
						</div>
						<div className='left-9 max-w-screen-md text-lg !leading-relaxed xl:text-xl 2xl:text-2xl'>
							Reach out today and let's turn your challenges into stepping stones for
							success. Whether it's legal wisdom or strategic insights you seek, I am
							here to simplify the journey and help you achieve your goals.
						</div>
						<div className='pt-4 2xl:pt-8'>
							<img src={Linn04} alt='Linn Signature' className='w-48 2xl:w-64' />
						</div>
					</div>
					<div className='relative !bg-[#f7f1fd] max-lg:pb-8 lg:-ms-24 lg:h-2/5 lg:px-24 2xl:h-1/3'>
						<img
							src={LinnBadge}
							alt=''
							className='absolute -top-8 left-[calc(50%-2rem)] w-16 lg:-left-24 lg:-top-24 lg:w-48'
						/>
						<form className='grid h-full grid-cols-12 content-center gap-4 max-lg:px-4 max-lg:pt-8'>
							<div className='col-span-12 md:col-span-4'>
								<div className='relative z-0'>
									<input
										type='text'
										id='name'
										name='name'
										onChange={formik.handleChange}
										value={formik.values.name}
										className='peer block w-full appearance-none border-0 border-b-2 border-linn-300 bg-transparent px-0 py-2.5 text-sm text-linn-600 focus:border-linn-600 focus:outline-none focus:ring-0'
										placeholder=' '
										autoComplete='name'
									/>
									<label
										htmlFor='name'
										className='absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-linn-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:start-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-linn-600 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'>
										Name
									</label>
								</div>
							</div>
							<div className='col-span-12 md:col-span-4'>
								<div className='relative z-0'>
									<input
										type='email'
										id='email'
										name='email'
										onChange={formik.handleChange}
										value={formik.values.email}
										className='peer block w-full appearance-none border-0 border-b-2 border-linn-300 bg-transparent px-0 py-2.5 text-sm text-linn-600 focus:border-linn-600 focus:outline-none focus:ring-0'
										placeholder=' '
										autoComplete='email'
									/>
									<label
										htmlFor='email'
										className='absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-linn-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:start-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-linn-600 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'>
										Email
									</label>
								</div>
							</div>
							<div className='col-span-12 md:col-span-4'>
								<div className='relative z-0'>
									<input
										type='text'
										id='subject'
										name='subject'
										onChange={formik.handleChange}
										value={formik.values.subject}
										className='peer block w-full appearance-none border-0 border-b-2 border-linn-300 bg-transparent px-0 py-2.5 text-sm text-linn-600 focus:border-linn-600 focus:outline-none focus:ring-0'
										placeholder=' '
									/>
									<label
										htmlFor='subject'
										className='absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-linn-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:start-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-linn-600 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'>
										Subject
									</label>
								</div>
							</div>
							<div className='col-span-12'>
								<div className='relative z-0'>
									<textarea
										id='description'
										name='description'
										onChange={formik.handleChange}
										value={formik.values.description}
										className='peer block w-full appearance-none border-0 border-b-2 border-linn-300 bg-transparent px-0 py-2.5 text-sm text-linn-600 focus:border-linn-600 focus:outline-none focus:ring-0'
										placeholder=' '
										rows={5}
									/>
									<label
										htmlFor='description'
										className='absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-linn-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:start-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-linn-600 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'>
										Description
									</label>
								</div>
							</div>
							<div className='col-span-12 md:col-span-4 md:col-start-9'>
								<button
									onClick={formik.submitForm}
									className='w-full border-2 border-linn-500 p-2 text-xl uppercase text-linn-500 transition-all duration-300 ease-in-out hover:border-linnt hover:text-linnt'>
									Send
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
});

SectionOne.displayName = 'SectionOne';
SectionTwo.displayName = 'SectionTwo';
SectionThree.displayName = 'SectionThree';
SectionFour.displayName = 'SectionFour';
SectionFive.displayName = 'SectionFive';
SectionSix.displayName = 'SectionSix';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SectionPricing = () => {
	return (
		<div className='snap-center bg-white font-poppins md:min-h-dvh'>
			<div className='grid grid-cols-12 content-center gap-y-6 p-4 md:min-h-dvh lg:gap-12 xl:px-12 2xl:px-24'>
				<div className='col-span-12'>
					<div className='text-3xl font-bold uppercase text-linn-500 xl:text-5xl 2xl:text-6xl'>
						Packages & pricing
					</div>
				</div>
				<div className='col-span-12 lg:col-span-4'>
					<div className='rounded-2xl !bg-[#f7f1fd] p-12'>
						<div className='flex h-[4.5rem] flex-col gap-4 text-lg font-medium leading-9 tracking-widest xl:text-xl 2xl:text-2xl'>
							<span>Basic Access</span>
							<div className='h-1.5 w-8 bg-linn-500'></div>
						</div>
						<div className='!leading-relaxed tracking-widest xl:text-xl 2xl:text-2xl'>
							<div>
								Includes a dedicated set of hours per month allowing you to plan and
								budget your legal expenses.
							</div>
							<br />
							<br />
							<div className='font-medium'>Hourly Rate</div>
							<div className='flex'>
								<div className='grow'>Remote</div>
								<div className=''>SEK 1500</div>
							</div>
							<div className='flex'>
								<div className='grow'>On-site</div>
								<div className=''>SEK 2000</div>
							</div>
							<br />
							<div className='flex'>
								<div className='grow font-medium'>Commitment Period</div>
								<div className=''>3 months</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-span-12 lg:col-span-4'>
					<div className='rounded-2xl !bg-[#f7f1fd] p-12'>
						<div className='flex h-[4.5rem] flex-col gap-4 text-lg font-medium leading-9 tracking-widest xl:text-xl 2xl:text-2xl'>
							<span>Classic Access</span>
							<div className='h-1.5 w-8 bg-linn-500'></div>
						</div>
						<div className='!leading-relaxed tracking-widest xl:text-xl 2xl:text-2xl'>
							<div>
								Suitable for projects requiring quicker turnarounds or shorter
								engagement periods, with priority scheduling.
							</div>
							<br />
							<br />
							<div className='font-medium'>Hourly Rate</div>
							<div className='flex'>
								<div className='grow'>Remote</div>
								<div className=''>SEK 2000</div>
							</div>
							<div className='flex'>
								<div className='grow'>On-site</div>
								<div className=''>SEK 2500</div>
							</div>
							<br />
							<div className='flex'>
								<div className='grow font-medium'>Commitment Period</div>
								<div className=''>6 months</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-span-12 lg:col-span-4'>
					<div className='rounded-2xl !bg-[#f7f1fd] p-12'>
						<div className='flex h-[4.5rem] flex-col gap-4 text-lg font-medium leading-9 tracking-widest xl:text-xl 2xl:text-2xl'>
							<span>Premium Access</span>
							<div className='h-1.5 w-8 bg-linn-500'></div>
						</div>
						<div className='!leading-relaxed tracking-widest xl:text-xl 2xl:text-2xl'>
							<div>
								For clients needing on-demand legal services with the highest level
								of flexibility. No long-term commitment required.
							</div>
							<br />
							<br />
							<div className='font-medium'>Hourly Rate</div>
							<div className='flex'>
								<div className='grow'>Remote</div>
								<div className=''>SEK 3000</div>
							</div>
							<div className='flex'>
								<div className='grow'>On-site</div>
								<div className=''>SEK 3500</div>
							</div>
							<br />
							<div className='flex'>
								<div className='grow font-medium'>Commitment Period</div>
								<div className=''>Rolling</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-span-12'>
					<div className='md:max-w-screen-sm'>
						*All prices are exclusive of VAT. Payment due within 30 days of invoicing.
						Additional costs may apply for travel and third-party fees. Custom packages
						available upon request.
					</div>
				</div>
			</div>
		</div>
	);
};
export const SectionFooter = () => {
	return (
		<div className='snap-center !bg-[#fdfbff] font-poppins'>
			<div className='grid grid-cols-3 gap-4 px-4 pb-4 pt-4 max-md:px-4 md:grid-cols-12 md:gap-x-6 md:px-6 md:pt-6 xl:gap-x-12 xl:px-12 xl:pb-6 xl:pt-12 2xl:gap-x-24 2xl:px-24 2xl:pb-12'>
				<div className='col-span-12 mb-8 border-b border-linn-500'></div>
				<div className='col-span-12 flex flex-col gap-4 lg:col-span-4'>
					<div className='font-medium uppercase tracking-widest text-linn-500 xl:text-lg 2xl:text-xl'>
						LA LEGAL
					</div>
					<div className='2xl:text-lg'>
						Helping businesses navigate complex legal challenges to drive growth and
						unlock new opportunities.
					</div>
				</div>
				<div className='col-span-12 flex flex-col gap-4 lg:col-span-4 xl:text-lg 2xl:text-xl'>
					<div className='font-medium uppercase tracking-widest text-linn-500'>Legal</div>
					<Link to='../lalegal/privacy-policy'>Privacy Policy</Link>
					<Link to='../lalegal/terms'>Terms of Use</Link>
				</div>
				<div className='col-span-12 flex flex-col gap-4 lg:col-span-4'>
					<div className='font-medium uppercase tracking-widest text-linn-500 xl:text-lg 2xl:text-xl'>
						Newsletter
					</div>
					<div className='2xl:text-lg'>Stay updated with our latest insights</div>
					<div className='flex items-end gap-4'>
						<div className='relative z-0 grow'>
							<input
								type='email'
								id='yourEmail'
								className='peer block w-full appearance-none border-0 border-b-2 border-linn-300 bg-transparent px-0 py-2.5 text-sm text-linn-600 focus:border-linn-600 focus:outline-none focus:ring-0'
								placeholder=' '
							/>
							<label
								htmlFor='yourEmail'
								className='absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-linn-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:start-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-linn-600 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'>
								Your Email
							</label>
						</div>
						<button className='border-2 border-linn-500 px-4 py-2 text-sm uppercase text-linn-500 transition-all duration-300 ease-in-out hover:border-linnt hover:text-linnt'>
							Subscribe
						</button>
					</div>
				</div>

				<div className='col-span-12 flex gap-4 max-lg:flex-col md:py-6'>
					<div className='text-sm lg:grow'>
						© {dayjs().year()} LA Legal. All rights reserved.
					</div>
				</div>
			</div>
		</div>
	);
};

const Lalegal = () => {
	useHash();
	const navigate = useNavigate();
	const location = useLocation();

	const { setFontSize } = useFontSize();
	useLayoutEffect(() => {
		setFontSize(16);
	});

	const [visibleDivs, setVisibleDivs] = useState<string[]>([]);
	const divRefs = useRef({});

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					const id = entry.target.id;
					if (entry.isIntersecting) {
						setVisibleDivs((prev) => Array.from(new Set([...prev, id])));
					} else {
						setVisibleDivs((prev) => prev.filter((visibleId) => visibleId !== id));
					}
				});
			},
			{ threshold: 0.3 },
		);

		Object.values(divRefs.current).forEach((div) => {
			if (div) observer.observe(div as Element);
		});

		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			Object.values(divRefs.current).forEach((div) => {
				if (div) observer.unobserve(div as Element);
			});
		};
	}, []);

	// const [scrollTop, setScrollTop] = useState<number>(0);
	// const divRef = useRef(null);
	//
	// const handleScroll = () => {
	// 	if (divRef.current) {
	// 		// @ts-ignore
	// 		setScrollTop(divRef.current?.scrollTop);
	// 	}
	// };

	const sections = [
		{ id: 'home', text: 'Home' },
		{ id: 'about', text: 'About' },
		{ id: 'experience', text: 'Experience' },
		{ id: 'services', text: 'Services' },
		{ id: 'courses', text: 'Courses' },
		{ id: 'contact', text: 'Contact' },
	];

	const authContext = useContext(AuthContext);
	const { authUser } = authContext;
	const { onLogout } = useAuth();

	const [state, setState] = useState<boolean>(false);

	useEffect(() => {
		setState(false);
	}, [location.hash]);

	useEffect(() => {
		if (location.hash) {
			window.history.replaceState(null, '', ' ');
		}
	}, [location.hash]);

	return (
		<>
			<style>{`html, body { height: 100vh; overflow: hidden;}`}</style>
			<PageWrapper isProtectedRoute={false} title='Linn Alfredsson'>
				<div
					// ref={divRef}
					// onScroll={handleScroll}
					className='h-dvh snap-mandatory overflow-y-scroll scroll-smooth font-normal text-linnt md:snap-y'>
					<Header
						className={classNames(
							'!border-0 !bg-transparent !backdrop-blur-none max-lg:hidden',
							{
								'-translate-y-[var(--header-height)] opacity-0':
									visibleDivs[0] !== 'home',
							},
						)}>
						<div className='container mx-auto flex w-full justify-between px-4'>
							<HeaderRight>
								<Visible is={!authUser}>
									<Button
										color='linn'
										variant='solid'
										icon='HeroArrowLeftOnRectangle'
										onClick={() => navigate(authPages.loginPage.to)}
										className='text-lg font-extrabold'>
										Login
									</Button>
								</Visible>
								<Visible is={!!authUser}>
									<Dropdown className='!hidden xl:!flex'>
										<DropdownToggle>
											<Button
												color='zinc'
												icon='HeroUser'
												className='text-lg font-extrabold'>
												{authUser?.username}
											</Button>
										</DropdownToggle>
										<DropdownMenu placement='bottom-end'>
											<DropdownItem
												icon='HeroArrowRightOnRectangle'
												className='border-t-2'
												onClick={() => onLogout()}>
												Logout
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</Visible>
							</HeaderRight>
						</div>
					</Header>
					<Header
						className={classNames('!border-0 !bg-white max-lg:hidden', {
							'lg:-translate-y-[var(--header-height)] lg:opacity-0':
								visibleDivs[0] === 'home',
						})}>
						<div className='container mx-auto flex w-full justify-between px-4'>
							<HeaderLeft isShowToggleAside={false}>
								<Button
									onClick={() => setState(true)}
									className='xl:!hidden'
									icon='HeroBars3'
								/>
								<div className='hidden gap-8 px-8 xl:inline-flex'>
									{sections.map((section) => (
										<a
											key={section.id}
											href={`#${section.id}`}
											className={classNames(
												'cursor-pointer font-bold text-linnt',
												{
													'!text-linn-500': visibleDivs[0] === section.id,
												},
											)}>
											{section.text}
										</a>
									))}
								</div>
							</HeaderLeft>
							<HeaderRight>
								<Visible is={!authUser}>
									<Button
										color='linn'
										variant='solid'
										icon='HeroArrowLeftOnRectangle'
										onClick={() => navigate(authPages.loginPage.to)}
										className='text-lg font-extrabold'>
										Login
									</Button>
								</Visible>
								<Visible is={!!authUser}>
									<Dropdown className='!hidden xl:!flex'>
										<DropdownToggle>
											<Button
												color='zinc'
												icon='HeroUser'
												className='text-lg font-extrabold'>
												{authUser?.username}
											</Button>
										</DropdownToggle>
										<DropdownMenu placement='bottom-end'>
											<DropdownItem
												icon='HeroArrowRightOnRectangle'
												className='border-t-2'
												onClick={() => onLogout()}>
												Logout
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</Visible>
							</HeaderRight>
						</div>
					</Header>
					<Button
						onClick={() => setState(true)}
						className='absolute left-4 top-4 z-20 bg-linnt/10 !text-white shadow-2xl backdrop-blur-xl xl:!hidden'
						icon='HeroBars3'
					/>
					<OffCanvas position='left' isOpen={state} setIsOpen={setState}>
						<OffCanvasHeader>Menu</OffCanvasHeader>
						<OffCanvasBody className='flex shrink-0 flex-col items-start gap-4'>
							{sections
								.filter((section) => section.id !== 'home')
								.map((section) => (
									<a
										key={section.id}
										href={`#${section.id}`}
										className={classNames('cursor-pointer', {
											'!text-linn-500': visibleDivs[0] === section.id,
										})}>
										{section.text}
									</a>
								))}
						</OffCanvasBody>
						<OffCanvasFooter>
							<OffCanvasFooterChild className='w-full'>
								<Visible is={!!authUser}>
									<Button
										icon='HeroArrowRightOnRectangle'
										onClick={() => onLogout()}>
										{authUser?.username}, Logout
									</Button>
								</Visible>
								<Visible is={!authUser}>
									<Button
										color='linn'
										variant='solid'
										icon='HeroArrowLeftOnRectangle'
										onClick={() => navigate(authPages.loginPage.to)}
										className='w-full text-lg font-extrabold'>
										Login
									</Button>
								</Visible>
							</OffCanvasFooterChild>
						</OffCanvasFooter>
						<OffCanvasFooter>
							<OffCanvasFooterChild>
								<Button onClick={() => setState(false)} color='red'>
									Close
								</Button>
							</OffCanvasFooterChild>
						</OffCanvasFooter>
					</OffCanvas>
					{/* @ts-ignore */}
					<SectionOne ref={(e) => (divRefs.current['home'] = e)} />
					{/* @ts-ignore */}
					<SectionTwo ref={(e) => (divRefs.current['about'] = e)} />
					{/* @ts-ignore */}
					<SectionThree ref={(e) => (divRefs.current['experience'] = e)} />
					{/* @ts-ignore */}
					<SectionFour ref={(e) => (divRefs.current['services'] = e)} />
					{/* @ts-ignore */}
					<SectionFive ref={(e) => (divRefs.current['courses'] = e)} />
					{/* @ts-ignore */}
					<SectionSix ref={(e) => (divRefs.current['contact'] = e)} />
					{/*<SectionPricing />*/}
					<SectionFooter />
				</div>
			</PageWrapper>
		</>
	);
};

export default Lalegal;
