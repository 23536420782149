export const customerViewPages = {
	landingPage: {
		id: 'landingPage',
		to: '/',
		text: 'Landing',
		icon: 'HeroBookOpen',
	},
	pricingPage: {
		id: 'pricingPage',
		to: '/pricing',
		text: 'Pricing',
		icon: 'HeroCurrencyEuro',
	},
	businessTypesPages: {
		id: 'businessTypesPages',
		to: '/business-types',
		text: 'Business Types',
		icon: 'HeroBuildingOffice',
		subPages: {
			consultingPages: {
				id: 'consultingPages',
				to: '/business-types/consulting',
				text: 'Consulting',
				icon: 'HeroStar',
			},
			productBasedPages: {
				id: 'productBasedPages',
				to: '/business-types/product-based',
				text: 'Product-Based',
				icon: 'HeroArchiveBox',
			},
			serviceProvidersPages: {
				id: 'serviceProviders',
				to: '/business-types/service-providers',
				text: 'Service Providers',
				icon: 'HeroBriefcase',
			},
			boardOfDirectorsPages: {
				id: 'boardOfDirectorsPages',
				to: '/business-types/board-of-directors',
				text: 'Board of Directors',
				icon: 'HeroChartBar',
			},
			investorsPages: {
				id: 'investorsPages',
				to: '/business-types/investors',
				text: 'Investors',
				icon: 'HeroArrowTrendingUp',
			},
			procurementPages: {
				id: 'procurementPages',
				to: '/business-types/procurement',
				text: 'Procurement',
				icon: 'HeroTruck',
			},
		},
	},
	customerPage: {
		id: 'customerPage',
		to: '/customer',
		text: 'Customer',
		icon: 'HeroBookOpen',
	},
	contactUsPage: {
		id: 'contactUsPage',
		to: '/contact-us',
		text: 'Contact Us',
		icon: 'HeroPhone',
	},
};
export const customerTabs = {
	dashboardTab: {
		id: 'dashboardTab',
		to: 'dashboard',
		text: 'Dashboard',
		icon: 'HeroRectangleGroup',
	},
	projectsTab: {
		id: 'projectsTab',
		to: 'projects',
		text: 'Projects',
		icon: 'HeroClipboardDocumentList',
	},
	tasksTab: {
		id: 'tasksTab',
		to: 'tasks',
		text: 'Tasks',
		icon: 'HeroQueueList',
	},
	timeslipsTab: {
		id: 'timeslipsTab',
		to: 'timeslips',
		text: 'Timeslips',
		icon: 'HeroClock',
	},
	invoicesTab: {
		id: 'invoicesTab',
		to: 'invoices',
		text: 'Invoices',
		icon: 'HeroDocumentText',
	},
	offersTab: {
		id: 'offersTab',
		to: 'offers',
		text: 'Offers',
		icon: 'HeroBolt',
	},
	ordersTab: {
		id: 'ordersTab',
		to: 'orders',
		text: 'Orders',
		icon: 'HeroShoppingCart',
	},
	accountTab: {
		id: 'accountTab',
		to: 'account',
		text: 'My Account',
		icon: 'HeroUserCircle',
	},
	organizationTab: {
		id: 'organizationTab',
		to: 'organization',
		text: 'Organization',
		icon: 'HeroBuildingOffice2',
	},
	usersTab: {
		id: 'usersTab',
		to: 'users',
		text: 'Users',
		icon: 'HeroUsers',
	},
	deliveryAddressTab: {
		id: 'deliveryAddressTab',
		to: 'deliveryAddress',
		text: 'Delivery Address',
		icon: 'HeroEnvelope',
	},
	customersTab: {
		id: 'customersTab',
		to: 'customers',
		text: 'Customers',
		icon: 'HeroUsers',
	},
	eventsTab: {
		id: 'eventsTab',
		to: 'events',
		text: 'Events',
		icon: 'HeroCalendar',
	},
};
// export const eCommercePage = {
// 	productsTab: {
// 		id: 'productsTab',
// 		to: 'products',
// 		text: 'Products',
// 		icon: 'HeroBookOpen',
// 	},
// 	offersTab: {
// 		id: 'offersTab',
// 		to: 'offers',
// 		text: 'Offers',
// 		icon: 'HeroBookOpen',
// 	},
// 	ordersTab: {
// 		id: 'ordersTab',
// 		to: 'orders',
// 		text: 'Orders',
// 		icon: 'HeroBookOpen',
// 	},
// 	deliveryAddressesTab: {
// 		id: 'deliveryAddressesTab',
// 		to: 'delivery-addresses',
// 		text: 'Delivery Addresses',
// 		icon: 'HeroBookOpen',
// 	},
// 	cartTab: {
// 		id: 'cartTab',
// 		to: 'cart',
// 		text: 'Cart',
// 		icon: 'HeroBookOpen',
// 	},
// };

export const tenantViewPages = {
	tenantPage: {
		id: 'tenantPage',
		to: '/tenant',
		text: 'Tenant',
		icon: 'HeroBookOpen',
		subPages: {
			dashboardPage: {
				id: 'dashboardPage',
				to: '/tenant',
				text: 'Dashboard',
				icon: 'HeroRectangleGroup',
			},
			customersPage: {
				id: 'customersPage',
				to: '/tenant/customers',
				text: 'Customers',
				icon: 'HeroUserGroup',
				subPages: {
					usersPage: {
						id: 'usersPage',
						to: '/tenant/customers/users',
						onlyTo: 'users',
						text: 'Users',
						icon: 'HeroUser',
					},
				},
			},
			productsPage: {
				id: 'productsPage',
				to: '/tenant/products',
				text: 'Products',
				icon: 'HeroTag',
				subPages: {
					articlesPage: {
						id: 'articlesPage',
						to: '/tenant/products/articles',
						text: 'Articles',
						icon: 'HeroDocumentText',
					},
					articleGroupsPage: {
						id: 'articleGroupsPage',
						to: '/tenant/products/article-groups',
						text: 'Article Groups',
						icon: 'HeroClipboardDocument',
					},
					categoriesPage: {
						id: 'categoriesPage',
						to: '/tenant/products/categories',
						text: 'Categories',
						icon: 'HeroRectangleGroup',
					},
					productListsPage: {
						id: 'productListsPage',
						to: '/tenant/products/product-lists',
						text: 'Product Lists',
						icon: 'HeroSquare3Stack3d',
					},
					manufacturersPage: {
						id: 'manufacturersPage',
						to: '/tenant/products/manufacturers',
						text: 'Manufacturers',
						icon: 'HeroWrenchScrewdriver',
					},
				},
			},
			salesPage: {
				id: 'salesPage',
				to: '/tenant/sales',
				text: 'Sales',
				icon: 'HeroCursorArrowRipple',
				subPages: {
					offersPage: {
						id: 'offersPage',
						to: '/tenant/sales/offers',
						text: 'Offers',
						icon: 'HeroBolt',
					},
					ordersPage: {
						id: 'ordersPage',
						to: '/tenant/sales/orders',
						text: 'Orders',
						icon: 'HeroShoppingCart',
					},
					invoicesPage: {
						id: 'invoicesPage',
						to: '/tenant/sales/invoices',
						text: 'Invoices',
						icon: 'HeroDocumentText',
					},
					// statisticsPage: {
					// 	id: 'statisticsPage',
					// 	to: '/tenant/sales/statistics',
					// 	text: 'Statistics',
					// 	icon: 'HeroChartBar',
					// },
				},
			},
			workManagementPages: {
				id: 'workManagement',
				to: '/tenant/work-management',
				text: 'Work Management',
				icon: 'HeroBriefcase',
				subPages: {
					timeslipsPage: {
						id: 'timeslipsPage',
						to: '/tenant/work-management/timeslips',
						text: 'Timeslips',
						icon: 'HeroClock',
					},
					tasksPage: {
						id: 'tasksPage',
						to: '/tenant/work-management/tasks',
						text: 'Tasks',
						icon: 'HeroQueueList',
					},
					projectsPage: {
						id: 'projectsPage',
						to: '/tenant/work-management/projects',
						text: 'Projects',
						icon: 'HeroClipboardDocumentList',
					},
				},
			},
			content: {
				id: 'content',
				to: '/tenant/content',
				text: 'Content',
				icon: 'HeroBars3BottomLeft',
				subPages: {
					postsPage: {
						id: 'postsPage',
						to: '/tenant/content/posts',
						text: 'Posts',
						icon: 'HeroDocumentText',
					},
				},
			},
			eventsPage: {
				id: 'eventsPage',
				to: '/tenant/events',
				text: 'Events',
				icon: 'HeroCalendar',
			},
			usersPage: {
				id: 'usersPage',
				to: '/tenant/users',
				text: 'Users',
				icon: 'HeroUser',
			},
			// deliveryAddressesPage is not show in aside
			deliveryAddressesPage: {
				id: 'deliveryAddressesPage',
				to: '/tenant/delivery-addresses',
				text: 'Delivery Addresses',
				icon: 'HeroBookOpen',
			},
			organizationPage: {
				id: 'organizationPage',
				to: '/tenant/organization',
				text: 'Organization',
				icon: 'HeroBuildingOffice2',
			},
		},
	},
};
export const tenantDocPages = {
	documentationPage: {
		id: 'documentationPage',
		to: '/documentation',
		text: 'Documentation',
		icon: 'HeroBookOpen',
		subPages: {
			customersPage: {
				id: 'customersPage',
				to: '/documentation/customers',
				text: 'Customers',
				icon: 'HeroUserGroup',
				subPages: {
					usersPage: {
						id: 'usersPage',
						to: '/documentation/customers/users',
						onlyTo: 'users',
						text: 'Users',
						icon: 'HeroUser',
					},
				},
			},
			productsPage: {
				id: 'productsPage',
				to: '/documentation/products',
				text: 'Products',
				icon: 'HeroTag',
				subPages: {
					articlesPage: {
						id: 'articlesPage',
						to: '/documentation/products/articles',
						text: 'Articles',
						icon: 'HeroDocumentText',
					},
					articleGroupsPage: {
						id: 'articleGroupsPage',
						to: '/documentation/products/article-groups',
						text: 'Article Groups',
						icon: 'HeroClipboardDocument',
					},
					categoriesPage: {
						id: 'categoriesPage',
						to: '/documentation/products/categories',
						text: 'Categories',
						icon: 'HeroRectangleGroup',
					},
					productListsPage: {
						id: 'productListsPage',
						to: '/documentation/products/product-lists',
						text: 'Product Lists',
						icon: 'HeroSquare3Stack3d',
					},
					manufacturersPage: {
						id: 'manufacturersPage',
						to: '/documentation/products/manufacturers',
						text: 'Manufacturers',
						icon: 'HeroWrenchScrewdriver',
					},
				},
			},
			salesPage: {
				id: 'salesPage',
				to: '/documentation/sales',
				text: 'Sales',
				icon: 'HeroCursorArrowRipple',
				subPages: {
					offersPage: {
						id: 'offersPage',
						to: '/documentation/sales/offers',
						text: 'Offers',
						icon: 'HeroBolt',
					},
					ordersPage: {
						id: 'ordersPage',
						to: '/documentation/sales/orders',
						text: 'Orders',
						icon: 'HeroShoppingCart',
					},
					invoicesPage: {
						id: 'invoicesPage',
						to: '/documentation/sales/invoices',
						text: 'Invoices',
						icon: 'HeroDocumentText',
					},
					statisticsPage: {
						id: 'statisticsPage',
						to: '/documentation/sales/statistics',
						text: 'Statistics',
						icon: 'HeroChartBar',
					},
				},
			},
			workManagementPages: {
				id: 'workManagement',
				to: '/documentation/work-management',
				text: 'Work Management',
				icon: 'HeroBriefcase',
				subPages: {
					timeslipsPage: {
						id: 'timeslipsPage',
						to: '/documentation/work-management/timeslips',
						text: 'Timeslips',
						icon: 'HeroClock',
					},
					tasksPage: {
						id: 'tasksPage',
						to: '/documentation/work-management/tasks',
						text: 'Tasks',
						icon: 'HeroQueueList',
					},
					projectsPage: {
						id: 'projectsPage',
						to: '/documentation/work-management/projects',
						text: 'Projects',
						icon: 'HeroClipboardDocumentList',
					},
				},
			},
			eventsPage: {
				id: 'eventsPage',
				to: '/documentation/events',
				text: 'Events',
				icon: 'HeroCalendar',
			},
			usersPage: {
				id: 'usersPage',
				to: '/documentation/users',
				text: 'Users',
				icon: 'HeroUser',
			},
			// deliveryAddressesPage is not show in aside
			deliveryAddressesPage: {
				id: 'deliveryAddressesPage',
				to: '/documentation/delivery-addresses',
				text: 'Delivery Addresses',
				icon: 'HeroBookOpen',
			},
			organizationPage: {
				id: 'organizationPage',
				to: '/documentation/organization',
				text: 'Organization',
				icon: 'HeroBuildingOffice2',
			},
		},
	},
};

export const superAdminPage = {
	superAdminPage: {
		id: 'superAdminPage',
		to: '/admin',
		text: 'Super Admin',
		icon: 'HeroRectangleGroup',
		subPages: {
			dashboardPage: {
				id: 'dashboardPage',
				to: '/admin/dashboard',
				text: 'Dashboard',
				icon: 'HeroRectangleGroup',
			},
			tenantsPage: {
				id: 'tenantsPage',
				to: '/admin/tenants',
				text: 'Tenants',
				icon: 'HeroUserGroup',
			},
			tasksPage: {
				id: 'tasksPage',
				to: '/admin/tasks',
				text: 'Tasks',
				icon: 'HeroQueueList',
			},
			timeslipsPage: {
				id: 'timeslipsPage',
				to: '/admin/timeslips',
				text: 'Timeslips',
				icon: 'HeroClock',
			},
			sessionsPage: {
				id: 'sessionsPage',
				to: '/admin/sessions',
				text: 'Sessions',
				icon: 'HeroCircleStack',
			},
			logsPage: {
				id: 'logsPage',
				to: '/admin/logs',
				text: 'Logs',
				icon: 'HeroListBullet',
			},
			performancesPage: {
				id: 'performancesPage',
				to: '/admin/performances',
				text: 'Performance',
				icon: 'HeroRocketLaunch',
			},
		},
	},
};

export const appPages = {
	mailAppPages: {
		id: 'mailApp',
		to: '/mail',
		text: 'Mail',
		icon: 'HeroEnvelope',
		subPages: {
			inboxPages: {
				id: 'inboxPages',
				to: '/mail/inbox',
				text: 'Inbox',
				icon: 'HeroEnvelope',
			},
			draftPages: {
				id: 'draftPages',
				to: '/mail/draft',
				text: 'Draft',
				icon: 'HeroDocument',
			},
			sendPages: {
				id: 'sendPages',
				to: '/mail/send',
				text: 'Send',
				icon: 'HeroPaperAirplane',
			},
			junkPages: {
				id: 'junkPages',
				to: '/mail/junk',
				text: 'Junk',
				icon: 'HeroArchiveBoxXMark',
			},
			trashPages: {
				id: 'trashPages',
				to: '/mail/trash',
				text: 'Trash',
				icon: 'HeroTrash',
			},
			archivePages: {
				id: 'archivePages',
				to: '/mail/archive',
				text: 'Archive',
				icon: 'HeroArchiveBox',
			},
			newMailPages: {
				id: 'newMailPages',
				to: '/mail/new',
				text: 'Inbox',
				icon: 'HeroEnvelope',
			},
		},
	},
};
export const authPages = {
	loginPage: {
		id: 'loginPage',
		to: '/login',
		text: 'Login',
		icon: 'HeroArrowRightOnRectangle',
	},
	profilePage: {
		id: 'profilePage',
		to: '/profile',
		text: 'Profile',
		icon: 'HeroUser',
	},
};

export const settingsPages = {
	settingsMainPages: {
		id: 'settingsPages',
		to: '/settings',
		text: 'Settings',
		icon: 'HeroCog6Tooth',
		subPages: {
			erpPages: {
				id: 'erpPages',
				to: '/settings/erp',
				text: 'ERP',
				icon: 'HeroCloud',
			},
			dataSyncPages: {
				id: 'dataSyncPages',
				to: '/settings/data-sync',
				text: 'Data Sync',
				icon: 'HeroArrowPath',
				subPages: {
					fullSyncPages: {
						id: 'fullSyncPages',
						to: '/settings/data-sync/full-sync',
						text: 'Full Sync',
						icon: 'HeroArrowPath',
					},
					websocketLogPages: {
						id: 'websocketLogPages',
						to: '/settings/data-sync/websocket-log',
						text: 'Websocket log',
						icon: 'HeroStopCircle',
					},
				},
			},
			siteSettingsPages: {
				id: 'syncPages',
				to: '/settings/site-settings',
				text: 'Site Settings',
				icon: 'HeroWrenchScrewdriver',
				subPages: {
					brandingPages: {
						id: 'brandingPages',
						to: '/settings/site-settings/branding',
						text: 'Branding',
						icon: 'HeroBolt',
					},
					languagesPages: {
						id: 'languagesPages',
						to: '/settings/site-settings/languages',
						text: 'Languages',
						icon: 'HeroLanguage',
					},
					dateTimePages: {
						id: 'dateTimePages',
						to: '/settings/site-settings/date-time',
						text: 'Date/Time',
						icon: 'HeroClock',
					},
					currenciesPages: {
						id: 'currenciesPages',
						to: '/settings/site-settings/currencies',
						text: 'Currencies',
						icon: 'HeroCurrencyEuro',
						subPages: {
							currenciesListPages: {
								id: 'currenciesListPages',
								to: '/settings/site-settings/currencies/list',
								text: 'Currencies List',
								icon: 'HeroCurrencyEuro',
							},
						},
					},
					emailPages: {
						id: 'emailPages',
						to: '/settings/site-settings/email',
						text: 'Email',
						icon: 'HeroAtSymbol',
					},
				},
			},
			articlesPages: {
				id: 'articlesPages',
				to: '/settings/articles',
				text: 'Articles',
				icon: 'HeroAdjustmentsHorizontal',
				subPages: {
					settingsPages: {
						id: 'settingsPages',
						to: '/settings/articles/settings',
						text: 'Settings',
						icon: 'HeroWrench',
					},
					fieldsPages: {
						id: 'fieldsPages',
						to: '/settings/articles/fields',
						text: 'Fields',
						icon: 'HeroPencilSquare',
					},
					tabsPages: {
						id: 'tabsPages',
						to: '/settings/articles/tabs',
						text: 'Tabs',
						icon: 'HeroViewColumns',
					},
					templatesPages: {
						id: 'templatesPages',
						to: '/settings/articles/templates',
						text: 'Templates',
						icon: 'HeroComputerDesktop',
					},
					importExportDataPages: {
						id: 'importExportDataPages',
						to: '/settings/articles/import-export-data',
						text: 'Import/Export Data',
						icon: 'HeroCircleStack',
					},
				},
			},
			projectsPages: {
				id: 'projectsPages',
				to: '/settings/projects',
				text: 'Projects',
				icon: 'HeroClipboardDocumentList',
				subPages: {
					settingsPages: {
						id: 'settingsPages',
						to: '/settings/projects/settings',
						text: 'Settings',
						icon: 'HeroWrench',
					},
					fieldsPages: {
						id: 'fieldsPages',
						to: '/settings/projects/fields',
						text: 'Fields',
						icon: 'HeroPencilSquare',
					},
					templatesPages: {
						id: 'templatesPages',
						to: '/settings/projects/templates',
						text: 'Templates',
						icon: 'HeroComputerDesktop',
					},
				},
			},
			tasksPages: {
				id: 'tasksPages',
				to: '/settings/tasks',
				text: 'Tasks',
				icon: 'HeroQueueList',
				subPages: {
					categoriesPages: {
						id: 'categoriesPages',
						to: '/settings/tasks/categories',
						text: 'Categories',
						icon: 'HeroRectangleGroup',
					},
					prioritiesPages: {
						id: 'prioritiesPages',
						to: '/settings/tasks/priorities',
						text: 'Priorities',
						icon: 'HeroListBullet',
					},
				},
			},
			timeslipsPages: {
				id: 'timeslipsPages',
				to: '/settings/timeslips',
				text: 'Timeslips',
				icon: 'HeroClock',
				subPages: {
					timeslipsSettingsPages: {
						id: 'timeslipsSettingsPages',
						to: '/settings/timeslips/timeslips-settings',
						text: 'Timeslips Settings',
						icon: 'HeroCog8Tooth',
					},
					timeslipsTypesPages: {
						id: 'categoriesPages',
						to: '/settings/timeslips/timeslips-types',
						text: 'Timeslips Types',
						icon: 'HeroClock',
					},
				},
			},
			content: {
				id: 'content',
				to: '/settings/content',
				text: 'Content',
				icon: 'HeroBars3BottomLeft',
				subPages: {
					categoriesPages: {
						id: 'categoriesPages',
						to: '/settings/content/categories',
						text: 'Categories',
						icon: 'HeroRectangleGroup',
					},
				},
			},
		},
	},
};

export const subscriptionPages = {
	subscriptionMainPages: {
		id: 'subscriptionMainPages',
		to: '/subscription',
		text: 'Subscription',
		icon: 'HeroNewspaper',
		subPages: {
			companyInformationPage: {
				id: 'companyInformationPage',
				to: '/subscription/company-information',
				text: 'Company Information',
				icon: 'HeroBriefcase',
			},
			licensesPage: {
				id: 'licensesPage',
				to: '/subscription/licenses',
				text: 'Licenses',
				icon: 'HeroCheckBadge',
			},
			invoicesPage: {
				id: 'invoicesPage',
				to: '/subscription/invoices',
				text: 'Invoices',
				icon: 'HeroDocumentText',
			},
			termsPage: {
				id: 'termsPage',
				to: '/subscription/terms',
				text: 'Terms',
				icon: 'HeroDocumentCheck',
			},
			inviteFriendsPage: {
				id: 'inviteFriendsPage',
				to: '/subscription/invite-friends',
				text: 'Invite Friends',
				icon: 'HeroUserPlus',
			},
		},
	},
};
export const supportPages = {
	supportMainPages: {
		id: 'supportMainPages',
		to: '/support',
		text: 'Support',
		icon: 'HeroQuestionMarkCircle',
		subPages: {
			contactSupportPages: {
				id: 'contactSupportPages',
				to: '/support/contact-support',
				text: 'Contact Support',
				icon: 'HeroChatBubbleLeftRight',
			},
			supportStartPages: {
				id: 'supportStartPages',
				to: '/support/support-start',
				text: 'Support Start',
				icon: 'HeroFlag',
			},
			mySupportCasesPages: {
				id: 'mySupportCasesPages',
				to: '/support/my-support-cases',
				text: 'My Support Cases',
				icon: 'HeroChatBubbleLeftEllipsis',
			},
		},
	},
};
export const documentationPages = {
	documentationMainPages: {
		id: 'documentationMainPages',
		to: '/documentation',
		text: 'Documentation',
		icon: 'HeroBookOpen',
	},
};

export const contractsPages = {
	contractsMainPages: {
		id: 'contractsMainPages',
		to: '/contracts',
		text: 'Contracts',
		icon: 'HeroBookOpen',
		subPages: {
			cookiePolicyPages: {
				id: 'cookiePolicyPages',
				to: '/contracts/cookie-policy',
				text: 'Cookie Policy',
				icon: 'HeroBookOpen',
			},
			privacyPolicyPages: {
				id: 'privacyPolicyPages',
				to: '/contracts/privacy-policy',
				text: 'Privacy Policy',
				icon: 'HeroBookOpen',
			},
			termsAndConditionsPages: {
				id: 'termsAndConditionsPages',
				to: '/contracts/terms-and-conditions',
				text: 'Terms And Conditions',
				icon: 'HeroBookOpen',
			},
		},
	},
};

const pagesConfig = {
	...authPages,
	...customerViewPages,
	...customerTabs,
	// ...eCommercePage,
	...tenantViewPages,
	...superAdminPage,
	...settingsPages,
};

export default pagesConfig;
