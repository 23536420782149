import { find } from 'lodash';
import { IAuthenticateUserResponse, ITenantFeatures } from '../interface/Auth.interface';
import { getAuthUser } from '../api/apiContext';

export type TModule =
	| 'CUSTOMERS'
	| 'ARTICLES'
	| 'OFFERS'
	| 'ORDERS'
	| 'INVOICES'
	| 'STATISTICS'
	| 'PROJECTS'
	| 'TASKS'
	| 'EVENTS'
	| 'CONTENT';

export const hasModuleEnabledForLoggedInUser = (module: TModule) => {
	//console.log('Called', module);
	const authUser: IAuthenticateUserResponse = getAuthUser();
	return hasModuleEnabled(authUser?.tenantFeatures, module);
};

export const hasModuleEnabled = (features: ITenantFeatures[], module: TModule) => {
	const feature = find(features, { module }) as ITenantFeatures | undefined;
	return feature?.enabled;
};

export const hasAnyModuleEnabled = (modules: TModule[]) => {
	const authUser: IAuthenticateUserResponse = getAuthUser();
	for (let i = 0; i < modules.length; i++) {
		const feature = find(authUser?.tenantFeatures, { module: modules[i] });
		if (feature?.enabled) {
			return true;
		}
	}
	return false;
};
