import React from 'react';
import Header, { HeaderLeft } from '../../../../../components/layouts/Header/Header';
import PageWrapper from '../../../../../components/layouts/PageWrapper/PageWrapper';

const AdminTenantUserViewPage = () => {
	return (
		<>
			<Header>
				<HeaderLeft>Left</HeaderLeft>
			</Header>
			<PageWrapper title='AdminTenantUserViewPage'>AdminTenantUserViewPage</PageWrapper>
		</>
	);
};

export default AdminTenantUserViewPage;
