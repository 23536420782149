import React, { useEffect, useState } from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Container from '../../../components/layouts/Container/Container';
import Card, {
	CardBody,
	CardFooter,
	CardFooterChild,
	CardHeader,
	CardHeaderChild,
	CardTitle,
} from '../../../components/ui/Card';
import BackButton from '../../../components/utils/BackButton';
import BreadcrumbList from '../../../components/layouts/Breadcrumb/BreadcrumbList';
import { settingsPages, tenantDocPages, tenantViewPages } from '../../../config/pages.config';
import Label from '../../../components/form/Label';
import FieldWrap from '../../../components/form/FieldWrap';
import Icon from '../../../components/icon/Icon';
import Input from '../../../components/form/Input';
import { useFormik } from 'formik';
import useSaveBtn from '../../../hooks/useSaveBtn';
import Button from '../../../components/ui/Button';
import Badge from '../../../components/ui/Badge';
import { toast } from 'react-toastify';
import priceFormat from '../../../utils/priceFormat.util';
import ProjectDetailsEditPartial from './ProjectDetails.edit.partial';
import DeliveryAddressEditPartial from './DeliveryAddress.edit.partial';
import CustomerUserEditPartial from './CustomerUser.edit.partial';
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import SortableArticleItemPartial from './_partial/SortableArticleItem.partial';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import { useTranslation } from 'react-i18next';
import Visible from '../../../components/utils/Visible';
import dayjs from 'dayjs';
import { SubheaderSeparator } from '../../../components/layouts/Subheader/Subheader';
import HelperLinkButtons from '../../../components/utils/HelperLinkButtons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDataContext } from '../../../context/appDataContext';
import { ILabelValue } from '../../../interface/LabelValue.interface';
import { fetchArticlePriceList } from '../../../services/articleService';
import { IPricesResponse } from '../../../interface/Article.interface';
import { evaluate } from 'mathjs';
import SelectReact, { TSelectOption, TSelectOptions } from '../../../components/form/SelectReact';
import { IFetchOfferByIdRequest, IOfferDetails } from '../../../interface/OfferResource.interface';
import { fetchOfferById } from '../../../services/offerService';
import Validation from '../../../components/form/Validation';
import { isCustomerLogin } from '../../../api/apiContext';
import { ICreateInvoiceForm, IInvoiceDetails } from '../../../interface/Invoice.interface';
import { createOrUpdateInvoice, fetchInvoiceById } from '../../../services/invoiceService';

const InvoiceEditPartial = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const { id } = useParams();
	const [invoiceData, setInvoiceData] = useState<IInvoiceDetails>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [offer, setOffer] = useState<IOfferDetails>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchParam] = useSearchParams();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const isNewItem = id === 'new';
	const isDuplicate = searchParam.get('duplicate') === 'true';
	const offerId = searchParam.get('offerId');
	const customerLogin = isCustomerLogin();

	const [orderSummary, setOrderSummary] = useState({
		tax: 0,
		subTotal: 0,
		total: 0,
	});

	const {
		customers,
		wayOfDeliveries,
		termsOfPayments,
		projects,
		customerUsers,
		tenantUsers,
		articles,
		deliveryAddress,
		stockPoints,
	} = useAppDataContext();

	useEffect(() => {
		if (id != 'new') {
			if (id) {
				fetchInvoiceById({ invoiceId: id, setIsLoading, setData: setInvoiceData });
			}
		}
	}, [id]);

	useEffect(() => {
		if (offerId) {
			const request: IFetchOfferByIdRequest = {
				offerId: parseInt(offerId),
				setData: setOffer,
				setIsLoading,
			};
			fetchOfferById(request);
		}
	}, [offerId]);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const notify = (promise: Promise<unknown>) =>
		toast
			.promise(promise, {
				pending: 'Saving..',
				success: 'Invoice created successfully.',
				error: 'Error',
			})
			.then((response) => {
				// @ts-ignore
				const orderNumber = response?.data?.Invoice?.DocumentNumber;

				if (!customerLogin) {
					navigate(
						// @ts-ignore
						`/tenant/sales/invoices/${orderNumber}`,
					);
				}
				if (customerLogin) {
					navigate(`/customer/invoices/${orderNumber}`);
				}
				//window.history.back();
			})
			.finally(() => {
				setIsSaving(false);
			});

	const data = {
		CustomerName: invoiceData?.CustomerName ?? '',
		CustomerNumber: invoiceData?.CustomerNumber ?? '',
		InvoiceRows: invoiceData?.InvoiceRows ?? null,
		StockPoint: '',
		Project: invoiceData?.Project ?? '',
		DocumentNumber: isDuplicate ? '' : invoiceData?.DocumentNumber || '',
		YourReference: invoiceData?.YourReference ?? '',
		OurReference: invoiceData?.OurReference ?? '',
		DeliveryAddress1: invoiceData?.DeliveryAddress1 ?? '',
		DeliveryAddress2: invoiceData?.DeliveryAddress2 ?? '',
		DeliveryName: invoiceData?.DeliveryName ?? '',
		DeliveryCountry: invoiceData?.DeliveryCountry ?? '',
		DeliveryCity: invoiceData?.DeliveryCity ?? '',
		DeliveryZipCode: invoiceData?.DeliveryZipCode ?? '',
		Phone1: invoiceData?.Phone1 ?? '',
		InvoiceDate: invoiceData?.InvoiceDate ?? '',
		DueDate: invoiceData?.DeliveryDate ?? '',
		PriceList: '',
		Freight: invoiceData?.Freight ?? 0,
		TermsOfDelivery: '',
		TermsOfPayment: invoiceData?.TermsOfPayment ?? '',
		WayOfDelivery: invoiceData?.WayOfDelivery ?? '',
		YourOrderNumber: invoiceData?.YourOrderNumber || '',
		deliveryAddressId: null,
	};

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const formik = useFormik<ICreateInvoiceForm>({
		enableReinitialize: true,
		initialValues: {
			CustomerName: data?.CustomerName || '',
			CustomerNumber: data?.CustomerNumber || '',
			InvoiceRows:
				data?.InvoiceRows && data?.InvoiceRows.length > 0
					? data?.InvoiceRows.map((o) => ({
							id: o.RowId,
							type: o?.ArticleNumber ? 'article' : 'text',
							ArticleNumber: o?.ArticleNumber,
							Description: o?.Description,
							Discount: o?.Discount ?? 0,
							DeliveredQuantity: o?.DeliveredQuantity
								? Number(o.DeliveredQuantity)
								: 0,
							Price: o?.Price ?? 0,
							VAT: o?.VAT ?? 0,
							StockPointCode:
								'StockPointId' in o
									? String(o?.StockPointId || '')
									: String(
											stockPoints.find((s) => s.default === true)?.value ||
												'',
										),
						}))
					: [
							{
								id: 1,
								type: 'article',
								ArticleNumber: '',
								Description: '',
								Discount: 0,
								DeliveredQuantity: 0,
								Price: 0,
								VAT: 0,
								StockPointCode:
									stockPoints.find((s) => s.default === true)?.value || '',
							},
						],
			StockPoint:
				data?.StockPoint || stockPoints.find((s) => s.default === true)?.value || '',
			Project: data?.Project || '',
			DocumentNumber: isDuplicate ? '' : data?.DocumentNumber || '',
			YourReference: data?.YourReference || '',
			OurReference: data?.OurReference || '',
			DeliveryAddress1: data?.DeliveryAddress1 || '',
			DeliveryAddress2: data?.DeliveryAddress2 || '',
			DeliveryName: data?.DeliveryName || '',
			DeliveryCountry: data?.DeliveryCountry || '',
			DeliveryCity: data?.DeliveryCity || '',
			DeliveryZipCode: data?.DeliveryZipCode || '',
			Phone1: data?.Phone1 || '',
			DueDate: data?.DueDate ? dayjs(data?.DueDate).format('YYYY-MM-DD') : '',
			PriceList: data?.PriceList || '',
			Freight: data?.Freight ?? 0,
			TermsOfDelivery: data?.TermsOfDelivery || '',
			TermsOfPayment: data?.TermsOfPayment || '',
			WayOfDelivery: data?.WayOfDelivery || '',
			YourOrderNumber: data?.YourOrderNumber || '',
			deliveryAddressId: null,
			InvoiceDate: data?.InvoiceDate
				? dayjs(data?.InvoiceDate).format('YYYY-MM-DD')
				: dayjs().format('YYYY-MM-DD'),
		},
		validate: (values) => {
			const errors: { [key: string]: string } = {};

			if (!values.CustomerNumber) {
				errors.CustomerNumber = t('Required');
			}
			if (!values.YourReference) {
				errors.YourReference = t('Required');
			}
			if (!values.OurReference) {
				errors.OurReference = t('Required');
			}
			if (!values.InvoiceDate) {
				errors.InvoiceDate = t('Required');
			}
			if (!customerLogin && !values.DueDate) {
				errors.DueDate = t('Required');
			}
			if (!values.TermsOfDelivery) {
				errors.TermsOfDelivery = t('Required');
			}
			if (!values.TermsOfPayment) {
				errors.TermsOfPayment = t('Required');
			}
			if (!values.WayOfDelivery) {
				errors.WayOfDelivery = t('Required');
			}
			if (!values.DeliveryName) {
				errors.DeliveryName = t('Required');
			}
			if (!values.deliveryAddressId) {
				errors.deliveryAddressId = t('Required');
			}
			if (!values.DeliveryAddress1) {
				errors.DeliveryAddress1 = t('Required');
			}
			if (!values.DeliveryAddress2) {
				errors.DeliveryAddress2 = t('Required');
			}
			if (!values.DeliveryCountry) {
				errors.DeliveryCountry = t('Required');
			}
			if (!values.DeliveryCity) {
				errors.DeliveryCity = t('Required');
			}
			if (!values.DeliveryZipCode) {
				errors.DeliveryZipCode = t('Required');
			}
			if (!values.Phone1) {
				errors.Phone1 = t('Required');
			}
			return errors;
		},
		onSubmit: (values) => {
			if (values.YourReference) {
				const yourReference = customerUsers.find(
					(option) => option.label === formik.values.YourReference,
				);
				values.YourReference = yourReference?.value || '';
			}
			const savePromise = createOrUpdateInvoice(values);
			notify(savePromise);
		},
	});
	const { saveBtnText, saveBtnColor, saveBtnDisable } = useSaveBtn({
		isNewItem: true,
		isSaving,
		isDirty: formik.dirty,
	});

	const _BUTTONS = (
		<HelperLinkButtons
			documentationTo={
				tenantDocPages.documentationPage.subPages.salesPage.subPages.ordersPage.to
			}
			settingsTo={`${settingsPages.settingsMainPages.to}?filter=`}
		/>
	);
	const _SAVE_BTN = (
		<Button
			icon='HeroServer'
			variant='solid'
			color={saveBtnColor}
			isDisable={saveBtnDisable}
			onClick={() => formik.handleSubmit()}>
			{t(saveBtnText)}
		</Button>
	);

	const addArticleItem = (type: 'text' | 'article', id: number) => {
		if (type === 'text') {
			formik.setFieldValue('InvoiceRows', [
				...formik.values.InvoiceRows,
				{
					id,
					type: 'text',
					text: '',
				},
			]);
		} else if (type === 'article') {
			formik.setFieldValue('InvoiceRows', [
				...formik.values.InvoiceRows,
				{
					id,
					type: 'article',
					ArticleNumber: '',
					Description: '',
					Discount: 0,
					DeliveredQuantity: 0,
					Price: 0,
					VAT: 0,
					StockPointCode: stockPoints.find((s) => s.default === true)?.value || '',
				},
			]);
		}
	};
	const removeArticleItem = (index: number) => {
		const articles = formik.values.InvoiceRows.filter((_, i) => i !== index);
		formik.setFieldValue('InvoiceRows', articles).then(() =>
			toast.success(`Item ${index + 1} was deleted.`, {
				icon: () => <Icon icon='HeroTrash' size='text-2xl' color='red' />,
			}),
		);
	};

	const [isOffcanvasNewProject, setIsOffcanvasNewProject] = useState<boolean>(false);
	const [isOffcanvasDeliveryAddress, setIsOffcanvasDeliveryAddress] = useState<boolean>(false);
	const [isOffCanvasReference, setIsOffCanvasReference] = useState<boolean>(false);

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event;

		if (over && active.id !== over.id) {
			const oldIndex = formik.values.InvoiceRows.findIndex((item) => item.id === active.id);
			const newIndex = formik.values.InvoiceRows.findIndex((item) => item.id === over.id);

			if (oldIndex !== -1 && newIndex !== -1) {
				const newArticles = arrayMove(formik.values.InvoiceRows, oldIndex, newIndex);

				formik.setFieldValue('InvoiceRows', newArticles).then(() =>
					toast.success(
						`Item ${active.id} moved from ${oldIndex + 1} to ${newIndex + 1}.`,
						{
							icon: () => (
								<Icon icon='HeroArrowsUpDown' size='text-2xl' color='emerald' />
							),
						},
					),
				);
			}
		}
	}

	const handleLoadArticleDetails = (option: ILabelValue, rowId: number) => {
		if (option && option.value && option.id) {
			fetchArticlePriceList(option.id, formik.values.PriceList).then(
				(price: IPricesResponse) => {
					const updatedOfferRows = formik.values.InvoiceRows.map((offerRow) => {
						if (offerRow.id === rowId) {
							return {
								...offerRow,
								ArticleNumber: option.value || '',
								Description: option.label || '',
								Price: price.prices[0].Price || 0,
								VAT: Number(price.vat) || 0,
								articlePackage: price?.articlePackage,
							};
						}
						return offerRow;
					});
					formik.setFieldValue('InvoiceRows', updatedOfferRows);
				},
			);
		}
	};

	const handlePriceListChange = async (priceList: string | null | undefined) => {
		const copyOfInvoiceRows = [...formik.values.InvoiceRows];
		const updatedInvoiceRows = await Promise.all(
			copyOfInvoiceRows.map(async (orderRow) => {
				if (orderRow.type == 'article') {
					const articleId = articles.find((a) => a.value === orderRow.ArticleNumber)?.id;
					if (articleId) {
						if (priceList) {
							const price: IPricesResponse = await fetchArticlePriceList(
								articleId,
								priceList,
							);
							orderRow.Price = price.prices[0].Price || 0;
						} else {
							//TODO : Handle if priceList is not define for customer.
						}
					}
				}
				return orderRow;
			}),
		);
		calculateOrderSummary();
		formik.setFieldValue('InvoiceRows', updatedInvoiceRows, true);
	};

	const handleQuantityChange = (value: number, rowId: number) => {
		const updatedOfferRows = formik.values.InvoiceRows.map((offerRow) => {
			if (offerRow.id === rowId) {
				return {
					...offerRow,
					DeliveredQuantity: value,
				};
			}
			return offerRow;
		});
		calculateOrderSummary();
		formik.setFieldValue('InvoiceRows', updatedOfferRows);
	};

	const handleDiscountChange = (value: number, rowId: number) => {
		const updatedOfferRows = formik.values.InvoiceRows.map((offerRow) => {
			if (offerRow.id === rowId) {
				return {
					...offerRow,
					Discount: value,
				};
			}
			return offerRow;
		});
		formik.setFieldValue('InvoiceRows', updatedOfferRows);
	};

	const handleStockPointChange = (value: string, rowId: number) => {
		const updatedOfferRows = formik.values.InvoiceRows.map((offerRow) => {
			if (offerRow.id === rowId) {
				return {
					...offerRow,
					StockPointCode: value,
				};
			}
			return offerRow;
		});
		formik.setFieldValue('InvoiceRows', updatedOfferRows);
	};

	const handleDescriptionChange = (value: string, rowId: number) => {
		const updatedOfferRows = formik.values.InvoiceRows.map((offerRow) => {
			if (offerRow.id === rowId) {
				return {
					...offerRow,
					Description: value,
				};
			}
			return offerRow;
		});
		formik.setFieldValue('InvoiceRows', updatedOfferRows);
	};

	const calculateOrderSummary = () => {
		const subTotal =
			formik.values.Freight +
			formik.values.InvoiceRows.filter((value) => value.type == 'article').reduce(
				(total, row) =>
					total + row.DeliveredQuantity * row.Price * (1 - row.Discount / 100),
				0,
			);
		const tax =
			formik.values.InvoiceRows.filter((value) => value.type == 'article').reduce(
				(vat, row) =>
					vat +
					row.DeliveredQuantity *
						row.Price *
						(1 - row.Discount / 100) *
						(row.VAT > 0 ? row.VAT / 100 : 0),
				0,
			) +
			formik.values.Freight * 0.25;

		setOrderSummary({
			tax,
			subTotal,
			total: Number(subTotal) + Number(tax),
		});
	};

	useEffect(() => {
		calculateOrderSummary();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.Freight, formik.values.InvoiceRows]);

	const parseExpression = (oprand1: number, operator: string, oprand2: number) => {
		operator = operator == 'x' ? '*' : '/';
		return Math.round(evaluate(`${oprand1} ${operator} ${oprand2}`));
	};

	return (
		<>
			<Header>
				<HeaderLeft>
					<BackButton />
					<BreadcrumbList
						list={[
							{
								...tenantViewPages.tenantPage.subPages.salesPage.subPages
									.invoicesPage,
							},
							{
								text: 'Invoice',
								to: `.`,
								type: 'New',
								color: 'emerald',
							},
						]}
					/>
				</HeaderLeft>
				<HeaderRight>
					{_BUTTONS}
					<SubheaderSeparator />
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>
			<PageWrapper title={t('New Invoice')}>
				<Container>
					<div className='grid grid-cols-12 gap-4'>
						<div className='col-span-12'>
							<Card>
								<CardHeader>
									<CardHeaderChild>
										<CardTitle>{t('Customer Details')}</CardTitle>
									</CardHeaderChild>
								</CardHeader>
								<CardBody>
									<div className='grid grid-cols-12 gap-4'>
										{!customerLogin && (
											<>
												<div className='col-span-12 lg:col-span-4'>
													<Label htmlFor='customer'>
														{t('Customer')}
													</Label>
													<Validation
														isValid={formik.isValid}
														isTouched={formik.touched.CustomerNumber}
														invalidFeedback={
															formik.errors.CustomerNumber
														}
														validFeedback='Good'>
														<FieldWrap
															firstSuffix={
																<Icon
																	icon='HeroUser'
																	className='mx-2'
																/>
															}
															lastSuffix={
																<Icon
																	icon='HeroChevronDown'
																	className='mx-2'
																/>
															}>
															<SelectReact
																options={
																	customers as TSelectOptions
																}
																id='customer'
																name='customer'
																value={
																	customers.find(
																		(option) =>
																			option.value ===
																			formik.values
																				.CustomerNumber,
																	) as TSelectOption
																}
																className='ps-10'
																disabled={offerId != undefined}
																onChange={(value) => {
																	const selectedValue =
																		value as TSelectOption;
																	const labelValue =
																		customers.find(
																			(c) =>
																				c.value ===
																				selectedValue?.value,
																		);
																	formik.setFieldValue(
																		'CustomerNumber',
																		selectedValue?.value,
																	);
																	formik.setFieldValue(
																		'CustomerName',
																		selectedValue?.label,
																	);
																	formik.setFieldValue(
																		'PriceList',
																		labelValue?.priceList,
																	);

																	/*formik.setFieldValue(
                                                                        'TermsOfDelivery',
                                                                        labelValue?.termsOfDelivery,
                                                                    );*/

																	formik.setFieldValue(
																		'TermsOfPayment',
																		labelValue?.termsOfPayment,
																	);
																	formik.setFieldValue(
																		'DueDate',
																		'',
																	);
																	if (
																		labelValue?.termsOfPayment
																	) {
																		formik.setFieldValue(
																			'DueDate',
																			dayjs(
																				formik.values
																					.InvoiceDate,
																			)
																				.add(
																					Number(
																						selectedValue?.value,
																					),
																					'day',
																				)
																				.format(
																					'YYYY-MM-DD',
																				),
																		);
																	}

																	formik.setFieldValue(
																		'WayOfDelivery',
																		labelValue?.wayOfDelivery,
																	);

																	handlePriceListChange(
																		labelValue?.priceList,
																	);
																}}
																onBlur={formik.handleBlur}
															/>
														</FieldWrap>
													</Validation>
												</div>
												<div className='col-span-12 lg:col-span-4'>
													<Label htmlFor='priceList'>
														{t('Price List')}
													</Label>
													<FieldWrap
														firstSuffix={
															<Icon
																icon='HeroCurrencyEuro'
																className='mx-2'
															/>
														}>
														<Input
															id='priceList'
															name='priceList'
															onChange={formik.handleChange}
															value={formik.values.PriceList}
															placeholder={t('Price List')}
															disabled
														/>
													</FieldWrap>
												</div>
												{/*<div className='col-span-12 lg:col-span-4 lg:col-start-1'>
                                                    <Label htmlFor='deliveryTerms'>
                                                        {t('Delivery Terms')}
                                                    </Label>
                                                    <Validation
                                                        isValid={formik.isValid}
                                                        isTouched={formik.touched.TermsOfDelivery}
                                                        invalidFeedback={
                                                            formik.errors.TermsOfDelivery
                                                        }
                                                        validFeedback='Good'>
                                                        <FieldWrap
                                                            firstSuffix={
                                                                <Icon
                                                                    icon='HeroDocumentText'
                                                                    className='mx-2'
                                                                />
                                                            }
                                                            lastSuffix={
                                                                <Icon
                                                                    icon='HeroChevronDown'
                                                                    className='mx-2'
                                                                />
                                                            }>
                                                            <SelectReact
                                                                options={
                                                                    termsOfDeliveries as TSelectOptions
                                                                }
                                                                id='TermsOfDelivery'
                                                                name='TermsOfDelivery'
                                                                value={
                                                                    termsOfDeliveries.find(
                                                                        (option) =>
                                                                            option.value ===
                                                                            formik.values
                                                                                .TermsOfDelivery,
                                                                    ) as TSelectOption
                                                                }
                                                                className='ps-10'
                                                                onChange={(value) => {
                                                                    const selectedValue =
                                                                        value as TSelectOption;
                                                                    formik.setFieldValue(
                                                                        'TermsOfDelivery',
                                                                        selectedValue?.value,
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        </FieldWrap>
                                                    </Validation>
                                                </div>*/}
												<div className='col-span-12 lg:col-span-4'>
													<Label htmlFor='paymentTerms'>
														{t('Payment Terms')}
													</Label>
													<Validation
														isValid={formik.isValid}
														isTouched={formik.touched.TermsOfPayment}
														invalidFeedback={
															formik.errors.TermsOfPayment
														}
														validFeedback='Good'>
														<FieldWrap
															firstSuffix={
																<Icon
																	icon='HeroDocumentText'
																	className='mx-2'
																/>
															}
															lastSuffix={
																<Icon
																	icon='HeroChevronDown'
																	className='mx-2'
																/>
															}>
															<SelectReact
																options={
																	termsOfPayments as TSelectOptions
																}
																id='TermsOfPayment'
																name='TermsOfPayment'
																value={
																	termsOfPayments.find(
																		(option) =>
																			option.value ===
																			formik.values
																				.TermsOfPayment,
																	) as TSelectOption
																}
																className='ps-10'
																onChange={(value) => {
																	const selectedValue =
																		value as TSelectOption;
																	formik.setFieldValue(
																		'TermsOfPayment',
																		selectedValue?.value,
																	);

																	if (
																		!isNaN(
																			Number(
																				selectedValue?.value,
																			),
																		) &&
																		selectedValue?.value
																	) {
																		formik.setFieldValue(
																			'DueDate',
																			dayjs(
																				formik.values
																					.InvoiceDate,
																			)
																				.add(
																					Number(
																						selectedValue?.value,
																					),
																					'day',
																				)
																				.format(
																					'YYYY-MM-DD',
																				),
																		);
																	}
																}}
																onBlur={formik.handleBlur}
															/>
														</FieldWrap>
													</Validation>
												</div>
												{/*<div className='col-span-12 lg:col-span-4'>
                                                    <Label htmlFor='stockpoint'>
                                                        {t('Stockpoint')}
                                                    </Label>
                                                    <FieldWrap
                                                        firstSuffix={
                                                            <Icon
                                                                icon='HeroMapPin'
                                                                className='mx-2'
                                                            />
                                                        }
                                                        lastSuffix={
                                                            <Icon
                                                                icon='HeroChevronDown'
                                                                className='mx-2'
                                                            />
                                                        }>
                                                        <SelectReact
                                                            options={stockPoints as TSelectOptions}
                                                            id='StockPoint'
                                                            name='StockPoint'
                                                            value={
                                                                stockPoints.find(
                                                                    (option) =>
                                                                        option.value ===
                                                                        formik.values.StockPoint,
                                                                ) as TSelectOption
                                                            }
                                                            className='ps-10'
                                                            onChange={(value) => {
                                                                const selectedValue =
                                                                    value as TSelectOption;
                                                                formik.setFieldValue(
                                                                    'StockPoint',
                                                                    selectedValue?.value,
                                                                );
                                                            }}
                                                        />
                                                    </FieldWrap>
                                                </div>*/}
												<div className='col-span-12 lg:col-span-4 lg:col-start-1'>
													<Label htmlFor='offerDate'>
														{t('Invoice Date')}
													</Label>
													<Validation
														isValid={formik.isValid}
														isTouched={formik.touched.InvoiceDate}
														invalidFeedback={formik.errors.InvoiceDate}
														validFeedback='Good'>
														<FieldWrap
															firstSuffix={
																<Icon
																	icon='HeroCalendarDays'
																	className='mx-2'
																/>
															}>
															<Input
																id='InvoiceDate'
																name='InvoiceDate'
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																value={formik.values.InvoiceDate}
																placeholder={t('Invoice Date')}
																type='date'
																className='flex h-[33px] items-center'
															/>
														</FieldWrap>
													</Validation>
												</div>
												<div className='col-span-12 lg:col-span-4'>
													<Label htmlFor='deliveryDate'>
														{t('Due Date')}
													</Label>
													<Validation
														isValid={formik.isValid}
														isTouched={formik.touched.DueDate}
														invalidFeedback={formik.errors.DueDate}
														validFeedback='Good'>
														<FieldWrap
															firstSuffix={
																<Icon
																	icon='HeroCalendarDays'
																	className='mx-2'
																/>
															}>
															<Input
																id='DueDate'
																name='DueDate'
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																value={formik.values.DueDate}
																placeholder={t('Due Date')}
																type='date'
																className='flex h-[33px] items-center'
															/>
														</FieldWrap>
													</Validation>
												</div>
												<div className='col-span-12 lg:col-span-4'>
													<Label htmlFor='ourReference'>
														{t('Our Reference')}
													</Label>
													<Validation
														isValid={formik.isValid}
														isTouched={formik.touched.OurReference}
														invalidFeedback={formik.errors.OurReference}
														validFeedback='Good'>
														<FieldWrap
															firstSuffix={
																<Icon
																	icon='HeroUser'
																	className='mx-2'
																/>
															}
															lastSuffix={
																<Icon
																	icon='HeroChevronDown'
																	className='mx-2'
																/>
															}>
															<SelectReact
																options={
																	tenantUsers as TSelectOptions
																}
																id='OurReference'
																name='OurReference'
																value={
																	tenantUsers.find(
																		(option) =>
																			option.label ===
																			formik.values
																				.OurReference,
																	) as TSelectOption
																}
																className='ps-10'
																onChange={(value) => {
																	const selectedValue =
																		value as TSelectOption;
																	formik.setFieldValue(
																		'OurReference',
																		selectedValue?.label,
																	);
																}}
																onBlur={formik.handleBlur}
															/>
														</FieldWrap>
													</Validation>
												</div>
											</>
										)}
										<div className='col-span-12 lg:col-span-4 lg:col-start-1'>
											<div className='flex gap-4'>
												<div className='grow'>
													<Label htmlFor='project'>{t('Project')}</Label>
													<FieldWrap
														firstSuffix={
															<Icon
																icon='HeroClipboardDocumentList'
																className='mx-2'
															/>
														}
														lastSuffix={
															<Icon
																icon='HeroChevronDown'
																className='mx-2'
															/>
														}>
														<SelectReact
															options={
																projects.filter(
																	(c) =>
																		Number(c.customerId) ==
																		Number(
																			customers.find(
																				(c) =>
																					c.value ==
																					formik.values
																						.CustomerNumber,
																			)?.id,
																		),
																) as TSelectOptions
															}
															disabled={!formik.values.CustomerNumber}
															id='Project'
															name='Project'
															value={
																projects.find(
																	(option) =>
																		option.value ===
																		formik.values.Project,
																) as TSelectOption
															}
															className='ps-10'
															onChange={(value) => {
																const selectedValue =
																	value as TSelectOption;
																formik.setFieldValue(
																	'Project',
																	selectedValue?.value,
																);
															}}
														/>
													</FieldWrap>
												</div>
												<div className='flex flex-shrink items-end'>
													<Button
														icon='HeroPlus'
														variant='outline'
														color='emerald'
														onClick={() =>
															setIsOffcanvasNewProject(true)
														}
													/>
													<ProjectDetailsEditPartial
														inCanvas
														isNew
														isOpen={isOffcanvasNewProject}
														setIsOpen={setIsOffcanvasNewProject}
													/>
												</div>
											</div>
										</div>
										<div className='col-span-12 lg:col-span-4'>
											<div className='flex gap-4'>
												<div className='grow'>
													<Label htmlFor='yourReference'>
														{t('Your Reference')}
													</Label>
													<Validation
														isValid={formik.isValid}
														isTouched={formik.touched.YourReference}
														invalidFeedback={
															formik.errors.YourReference
														}
														validFeedback='Good'>
														<FieldWrap
															firstSuffix={
																<Icon
																	icon='HeroUser'
																	className='mx-2'
																/>
															}
															lastSuffix={
																<Icon
																	icon='HeroChevronDown'
																	className='mx-2'
																/>
															}>
															<SelectReact
																options={
																	customerUsers.filter(
																		(c) =>
																			Number(c.customerId) ==
																			Number(
																				customers.find(
																					(c) =>
																						c.value ==
																						formik
																							.values
																							.CustomerNumber,
																				)?.id,
																			),
																	) as TSelectOptions
																}
																id='YourReference'
																name='YourReference'
																disabled={
																	!formik.values.CustomerNumber
																}
																value={
																	customerUsers.find(
																		(option) =>
																			option.label ===
																			formik.values
																				.YourReference,
																	) as TSelectOption
																}
																className='ps-10'
																onChange={(value) => {
																	const selectedValue =
																		value as TSelectOption;
																	formik.setFieldValue(
																		'YourReference',
																		selectedValue?.value,
																	);
																}}
																onBlur={formik.handleBlur}
															/>
														</FieldWrap>
													</Validation>
												</div>
												<div className='flex flex-shrink items-end'>
													<Button
														icon='HeroPlus'
														variant='outline'
														color='emerald'
														onClick={() =>
															setIsOffCanvasReference(true)
														}
													/>
													<CustomerUserEditPartial
														inCanvas
														isNew
														isOpen={isOffCanvasReference}
														setIsOpen={setIsOffCanvasReference}
													/>
												</div>
											</div>
										</div>
										<div className='col-span-12 lg:col-span-4'>
											<Label htmlFor='yourOrderNumber'>
												{t('Your Order Number')}
											</Label>
											<FieldWrap
												firstSuffix={
													<Icon icon='HeroHashtag' className='mx-2' />
												}>
												<Input
													id='YourOrderNumber'
													name='YourOrderNumber'
													onChange={formik.handleChange}
													value={formik.values.YourOrderNumber}
													placeholder={t('Your Order Number')}
												/>
											</FieldWrap>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className='col-span-12'>
							<Card>
								<CardHeader>
									<CardHeaderChild>
										<CardTitle>{t('Address')}</CardTitle>
									</CardHeaderChild>
								</CardHeader>
								<CardBody>
									<div className='grid grid-cols-12 gap-4'>
										<div className='col-span-12 lg:col-span-4'>
											<div className='flex gap-4'>
												<div className='grow'>
													<Label htmlFor='deliveryAddress'>
														{t('Delivery Address')}
													</Label>
													<Validation
														isValid={formik.isValid}
														isTouched={formik.touched.deliveryAddressId}
														invalidFeedback={
															formik.errors.deliveryAddressId
														}
														validFeedback='Good'>
														<FieldWrap
															firstSuffix={
																<Icon
																	icon='HeroMap'
																	className='mx-2'
																/>
															}
															lastSuffix={
																<Icon
																	icon='HeroChevronDown'
																	className='mx-2'
																/>
															}>
															<SelectReact
																options={
																	deliveryAddress
																		.filter(
																			(value) =>
																				value.customerId ==
																				Number(
																					customers.find(
																						(c) =>
																							c.value ==
																							formik
																								.values
																								.CustomerNumber,
																					)?.id,
																				),
																		)
																		.map((value) => ({
																			id: value.id,
																			label: value.name,
																			value: value.id.toString(),
																		})) as TSelectOptions
																}
																id='deliveryAddressId'
																name='deliveryAddressId'
																disabled={
																	!formik.values.CustomerNumber
																}
																value={
																	deliveryAddress.find(
																		(option) =>
																			Number(option.id) ===
																			formik.values
																				.deliveryAddressId,
																	) as TSelectOption
																}
																className='ps-10'
																onChange={(value) => {
																	const selectedValue =
																		value as TSelectOption;
																	formik.setFieldValue(
																		'deliveryAddressId',
																		selectedValue?.value,
																	);
																	if (selectedValue) {
																		const cAddres =
																			deliveryAddress.find(
																				(a) =>
																					a.id ==
																					Number(
																						selectedValue.value,
																					),
																			);
																		formik.setFieldValue(
																			'DeliveryName',
																			cAddres?.name,
																		);
																		formik.setFieldValue(
																			'DeliveryAddress2',
																			cAddres?.address,
																		);
																		formik.setFieldValue(
																			'DeliveryCountry',
																			cAddres?.country,
																		);
																		formik.setFieldValue(
																			'DeliveryCity',
																			cAddres?.city,
																		);
																		formik.setFieldValue(
																			'DeliveryZipCode',
																			cAddres?.zipCode,
																		);
																		formik.setFieldValue(
																			'Phone1',
																			cAddres?.phone,
																		);
																	}
																}}
																onBlur={formik.handleBlur}
															/>
														</FieldWrap>
													</Validation>
												</div>
												<div className='flex flex-shrink items-end'>
													<Button
														icon='HeroPlus'
														variant='outline'
														color='emerald'
														onClick={() =>
															setIsOffcanvasDeliveryAddress(true)
														}
													/>
													<DeliveryAddressEditPartial
														inCanvas
														isNew
														isOpen={isOffcanvasDeliveryAddress}
														setIsOpen={setIsOffcanvasDeliveryAddress}
													/>
												</div>
											</div>
										</div>
										<div className='col-span-12 lg:col-span-4'>
											<Label htmlFor='WayOfDelivery'>
												{t('Delivery Service')}
											</Label>
											<Validation
												isValid={formik.isValid}
												isTouched={formik.touched.WayOfDelivery}
												invalidFeedback={formik.errors.WayOfDelivery}
												validFeedback='Good'>
												<FieldWrap
													firstSuffix={
														<Icon
															icon='HeroHandRaised'
															className='mx-2'
														/>
													}
													lastSuffix={
														<Icon
															icon='HeroChevronDown'
															className='mx-2'
														/>
													}>
													<SelectReact
														options={wayOfDeliveries as TSelectOptions}
														id='WayOfDelivery'
														name='WayOfDelivery'
														value={
															wayOfDeliveries.find(
																(option) =>
																	option.value ===
																	formik.values.WayOfDelivery,
															) as TSelectOption
														}
														className='ps-10'
														onChange={(value) => {
															const selectedValue =
																value as TSelectOption;
															formik.setFieldValue(
																'WayOfDelivery',
																selectedValue?.value,
															);
														}}
														onBlur={formik.handleBlur}
													/>
												</FieldWrap>
											</Validation>
										</div>
										<div className='col-span-12 lg:col-span-4'>
											<Label htmlFor='deliveryReference'>
												{t('Delivery Reference')}
											</Label>
											<Validation
												isValid={formik.isValid}
												isTouched={formik.touched.DeliveryAddress1}
												invalidFeedback={formik.errors.DeliveryAddress1}
												validFeedback='Good'>
												<FieldWrap
													firstSuffix={
														<Icon
															icon='HeroUserGroup'
															className='mx-2'
														/>
													}>
													<Input
														id='DeliveryAddress1'
														name='DeliveryAddress1'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.DeliveryAddress1}
														placeholder={t('Delivery Reference')}
													/>
												</FieldWrap>
											</Validation>
										</div>
										<div className='col-span-12'>
											<Label htmlFor='deliveryName'>
												{t('Delivery Name')}
											</Label>
											<Validation
												isValid={formik.isValid}
												isTouched={formik.touched.DeliveryName}
												invalidFeedback={formik.errors.DeliveryName}
												validFeedback='Good'>
												<FieldWrap
													firstSuffix={
														<Icon icon='HeroTruck' className='mx-2' />
													}>
													<Input
														id='DeliveryName'
														name='DeliveryName'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.DeliveryName}
														placeholder={t('Delivery Name')}
														autoComplete='organization'
													/>
												</FieldWrap>
											</Validation>
										</div>
										<div className='col-span-12 lg:col-span-8'>
											<Label htmlFor='address'>{t('Address')}</Label>
											<Validation
												isValid={formik.isValid}
												isTouched={formik.touched.DeliveryAddress2}
												invalidFeedback={formik.errors.DeliveryAddress2}
												validFeedback='Good'>
												<FieldWrap
													firstSuffix={
														<Icon icon='HeroMapPin' className='mx-2' />
													}>
													<Input
														id='DeliveryAddress2'
														name='DeliveryAddress2'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.DeliveryAddress2}
														placeholder={t('Address')}
														autoComplete='street-address'
													/>
												</FieldWrap>
											</Validation>
										</div>
										<div className='col-span-12 lg:col-span-4'>
											<Label htmlFor='country'>{t('Country')}</Label>
											<Validation
												isValid={formik.isValid}
												isTouched={formik.touched.DeliveryCountry}
												invalidFeedback={formik.errors.DeliveryCountry}
												validFeedback='Good'>
												<FieldWrap
													firstSuffix={
														<Icon
															icon='HeroGlobeEuropeAfrica'
															className='mx-2'
														/>
													}>
													<Input
														id='DeliveryCountry'
														name='DeliveryCountry'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.DeliveryCountry}
														placeholder={t('Country')}
														autoComplete='country-name'
													/>
												</FieldWrap>
											</Validation>
										</div>
										<div className='col-span-12 lg:col-span-4'>
											<Label htmlFor='city'>{t('City')}</Label>
											<Validation
												isValid={formik.isValid}
												isTouched={formik.touched.DeliveryCity}
												invalidFeedback={formik.errors.DeliveryCity}
												validFeedback='Good'>
												<FieldWrap
													firstSuffix={
														<Icon icon='HeroMap' className='mx-2' />
													}>
													<Input
														id='DeliveryCity'
														name='DeliveryCity'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.DeliveryCity}
														placeholder={t('City')}
														autoComplete='address-level2'
													/>
												</FieldWrap>
											</Validation>
										</div>
										<div className='col-span-12 lg:col-span-4'>
											<Label htmlFor='zipCode'>{t('ZIP Code')}</Label>
											<Validation
												isValid={formik.isValid}
												isTouched={formik.touched.DeliveryZipCode}
												invalidFeedback={formik.errors.DeliveryZipCode}
												validFeedback='Good'>
												<FieldWrap
													firstSuffix={
														<Icon icon='HeroInbox' className='mx-2' />
													}>
													<Input
														id='DeliveryZipCode'
														name='DeliveryZipCode'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.DeliveryZipCode}
														placeholder={t('ZIP Code')}
														autoComplete='postal-code'
													/>
												</FieldWrap>
											</Validation>
										</div>
										<div className='col-span-12 lg:col-span-4'>
											<Label htmlFor='phone'>{t('Phone')}</Label>
											<Validation
												isValid={formik.isValid}
												isTouched={formik.touched.Phone1}
												invalidFeedback={formik.errors.Phone1}
												validFeedback='Good'>
												<FieldWrap
													firstSuffix={
														<Icon
															icon='HeroGlobeEuropeAfrica'
															className='mx-2'
														/>
													}>
													<Input
														id='Phone1'
														name='Phone1'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.Phone1}
														placeholder={t('Phone')}
														autoComplete='tel'
													/>
												</FieldWrap>
											</Validation>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className='col-span-12'>
							<Card>
								<CardHeader>
									<CardHeaderChild>
										<CardTitle>{t('Articles')}</CardTitle>
									</CardHeaderChild>
								</CardHeader>
								<CardBody>
									<div className='grid grid-cols-12 gap-4'>
										<DndContext
											sensors={sensors}
											collisionDetection={closestCenter}
											onDragEnd={handleDragEnd}>
											<SortableContext
												items={formik.values.InvoiceRows}
												strategy={verticalListSortingStrategy}>
												{formik.values.InvoiceRows.map((row, index) => {
													const _BTN = (
														<div className='col-auto flex items-end gap-4'>
															<Button
																variant='outline'
																color='red'
																onClick={() =>
																	removeArticleItem(index)
																}
																icon='HeroTrash'
																isDisable={
																	formik.values.InvoiceRows
																		.length === 1
																}
																className='mx-2'
															/>
														</div>
													);
													if (row.type === 'article') {
														return (
															<SortableArticleItemPartial
																key={row.id}
																id={row.id}>
																<div className='grid w-full grid-cols-12 gap-4'>
																	<div className='col-span-12 lg:col-span-2'>
																		<Label
																			htmlFor={`articles[${index}].articleName`}>
																			<Badge
																				color='violet'
																				className='me-2'
																				variant='outline'
																				borderWidth='border-0'
																				rounded='rounded-full'>{`${t('Item')} ${row.id}`}</Badge>
																			{t('Article')}
																		</Label>
																		<FieldWrap
																			firstSuffix={
																				<Icon
																					icon='HeroTag'
																					className='mx-2'
																				/>
																			}
																			lastSuffix={
																				<Icon
																					icon='HeroChevronDown'
																					className='mx-2'
																				/>
																			}>
																			<SelectReact
																				options={
																					articles as TSelectOptions
																				}
																				id={`articles[${index}].ArticleNumber`}
																				name={`articles[${index}].ArticleNumber`}
																				value={
																					{
																						value: row.ArticleNumber,
																						label: row.Description,
																					} as TSelectOption
																				}
																				onChange={(
																					value,
																				) => {
																					handleLoadArticleDetails(
																						value as ILabelValue,
																						row.id,
																					);
																				}}
																				className='ps-10'
																			/>
																		</FieldWrap>
																	</div>
																	<div className='col-span-12 lg:col-span-2'>
																		<Label
																			htmlFor={`articles[${index}].quantity`}>
																			{t('Quantity')}
																		</Label>
																		<FieldWrap
																			firstSuffix={
																				<Icon
																					icon='HeroListBullet'
																					className='mx-2'
																				/>
																			}>
																			<Input
																				id={`articles[${index}].DeliveredQuantity`}
																				name={`articles[${index}].DeliveredQuantity`}
																				value={
																					row.DeliveredQuantity
																				}
																				onChange={(
																					event,
																				) => {
																					handleQuantityChange(
																						Number(
																							event
																								.target
																								.value,
																						),
																						row.id,
																					);
																				}}
																				placeholder={t(
																					'Quantity',
																				)}
																				type='number'
																			/>
																		</FieldWrap>
																	</div>
																	<div className='col-span-12 lg:col-span-2'>
																		<Label
																			htmlFor={`articles[${index}].stockpoint`}>
																			{t('Stockpoint')}
																		</Label>
																		<FieldWrap
																			firstSuffix={
																				<Icon
																					icon='HeroMapPin'
																					className='mx-2'
																				/>
																			}
																			lastSuffix={
																				<Icon
																					icon='HeroChevronDown'
																					className='mx-2'
																				/>
																			}>
																			<SelectReact
																				options={
																					stockPoints as TSelectOptions
																				}
																				id={`articles[${index}].StockPointCode`}
																				name={`articles[${index}].StockPointCode`}
																				value={
																					stockPoints.find(
																						(option) =>
																							option.value ===
																							row.StockPointCode,
																					) as TSelectOption
																				}
																				className='ps-10'
																				onChange={(
																					value,
																				) => {
																					const selectedValue =
																						value as TSelectOption;
																					if (
																						selectedValue
																					) {
																						handleStockPointChange(
																							selectedValue.value,
																							row.id,
																						);
																					}
																				}}
																			/>
																		</FieldWrap>
																	</div>
																	<div className='col-span-12 lg:col-span-2'>
																		<Label
																			htmlFor={`articles[${index}].price`}>
																			{t('Price')}
																		</Label>
																		<FieldWrap
																			firstSuffix={
																				<Icon
																					icon='HeroCurrencyEuro'
																					className='mx-2'
																				/>
																			}
																			lastSuffix='kr'>
																			<Input
																				id={`articles[${index}].Price`}
																				name={`articles[${index}].Price`}
																				value={row.Price}
																				readOnly={true}
																				placeholder={t(
																					'Price',
																				)}
																				type='number'
																				min='0.00'
																				step='0.01'
																			/>
																		</FieldWrap>
																	</div>
																	<div className='col-span-12 lg:col-span-2'>
																		<Label
																			htmlFor={`articles[${index}].discount`}>
																			{t('Discount')}
																		</Label>
																		<FieldWrap
																			firstSuffix={
																				<Icon
																					icon='HeroReceiptPercent'
																					className='mx-2'
																				/>
																			}
																			lastSuffix='%'>
																			<Input
																				id={`articles[${index}].Discount`}
																				name={`articles[${index}].Discount`}
																				value={row.Discount}
																				onChange={(
																					event,
																				) => {
																					handleDiscountChange(
																						Number(
																							event
																								.target
																								.value,
																						),
																						row.id,
																					);
																				}}
																				placeholder={t(
																					'Discount',
																				)}
																				type='number'
																				min={0}
																				max={100}
																			/>
																		</FieldWrap>
																	</div>
																	<div className='col-span-12 lg:col-span-2'>
																		<Label
																			htmlFor={`articles[${index}].total`}>
																			{t('Total')}
																		</Label>
																		<FieldWrap
																			firstSuffix={
																				<Icon
																					icon='HeroCalculator'
																					className='mx-2'
																				/>
																			}
																			lastSuffix='kr'>
																			<Input
																				id={`articles[${index}].total`}
																				name={`articles[${index}].total`}
																				value={
																					row.DeliveredQuantity *
																					row.Price *
																					(1 -
																						row.Discount /
																							100)
																				}
																				readOnly={true}
																				placeholder={t(
																					'Total',
																				)}
																				type='number'
																				min='0.00'
																				step='0.01'
																			/>
																		</FieldWrap>
																	</div>
																	{row.articlePackage &&
																		row.articlePackage[0]
																			?.type == 'DYNAMIC' &&
																		row.DeliveredQuantity >
																			0 && (
																			<div className='col-span-12 lg:col-span-5'>
																				<ol className='list-inside list-decimal'>
																					{row?.articlePackage[0]?.packageItems.map(
																						// eslint-disable-next-line @typescript-eslint/no-unused-vars
																						(p, i) => {
																							return (
																								<li
																									key={
																										p.id
																									}>
																									<label>
																										{
																											p
																												?.articleDto
																												?.description
																										}
																									</label>{' '}
																									&nbsp;&nbsp;{' '}
																									<label>
																										Qty
																										-{' '}
																										{parseExpression(
																											row?.DeliveredQuantity,
																											p.operator,
																											p.quantity,
																										)}{' '}
																									</label>
																								</li>
																							);
																						},
																					)}
																				</ol>
																			</div>
																		)}
																</div>
																{_BTN}
															</SortableArticleItemPartial>
														);
													} else {
														return (
															<SortableArticleItemPartial
																key={row.id}
																id={row.id}>
																<div className='grid w-full grid-cols-12 gap-4'>
																	<div className='col-span-12'>
																		<Label
																			htmlFor={`articles[${index}].text`}>
																			<Badge
																				className='me-2'
																				variant='outline'
																				borderWidth='border-0'
																				rounded='rounded-full'>{`${t('Item')} ${row.id}`}</Badge>
																			{t('Text')}
																		</Label>
																		<FieldWrap
																			firstSuffix={
																				<Icon
																					icon='HeroDocumentText'
																					className='mx-2'
																				/>
																			}>
																			<Input
																				id={`articles[${index}].Description`}
																				name={`articles[${index}].Description`}
																				value={
																					row.Description
																				}
																				onChange={(e) =>
																					handleDescriptionChange(
																						e.target
																							.value,
																						row.id,
																					)
																				}
																				placeholder={t(
																					'Text',
																				)}
																			/>
																		</FieldWrap>
																	</div>
																</div>
																{_BTN}
															</SortableArticleItemPartial>
														);
													}
												})}
											</SortableContext>
										</DndContext>
										<div className='col-span-12 flex gap-4'>
											<Button
												variant='outline'
												color='violet'
												icon='HeroTag'
												onClick={() =>
													addArticleItem(
														'article',
														formik.values.InvoiceRows.length + 1,
													)
												}>
												{t('Add Article')}
											</Button>
											<Button
												variant='outline'
												icon='HeroDocumentText'
												onClick={() =>
													addArticleItem(
														'text',
														formik.values.InvoiceRows.length + 1,
													)
												}>
												{t('Add Article Text')}
											</Button>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className='col-span-4 col-start-9'>
							<Card>
								<CardHeader>
									<CardHeaderChild>
										<CardTitle>{t('Summary')}</CardTitle>
									</CardHeaderChild>
								</CardHeader>
								<CardBody>
									<div className='grid grid-cols-12 items-center gap-4'>
										<div className='col-span-3 col-start-1'>
											<Label htmlFor='shipping' className='font-extrabold'>
												{t('Shipping')} :
											</Label>
										</div>
										<div className='col-span-9'>
											<FieldWrap
												firstSuffix={
													<Icon icon='HeroTruck' className='mx-2' />
												}
												lastSuffix='kr'>
												<Input
													id='Freight'
													name='Freight'
													placeholder={t('Shipping')}
													onChange={formik.handleChange}
													value={formik.values.Freight}
													type='number'
													min='0.00'
													step='0.01'
												/>
											</FieldWrap>
										</div>
										<div className='col-span-3 col-start-1'>
											<Label htmlFor='subTotal' className='font-extrabold'>
												{t('Sub Total')} :
											</Label>
										</div>
										<div className='col-span-9 text-end'>
											{priceFormat(orderSummary?.subTotal)}
										</div>
										<div className='col-span-3 col-start-1'>
											<Label htmlFor='tax' className='font-extrabold'>
												{t('Tax')} :
											</Label>
										</div>
										<div className='col-span-9 text-end'>
											{priceFormat(orderSummary?.tax)}
										</div>
										<div className='col-span-12 w-full overflow-hidden text-clip text-transparent underline decoration-indigo-500 decoration-wavy'>
											.......................................................................................................................................................
										</div>
										<div className='col-span-3 col-start-1'>
											<Label
												htmlFor='total'
												className='!text-2xl font-extrabold'>
												{t('Total')} :
											</Label>
										</div>
										<div className='col-span-9 text-end text-2xl font-extrabold'>
											{priceFormat(orderSummary?.total)}
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className='col-span-12'>
							<Card className='sticky bottom-0'>
								<CardFooter>
									<CardFooterChild>
										<Visible is={!!formik.values.CustomerName}>
											<span className='font-extrabold'>
												{t('Customer Name')}:
											</span>
											<span>{formik.values.CustomerName}</span>
										</Visible>
										<Visible is={dayjs(formik.values.InvoiceDate).isValid()}>
											<span className='font-extrabold'>
												{t('Offer Date')}:
											</span>
											<span>
												{dayjs(formik.values.InvoiceDate)
													.locale(i18n.language)
													.format('LL')}
											</span>
										</Visible>
									</CardFooterChild>
									<CardFooterChild>{_SAVE_BTN}</CardFooterChild>
								</CardFooter>
							</Card>
						</div>
					</div>
				</Container>
			</PageWrapper>
		</>
	);
};

export default InvoiceEditPartial;
