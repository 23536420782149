import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { settingsPages } from '../../config/pages.config';
import { AppDataProvider } from '../../context/appDataContext';

const SettingsMainPage = lazy(() => import('../../pages/settings/SettingsMain.page'));

/**
 * Erp
 */
const FortnoxConfigPage = lazy(() => import('../../pages/settings/ERP.page'));

/**
 * Site Settings
 */
const BrandingPage = lazy(() => import('../../pages/settings/siteSettings/Branding.page'));
const LanguagesPage = lazy(
	() => import('../../pages/settings/siteSettings/Languages/LanguagesList.page'),
);
const LanguageWordsListPage = lazy(
	() => import('../../pages/settings/siteSettings/Languages/LanguageWordsList.page'),
);
const LanguagesEditPage = lazy(
	() => import('../../pages/settings/siteSettings/Languages/LanguagesEdit.page'),
);
const DateTimePage = lazy(() => import('../../pages/settings/siteSettings/DateTime.page'));
const CurrenciesPage = lazy(
	() => import('../../pages/settings/siteSettings/Currency/Currencies.page'),
);
const CurrenciesListPage = lazy(
	() => import('../../pages/settings/siteSettings/Currency/CurrenciesList.page'),
);
const EmailPage = lazy(() => import('../../pages/settings/siteSettings/Email.page'));

/**
 * Data Sync
 */
const SyncJobsPage = lazy(() => import('../../pages/settings/dataSync/FullSync.page'));
const WebsocketLogPage = lazy(() => import('../../pages/settings/dataSync/WebsocketLog.page'));

/**
 * Articles
 */
const ArticleSettingsPage = lazy(
	() => import('../../pages/settings/articles/ArticleSettings.page'),
);
const ArticlesFieldsPage = lazy(() => import('../../pages/settings/Fields/Fields.page'));
const ArticlesFieldsEditPage = lazy(() => import('../../pages/settings/Fields/FieldsEdit.page'));
const ArticlesTabsPage = lazy(
	() => import('../../pages/settings/articles/ArticleTabs/ArticlesTabs.page'),
);
const ArticleTabsViewPage = lazy(
	() => import('../../pages/settings/articles/ArticleTabs/ArticleTabsView.page'),
);
const ArticleTabsFieldsPage = lazy(
	() => import('../../pages/settings/articles/ArticleTabs/ArticleTabsFields.page'),
);

const ArticlesTemplatesPage = lazy(
	() => import('../../pages/settings/articles/ArticleTemplates/ArticlesTemplates.page'),
);
const ArticleTemplatesViewPage = lazy(
	() => import('../../pages/settings/articles/ArticleTemplates/ArticleTemplatesView.page'),
);
const ArticleTemplatesFieldsPage = lazy(
	() => import('../../pages/settings/articles/ArticleTemplates/ArticleTemplatesFields.page'),
);
const ManualImportExportPage = lazy(
	() => import('../../pages/settings/articles/ManualImportExport.page'),
);

/**
 * Projects
 */
const ProjectSettingsPage = lazy(() => import('../../pages/settings/project/ProjectSettings.page'));
// const ProjectFieldsPage = lazy(
// 	() => import('../../pages/settings/project/ProjectFields/ProjectFields.page'),
// );
// const IProjectFieldsResponse = lazy(
// 	() => import('../../pages/settings/project/ProjectFields/ProjectFieldsEdit.page'),
// );
const ProjectTemplatesPage = lazy(
	() => import('../../pages/settings/project/ProjectTemplates/ProjectTemplates.page'),
);
const ProjectTemplatesViewPage = lazy(
	() => import('../../pages/settings/project/ProjectTemplates/ProjectTemplatesView.page'),
);
const ProjectTemplatesFieldsPage = lazy(
	() => import('../../pages/settings/project/ProjectTemplates/ProjectTemplatesFields.page'),
);

/**
 * Tasks
 */
const TaskCategoriesPage = lazy(
	() => import('../../pages/settings/tasks/TasKCategories/TaskCategories.page'),
);
const TaskCategoriesEditPage = lazy(
	() => import('../../pages/settings/tasks/TasKCategories/TaskCategoriesEdit.page'),
);
const PrioritiesPage = lazy(() => import('../../pages/settings/tasks/Priorities/Priorities.page'));
const PrioritiesEditPage = lazy(
	() => import('../../pages/settings/tasks/Priorities/PrioritiesEdit.page'),
);
const TimeslipSettings = lazy(() => import('../../pages/settings/timeslips/TimeslipSettings.page'));
const TimeslipsTypes = lazy(
	() => import('../../pages/settings/timeslips/TimeslipsTypes/TimeslipsTypes.page'),
);
const TimeslipsTypesEditPage = lazy(
	() => import('../../pages/settings/timeslips/TimeslipsTypes/TimeslipsTypesEdit.page'),
);

/**
 * Content
 */
const ContentCategoriesPage = lazy(
	() => import('../../pages/settings/content/ContentCategories/ContentCategories.page'),
);
const ContentCategoriesEditPage = lazy(
	() => import('../../pages/settings/content/ContentCategories/ContentCategoriesEdit.page'),
);

const sRoutes: RouteProps[] = [
	{
		path: settingsPages.settingsMainPages.to,
		element: <SettingsMainPage />,
	},

	/**
	 * Erp
	 */
	{
		path: settingsPages.settingsMainPages.subPages.erpPages.to,
		element: <FortnoxConfigPage />,
	},

	/**
	 * Site Settings
	 */
	{
		path: settingsPages.settingsMainPages.subPages.siteSettingsPages.subPages.brandingPages.to,
		element: <BrandingPage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.siteSettingsPages.subPages.languagesPages.to,
		element: <LanguagesPage />,
	},
	{
		path: `${
			settingsPages.settingsMainPages.subPages.siteSettingsPages.subPages.languagesPages.to
		}/:lang`,
		element: <LanguageWordsListPage />,
	},
	{
		path: `${
			settingsPages.settingsMainPages.subPages.siteSettingsPages.subPages.languagesPages.to
		}/:lang/:key`,
		element: <LanguagesEditPage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.siteSettingsPages.subPages.dateTimePages.to,
		element: <DateTimePage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.siteSettingsPages.subPages.currenciesPages
			.to,
		element: <CurrenciesPage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.siteSettingsPages.subPages.currenciesPages
			.subPages.currenciesListPages.to,
		element: <CurrenciesListPage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.siteSettingsPages.subPages.emailPages.to,
		element: <EmailPage />,
	},

	/**
	 * Data Sync
	 */
	{
		path: settingsPages.settingsMainPages.subPages.dataSyncPages.subPages.fullSyncPages.to,
		element: <SyncJobsPage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.dataSyncPages.subPages.websocketLogPages.to,
		element: <WebsocketLogPage />,
	},

	/**
	 * Articles
	 */
	{
		path: settingsPages.settingsMainPages.subPages.articlesPages.subPages.settingsPages.to,
		element: <ArticleSettingsPage />,
	},
	{
		path: `${settingsPages.settingsMainPages.to}/:type/fields`,
		element: <ArticlesFieldsPage />,
	},
	{
		path: `${settingsPages.settingsMainPages.to}/:type/fields/:id`,
		element: <ArticlesFieldsEditPage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.articlesPages.subPages.tabsPages.to,
		element: <ArticlesTabsPage />,
	},
	{
		path: `${
			settingsPages.settingsMainPages.subPages.articlesPages.subPages.tabsPages.to
		}/fields/:id`,
		element: <ArticleTabsFieldsPage />,
	},
	{
		path: `${
			settingsPages.settingsMainPages.subPages.articlesPages.subPages.tabsPages.to
		}/view/:id`,

		element: <ArticleTabsViewPage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.articlesPages.subPages.templatesPages.to,
		element: <ArticlesTemplatesPage />,
	},
	{
		path: `${
			settingsPages.settingsMainPages.subPages.articlesPages.subPages.templatesPages.to
		}/fields/:id`,
		element: <ArticleTemplatesFieldsPage />,
	},
	{
		path: `${
			settingsPages.settingsMainPages.subPages.articlesPages.subPages.templatesPages.to
		}/view/:id`,
		element: <ArticleTemplatesViewPage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.articlesPages.subPages.importExportDataPages
			.to,
		element: <ManualImportExportPage />,
	},

	/**
	 * Projects
	 */
	{
		path: settingsPages.settingsMainPages.subPages.projectsPages.subPages.settingsPages.to,
		element: <ProjectSettingsPage />,
	},
	{
		path: `${settingsPages.settingsMainPages.to}/:type/fields`,
		element: <ArticlesFieldsPage />,
	},
	{
		path: `${settingsPages.settingsMainPages.to}/:type/fields/:id`,
		element: <ArticlesFieldsEditPage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.projectsPages.subPages.templatesPages.to,
		element: <ProjectTemplatesPage />,
	},
	{
		path: `${
			settingsPages.settingsMainPages.subPages.projectsPages.subPages.templatesPages.to
		}/fields/:id`,
		element: <ProjectTemplatesFieldsPage />,
	},
	{
		path: `${
			settingsPages.settingsMainPages.subPages.projectsPages.subPages.templatesPages.to
		}/view/:id`,
		element: <ProjectTemplatesViewPage />,
	},

	/**
	 * Tasks
	 */
	{
		path: settingsPages.settingsMainPages.subPages.tasksPages.subPages.categoriesPages.to,
		element: <TaskCategoriesPage />,
	},
	{
		path: `${
			settingsPages.settingsMainPages.subPages.tasksPages.subPages.categoriesPages.to
		}/:id`,
		element: <TaskCategoriesEditPage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.tasksPages.subPages.prioritiesPages.to,
		element: <PrioritiesPage />,
	},
	{
		path: `${
			settingsPages.settingsMainPages.subPages.tasksPages.subPages.prioritiesPages.to
		}/:id`,
		element: <PrioritiesEditPage />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.timeslipsPages.subPages
			.timeslipsSettingsPages.to,
		element: <TimeslipSettings />,
	},
	{
		path: settingsPages.settingsMainPages.subPages.timeslipsPages.subPages.timeslipsTypesPages
			.to,
		element: <TimeslipsTypes />,
	},
	{
		path: `${
			settingsPages.settingsMainPages.subPages.timeslipsPages.subPages.timeslipsTypesPages.to
		}/:id`,
		element: <TimeslipsTypesEditPage />,
	},

	/**
	 * Content
	 */
	{
		path: settingsPages.settingsMainPages.subPages.content.subPages.categoriesPages.to,
		element: <ContentCategoriesPage />,
	},
	{
		path: `${settingsPages.settingsMainPages.subPages.content.subPages.categoriesPages.to}/:id`,
		element: <ContentCategoriesEditPage />,
	},
];

const settingsRoutes = sRoutes.map((route) => ({
	...route,
	element: <AppDataProvider>{route.element}</AppDataProvider>,
}));

export default settingsRoutes;
